import BackButton from './components/BackButton.js';
import { Outlet, useLocation } from 'react-router-dom';

const ContentRoutes = (props) => {
  const location = useLocation();
  const lobbyingMaterialPages = ['policy-asks', 'briefing-material', 'news-release', 'research'];
  const pathnames = [...lobbyingMaterialPages, 'stakeholders'];
  const detectDifferentLayout = ({ pathnames }) => {
    for (let i = 0; i < pathnames.length; i++) {
      let pathname = pathnames[i];
      if (location.pathname.startsWith(`/${pathname}`)) {
        return true;
      }
    }
  };
  const differentLayoutPage = detectDifferentLayout({ pathnames });

  return (
    <div
      className={`mx-auto w-100 ${
        detectDifferentLayout({ pathnames: lobbyingMaterialPages }) ? '' : 'main-content-wrapper'
      } ${
        !location.pathname.includes('stakeholders') ? 'main-content-wrapper-content' : 'flex-grow-1'
      } position-relative`}
    >
      {!differentLayoutPage && <BackButton />}
      <div className={`h-100 ${differentLayoutPage ? '' : 'key-update-content pt-lg-5'}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default ContentRoutes;
