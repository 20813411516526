//https://stackoverflow.com/questions/54892403/usereducer-action-dispatched-twice
import React, { createContext, useReducer } from 'react';
import { storageSupported } from './Dashboard/helpers/validateLocalStorage';
import getUrlParam from './Dashboard/utilities/getUrlParam';
import { createAnalyticsState } from './Dashboard/Analytics/createAnalyticsState';

const gimmicks = ['test', 'hi', 'hello', 'hola'];
const parameter = getUrlParam('search');
const listStorage = storageSupported ? localStorage.getItem('keywordList') !== null : null;
const searchFromUrl = (parameter !== undefined && !gimmicks.includes(parameter.split('#')[0])) || listStorage;
const net_api_url = `${process.env.REACT_APP_DOTNETAPIURL}`;
const app_url = `${process.env.REACT_APP_APPURL}`;
export const initialCrmEmailState = () => ({
  crmEmailId: null,
  from: '',
  sendRecords: [],
  emailDomainId: null,
  subject: '',
  body: `<p style="font-family: Lato, sans-serif; font-size: 15px; margin: 0; color: #00122b;">Dear&nbsp;<img id="salutation_text" style="pointer-events: none; margin-bottom: -6px;" contenteditable="false" src="https://cdn.policymogul.com/images/salutation_small.svg" alt="salutation">,&nbsp;</p>\n<p style="font-family: Arial, Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; font-size: 15px; margin: 0; color: #00122b;">&nbsp;</p>`,
  toList: [],
});
const initialState = {
  net_api_url: `${process.env.REACT_APP_DOTNETAPIURL}`,
  app_url: `${process.env.REACT_APP_APPURL}`,
  isHiddenMenu: true,
  activeResults: searchFromUrl ? 'searchResults' : 'allResults',
  consultations: {},
  upComingEvents: {},
  gimmickWord: '',
  search: {
    searchState: false,
    activeSearch: false,
    gimmicks: gimmicks,
    query: '',
    list: null,
  },
  contentResults: {
    allResults: {},
    searchResults: {},
  },
  showRelevantExtract: {
    selectedOptionRelevantExtract:
      storageSupported && !!localStorage?.getItem('showRelevantExtract')
        ? localStorage?.getItem('showRelevantExtract')
        : 'Most relevant extract',
  },
  locationToStorage: '',
  hash: {},
  settings: {
    createKeywordFromAlert: '',
    keywordId: '',
    listToEdit: '',
  },
  keywordsLists: [],
  InitialKeywordsState: {},
  IsSavingKeywordList: false,
  remainingKeywords: 0,
  activePlan: {},
  activeSubscriptions: {},
  userProfile: {},
  team: {},
  emailAlerts: [],
  clientsAlerts: [],
  clients: [],
  trialBannerHeight: 0,
  listPosition: null,
  loadingNumbers: true,
  contentFilters: {},
  clearFilters: false,
  additionalFilters: {},
  unseenNumbersCurrentList: {},
  modifiedListId: null,
  readyKeywordLists: false,
  totalNumbers: {},
  referenceState: {
    activeReference: false,
    keywords: [],
    andKeywords: [],
    keywordExclusions: [],
  },
  openResearchPopup: false,
  clickedOnContentUrl: null,
  searchResultsReturnUrl: [],
  clearValueSimpleSearchBar: false,
  processingMarkItemAsRead: false,
  useLocalStorageForReference: false,
  userSavedItemsFolders: [],
  saveItemPopup: {
    showPopup: false,
    item: null,
    listItem: false,
  },
  saveItemsIds: [],
  reloadSavedItemInformation: null,
  scrollPositionSavedItems: null,
  analyticsState: createAnalyticsState(),
  chartState: {},
  memoryMasterTopic: false,
  directLoadSentimentPage: true,
  lastSeenMarkers: {},
  itemsViewed: [],
  promptFromWritingAssistant: null,
  feedbackItemPopup: {
    isOpen: false,
    isHandPicked: false,
    ratingScored: null,
    item: null,
    index: -1,
    keywords: [],
  },
  enforceReloadFeed: false,
  topicForReport: null,
  updateToCreateReport: null,
  autofocusCommentary: false,
  temporaryReportState: null,
  changeOfGraphFilters: false,
  analyticsHubSearchQuery: null,
  analyticsRateLimitReached: false,
  changesOnReports: false,
  clientToSwitchOnReports: null,
  targetClient: null,
  readySetCurrentTeam: null,
  dispatchSearchForClients: null,
  changingClientFinished: false,

  currentEmailDetails: initialCrmEmailState(),
  listDomainsVerified: {
    verifiedDomains: [],
    allDomains: [],
    verifiedEmailAddresses: [],
    allEmailAddresses: [],
    domainsProccessing: true,
  },
  allStakeholders: [],
  emailStateAfterSave: initialCrmEmailState(),
  emailStateAfterSavePreviewMode: {},
  emailSelectedContacts: [],
  engagementStatisticsResult: [],
  selectedStakeholders: {
    selectedAll: false,
    selectedAllVisible: [],
    selectedIds: [],
  },
  selectedCrmContacts: {
    selectedAll: false,
    selectedAllVisible: [],
    selectedIds: [],
  },
  selectedGroups: {
    selectedAll: false,
    selectedIds: [],
  },
  previousStoredSelectedCrmContacts: {
    selectedAll: false,
    selectedAllVisible: [],
    selectedIds: [],
  },
  hideInfluenceSidebar: false,
  engagementStatisticsOnLoad: true,
  addedCustomContacts: [],
  crmContactsTableOnSort: { sort: '' },
  crmEmailsTableOnSort: { sort: '' },
  showAddContactModal: false,
  availableStakeholders: null,
  contactIdsCheck: {},
  crmContactsTotalHits: null,
  showAddTagsModalCrmContacts: { show: false, preselectedContact: undefined },
  totalEmailsCount: 0,
  teamContentResultsQntty: 0,
  teamContentFilter: null,
  updateCustomContactTitle: { firstName: '', lastName: '' },
  showRemoveContactsModal: false,
  showRemoveContactTagsModal: false,
  activeTabEmailSent: 'Email',
  emailPerformanceResult: [],
  emailPerformanceOnLoad: true,
  newContactFromSelect: null,
  emailAddresses: [],
  removedContactsQntty: null,
  searchEmailsString: '',
  deleteStakeholderId: null,
  crmContactsQtty: null,
  showContactsOversubscribedModal: false,
  shownInnitiallyContactsOversubscribed: false,
  showContactsOversubscribedConfirmModal: false,
  showContactsOversubscribedInTrialModal: false,
  showChangeChampionStatusModal: false,
  newlyAddedContacts: [],
  engagementStatisticItem: {},
  contactsTags: [],
  contactOnGroupUpdated: null,
  showTailorSummaryModal: false,
  initialOpenAiSummary: false,
  stakeholderMobileSection: '',
  loadingCreateSummary: false,
  updateFilterFromStore: null,
  selectedContactTags: [],
  showStakeholderCrmPopup: false,
  showContactsPopup: { show: false },
  customContactInfo: null,
  emailComponentOnLoad: true,
  showSendPreviewPopUp: false,
  group: null,
  remainingEmailCampaigns: null,
};
const store = createContext(initialState);
const { Provider } = store;

function reducer(state, action) {
  switch (action.type) {
    case 'MODIFY_SECTION':
      //return {...state, [action.parameter]: action.value}
      let newStateSection = Object.assign(state);
      newStateSection[action.parameter] = action.value;
      return { ...state, newStateSection };

    case 'SHOW_HIDE_MENU':
      return { ...state, isHiddenMenu: action.value };
    default:
      throw new Error();
  }
}

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider, net_api_url, app_url };
