import { useContext } from 'react';
import { store } from '../../../Store';
import RegionFilters from '../../Filters/FiltersData/RegionFilters';
import { PartyOptions, PositionOptions, RoleOptions } from '../../Filters/FiltersData/StakeholdersFilters';
import { ChampionStatusOptions } from '../tableComponents/ChampionStatus';
import { useChangeKeywordsListsOrder } from '../../hooks/useSearchBarUiFunctions';
const useGroupFiltersData = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({
    keywordsLists: keywordsLists?.filter((item) => item?.id !== null),
  });

  const GroupsFiltersData = {
    categories: [
      {
        name: 'Vocal about...',
        filterName: 'topicMatchedKeywordListIds',
        subtext: `(e.g ${changeKeywordsListsOrder()?.[0]?.name ?? 'immigration'})`,
        type: 'picker',
        placeholder: 'Select a topic',
      },
      {
        name: 'Role',
        filterName: 'categories',
        options: RoleOptions,
        type: 'select',
        subtext: '(e.g. MPs, peers, special advisers)',
        placeholder: 'Select roles',
      },
      {
        name: 'Position',
        filterName: 'positionCategories',
        options: PositionOptions,
        type: 'select',
        subtext: '(e.g. Cabinet, Shadow Cabinet)',
        placeholder: 'Select positions',
      },
      {
        name: 'Party',
        options: PartyOptions,
        filterName: 'party',
        type: 'select',
        subtext: '(e.g. Labour, Conservative)',
        placeholder: 'Select parties',
      },
      {
        name: 'Champion status',
        filterName: 'championStatus',
        options: ChampionStatusOptions?.filter((item) => item?.name !== 'No status'),
        type: 'select',
        subtext: '(e.g. promoter, detractor)',
        placeholder: 'Select champion status',
      },
      {
        name: 'Sentiment',
        filterName: 'topicMatchedKeywordListIds',
        subtext: `(e.g positive about ${changeKeywordsListsOrder()?.[0]?.name ?? 'immigration'})`,
        type: 'selectSentiment',
        placeholder: 'Select a topic',
        options: [{ name: 'Positive' }, { name: 'Negative' }, { name: 'Mixed' }, { name: 'Neutral' }],
      },
      {
        name: 'Tags',
        type: 'tags',
        filterName: 'tags',
      },
      {
        name: 'Region',
        filterName: 'region',
        options: RegionFilters?.[0]?.options,
        type: 'select',
        placeholder: 'Select regions',
      },
      {
        name: 'Select Committee',
        type: 'membership',
        subtype: 'MembershipOfSelectCommittees',
        filterName: 'selectCommittees',
      },
      {
        name: 'Lords Select Committee',
        type: 'membership',
        subtype: 'MembershipOfLordsSelectCommittees',
        filterName: 'lordsSelectCommittees',
      },
      {
        name: 'APPG',
        type: 'membership',
        subtype: 'MembershipOfAPPGs',
        filterName: 'allPartyGroups',
      },
    ],
    operators: [
      /* { name: 'is equal to', value: 'str_eq', type: 'single' },
      { name: 'is not equal to', value: 'str_not_eq', type: 'single' }, */
      {
        name: 'is equal to any of',
        format: () => <>is equal to any of</>,
        value: 'str_eq',
        type: 'multiselect',
      },
      {
        name: 'is not equal to any of',
        format: () => <>is not equal to any of</>,
        value: 'str_not_eq',
        type: 'multiselect',
      },
      {
        name: 'is known',
        format: () => <>is known</>,
        value: 'exists',
        type: 'not_value_filter',
      },
      {
        name: 'is not known',
        format: () => <>is not known</>,
        value: 'not_exists',
        type: 'not_value_filter',
      },
    ],
    topicDates: [
      {
        name: '365 days',
        filterValue: '12mo',
      },
      {
        name: '90 days',
        filterValue: '3mo',
      },
      {
        name: '30 days',
        filterValue: '1mo',
      },
      /* {
        name: '7 days',
        filterValue: '1w',
      }, */
    ],
  };
  return { GroupsFiltersData };
};

export default useGroupFiltersData;
