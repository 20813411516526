import React, { useContext, useCallback, useState, useRef, useEffect } from 'react';
import Share from '../components/Share.js';
import { store } from '../../Store.js';
import { createRoot } from 'react-dom/client';
import { useNavigate, useParams } from 'react-router-dom';
import StakeholderHoverLink from '../components/StakeholderHoverLink.js';
import useIsMobile from '../utilities/useIsMobile.js';
import getUrlParam from '../utilities/getUrlParam.js';
import ThumbsDown from '../../Common/ThumbsDown.js';
import FeedbackOnItem from '../FeedbackItem/FeedbackOnItem.js';
import TextSelector from '../../Common/TextSelector.js';
import ContentSummary from '../components/ContentSummary.js';
const dayjs = require('dayjs');

const ShareButtonRender = (props) => {
  const { currentNew, shareButtonWidget, embed } = props;
  const { id, hansardData, title, questionFor, canonicalUrl, objectId, objectType, edmSignatories } = currentNew;
  let item = {
    id,
    title: hansardData || edmSignatories ? title : questionFor,
    objectID: objectId,
    type: objectType,
  };
  const ShareOptions = {
    item,
    id: item.id,
    title: item.title,
    shareButtonWidget,
    itemShare: embed,
  };
  if (canonicalUrl) {
    ShareOptions.link = `${process.env.REACT_APP_APPURL}${canonicalUrl}`;
  }
  return <Share {...ShareOptions} />;
};

const CommitteePublicationNewContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, contentResults, activeResults } = state;

  const { CommitteePublications } = contentResults[activeResults];

  const { activeSearch } = search;
  const {
    currentNew,
    scrollBarRef,
    shareButtonWidget,
    contentContainer,
    isScrolling,
    reportWidget,
    embed,
    hideSummary,
  } = props;

  const navigate = useNavigate();
  const params = useParams();
  const { type } = params;

  const isMobile = useIsMobile();
  const [showFixedTitle, setShowFixedTitle] = useState(window.location.hash.includes('contribution-'));
  const createMarkup = (body) => {
    return { __html: body };
  };
  const itemClass = () => {
    switch (currentNew?.hansardData?.type?.toLowerCase()) {
      case 'commons':
        return 'legislation-commons';
      case 'lords':
        return 'legislation-lords';
      default:
        return 'legislation-grey';
    }
  };
  const renderTitle = () => {
    let title;
    if (!!currentNew.titleHighlighted && (activeSearch || shareButtonWidget || getUrlParam('search'))) {
      title = currentNew.titleHighlighted;
    } else {
      title = currentNew.title;
    }
    return title;
  };

  const contributionContainer = useCallback((node) => {
    if (node !== null) {
      let { width } = node.getBoundingClientRect();
      const adjustPDFScale = () => {
        let elements = document.querySelectorAll("div[id^='page'][id$='-div']");
        if (elements.length > 1) {
          for (let i = 0; i < elements.length; i++) {
            let item = elements[i];
            let { width: itemWidth, height: itemHeight } = item.getBoundingClientRect();
            let ratio = width / itemWidth;
            let widthDiff = itemWidth - width - 61;
            let heightDiff = itemHeight * (1 - ratio);
            if (ratio && !isNaN(ratio) && ratio < 1) {
              item.style.transform = `scale(${ratio})`;
              item.style.marginLeft = `-${widthDiff * ratio}px`;
              if (i !== 0) {
                item.style.marginTop = `-${heightDiff}px`;
              } else {
                item.style.marginTop = `-${heightDiff * ratio - 80}px`;
              }
            }
          }
        }
      };
      const addTargetToAnchorElems = () => {
        let elements = document.querySelectorAll('a');
        if (elements.length > 1) {
          for (let i = 0; i < elements.length; i++) {
            let item = elements[i];
            item.setAttribute('target', '_blank');
          }
        }
      };
      adjustPDFScale();
      addTargetToAnchorElems();
    }
  }, []);

  const parliamentaryContentContainer = useCallback((node) => {
    if (node !== null && !isMobile) {
      const createStakeholdersComponent = () => {
        let elements = document.querySelectorAll('.news-content-in a');
        for (let i = 0; i < elements.length; i++) {
          let item = elements[i];
          if (!!item.href) {
            let urlItem = new URL(item.href);
            let pathname = urlItem.pathname;
            if (
              (!item.classList?.contains('edm-link') && pathname && pathname.startsWith('/stakeholders')) ||
              pathname.startsWith('/monitor/stakeholders') ||
              pathname.startsWith('/influence/stakeholders') ||
              pathname.startsWith('/stakeholder-mapping')
            ) {
              const timeStamp = item.querySelector('.time');
              if (timeStamp) {
                timeStamp.parentNode.removeChild(timeStamp);
              }
              const stakeholderLink = document.createElement('span');
              const el = createRoot(stakeholderLink);
              el.render(
                <StakeholderHoverLink
                  contactId={pathname.match(/[0-9]+/)[0]}
                  name={item.textContent}
                  contentContainer={contentContainer}
                  navigate={navigate}
                />
              );
              item.parentNode.replaceChild(stakeholderLink, item);
            }
          }
        }
      };
      createStakeholdersComponent();
    }
  }, []);

  const titleSection = useRef(null);
  let observer = useRef();
  let createObserver = useRef();
  createObserver.current = () => {
    if (!!window.IntersectionObserver) {
      observer.current = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (titleSection.current !== null) {
              setShowFixedTitle(!entry.isIntersecting);
            }
          });
        },
        { rootMargin: '0px 0px 0px 0px' }
      );
      observer.current.observe(titleSection.current);
    }
  };

  const createStyles = () => {
    if (!!contentContainer.current) {
      const { width, left, top } = contentContainer.current.getBoundingClientRect();
      let borderValue = 1;
      let styles = {
        position: 'fixed',
        top: top,
        left: left + borderValue,
        width: width - borderValue * 2,
      };
      return styles;
    }
  };
  const [titleStyleRules, setTitleStyleRules] = useState(createStyles());
  const updateStyles = () => {
    setTitleStyleRules(createStyles());
  };

  useEffect(() => {
    if (createObserver.current) {
      createObserver.current();
    }
    window.addEventListener('resize', updateStyles);
    return () => {
      window.removeEventListener('resize', updateStyles);
      if (!!observer.current && titleSection.current !== null) {
        observer.current.unobserve(titleSection.current);
      }
    };
  }, []);

  const alreadyMarkedFeedback = !!currentNew?.feedback;
  const index = CommitteePublications
    ? CommitteePublications?.hits?.findIndex((item) => item.id === currentNew.id)
    : null;
  const ThumbsDownProperties = {
    keywordsFromLists: currentNew?.keywordsFromLists,
    item: { objectID: currentNew?.objectId },
    nameSection: 'CommitteePublications',
    show: !shareButtonWidget && !!currentNew?.keywordsFromLists,
    embedFeed: embed,
    contentPage: true,
    alreadyMarked: alreadyMarkedFeedback,
    thick: true,
  };

  const titleRef = useCallback((el) => {
    if (el && el?.scrollHeight > el?.clientHeight + 5) {
      el.setAttribute('data-tooltip-class-name', 'header-tooltip pricing-tooltip-description table-item-cell-tooltip');
      el.setAttribute('data-tooltip-content', el?.innerText);
      el.setAttribute('data-tooltip-id', 'general-tooltip');
    }
  }, []);

  return (
    <>
      <div ref={titleSection}>
        <div
          className={`section-title pb-2 ${reportWidget ? 'content-title-in-widget' : 'content-title-with-summary'} content-border-title`}
        >
          <h3
            className='main-title news-title bg-white content-item-title'
            dangerouslySetInnerHTML={createMarkup(renderTitle())}
            ref={titleRef}
          />
          <div className='row mx-0 justify-content-between align-items-center'>
            <p className='heading-date mb-0'>
              {(currentNew.house || currentNew.contentType) && (
                <>
                  <span className={`${itemClass()}`}>{currentNew.house || currentNew.contentType}</span>
                  <span className='dot'></span>
                </>
              )}
              {currentNew.committee ? (
                <>
                  {currentNew.committee}
                  <span className='dot'></span>
                </>
              ) : (
                ''
              )}
              {currentNew.publicationType ? (
                <>
                  {currentNew.publicationType}
                  <span className='dot'></span>
                </>
              ) : (
                ''
              )}
              <span className='date'>{dayjs(currentNew.dateTime).format('DD MMMM YYYY')}</span>
              {!!currentNew.sourceUrl && (
                <>
                  <span className='dot'></span>
                  <a
                    className='legislation-source mb-0'
                    href={currentNew.sourceUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Go to source
                  </a>
                </>
              )}
            </p>
            <div className='flex-centered'>
              <ThumbsDown {...ThumbsDownProperties} />
              <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} embed={embed} />
            </div>
          </div>
          {currentNew.tabledOn && <p className='heading-date mb-2'>Tabled in the {currentNew.session} session</p>}
        </div>
      </div>

      {alreadyMarkedFeedback && (
        <div className='debate-item-contributiondebateitem-padding px-0 pb-0'>
          <FeedbackOnItem
            contentPage={true}
            item={{ objectID: currentNew?.objectId }}
            feedback={currentNew?.feedback}
            keywordsFromLists={currentNew?.keywordsFromLists}
            index={index}
            customClass={'px-3 px-lg-4 content-border-title'}
          />
        </div>
      )}
      <ContentSummary content={currentNew} hide={hideSummary} />
      <div className='pb-3 pb-lg-4 pb-lg-0 specific-content-container'>
        {/* FIXED TITLE */}
        <div
          className={`content-item-scrolled-title main-sticky section-title bg-white parliamentary-scrolled-title ${
            showFixedTitle && !reportWidget ? 'item-scrolled-title-visible' : ''
          }`}
          style={titleStyleRules}
        >
          <div className='d-flex justify-content-between scrolled-title-container'>
            <h3
              className='main-title news-title bg-white content-item-title'
              dangerouslySetInnerHTML={createMarkup(renderTitle())}
              ref={titleRef}
            />
            <ThumbsDown {...ThumbsDownProperties} />
            <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} />
          </div>
        </div>
        {/* END FIXED TITLE */}
        <TextSelector currentNew={currentNew} scrollbarRef={scrollBarRef} scrolling={isScrolling}>
          <div
            className={`py-3 news-content-in parliamentary-content-in context ${
              type === 'hansard-content' ? 'parliamentary-hansard-content' : ''
            } ${type === 'senedd-committee-transcript' ? 'parliamentary-senedd-committee-transcript-content' : ''}${
              type === 'welsh-official-report' ? 'welsh-official-report-content' : ''
            }`}
            ref={parliamentaryContentContainer}
          >
            {currentNew.body && (
              <div className='debate-item-contributiondebateitem'>
                <div
                  className='explanatory-container'
                  dangerouslySetInnerHTML={createMarkup(currentNew.body)}
                  ref={contributionContainer}
                />
              </div>
            )}
          </div>
        </TextSelector>
      </div>
    </>
  );
};

export default CommitteePublicationNewContent;
