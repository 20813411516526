import React, { useContext, useEffect, useRef } from 'react';
import Navigation from './Navigation/index.js';
import InitialValidations from '../InitialValidations';
import { store } from '../Store.js';
import { useAuth0 } from '@auth0/auth0-react';
import GeneralTooltips from './components/GeneralTooltips';
import SplitBanner from './Navigation/SplitBanner';
import SubNav from './SubNav';
import Loading from '../Login/Loading';
import SaveItemPopup from '../SavedItems/SaveItemPopup';
import ContactsOversubscribedModal from './components/ContactsOversubscribedConfirmModal.js';
import useValidateContactsOversubscribed from './utilities/useValidateContactsOversubscribed';
import ContactsOversubscribedConfirmModal from './components/ContactsOversubscribedConfirmModal';
import ContactsOversubscribedInTrialModal from './components/ContactsOversubscribedInTrialModal';
import useDashboardInitialController from './SearchControl/useDashboardInitialController';
import FeedbackItemPopup from './FeedbackItem/FeedbackItemPopup';
import StakeHolderSharePopUp from './StakeHolders/StakeHolderSharePopUp.js';
import ClientLoadingPage from './SearchControl/ClientLoadingPage.js';

import { Outlet, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const Dashboard = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    search,
    contentResults,
    activePlan,
    showContactsOversubscribedModal,
    showContactsOversubscribedConfirmModal,
    showContactsOversubscribedInTrialModal,
    readyKeywordLists,
    targetClient,
    readySetCurrentTeam,
    dispatchSearchForClients,
  } = state;
  const { isAuthenticated } = useAuth0();

  const params = useParams();
  const location = useLocation();
  const mainSection = location?.pathname === '/influence/stakeholder-mapping' ? 'stakeholders' : params?.mainSection;

  const { loadKeywordsLists, dispatchSearch, isLoading } = useDashboardInitialController();
  // SEARCH FUNCTIONS
  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      if (readySetCurrentTeam === null || readySetCurrentTeam === true || !isAuthenticated) {
        loadKeywordsLists.current(source);
      }
    } catch (error) {}
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);

    return () => {
      source.cancel('Keywords lists cancelled by the user');
    };
  }, [activePlan, readySetCurrentTeam]);

  useEffect(() => {
    //AE:Conditions when switching clients
    if (readyKeywordLists && (dispatchSearchForClients === null || dispatchSearchForClients === true)) {
      dispatchSearch.current();
    }
  }, [mainSection, readyKeywordLists, dispatchSearchForClients]);

  const {
    setShowContactsOversubscribedModal,
    setShowContactsOversubscribedConfirmModal,
    setShowContactsOversubscribedInTrialModal,
  } = useValidateContactsOversubscribed();

  useEffect(() => {
    return () => {
      if (!isAuthenticated) {
        let stateForResults = { ...contentResults, searchResults: {} };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'search',
          value: {
            ...search,
            query: '',
            activeSearch: false,
            searchState: true,
          },
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'activeResults',
          value: 'allResults',
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: stateForResults,
        });
      }
    };
  }, []);

  const analyticsPage = !isAuthenticated && location.pathname.startsWith('/data-hub');

  return (
    <>
      <InitialValidations />
      <div className='container-fluid px-0 dashboard-root-container'>
        <Navigation />
        {!analyticsPage && <SplitBanner />}

        {isLoading && <Loading />}
        {targetClient !== null && <ClientLoadingPage />}
        {!isLoading && (
          <>
            <SubNav />
            <Outlet />
          </>
        )}
        <SaveItemPopup />
        <FeedbackItemPopup />
        {showContactsOversubscribedModal && (
          <ContactsOversubscribedModal setShowPopup={setShowContactsOversubscribedModal} />
        )}
        {showContactsOversubscribedConfirmModal && (
          <ContactsOversubscribedConfirmModal setShowPopup={setShowContactsOversubscribedConfirmModal} />
        )}
        {showContactsOversubscribedInTrialModal && (
          <ContactsOversubscribedInTrialModal setShowPopup={setShowContactsOversubscribedInTrialModal} />
        )}
      </div>
      <StakeHolderSharePopUp />
      <GeneralTooltips />
    </>
  );
};

export default Dashboard;
