import { useContext } from 'react';
import { store } from '../../../Store';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';
import useGetSectionFilters from '../../Filters/useGetSectionFilters';
import { useLocation, useParams } from 'react-router-dom';

function useContactsApiCalls() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { selectedCrmContacts } = state;
  const { selectedAll } = selectedCrmContacts;
  const { generalApiCall } = useGeneralApiCall();
  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const sectionFilters = getSectionFilters();
  const { filters } = sectionFilters;

  const location = useLocation();
  const params = useParams();
  const query = new URLSearchParams(location.search);
  const searchQuery = query.get('search') ? decodeURIComponent(query.get('search')) : '';
  const { groupId } = params;

  const getContactTagsByQuery = async (source) => {
    let method = 'post';
    let pathname = `/api/crm-contact/list-tags-by-query`;
    let requestProperties = {
      query: searchQuery,
      filters,
    };

    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });

    return result;
  };

  const addContactsToGroupByQuery = async ({ crmContactGroupId, source }) => {
    try {
      const pathname = `/api/crm-contact/add-crm-contact-to-group-by-query`;
      const method = 'post';
      const requestProperties = {
        query: searchQuery,
        filters,
        crmContactGroupId,
      };
      return await generalApiCall({
        method,
        pathname,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
        requestProperties,
      });
    } catch (error) {}
  };

  const removeContactsFromGroupByQuery = async ({ crmContactGroupId, source }) => {
    try {
      const pathname = `/api/crm-contact/remove-crm-contact-from-group-by-query`;
      const method = 'post';
      const requestProperties = {
        query: '',
        filters: [],
        crmContactGroupId,
      };
      return await generalApiCall({
        method,
        pathname,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
        requestProperties,
      });
    } catch (error) {}
  };

  const removeTagsFromContacts = async ({ tags, contactIds }) => {
    let requestProperties = selectedAll
      ? {
          query: searchQuery,
          filters,
          tags,
        }
      : {
          ids: contactIds,
          tags,
        };
    requestProperties = {
      ...requestProperties,
      crmGroupId: groupId,
    };
    const pathname = selectedAll
      ? `/api/crm-contact/${groupId ? 'remove-tag-by-group' : 'remove-tags-by-query'}`
      : '/api/crm-contact/remove-tag';
    const method = 'post';
    const results = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      returnCompleteRequest: !!groupId,
    });
    return results;
  };

  const addTagsToContacts = async ({ tags, contactIds, preselectedContact }) => {
    let requestProperties =
      selectedAll && !preselectedContact
        ? {
            query: searchQuery,
            filters,
            tags,
          }
        : {
            ids: contactIds,
            tags,
          };
    requestProperties = { ...requestProperties, crmGroupId: groupId };
    const pathname =
      selectedAll && !preselectedContact
        ? `/api/crm-contact/${groupId ? 'tag-by-group' : 'tag-by-query'}`
        : '/api/crm-contact/tag';
    const method = 'post';
    const results = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      returnCompleteRequest: !!groupId,
    });
    return results;
  };

  return {
    getContactTagsByQuery,
    removeTagsFromContacts,
    addTagsToContacts,
    addContactsToGroupByQuery,
    removeContactsFromGroupByQuery,
  };
}

export default useContactsApiCalls;
