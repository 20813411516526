import { useContext, useEffect, useRef, useState } from 'react';
import ModalComponent from '../../../Common/Modal';
import { CreateGroupModal } from './CreateGroupButton';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { store } from '../../../Store';
import DropdownComponent from '../../../Settings/WebsiteFeeds/DropdownComponent';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import { useGroupFunctions } from './GroupFunctions';
import thousandCommas from '../../helpers/thousandCommas';
import { useNavigate } from 'react-router-dom';
import useContactsApiCalls from '../CrmContacts/useContactsApiCalls';
import useIsMobile from '../../utilities/useIsMobile';
import createNotification from '../../../Settings/Utilities/CreateNotification';

const AddContactsToGroupButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onlyIconOnMobile = useIsMobile(677);
  return (
    <>
      <button
        className='contacts-list-bar-action-container-btn px-3'
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <span className='icon-team ' />
        <span className={`${onlyIconOnMobile ? 'd-none' : ''}`}>Add to group</span>
      </button>
      <AddToGroupModal openState={{ setIsOpen, isOpen }} />
    </>
  );
};

const AddToGroupModal = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { selectedCrmContacts, crmContactsTotalHits, activeResults, contentResults, activePlan } = state;
  const { selectedAll, selectedIds } = selectedCrmContacts;
  const { Groups, CrmContacts } = contentResults[activeResults] ?? {};
  const { maxNumberOfGroups } = activePlan;
  const allowCreateGroups = maxNumberOfGroups === null || Groups?.length < maxNumberOfGroups;

  const { openState } = props ?? {};
  const stakeholder =
    props?.stakeholder ??
    (selectedIds?.length === 1 ? CrmContacts?.hits?.find((item) => item?.id === selectedIds[0]) : null);
  const stakeholderName = stakeholder?.name ?? stakeholder?.title;
  const { isOpen, setIsOpen } = openState ?? {};

  const [modalContent, setModalContent] = useState('addToGroup');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { addRemoveContactToGroup } = useGroupFunctions();
  const { addContactsToGroupByQuery } = useContactsApiCalls();

  const { listingGroups } = useGroupFunctions();
  const navigate = useNavigate();

  const initialCall = useRef();
  initialCall.current = async (source) => {
    if (!Groups) {
      let results = await listingGroups({ source });
      if (!!results) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const closeModal = (value) => {
    setIsOpen(value);
    setTimeout(() => {
      setSelectedGroup(null);
      setModalContent('addToGroup');
    }, 300);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);

    return () => {
      source.cancel(`Groups cancelled by the user.`);
    };
  }, []);

  const contactsCount = !!stakeholder ? 1 : selectedAll ? crmContactsTotalHits : selectedIds?.length;
  const contactsText = contactsCount === 1 ? `this contact` : `these contacts`;

  const addContactsToGroup = async ({ selectedGroup }) => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const groupId = selectedGroup?.id;
        if (props?.stakeholder) {
          await addRemoveContactToGroup({
            contactId: stakeholder?.contactInformation?.[0]?.id ?? stakeholder?.id,
            groupId: groupId,
          });
        } else {
          if (selectedAll) {
            await addContactsToGroupByQuery({ crmContactGroupId: groupId });
          } else {
            for (let i = 0; i < selectedIds?.length; i++) {
              await addRemoveContactToGroup({ contactId: selectedIds[i], groupId: groupId });
            }
          }
        }
        createNotification(
          'success',
          `${selectedIds?.length === 1 || contactsCount === 1 ? 'Contact has' : `${selectedIds?.length} contacts have`} been successfully added to this group`
        );
        navigate(`/influence/my-groups/${groupId}`);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      setIsOpen={closeModal}
      maxWidth={670}
      overlayClassName={'react-modal-overlay modal-close-position-adjustment'}
    >
      {modalContent === 'addToGroup' && (
        <>
          <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 px-3 pt-4'>
            Add{' '}
            {stakeholderName ??
              `${contactsCount > 1 ? `${thousandCommas(contactsCount)} ` : ''} contact${contactsCount === 1 ? '' : 's'}`}{' '}
            to a group
          </h3>
          <div className='px-4 px-lg-5 pt-3 pt-lg-4 pb-4'>
            <label className='mb-1 font-weight-bold'>{`Select the group ${contactsText} should appear in`}</label>
            <div className='mt-2 pb-4'>
              <DropdownComponent
                fixedDropdown={true}
                mainItem={
                  selectedGroup
                    ? `${selectedGroup?.name}`
                    : () => <span className='main-light-text'>Select a group</span>
                }
              >
                <CustomScrollbar
                  autoHeight
                  autoHeightMin='100%'
                  autoHeightMax={`${179}px`}
                  style={{ maxHeight: `${179}px` }}
                  className='simple-scrollbar'
                  maximalThumbYSize={100}
                  notForceOSStyles
                >
                  <div>
                    {isLoading ? (
                      <SkeletonTheme baseColor='#fff'>
                        <Skeleton height={19} width={`100%`} />
                        <Skeleton height={19} width={`80%`} />
                        <div className='my-4'>
                          <Skeleton height={19} width={`40%`} />
                        </div>
                      </SkeletonTheme>
                    ) : (
                      <>
                        {Groups?.filter((item) => item?.type === 'Static')?.map((item, index) => {
                          const { id, name } = item;
                          return (
                            <div
                              className='list-action-button py-2 px-3 pointer d-flex justify-content-between align-items-center'
                              key={`checkbox-item-group-${id}-${index}`}
                              onClick={() => {
                                setSelectedGroup(item);
                              }}
                            >
                              {name}
                              {id === selectedGroup?.id && <span className='icon-tick paragraph-p5' />}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </CustomScrollbar>
                <button
                  className='border-top general-button text-light-blue flex-centered w-100 text-left px-3 pt-2 action-state-hover '
                  onClick={() => {
                    if (allowCreateGroups) {
                      setModalContent('createGroup');
                    }
                  }}
                  data-tooltip-id='modal-tooltip'
                  data-tooltip-content={!allowCreateGroups ? 'Upgrade your subscription to create more groups' : ''}
                  style={{ paddingBottom: '10px' }}
                >
                  <span className='title-h3 mr-2 d-inline-block-centered centered-2' style={{ marginLeft: '-3px' }}>
                    +
                  </span>{' '}
                  Create a new group
                  {!allowCreateGroups && <span className='icon-padlock d-inline-block-centered ml-2 mt-0' />}
                </button>
              </DropdownComponent>
            </div>

            <div className='border rounded bg-blue-hovered p-3 d-flex main-subtle-text'>
              <span className='icon-info-circle-filled title-h2' style={{ marginTop: '2px', marginRight: '12px' }} />
              <span className='pr-lg-5'>
                Only static groups are shown. Dynamic groups update automatically based on set filters
              </span>
            </div>
          </div>

          <div className='d-flex justify-content-end py-3 border-top bg-grey px-4 px-lg-5 mt-3 rounded-bottom'>
            <button
              className='general-button simple-link-button mr-3 px-3'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className='general-button action-button px-4'
              onClick={() => {
                addContactsToGroup({ selectedGroup: selectedGroup });
              }}
              disabled={selectedGroup === null}
            >
              {isLoading && <i className='fas fa-spinner fa-spin mr-1 d-inline-block-centered' />} Add contact
              {contactsCount === 1 ? '' : 's'}
            </button>
          </div>
        </>
      )}
      {modalContent === 'createGroup' && (
        <CreateGroupModal setModalContent={setModalContent} addContactsToGroup={addContactsToGroup} />
      )}
    </ModalComponent>
  );
};

export { AddContactsToGroupButton, AddToGroupModal };
