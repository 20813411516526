import { useContext, useState } from 'react';
import { store } from '../../../../Store';
import { CreateGroupModal } from '../../CrmGroups/CreateGroupButton';
import ModalComponent from '../../../../Common/Modal';
import { RemoveGroupsModal } from '../../CrmGroups/RemoveGroupsModal';
import thousandCommas from '../../../helpers/thousandCommas';

const GroupViewSubnav = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeResults, contentResults, group } = state;
  const { CrmContacts } = contentResults[activeResults];

  const [isOpen, setIsOpen] = useState(false);
  const [defaultView, setDefaultView] = useState('create');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const { type } = group ?? {};

  return (
    <>
      <div className='w-100  flex-grow-1'>
        <div className='w-100 overflow-auto flex-centered justify-content-between px-3 pr-lg-0 pl-lg-4 pb-lg-0'>
          <h4
            className='mb-0 flex-centered justify-content-between  flex-grow-1 group-view-title'
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <span className='hidden-lines main-title hidden-one-line'>{group ? group?.name : ''}</span>
            <span className='bg-light-blue text-white rounded-circle mx-3 flex-centered justify-content-center group-view-edit-button'>
              <span className='icon-edit-pencil paragraph-p3' />
            </span>
          </h4>
          <div className='flex-centered'>
            {!!group && (
              <>
                {!!CrmContacts && (
                  <p
                    className={`nowrap-item mb-0 rounded-pill px-4 border subnav-group-type subnav-group-type-${type.toLowerCase()} paragraph-p2 subnav-buttons-spacing`}
                  >
                    <span className={`group-type group-type-${type?.toLowerCase()}`} />
                    {type} group with {!!CrmContacts?.totalHits ? thousandCommas(CrmContacts?.totalHits) : 0} contact
                    {CrmContacts?.totalHits === 1 ? '' : 's'}
                  </p>
                )}
                {type === 'Dynamic' && (
                  <button
                    className='nowrap-item d-lg-none outline-active-button px-4 general-button rounded-button ml-3 paragraph-p2 subnav-buttons-spacing'
                    onClick={() => {
                      setDefaultView('filters');
                      setIsOpen(true);
                    }}
                  >
                    Filters
                  </button>
                )}
                <button
                  className='nowrap-item border px-3 general-button rounded-button ml-3 paragraph-p2 subnav-buttons-spacing action-state-hover'
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <span
                    className='icon-three-dots mr-2 d-inline-block-centered main-light-text'
                    style={{ marginLeft: '-2px' }}
                  />
                  Group details
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <ModalComponent
        isOpen={isOpen}
        overlayClassName={'react-modal-overlay modal-close-position-adjustment'}
        setIsOpen={(value) => {
          setIsOpen(value);
          setDefaultView('create');
        }}
      >
        <CreateGroupModal
          setIsOpenDelete={setIsOpenDelete}
          group={group}
          maxWidth={750}
          openState={{ isOpen, setIsOpen }}
          defaultView={defaultView}
        />
      </ModalComponent>
      <RemoveGroupsModal openState={{ isOpen: isOpenDelete, setIsOpen: setIsOpenDelete }} groupsCount={1} />
    </>
  );
};

export default GroupViewSubnav;
