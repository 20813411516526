import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { SkeletonInfluenceSingleItem } from './SkeletonInfluenceList';
const SkeletonMyTeamsContent = () => {
  const location = useLocation();
  const influenceSection = location.pathname.includes('/influence');
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className={`${influenceSection ? 'px-md-4 mr-lg-4' : 'pt-lg-5'}`}>
        {!influenceSection && (
          <div className='d-flex justify-content-between align-items-center py-4 pr-lg-4 pb-2 pt-lg-0'>
            <Skeleton height={24} width={`230px`} />
            <div className='position-relative filter-main-container d-flex'>
              <div className='mr-3'>
                <Skeleton height={24} width={`140px`} />
              </div>

              <Skeleton height={19} width={`140px`} />
            </div>
          </div>
        )}
        <ul className='policy-list px-0 items-list mt-2'>
          {Array(9)
            .fill()
            .map((item, index) => (
              <SkeletonInfluenceSingleItem key={index} />
            ))}
        </ul>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonMyTeamsContent;
