import { useLocation } from 'react-router-dom';
import thousandCommas from '../../../helpers/thousandCommas';
import { changeSectionNames } from '../ContentItemsPanel';
import { useEffect, useRef, useState } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { useAllowRightPanel } from '../MentionsByTopic/ChartDataTable';

const StakeholdersTable = (props) => {
  const {
    highlightFunction,
    activeSection,
    rightPanelState,
    setRightPanelState,
    setStakeholdersData,
    stakeholdersData,
  } = props;
  const { graphData } = stakeholdersData;
  const addNumbers = (item, value) => {
    if (value === 'totalContribution' && process.env.REACT_APP_ENABLE_TWITTER === 'false') {
      let totalValue =
        item[`${value}Count12mo`] + item[`${value}Count3mo`] + item[`${value}Count1mo`] + item[`${value}Count1w`];
      let tweetValue = item[`tweetCount12mo`] + item[`tweetCount3mo`] + item[`tweetCount1mo`] + item[`tweetCount1w`];
      let retweetValue =
        item[`retweetCount12mo`] + item[`retweetCount3mo`] + item[`retweetCount1mo`] + item[`retweetCount1w`];

      return totalValue - tweetValue - retweetValue;
    } else {
      return item[`${value}Count12mo`] + item[`${value}Count3mo`] + item[`${value}Count1mo`] + item[`${value}Count1w`];
    }
  };

  const location = useLocation();
  const itemNumbers = (item) => {
    let object = {
      'Spoken parliamentary contributions': addNumbers(item, 'parliamentaryContribution'),
      'Written questions': addNumbers(item, 'writtenQuestionsAsked'),
      EDMs: addNumbers(item, 'edm'),
    };
    if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
      object = {
        ...object,
        Tweets: addNumbers(item, 'tweet'),
        Retweets: addNumbers(item, 'retweet'),
      };
    }
    object = {
      ...object,
      Total: addNumbers(item, 'totalContribution'),
    };
    return object;
  };

  const chartDataContainer = useRef(null);
  const headerContainer = useRef(null);

  const moveTable = ({ el }) => {
    if (el) {
      setTimeout(() => {
        chartDataContainer.current.node.scrollLeft = el.offsetLeft - 20;
      }, 10);
    }
  };

  const [headersDimentions, setHeaderDimentions] = useState({
    name: 0,
    house: 0,
  });
  const houseCell = useRef();
  const nameCell = useRef();
  useEffect(() => {
    setHeaderDimentions({
      name: nameCell?.current?.getBoundingClientRect()?.width,
      house: houseCell?.current?.getBoundingClientRect()?.width,
    });
  }, [rightPanelState]);

  const changeOrderTableLabel = (e) => {
    e.stopPropagation();
    const element = e.target;
    const column = e.target.innerText;
    const order = e.target.dataset.order;
    let rawData = JSON.parse(JSON.stringify(graphData));
    const canvasesOrder = {};
    for (let i = 0; i < rawData.length; i++) {
      canvasesOrder[rawData[i].ptId] = stakeholdersData.images[i];
    }

    const getHouseNumber = (item) => {
      return item.categories.includes('MP') ? -1 : item.categories.includes('Lord') ? 1 : 0;
    };
    let newGraphData = rawData.sort((a, b) => {
      if (column === 'House') {
        return order === 'asc' ? getHouseNumber(a) - getHouseNumber(b) : getHouseNumber(b) - getHouseNumber(a);
      } else if (column === 'Name') {
        return order === 'asc' ? ('' + a.name).localeCompare(b.name) : ('' + b.name).localeCompare(a.name);
      } else {
        const numbersA = itemNumbers(a);
        const numbersB = itemNumbers(b);
        return order === 'asc' ? numbersA[column] - numbersB[column] : numbersB[column] - numbersA[column];
      }
    });
    element.dataset.order = order === 'asc' ? 'desc' : 'asc';
    let newImagesOrder = newGraphData.map((item) => canvasesOrder[item.ptId]);
    setStakeholdersData({
      ...stakeholdersData,
      graphData: newGraphData,
      images: newImagesOrder,
    });
  };

  return (
    <ScrollSync>
      <div>
        <div className='pt-5 sticky-item'>
          <h3 className='title-h5-m-bold mb-4'>Chart data</h3>
          <ScrollSyncPane ref={headerContainer}>
            <div className='overflow-hidden chart-data-container'>
              <table
                className='table chart-data-info mb-0 pb-0 top-stakeholders-table'
                style={{ borderBottom: 'none' }}
              >
                <thead>
                  <tr>
                    <th
                      className='font-weight-bold text-left px-0'
                      style={rightPanelState?.open ? null : { width: `${headersDimentions?.name}px` }}
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      <span
                        style={{
                          paddingLeft: '19px',
                          paddingRight: '19px',
                          width: `${headersDimentions?.name - 1}px`,
                        }}
                        className='d-inline-block m-0'
                        onClick={(e) => {
                          changeOrderTableLabel(e);
                        }}
                        data-order={'asc'}
                      >
                        Name
                      </span>
                    </th>

                    <th
                      className='font-weight-bold  text-left px-0'
                      style={rightPanelState?.open ? null : { width: `${headersDimentions?.house}px` }}
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      <span
                        style={{
                          paddingLeft: '19px',
                          paddingRight: '19px',
                          width: `${headersDimentions?.house - 1}px`,
                        }}
                        className='d-inline-block m-0'
                        onClick={(e) => {
                          changeOrderTableLabel(e);
                        }}
                        data-order={'asc'}
                      >
                        House
                      </span>
                    </th>

                    <th
                      className='font-weight-bold'
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      Spoken parliamentary contributions
                    </th>
                    <th
                      className='font-weight-bold'
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      Written questions
                    </th>
                    <th
                      className='font-weight-bold'
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      EDMs
                    </th>
                    {process.env.REACT_APP_ENABLE_TWITTER === 'true' && (
                      <>
                        <th
                          className='font-weight-bold'
                          onClick={(e) => {
                            changeOrderTableLabel(e);
                          }}
                          data-order={'asc'}
                        >
                          Tweets
                        </th>
                        <th
                          className='font-weight-bold'
                          onClick={(e) => {
                            changeOrderTableLabel(e);
                          }}
                          data-order={'asc'}
                        >
                          Retweets
                        </th>
                      </>
                    )}
                    <th
                      className='font-weight-bold'
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </ScrollSyncPane>
        </div>
        <div
          className='chart-data-container'
          style={{
            marginTop: `-${headerContainer.current?.node?.getBoundingClientRect().height + 2}px`,
          }}
        >
          <ScrollSyncPane ref={chartDataContainer}>
            <div className='overflow-auto'>
              <table className='table chart-data-info top-stakeholders-table'>
                <thead>
                  <tr>
                    <th
                      className='font-weight-bold text-left'
                      ref={nameCell}
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      Name
                    </th>
                    <th
                      className='font-weight-bold  text-left'
                      ref={houseCell}
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      House
                    </th>
                    <th
                      className='font-weight-bold'
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      Spoken parliamentary contributions
                    </th>
                    <th
                      className='font-weight-bold'
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      Written questions
                    </th>
                    <th
                      className='font-weight-bold'
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      EDMs
                    </th>
                    {process.env.REACT_APP_ENABLE_TWITTER === 'true' && (
                      <>
                        <th
                          className='font-weight-bold'
                          onClick={(e) => {
                            changeOrderTableLabel(e);
                          }}
                          data-order={'asc'}
                        >
                          Tweets
                        </th>
                        <th
                          className='font-weight-bold'
                          onClick={(e) => {
                            changeOrderTableLabel(e);
                          }}
                          data-order={'asc'}
                        >
                          Retweets
                        </th>
                      </>
                    )}
                    <th
                      className='font-weight-bold'
                      onClick={(e) => {
                        changeOrderTableLabel(e);
                      }}
                      data-order={'asc'}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {graphData.map((item, i) => {
                    const { categories, contentUrl, id } = item;
                    const house = categories.includes('MP') ? 'Commons' : categories.includes('Lord') ? 'Lords' : '—';
                    const numbers = itemNumbers(item);

                    return (
                      <tr
                        key={`data-${i}`}
                        className='stakeholders-table-row'
                        onMouseOver={() => {
                          highlightFunction(i);
                        }}
                      >
                        <td
                          className={`table-stakeholder-name text-left ${
                            i === activeSection ? 'selected-label-total' : ''
                          }`}
                          onClick={() => {
                            window.open(`${contentUrl}${location.search}`);
                          }}
                        >
                          <span className='nowrap-item'>{item.name}</span>
                        </td>
                        <td
                          className={`${
                            i === activeSection ? 'selected-label-info' : ''
                          } text-left table-stakeholder-house stakeholders-table-${
                            house === '—' ? 'empty' : house.toLowerCase()
                          }`}
                        >
                          {house}
                        </td>

                        {Object.keys(numbers).map((section) => {
                          const value = numbers[section];
                          return (
                            <CellItem
                              key={`item-${id}-${contentUrl}-${section}`}
                              item={item}
                              i={i}
                              value={value}
                              activeSection={activeSection}
                              rightPanelState={rightPanelState}
                              setRightPanelState={setRightPanelState}
                              section={section}
                              moveTable={moveTable}
                              numbers={numbers}
                            />
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </ScrollSyncPane>
        </div>
      </div>
    </ScrollSync>
  );
};

const CellItem = (props) => {
  const { value, activeSection, i, section, setRightPanelState, item, rightPanelState, moveTable, numbers } = props;
  const { id, ptId } = item;
  const activeCell = rightPanelState?.stakeholderId === id && rightPanelState?.dataKey === section;
  const cellItem = useRef();
  const { enablePanel, showPanelPopup } = useAllowRightPanel();

  return (
    <td
      ref={cellItem}
      className={`pointer ${i === activeSection ? 'selected-label-info' : ''} ${
        section === 'Total' ? 'label-total' : ''
      }`}
      style={activeCell ? { backgroundColor: '#0094CC' } : null}
      onClick={() => {
        if (enablePanel) {
          const getSingleSectionOnTotal = () => {
            const newNumbers = {};
            Object.keys(numbers).forEach((item) => {
              if (item !== 'Total' && numbers[item] > 0) {
                newNumbers[item] = numbers[item];
              }
            });
            if (Object.keys(newNumbers).length === 1) {
              return Object.keys(newNumbers)[0];
            } else {
              return 'Total';
            }
          };

          setRightPanelState({
            open: true,
            rawData: numbers,
            text: `${thousandCommas(value)} ${changeSectionNames(section === 'Total' ? getSingleSectionOnTotal() : section)}${value === 1 ? '' : 's'} by ${item.name}`,
            dataKey: section,
            stakeholderId: id,
            stakeholderTweetsId: ptId,
          });
          moveTable({ el: cellItem.current });
        } else {
          showPanelPopup();
        }
      }}
    >
      <span
        className={`${!activeCell ? 'cell-element text-light-blue' : ''} ${
          activeCell ? 'text-white font-weight-bold' : ''
        }`}
      >
        {isNaN(value) ? 0 : value}
      </span>
    </td>
  );
};

export default StakeholdersTable;
