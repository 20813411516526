import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { store } from '../../Store.js';
import axios from 'axios';
import callHightlightContentAPI from '../utilities/callHightlightContentAPI.js';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions.js';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import highlightWidget from '../apiCalls/callHighlightWidget.js';
import ChangeKeywordsCategory from '../utilities/changeKeywordsCategory.js';
import { SaveButton } from '../components/Share.js';
import { useLocation } from 'react-router-dom';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter.js';
import { useParams } from 'react-router-dom';
import TagsLists from '../TagsList/index.js';
import useRememberPosition from '../hooks/useRememberPosition.js';
import ThumbsDown from '../../Common/ThumbsDown.js';
import FeedbackOnItem from '../FeedbackItem/FeedbackOnItem.js';
import createMarkup from '../helpers/createMarkup.js';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord.js';

const CalendarItem = (props) => {
  const { item, widgetEmbed, keywordListId, array, index, highlightedLists, customKeywords } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const {
    net_api_url,
    search,
    contentResults,
    activeResults,
    referenceState,
    keywordsLists,
    lastSeenMarkers,
    itemsViewed,
    clients,
  } = state;
  const { activeReference } = referenceState;
  const { UpcomingEvents } = contentResults[activeResults];
  const { query, list, activeSearch } = search;
  const [text, setText] = useState('');
  const [loadingHighlight, setLoadingHighlight] = useState(false);
  const [finalTitle, setFinalTitle] = useState(item.title);
  const [heightBody, setHeightBody] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const isMounted = useRef(null);
  const haveBody = item.body !== '';
  const { newAgencyPlan } = useTopicOrClientWord();
  const keywordsListToUse = newAgencyPlan
    ? clients?.flatMap((item) => {
        const { teamMemberKeywordLists, myKeywordLists, teamName } = item;
        const myLists = myKeywordLists.map((item) => {
          return { ...item, teamName: teamName };
        });
        const teamMemberLists = teamMemberKeywordLists.map((item) => {
          return { ...item, teamName: teamName };
        });
        return [...myLists, ...teamMemberLists];
      })
    : keywordsLists;

  const contentHighlightedLists = keywordsListToUse.filter((item) => highlightedLists?.includes(item?.id?.toString()));
  const mainSource = useRef();

  useEffect(() => {
    if (activeSearch || activeReference || widgetEmbed || customKeywords) {
      highlightEvents();
    } else {
      setLoadingHighlight(false);
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    mainSource.current = source;
    isMounted.current = true;
    return () => {
      source.cancel('Event item canceled by the user.');
      isMounted.current = false;
    };
  }, []);

  const highlightEvents = async () => {
    try {
      setLoadingHighlight(true);
      let andKeywords = list?.andKeywords ?? [];
      let queryHighlight = list !== null ? ChangeKeywordsCategory(list.keywords) : query;
      if (activeReference) {
        andKeywords = referenceState.topic?.andKeywords ?? referenceState.andKeywords;
        queryHighlight = ChangeKeywordsCategory(referenceState.topic?.keywords ?? referenceState.keywords);
      }

      if (customKeywords) {
        andKeywords = [];
        queryHighlight = ChangeKeywordsCategory(customKeywords);
      }

      let propertiesHighlight = [
        item.objectID,
        item.type,
        queryHighlight,
        ChangeKeywordsExclusions(andKeywords),
        mainSource.current,
      ];
      let contentHightlighted =
        widgetEmbed && keywordListId
          ? await highlightWidget(item.type, item.objectID, keywordListId)
          : await callHightlightContentAPI(propertiesHighlight);
      setFinalTitle(contentHightlighted?.highlightedTitle ?? item.title);
      setText(contentHightlighted?.highlightedHtmlBody ?? '');
      setLoadingHighlight(false);
    } catch (error) {}
  };

  const feedItem = item?.feedItemFlags;
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  const unseenConditional =
    list !== null &&
    lastSeenMarkers['eventFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition;

  const location = useLocation();
  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);
  const { rememberPosition } = useRememberPosition();

  const createContent = async () => {
    if (haveBody && text === '') {
      try {
        let content = await axios.get(`${net_api_url}/api/event/${item.id}`);
        if (isMounted.current) {
          let text = content.data.description;
          if (highlightedLists?.length > 0) {
            let highlightContent = await callHightlightContentAPI([
              item.objectID,
              item.type,
              ChangeKeywordsCategory(contentHighlightedLists.flatMap((list) => list.keywords)),
              ChangeKeywordsExclusions(contentHighlightedLists.flatMap((list) => list.andKeywords)),
              mainSource.current,
            ]);
            text = highlightContent?.highlightedHtmlBody ?? '';
          }
          setText(text);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const bodyContainer = useCallback(
    (node) => {
      let calculateHeight = () => {
        if (node !== null) {
          node.style.height = 'auto';
          let nodeHeight = node.getBoundingClientRect().height;
          if (heightBody !== nodeHeight) {
            setHeightBody(nodeHeight);
          }
          if (!isOpen) {
            node.style.height = 0;
          }
        }
      };
      calculateHeight(); /* 
      window.addEventListener('resize', calculateHeight);
      return () => {
        window.removeEventListener('resize', calculateHeight);
      }; */
    },
    [text]
  );

  const alreadyMarkedFeedback = feedItem?.ratingScored === 'Negative';

  return (
    <>
      {loadingHighlight && (
        <SkeletonTheme baseColor='#f2f2f2'>
          <div className='my-2 border-bottom'>
            <div className='py-3'>
              <Skeleton height={19} width={'70%'} />
            </div>
          </div>
        </SkeletonTheme>
      )}
      {!loadingHighlight && (
        <div
          className={`my-2 calendar-item ${unseenConditional ? 'unseen-item calendar-unseen' : ''} ${highlightedLists?.length > 0 ? 'item-highlighted' : 'no-highlight'}`}
          style={{ zIndex: array ? array.length - index : 1 }}
        >
          {unseenConditional && (
            <>
              <span className='unseen-dot calendar-unseen' />
            </>
          )}
          <div
            className={`upcoming-event  ${isOpen ? 'upcoming-event-open' : ''} ${haveBody ? 'upcoming-event-expandable' : ''}`}
          >
            <div
              onClick={async () => {
                createContent();
                if (haveBody) {
                  setIsOpen(!isOpen);
                }

                if (!!UpcomingEvents && inboxSection) {
                  rememberPosition('UpcomingEvents', null, index);
                }
              }}
              style={{ cursor: haveBody ? 'pointer' : 'normal' }}
            >
              <div className='flex-centered'>
                <h4 className='mb-0 py-3 flex-grow-1'>
                  <span dangerouslySetInnerHTML={createMarkup(finalTitle)} />
                  {haveBody && (
                    <button
                      className={`general-button ml-2 calendar-more-button ${!isOpen ? 'calendar-less-button' : ''}`}
                    >
                      {isOpen ? 'less' : 'more'}
                      <span className='icon-arrow-down'></span>
                    </button>
                  )}
                </h4>
                {!location.pathname.toLowerCase().startsWith('/embed') && !widgetEmbed && (
                  <div className='flex-centered'>
                    <ThumbsDown item={item} index={UpcomingEvents?.hits?.findIndex((hit) => hit.id === item.id)} />
                    <div className='pl-lg-2 calendar-save-button-container'>
                      <SaveButton item={item} listItem />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {haveBody && (
            <div className='upcoming-event-body' style={{ height: `${isOpen ? heightBody : 0}px` }} ref={bodyContainer}>
              <div className='p-3 upcoming-event-body-container'>
                {text === '' && <p className='mb-0'>Loading...</p>}
                {text !== '' && <div dangerouslySetInnerHTML={createMarkup(text)}></div>}
              </div>
            </div>
          )}

          {highlightedLists?.length > 0 && (
            <div className='calendar-tag-list'>
              <TagsLists feedItemFlags={[{ matchingKeywordLists: contentHighlightedLists }]} index={0} calendarTags />
            </div>
          )}
          {alreadyMarkedFeedback && (
            <FeedbackOnItem item={item} index={UpcomingEvents?.hits?.findIndex((hit) => hit.id === item.id)} />
          )}
        </div>
      )}
    </>
  );
};

export default CalendarItem;
