import { useSuggestionFunctions } from '../WritingAssistant/WritingCreate';
import { useEffect } from 'react';
import data from '@emoji-mart/data';
import { init, getEmojiDataFromNative } from 'emoji-mart';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import useAnalyticsIntervalLimit from '../Dashboard/Analytics/useAnalyticsIntervalLimit';

const useReportIconAutoselect = (props) => {
  const { getSuggestion } = useSuggestionFunctions();
  const { generalApiCall } = useGeneralApiCall();
  const { validateDataForLoggedOutUsers } = useAnalyticsIntervalLimit();

  const callSuggestionIcon = async ({ value, source }) => {
    const prompt = `Show one emoji, no words, that best describes the following report name: ${value}.`;
    //AE: This was part of the prompt to return the org logotype: If the report name is likely to refer to the organisation typing it, such as 'About us' or 'Our work', return the word 'logotype' and not show any emoji.
    const results = await getSuggestion({ prompt, source });
    const suggestion = results?.choices?.[0]?.message?.content;
    if (suggestion === 'logotype') {
      return suggestion;
    } else {
      const icon = await getEmojiDataFromNative(suggestion);
      return icon?.id;
    }
  };

  const callLoggedOutIconSearch = async ({ value }) => {
    try {
      if (value) {
        const pathname = `/api/graph-data/search-icon?query=${encodeURIComponent(value)}`;
        const method = 'get';
        const suggestion = await generalApiCall({ pathname, method, returnError: true });
        if (!!suggestion && validateDataForLoggedOutUsers(suggestion)) {
          const icon = await getEmojiDataFromNative(suggestion);
          return icon.id;
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    init({ data });
  }, []);

  return { callSuggestionIcon, callLoggedOutIconSearch };
};

export default useReportIconAutoselect;
