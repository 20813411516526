import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { store } from '../../Store.js';
import useIsMobile from '../utilities/useIsMobile';
import { useAuth0 } from '@auth0/auth0-react';
import returnCorrectWPUrl from '../utilities/returnCorrectWPUrl.js';
import normalize from '../utilities/normalizeString.js';
import { useHeightContainer } from '../utilities/useHeightContainer.js';
import parliamentarianUsers from './parliamentarianUsers.js';
import SkeletonParliamentarians from './SkeletonSideNav.js';
import { contentItemSearchQuery } from '../helpers/locationSearchDescriptor.js';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter.js';
import transformToPascalCase from '../../Settings/Utilities/TransformToPascalCase.js';
import thousandCommas from '../helpers/thousandCommas.js';
import getUrlParam from '../utilities/getUrlParam.js';
import array_move from '../helpers/arrayMove.js';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase.js';
import CustomScrollbar from '../../Common/CustomScrollbar.js';

const DashboardSidebar = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, activePlan } = state;
  const { code } = activePlan;
  const { searchState } = search;
  const isMobile = useIsMobile();

  const params = useParams();
  const [heightContainer, containerRef] = useHeightContainer();
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if ((Object.keys(activePlan).length > 0 && searchState) || !isAuthenticated) {
      setIsLoading(false);
    }
  }, [params, activePlan, searchState]);

  return (
    <div className={`main-navigation ${state.isHiddenMenu ? '' : 'show-menu'}`}>
      <div
        className={`intercom-main-navigation pt-lg-4 ${isAuthenticated ? 'pt-5' : ''}`}
        data-intercom-target='Side bar element'
      >
        {isLoading && <SkeletonParliamentarians />}
        {!isLoading && (
          <div className={`${parliamentarianUsers(code) ? 'navigation-parliamentarians' : ''}`}>
            <div ref={containerRef} />

            <CustomScrollbar
              style={{ height: `${heightContainer}px` }}
              maximalThumbYSize={100}
              className={'simple-scrollbar'}
            >
              <div
                className={`pl-lg-4 navigation-container d-flex justify-content-between flex-column ${isMobile ? 'navigation-container-mobile-logged-out' : ''}`}
              >
                <div>
                  {!isMobile && (
                    <div className={`sidebar-nav`}>
                      <ul className={`px-0 mb-0 overflow-hidden sidenav-list`}>
                        <SideBarItems />
                      </ul>
                    </div>
                  )}
                </div>

                <div className='bottom-terms'>
                  <div className='row mx-0 pt-2 pb-3 align-items-center bottom-terms-container'>
                    <a href={`${returnCorrectWPUrl()}/terms-and-conditions/`} rel='noopener noreferrer' target='_blank'>
                      T&Cs
                    </a>
                    <div className='dot'></div>
                    <a href={`${returnCorrectWPUrl()}/privacy-policy/`} rel='noopener noreferrer' target='_blank'>
                      Privacy policy
                    </a>
                    <div className='dot'></div>
                    <p className='mb-0'>
                      Logos by{' '}
                      <a href='https://clearbit.com/' rel='noopener noreferrer' target='_blank'>
                        Clearbit
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </CustomScrollbar>
          </div>
        )}
      </div>
    </div>
  );
};

const SideBarItems = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { unseenNumbersCurrentList, activePlan, totalNumbers, search } = state;
  const { list } = search;
  const { enableTwitter, code } = activePlan;

  const { closeMenu } = props;

  const params = useParams();
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const { inboxSection, searchResultsSection } = useMainSectionParameter(params);

  const locationDescriptor = (path, influence) => {
    let locationSearch = contentItemSearchQuery(location, list);
    let returnUrl = getUrlParam('returnUrl')
      ? `${locationSearch === '' ? '?' : '&'}returnUrl=${getUrlParam('returnUrl')}`
      : '';
    return {
      pathname: `/${influence ? 'influence' : `${params?.mainSection ?? (isAuthenticated ? 'reference' : 'monitor')}`}${path}`,
      search: `${locationSearch}${returnUrl}`,
      hash: '',
    };
  };

  let items = [
    {
      name: 'Key updates',
      type: 'KeyUpdate',
      className: 'news',
      unseenNumber: ['KeyUpdate'],
    },
    {
      name: 'Consultations',
      type: 'Consultation',
      className: 'consultation',
      unseenNumber: ['Consultation'],
    },
    {
      name: 'Legislation',
      unseenNumber: ['Legislation'],
    },
    {
      name: 'Parliamentary record',
      type: 'ParliamentaryRecord',
      className: 'parliamentary',
      unseenNumber: ['ParliamentaryRecord', 'HansardContent'],
    },
    {
      name: 'Committee publications',
      type: 'CommitteePublication',
      className: 'parliamentary',
      unseenNumber: ['CommitteePublication'],
      nameForUnseenNumber: 'CommitteePublication',
      icon: 'document',
    },
    {
      name: 'Library material',
      type: 'CommonsLibraryArticle',
      icon: 'library-material',
      unseenNumber: ['CommonsLibraryArticle'],
    },
    {
      name: 'Upcoming events',
      type: 'Event',
      icon: 'calendar',
      unseenNumber: ['Event'],
      hideOnSearch: true,
    },
    {
      name: 'Tweets',
      type: 'tweet',
      className: 'social',
      icon: 'twitter',
      unseenNumber: ['Tweet'],
    },
    {
      name: 'Stakeholders',
      unseenNumber: [''],
      icon: 'team',
      hideOnInbox: true,
      hideOnSearch: true,
    },
    {
      name: 'Lobbying material',
      type: 'UserContent',
      icon: 'briefing-material',
      unseenNumber: ['UserContent'],
    },
  ];

  if (parliamentarianUsers(code)) {
    let position = items.findIndex((item) => item.name === 'Lobbying material');
    items[position].name = 'Campaigns';
    items = array_move(items, position, 1);
  }

  if (process.env.REACT_APP_ENABLE_TWITTER === 'false') {
    let twitterPosition = items.findIndex((item) => item.name === 'Tweets');
    items.splice(twitterPosition, 1);
  }

  return (
    <>
      <h4 className={`mb-3 title-h5-m-bold pl-3`} style={{ marginTop: '12px' }}>
        Resources
      </h4>
      {items.map((item) => {
        const { name, unseenNumber, hideOnInbox, hideOnSearch, icon, type, nameForUnseenNumber } = item;
        let nameNormalize = normalize(name);
        let unseenCondition = list !== null && unseenNumbersCurrentList;
        let unseenConditionForTweets = unseenCondition;
        let inboxCondition = !inboxSection || (inboxSection && !hideOnInbox);
        let searchResultsCondition =
          !searchResultsSection ||
          (searchResultsSection && !hideOnSearch) ||
          (hideOnSearch && getUrlParam('search')) ||
          getUrlParam('or');
        let showItem = inboxCondition && searchResultsCondition;
        if (item.name === 'Tweets') {
          unseenConditionForTweets = unseenCondition && enableTwitter;
        }
        let unseenNumberToRender = 0;
        if (unseenConditionForTweets) {
          unseenNumber.forEach((item) => {
            let propertyNumber = unseenNumbersCurrentList[item];
            if (propertyNumber) {
              unseenNumberToRender += propertyNumber;
            }
          });
        }
        let totalNumber = inboxSection
          ? unseenNumberToRender
          : totalNumbers[nameForUnseenNumber ?? transformToPascalCase(name === 'Calendar' ? 'Upcoming events' : name)];
        const iconClass = icon ?? normalize(TransformFromPascalCase(type ?? name));
        const iconClassName = `political-updates-nav-icon icon-${iconClass} inline-block `;

        return (
          <li key={nameNormalize} style={{ display: showItem ? 'block' : 'none' }}>
            <NavLink
              to={locationDescriptor(`/${nameNormalize}`)}
              className={({ isActive }) => `sidebar-item ${isActive ? 'active' : ''}`}
              onClick={() => {
                if (closeMenu) {
                  closeMenu();
                }
              }}
            >
              <div className='d-flex'>
                <div className={`sidebar-item-container sidebar-${iconClass}`}>
                  <span className={iconClassName} />
                </div>
                <span>{name}</span>
              </div>
              {totalNumber && totalNumber > 0 ? (
                <NotificationCount inboxSection={inboxSection} unseenNumberToRender={totalNumber} />
              ) : (
                ''
              )}
            </NavLink>
          </li>
        );
      })}
    </>
  );
};

const NotificationCount = (props) => {
  const { unseenNumberToRender, inboxSection } = props;

  return (
    <div
      className={`notification-count`}
      data-tooltip-content={`${unseenNumberToRender >= 9999 ? '9,999+' : thousandCommas(unseenNumberToRender)} ${inboxSection ? 'unread update' : 'result'}${unseenNumberToRender === 1 ? '' : 's'}`}
      data-tooltip-id='general-tooltip'
    >
      {unseenNumberToRender > 99 ? '99+' : unseenNumberToRender}
    </div>
  );
};
export { SideBarItems };
export default DashboardSidebar;
