import { useContext, useEffect, useState, useRef } from 'react';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall';
import createNotification from '../Utilities/CreateNotification';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import { store } from '../../Store';
import { useNavigate, useLocation } from 'react-router-dom';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';

const SlackIntegration = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { userProfile, activeSubscriptions } = state;
  const { hasSlackConfigured } = userProfile;
  const { planName } = activeSubscriptions;

  const planCondition = planName === 'Mogul' || planName === 'Enterprise';

  const { generalApiCall } = useGeneralApiCall();
  const { agencyUser } = useTopicOrClientWord();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const logPageMetadata = useRef();
  logPageMetadata.current = () => {
    let title = `Integration with Slack - Settings - PolicyMogul`;
    document.title = title;
    let locationToStorage = {
      title: title,
      storageLocation: { ...location },
    };
    dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
  };

  const callIntegration = async () => {
    try {
      setIsLoading(true);
      const pathname = '/api/slack/authorize-url';
      const method = 'get';
      const url = await generalApiCall({ pathname, method, needsAuthentication: true });
      if (url) {
        window.open(url, '_self');
      }
      setIsLoading(false);
    } catch (error) {
      createNotification('danger', `An error ocurred.`);
    }
  };

  const [heightContainer, containerRef] = useHeightContainer();

  useEffect(() => {
    logPageMetadata.current();
  }, []);

  return (
    <div className='pt-4 pt-lg-5 settings-content-component email-alerts-page'>
      <div
        className='settings-box pb-lg-0 pt-4 position-relative d-flex flex-column'
        ref={containerRef}
        style={{ height: `${heightContainer}px` }}
      >
        <div className='p-3 px-lg-5'>
          <h3 className='main-title topics-title mb-1'>Integration with Slack</h3>
          <p className='main-light-text mb-4'>Set up immediate alerts via Slack for any topic</p>
          <button
            className={`general-button action-button ${planCondition ? '' : 'action-button-disabled'} px-3 mb-5 text-left`}
            onClick={() => {
              if (planCondition) {
                if (!isLoading) {
                  callIntegration();
                }
              } else {
                navigate('/pricing');
              }
            }}
            style={{ minWidth: '200px' }}
            data-tooltip-content={
              planCondition ? '' : `Available on the${agencyUser ? '' : ' Mogul and'}  Enterprise plan`
            }
            data-tooltip-id={'settings-tooltip'}
          >
            {isLoading ? (
              <i className='fas fa-spinner fa-spin mr-2' />
            ) : (
              <span className='bg-main-white mr-2 rounded' style={{ padding: '3px 4px' }}>
                <img
                  style={{
                    verticalAlign: 'middle',
                    marginTop: '-3px',
                    opacity: planCondition ? 1 : 0.6,
                    width: '18px',
                  }}
                  className='d-inline-block'
                  src={`${process.env.REACT_APP_CDNURL}/images/20230716-slack-icon.svg`}
                  alt='slack logo'
                />
              </span>
            )}
            {hasSlackConfigured ? 'Reconnect' : 'Connect'} to Slack{' '}
            {!planCondition ? <span className='icon-padlock ml-2' /> : ''}
          </button>
        </div>

        <div className='bg-light-blue-hovered px-lg-5 p-3 pt-4 flex-grow-1 border-top'>
          <img src={`${process.env.REACT_APP_CDNURL}/images/20230718-slack-example.png`} alt='slack alert example' />
        </div>
      </div>
    </div>
  );
};

export default SlackIntegration;
