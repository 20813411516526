import { Link } from 'react-router-dom';
import normalize from '../../Dashboard/utilities/normalizeString';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import { formatGeneralDate } from '../../Dashboard/helpers/formatGeneralDate';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';
import uppercaseFirstLetter from '../../Dashboard/utilities/uppercaseFirstLetter';
import { useContext, useRef, useState } from 'react';
import { useTypeOfUsers } from '../../Dashboard/sidebar/parliamentarianUsers';
import { store } from '../../Store';
import useIsMobile from '../../Dashboard/utilities/useIsMobile';
import { Portal } from 'react-portal';
import { useEffect } from 'react';
import CustomScrollbar from '../../Common/CustomScrollbar';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';

const MyContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state ?? {};

  const { results, isLoading, individualPage } = props;
  const [heightContainer, containerRef] = useHeightContainer();
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      <div className='flex-grow-1'>
        <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
          {!individualPage && (
            <div className='main-sticky z-200'>
              <div className={`bg-white monitor-items-bar inbox-items-bar trends-subnav-bar position-relative z-10`}>
                <div className='mx-auto main-content-wrapper px-lg-5 px-3 pt-2'>
                  <div className='flex-centered'>
                    <span className='icon-bot title-h2 inline-block mr-3' />
                    <SubNavText />
                  </div>
                </div>
                <div className='mx-auto main-content-wrapper overflow-auto px-lg-5 px-3 pb-4'>
                  <AIAssistantCreateButtons />
                </div>
              </div>
              <div className='mx-auto main-content-wrapper px-lg-5 px-3 flex-centered justify-content-between flex-wrap bg-grey py-3 pt-lg-4'>
                <h3 className='title-h4-bold mb-0'>My content</h3>
              </div>
            </div>
          )}

          <div
            className={`mx-auto main-content-wrapper ${individualPage ? 'px-3 pl-lg-4 pr-lg-5 pt-2' : 'px-lg-5 px-3'} d-flex flex-column`}
          >
            <div
              ref={containerRef}
              className={`box-top-rounded p-lg-5 p-3 writing-my-content`}
              style={{ minHeight: `${heightContainer}px` }}
            >
              {isLoading ? (
                <SkeletonContent />
              ) : (
                <>
                  {results?.length > 0 ? (
                    <ul className='policy-list px-0 items-list'>
                      {results?.map((item) => {
                        const { id, contentType, title, createdAt } = item;
                        return (
                          <Link
                            to={{ pathname: `/ai-assistant/content/${id}`, state: { influencePage: individualPage } }}
                            className={`content-item-link my-2`}
                            key={`item-${contentType}-${id}`}
                          >
                            <li className={`p-3`}>
                              <div className='pl-1'>
                                <p className='text-grey-description mb-2 paragraph-p3'>
                                  {contentType === 'ParliamentarianEDM'
                                    ? 'EDM'
                                    : uppercaseFirstLetter(TransformFromPascalCase(contentType))}{' '}
                                  <span className='heading-date'>
                                    <span className='dot' />
                                  </span>{' '}
                                  Created {formatGeneralDate(createdAt)}
                                </p>
                                <h4 className='content-item-title mb-0'>{title}</h4>
                              </div>
                            </li>
                          </Link>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className='empty-state-grid-item text-center' style={{ marginTop: '175px' }}>
                      <p
                        className='title-h4 text-center main-light-text mb-0 text-center mx-auto'
                        style={{ maxWidth: '500px' }}
                      >
                        You haven’t written anything with PolicyMogul’s AI assistant yet{' '}
                        {newAgencyPlan ? `for ${team?.teamName}` : ''}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </CustomScrollbar>
      </div>
    </>
  );
};

const AIAssistantCreateButtons = (props) => {
  const { influencePage } = props;
  const { isParliamentarian } = useTypeOfUsers();
  const createTypes = () => {
    let types = ['news release', 'email to parliamentarians', 'media interview'];
    if (isParliamentarian) {
      types = ['debate contribution', 'question', 'EDM', 'response to a constituent'];
    }
    return types;
  };
  const types = createTypes();
  return (
    <div className='pt-4 d-flex writing-creation-buttons'>
      {!isParliamentarian && <CreateLobbyingMaterialButton influencePage={influencePage} />}
      {types.map((item) => {
        return (
          <Link
            key={`item-${normalize(item)}`}
            className='nowrap-item creation-button py-1 px-3 mr-lg-3 py-lg-2 action-button rounded-button px-lg-5 mr-2'
            to={{
              pathname: `${influencePage ? `/influence/ai/${normalize(item)}` : `/ai-assistant/create/${normalize(item)}`}`,
              state: { influencePage: influencePage },
            }}
          >
            {item === 'media interview' ? 'Prepare for' : 'Write'}{' '}
            {item === 'EDM' || item === 'email to parliamentarians' ? 'an' : 'a'} {item}
          </Link>
        );
      })}
    </div>
  );
};

const SkeletonContent = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div>
        <ul className='policy-list px-0 items-list'>
          {Array(5)
            .fill()
            .map((item, index) => (
              <li className={`my-2`} key={index}>
                <div className='p-3 align-items-center'>
                  <div className='pr-lg-5 pr-2 pl-0'>
                    <Skeleton height={19} width={`80%`} />
                    <Skeleton height={19} width={`30%`} />
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </SkeletonTheme>
  );
};

const CreateLobbyingMaterialButton = (props) => {
  const { influencePage } = props;
  const [isOpen, setIsOpen] = useState(false);
  const contentTypes = ['Briefing material', 'Policy ask', 'Research', 'News release'];
  const optionsElement = useRef();
  const optionsContent = useRef();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        optionsContent.current &&
        !optionsContent.current.contains(event.target) &&
        optionsElement.current &&
        !optionsElement.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsContent, optionsElement]);

  const { width, top, height, left } = optionsElement?.current?.getBoundingClientRect() ?? {};
  const isMobile = useIsMobile();

  return (
    <div className='position-relative' ref={optionsElement}>
      <button
        className='nowrap-item creation-button create-lobbying-material-button py-1 px-3 mr-lg-3 py-lg-2 action-button rounded-button px-lg-5 mr-2'
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`dropdown-item-element ${isOpen ? 'open' : ''}`}>Create lobbying material</span>
      </button>
      {isOpen && (
        <Portal>
          <div
            className='box-shadow box-rounded px-4 py-3 position-fixed z-max'
            ref={optionsContent}
            style={{ top: top + height + (isMobile ? 5 : 10), width: width - (isMobile ? 8 : 16), left: left }}
          >
            {contentTypes.map((item) => {
              return (
                <Link
                  key={`item-${normalize(item)}`}
                  className={`w-100 text-center d-block creation-button py-1 px-3 py-lg-2 action-button rounded-button px-lg-5 mb-3 ${influencePage ? 'influence-assistant-button' : ''}`}
                  to={{
                    pathname: `${influencePage ? `/influence/ai/${normalize(item)}` : `/ai-assistant/create/${normalize(item)}`}`,
                    state: { influencePage: influencePage },
                  }}
                >
                  {item}
                </Link>
              );
            })}
          </div>
        </Portal>
      )}
    </div>
  );
};

const SubNavText = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { userProfile } = state;
  const { agencyUser } = useTopicOrClientWord();
  const createText = () => {
    if (agencyUser) {
      return 'public affairs agencies';
    }
    switch (userProfile?.userType) {
      case 'Campaigner':
        return 'campaigners';
      case 'Lobbying or PR agency':
        return 'public affairs agencies';
      case 'Consultant':
        return 'public affairs consultants';
      case 'media':
        return 'public affairs media work';
      default:
        return 'public affairs work';
    }
  };
  const { isParliamentarian } = useTypeOfUsers();

  return (
    <>
      {isParliamentarian ? (
        <p className='mb-0'>
          {' '}
          PolicyMogul’s AI writing assistant helps parliamentarians and their staff to write and improve speeches,
          questions, EDMs and more.{' '}
          <a
            href={'http://support.policymogul.com/en/articles/6930562-writing-assistant-for-parliamentarians'}
            target='_blank'
            rel={'noopener noreferrer'}
            className='simple-link-button inline-block'
          >
            Learn more
          </a>
        </p>
      ) : (
        <p className='mb-0'>
          PolicyMogul’s AI writing assistant is powered by OpenAI, and optimised for {createText()}
        </p>
      )}
    </>
  );
};

export { SubNavText, SkeletonContent, AIAssistantCreateButtons };
export default MyContent;
