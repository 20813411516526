import { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { store } from '../../Store';

const unseenNumbers = {
  KeyUpdates: { unseenNumber: 'KeyUpdate', lastSeenMarker: 'keyUpdateFeedItemId' },
  Consultations: { unseenNumber: 'Consultation', lastSeenMarker: 'consultationFeedItemId' },
  Legislation: { unseenNumber: 'Legislation', lastSeenMarker: 'legislationFeedItemId' },
  ParliamentaryRecord: { unseenNumber: 'ParliamentaryRecord', lastSeenMarker: 'parliamentaryRecordFeedItemId' },
  CommitteePublications: { unseenNumber: 'CommitteePublication', lastSeenMarker: 'committeePublicationFeedItemId' },
  LibraryMaterial: { unseenNumber: 'CommonsLibraryArticle', lastSeenMarker: 'commonsLibraryArticleFeedItemId' },
  SocialMedia: { unseenNumber: 'Tweet', lastSeenMarker: 'tweetFeedItemId' },
  Influence: { unseenNumber: 'UserContent', lastSeenMarker: 'userContentFeedItemId' },
  UpcomingEvents: { unseenNumber: 'Event', lastSeenMarker: 'eventFeedItemId' },
};

const useRememberPosition = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const location = useLocation();
  const params = useParams();
  const { mainSection } = params;

  const { activeResults, contentResults, unseenNumbersCurrentList, search, lastSeenMarkers, itemsViewed } = state;
  const { list } = search;

  const rememberPosition = (section, scrollBarRef, index) => {
    let currentSection = contentResults[activeResults][section];
    let sectionUnseenProperties = unseenNumbers[section];
    let { unseenNumber, lastSeenMarker } = sectionUnseenProperties ?? {};
    let newSectionObject = { ...currentSection };
    const feedItem = newSectionObject?.feedItemFlags?.[index];
    const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;

    let unseenItem =
      !!lastSeenMarkers[lastSeenMarker] &&
      lastSeenMarkers[lastSeenMarker] < feedItem?.feedItemId &&
      !isBackpopulatedCondition;

    if (!!list && unseenItem) {
      newSectionObject.feedItemFlags[index].feedItemId = -1;
    }

    if (scrollBarRef?.current) {
      newSectionObject = {
        ...newSectionObject,
        scrollPosition: scrollBarRef.current.viewScrollTop ?? 0,
        listSection: mainSection,
      };
    }

    let results = {
      ...contentResults,
      [activeResults]: {
        ...contentResults[activeResults],
        [section]: newSectionObject,
      },
    };
    if (!!list && unseenItem) {
      let newUnseenNumbers = { ...unseenNumbersCurrentList };
      newUnseenNumbers[unseenNumber] = (newUnseenNumbers[unseenNumber] ?? 0) - 1;
      let item = newSectionObject?.hits?.[index];
      let newItemsViewed = [...itemsViewed];
      if (!newItemsViewed.includes(item?.objectID)) {
        newItemsViewed.push(item?.objectID);
      }
      dispatch({ type: 'MODIFY_SECTION', parameter: 'itemsViewed', value: newItemsViewed });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'unseenNumbersCurrentList', value: newUnseenNumbers });
    }
    if (scrollBarRef) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'clickedOnContentUrl',
        value: `${location.pathname}${location.search}${location.hash}`,
      });
    }
    dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: results });
  };

  return { rememberPosition };
};

export { unseenNumbers };
export default useRememberPosition;
