import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import addMetaTags from '../utilities/addMetaTags';
import callHightlightContentAPI from '../utilities/callHightlightContentAPI';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions';
import LibraryMaterialContent from './LibraryMaterialContent';
import callContentRelated from '../utilities/callContentRelated';
import { store } from '../../Store';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import axios from 'axios';
import { getIdPositionOnSection, createNavigationLinksOnSection } from '../helpers/navigationFunctions.js';
import normalizeString from '../utilities/normalizeString';
import useGetHighlightingKeywords from '../hooks/useGetHighlighingKeywords';
import useCallCollectContent from '../hooks/useCallCollectContent';
import useGetMatchingKeywordsListsForContent from '../hooks/useMatchingKeywordsListsForContent';
import * as Sentry from '@sentry/react';

const LibraryMaterialContentControl = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { contentResults, activeResults, activePlan, search, referenceState } = state;
  const { searchState, activeSearch } = search;
  const { activeReference } = referenceState;
  const { LibraryMaterial } = contentResults[activeResults];

  const { isAuthenticated } = useAuth0();
  const params = useParams();
  const location = useLocation();
  const { id } = params;
  const getIdPosition = getIdPositionOnSection(LibraryMaterial, id);
  const createNavigationLinks = createNavigationLinksOnSection(LibraryMaterial);
  const { andKeywords, queryHighlight } = useGetHighlightingKeywords();

  const [idPosition, setIdPosition] = useState(getIdPosition());
  const [navigationLinks, setNavigationLinks] = useState(createNavigationLinks(getIdPosition()));
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRelatedContent, setIsLoadingRelatedContent] = useState(true);
  const { generalApiCall } = useGeneralApiCall();
  const { callCollectContent } = useCallCollectContent();
  const { getMatchingKeywordsListsAndFeedback } = useGetMatchingKeywordsListsForContent();

  const barNavigationConditional =
    idPosition !== null && (navigationLinks.next !== null || navigationLinks.prev !== null);

  const loadContent = async (source) => {
    try {
      setIsLoading(true);
      setIsLoadingRelatedContent(true);
      const pathname = `/api/commons-library-article/${id}`;
      const method = 'get';
      const content = await generalApiCall({ pathname, method });
      if (content) {
        const contentHightlighted = await highlightContent(content, source);
        let contentLoaded = {
          ...LibraryMaterial,
          content: contentHightlighted,
          currentId: parseInt(id),
        };
        let { feedback, keywordsFromLists, keywordsListsIds } = await getMatchingKeywordsListsAndFeedback({
          objectId: content?.objectId,
        });
        contentLoaded = {
          ...contentLoaded,
          content: {
            ...contentLoaded?.content,
            feedback,
            keywordsFromLists,
            keywordsListsIds,
          },
        };
        updateResultsOnState(contentLoaded);
        updateMetaTags(content);
        setIsLoading(false);
        let relatedContent = await callRelatedContent(content, source);
        if (relatedContent) {
          contentLoaded = {
            ...contentLoaded,
            contentRelated: relatedContent,
          };
        }
        updateResultsOnState(contentLoaded);
        setIsLoadingRelatedContent(false);
        await callCollectContent({ ...content, contentUrl: content?.canonicalUrl });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const highlightContent = async (content, source) => {
    const { objectId, objectType } = content ?? {};
    let propertiesHighlight = [objectId, objectType, queryHighlight, ChangeKeywordsExclusions(andKeywords), source];
    let contentHightlighted;
    let highlightCondition = activeSearch || activeReference;

    if (highlightCondition) {
      contentHightlighted = await callHightlightContentAPI(propertiesHighlight);
    }

    let newData = content;
    if (highlightCondition && !!contentHightlighted) {
      const { highlightedTitle, highlightedHtmlBody } = contentHightlighted;
      const { title, body } = content;
      newData = {
        ...content,
        titleHighlighted: !!highlightedTitle ? highlightedTitle : title,
        body: !!highlightedHtmlBody ? highlightedHtmlBody : body,
      };
    }
    return newData;
  };

  const updateResultsOnState = (newData) => {
    let data = {
      ...contentResults,
      [activeResults]: {
        LibraryMaterial: newData,
      },
    };
    dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: data });
  };

  const updateMetaTags = (content) => {
    //UPDATE METATAGS
    let { descriptionMeta, title: contentTitle } = content ?? {};
    let title = `${contentTitle} - Library material - PolicyMogul`;
    let hash = window.location.hash;
    let description = descriptionMeta;
    let contentUrl = `/library-material/${id}/${normalizeString(title)}`;
    addMetaTags({ title, hash, location, dispatch, contentUrl, description });
  };

  const initialCall = useRef();
  initialCall.current = (source) => {
    try {
      let activePlanItems = Object.keys(activePlan);
      let stateReady = activePlanItems.length > 0;
      if ((stateReady || !isAuthenticated) && searchState) {
        setIdPosition(getIdPosition());
        setNavigationLinks(createNavigationLinks(getIdPosition()));
        loadContent(source);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const callRelatedContent = async (content, source) => {
    //CALLS NECESSARY TO DO AFTER THE CONTENT IS SHOWN TO SPEED UP THE PAGE
    const { objectId, objectType } = content;
    let contentRelated = await callContentRelated(objectId, objectType, source);
    return contentRelated;
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Library content cancelled by the user');
    };
  }, [id, searchState, activePlan]);

  return (
    <LibraryMaterialContent
      currentNew={LibraryMaterial?.content ?? null}
      barNavigationConditional={barNavigationConditional}
      isLoading={isLoading}
      isLoadingRelatedContent={isLoadingRelatedContent}
      navigationLinks={navigationLinks}
      LibraryMaterial={LibraryMaterial}
      activePlan={activePlan}
      setIsLoading={setIsLoading}
    />
  );
};

export default LibraryMaterialContentControl;
