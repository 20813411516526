import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { net_api_url } from '../../../../Store';
import useGetAccessToken from '../../../apiCalls/useGetAccessToken';
import { useGroupFunctions } from '../../CrmGroups/GroupFunctions';

const useGetContactById = () => {
  const { getAccessToken } = useGetAccessToken();
  const { isAuthenticated } = useAuth0();
  const { listingGroupsForStakeholderOrContact } = useGroupFunctions();

  const getContactById = async (id, source) => {
    let token = isAuthenticated ? await getAccessToken() : '';
    let url = `${net_api_url}/api/crm-contact/query`;
    let requestBody = {
      filters: [
        {
          field: 'id',
          operator: 'str_eq',
          value: id.toString(),
        },
      ],
      query: '',
      pageSize: 1,
      pageNumber: 1,
    };

    let requestOptions = {
      cancelToken: source.token,
    };

    if (isAuthenticated) {
      requestOptions.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    let callResults = await Axios.post(url, requestBody, requestOptions);
    let results = callResults.data?.hits;
    if (!!results.length) {
      let result = results[0];
      let groups = await listingGroupsForStakeholderOrContact({ contactId: result?.id });
      if (!!groups) {
        result = { ...result, groups };
      }
      return result;
    }
  };

  return { getContactById };
};

export default useGetContactById;
