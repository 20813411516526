import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { store } from '../../Store.js';
import LoginButtons from '../Navigation/LoginButtons';
import { useAuth0 } from '@auth0/auth0-react';
import returnCorrectWPUrl from '../utilities/returnCorrectWPUrl.js';
import { useHeightContainer } from '../utilities/useHeightContainer.js';
import NavPages from '../Navigation/NavPages.js';
import CustomScrollbar from '../../Common/CustomScrollbar.js';

const NavigationSidebar = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  const [heightContainer, containerRef] = useHeightContainer();
  const { isAuthenticated } = useAuth0();

  const closeMenu = () => {
    if (!state.isHiddenMenu) {
      let hamburgerMenu = document.getElementsByClassName('hamburger')[0];
      hamburgerMenu.classList.remove('is-active');
      dispatch({ type: 'MODIFY_SECTION', parameter: 'isHiddenMenu', value: true });
    }
  };

  return (
    <div className={`main-navigation ${state.isHiddenMenu ? '' : 'show-menu'}`}>
      <div
        className={`intercom-main-navigation pt-lg-4 ${isAuthenticated ? 'pt-5' : ''}`}
        data-intercom-target='Side bar element'
      >
        <div>
          {!isAuthenticated && (
            <div
              className={`main-navigation-logo pt-4 ${!isAuthenticated ? 'navigation-container-mobile-logged-out' : ''}`}
            >
              <LoginButtons />
            </div>
          )}
          <div ref={containerRef} />
          <CustomScrollbar
            style={{ height: `${heightContainer - 1}px` }}
            maximalThumbYSize={100}
            className={'simple-scrollbar'}
          >
            <div
              className={`navigation-container d-flex justify-content-between flex-column navigation-container-mobile-logged-out`}
            >
              <div>
                {!isAuthenticated && (
                  <div className='main-navigation-logo'>
                    <div className='main-navigation-mobile'>
                      <Link
                        className={`static-page-link home-link`}
                        to='/'
                        onClick={() => {
                          closeMenu();
                        }}
                      >
                        Home
                      </Link>
                    </div>
                  </div>
                )}
                <div className='sidebar-nav-pages'>
                  <NavPages />
                </div>
              </div>

              <div className='bottom-terms'>
                <div className='row mx-0 pt-2 pb-3 align-items-center bottom-terms-container'>
                  <a href={`${returnCorrectWPUrl()}/terms-and-conditions/`} rel='noopener noreferrer' target='_blank'>
                    T&Cs
                  </a>
                  <div className='dot'></div>
                  <a href={`${returnCorrectWPUrl()}/privacy-policy/`} rel='noopener noreferrer' target='_blank'>
                    Privacy policy
                  </a>
                  <div className='dot'></div>
                  <p className='mb-0'>
                    Logos by{' '}
                    <a href='https://clearbit.com/' rel='noopener noreferrer' target='_blank'>
                      Clearbit
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );
};

export default NavigationSidebar;
