import { useContext, useEffect, useRef } from 'react';
import { store } from '../../Store';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import idsWithoutImages from '../key_updates/idsWithoutImages';
import getUrlParam from '../utilities/getUrlParam';
import normalize from '../utilities/normalizeString';
import { useLocation } from 'react-router-dom';
import ChangeKeywordsCategory from '../utilities/changeKeywordsCategory';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions';

const partyColours = {
  labour: '196, 44, 61',
  'labour-independent': '196, 44, 61',
  'independent-labour': '196, 44, 61',
  'labour-co-op': '196, 44, 61',
  'labour-co-op-': '196, 44, 61',
  conservative: '93, 156, 222',
  'conservative-independent': '93, 156, 222',
  'democratic-unionis': '0, 0, 128',
  'democratic-unionist-party': '0, 0, 128',
  dup: '0, 0, 128',
  'non-affiliated': '77, 77, 77',
  'lord-speaker': '77, 77, 77',
  speaker: '77, 77, 77',
  'liberal-democrat': '238, 197, 67',
  'lib-dems': '238, 197, 67',
  'green-party': '129, 175, 63',
  'scottish-national-party': '250, 245, 144',
  snp: '250, 245, 144',
  'social-democratic-labour-party': '63, 132, 40',
  'sinn-f-in': '153, 204, 0',
  'plaid-cymru': '63, 132, 40',
  'ulster-unionist-party': '153, 204, 255',
};

const stakeholderUrlImage = ({ imageId }) => {
  const placeHolderUrl = `${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png`;
  const placeHolderMime = 'image/png';
  const urlImage =
    imageId !== 0 && imageId !== null && !idsWithoutImages.includes(imageId)
      ? `${process.env.REACT_APP_DOTNETAPIURL}/api/parliament-today/image/${imageId}`
      : `${process.env.REACT_APP_DOTNETAPIURL}/api/cors/resource?url=${encodeURIComponent(placeHolderUrl)}&mime=${encodeURIComponent(placeHolderMime)}&cache=true`;
  return urlImage;
};

const useCallMostRelevantStakeholders = (props) => {
  const { teamId } = props ?? {};
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;

  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);

  const { generalApiCall } = useGeneralApiCall();
  const topicParameter = getUrlParam('topic-id');
  const location = useLocation();

  const mountedHook = useRef(true);
  useEffect(() => {
    return () => {
      mountedHook.current = false;
    };
  }, [location.pathname, location.search]);

  const createImageUrl = (imageId, party) => {
    return new Promise((resolve, reject) => {
      const urlImage = stakeholderUrlImage({ imageId });
      let base_image = new Image();
      base_image.crossOrigin = 'anonymous';
      base_image.src = urlImage;
      base_image.onload = () => {
        let tmpCanvas = document.createElement('canvas');
        let tmpCtx = tmpCanvas.getContext('2d');
        tmpCanvas.width = 40;
        tmpCanvas.height = 40;
        tmpCtx.save();
        tmpCtx.beginPath();
        tmpCtx.arc(20, 20, 17, 0, 2 * Math.PI);
        tmpCtx.fillStyle = partyColours?.[party] ? `rgb(${partyColours[party]})` : '0, 18, 43, 0.5';
        tmpCtx.fill();
        tmpCtx.closePath();

        tmpCtx.beginPath();
        tmpCtx.arc(20, 20, 16, 0, 2 * Math.PI);
        tmpCtx.clip();
        tmpCtx.drawImage(base_image, 4, 4, 32, 32);
        resolve(tmpCanvas);
      };
      base_image.onerror = reject;
    });
  };
  const loadImages = async (data) => {
    let images = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (mountedHook.current) {
        let canvas;
        try {
          canvas = await createImageUrl(item.imageId, item.party);
        } catch {}
        images.push(canvas);
      } else {
        return;
      }
    }
    return images;
  };

  const callRelevantStakeholders = async ({
    topic = topicParameter
      ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
      : keywordsListsToUse[0],
    source,
    freeSearch,
  }) => {
    try {
      if (topic && !freeSearch) {
        let pathname = `/api/${teamId ? 'widget/stakeholder-query-ranked' : `${topic?.prospective ? 'search/query' : 'stakeholder/query-ranked'}`}`;
        let method = 'post';
        let requestProperties = {
          pageSize: 50,
          filters: [
            { field: 'categories', value: 'MP', operator: 'str_eq' },
            { field: 'categories', value: 'Lord', operator: 'str_eq' },
          ],
          pageNumber: 1,
          sortMode: 'relevance',
        };

        if (topic?.prospective) {
          requestProperties = {
            ...requestProperties,
            type: 'stakeholder',
            query: ChangeKeywordsCategory(topic?.keywords),
            andKeywords: ChangeKeywordsExclusions(topic?.andKeywords),
            mustNotQueries: ChangeKeywordsExclusions(topic?.keywordExclusions),
          };
        } else {
          requestProperties = {
            ...requestProperties,
            keywordListId: topic?.id,
          };
        }

        if (teamId) {
          requestProperties = {
            ...requestProperties,
            teamId,
          };
        }
        let results = await generalApiCall({
          pathname,
          method,
          requestProperties,
          needsAuthentication: true,
          notShowErrorMessage: true,
          requestSource: source,
        });
        if (results) {
          mountedHook.current = true;
          if (results.currentlyProcessing) {
            return { currentlyProcessing: true };
          } else {
            let chartLabels = { x: 'Most interested', y: 'Most influential' };
            let data = results.hits?.map((item) => {
              let { title, summary, positionCategoryRank, totalContributionCount, party } = item;
              let x, y;
              y = positionCategoryRank;
              x = totalContributionCount;
              return {
                ...item,
                y,
                x,
                imageId: item.imageId,
                name: title,
                textBody: summary,
                party: party ? normalize(party) : null,
              };
            });
            let images = await loadImages(data);
            return {
              graphData: data,
              pointInTime: results?.pointInTime,
              images,
              chartLabels,
            };
          }
        }
      }
      if (freeSearch) {
        return { graphData: [], images: [] };
      }
    } catch (error) {}
  };

  return { callRelevantStakeholders };
};

export { partyColours, stakeholderUrlImage };
export default useCallMostRelevantStakeholders;
