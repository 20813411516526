import { useContext, useRef } from 'react';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import { store } from '../../Store';
import normalize from '../../Dashboard/utilities/normalizeString';
import AddTopicButton from '../../Dashboard/Navigation/AddTopicButton';
import createNotification from '../Utilities/CreateNotification';
import CustomScrollbar from '../../Common/CustomScrollbar';

const WebsiteSettingsTopics = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;
  const {
    selectedTopic = {},
    setSelectedTopic,
    selectedTopics,
    setSelectedTopics,
    agencyUser,
    returnSelected,
    hideButtons,
    topicsVariant,
    customItemElement,
    hideKeywords,
    disabledCondition,
    onSelectCallback,
    keywordsListsToUseProp,
    hideAddTopic,
    firstItem,
    changeTopicsWord,
    heightReference,
  } = props;
  const keywordsListsToUse =
    keywordsListsToUseProp ?? (!agencyUser ? keywordsLists : keywordsLists.filter((item) => item.id !== null));
  const multipleTopics = !!selectedTopics && !!setSelectedTopics;
  const listsContainer = useRef(null);
  const referenceHeight = heightReference ?? (firstItem ? 190 : 230);
  const elements = useRef([]);
  const { transformTopicOrClientWord } = useTopicOrClientWord();

  return (
    <div className='keyword-lists-website-feeds'>
      <CustomScrollbar
        className={`main-content-scrollbar website-list-scroll-component main-content-section ${
          topicsVariant ? 'website-list-scroll-component-topics' : ''
        }`}
        maximalThumbYSize={100}
        autoHeight
        autoHeightMin='100%'
        autoHeightMax={`${referenceHeight}px`}
      >
        <div
          className='website-popup-container-lists large-container'
          style={{ paddingBottom: '1px' }}
          ref={listsContainer}
        >
          {keywordsListsToUse?.length > 0 ? (
            <>
              {firstItem && (
                <div
                  ref={(div) => (elements.current[keywordsListsToUse?.length] = div)}
                  className={`${
                    hideButtons ? '' : multipleTopics ? `checkbox-item` : `radio-button uponclick-radio-button`
                  } ${
                    topicsVariant ? 'website-feed-topics-variant dropdown-keywordlist' : ''
                  } dropdown-firstItem position-relative`}
                  key={`topicItem-firstItem`}
                >
                  <label className={`${multipleTopics ? 'create-email-alert-label' : ''} font-weight-normal`}>
                    <p className='mb-0 line-height-1-5'>{firstItem}</p>
                  </label>
                </div>
              )}
              {keywordsListsToUse.map((item, index) => {
                const { description, id, name } = item;
                return (
                  <div
                    ref={(div) => (elements.current[index] = div)}
                    className={`${
                      hideButtons
                        ? ''
                        : multipleTopics
                          ? `checkbox-item`
                          : `radio-button uponclick-radio-button ${
                              id === null ? 'master-topic-option' : ''
                            } ${description ? '' : 'pb-2'}`
                    } ${topicsVariant ? 'website-feed-topics-variant dropdown-keywordlist' : ''} position-relative`}
                    key={`topicItem-${id}`}
                    style={{
                      marginTop: index === 0 && firstItem ? '7px' : '',
                    }}
                  >
                    <input
                      id={`${normalize(name)}-${id}`}
                      className={`${hideButtons ? 'd-none' : ''}`}
                      onChange={() => {
                        if (multipleTopics) {
                          let newItems = [...selectedTopics];
                          let itemPosition = newItems.findIndex((item) => item.id === id);
                          if (itemPosition >= 0) {
                            if (newItems.length === 1) {
                              createNotification('danger', 'You must have at least 1 topic selected');
                            } else {
                              newItems.splice(itemPosition, 1);
                              returnSelected ? setSelectedTopics(newItems, item) : setSelectedTopics(newItems);
                            }
                          } else {
                            returnSelected
                              ? setSelectedTopics([...selectedTopics, item], item)
                              : setSelectedTopics([...selectedTopics, item]);
                          }
                        } else {
                          setSelectedTopic(item);
                        }
                        if (onSelectCallback) {
                          onSelectCallback(item);
                        }
                      }}
                      type={multipleTopics ? 'checkbox' : 'radio'}
                      checked={
                        multipleTopics ? !!selectedTopics.find((item) => item?.id === id) : id === selectedTopic.id
                      }
                      disabled={disabledCondition ? disabledCondition(item) : false}
                    />
                    {topicsVariant && <span className={`icon-topics-thin`} />}
                    <label
                      htmlFor={`${normalize(name)}-${id}`}
                      className={`${multipleTopics ? 'create-email-alert-label' : ''} font-weight-normal`}
                    >
                      <p className='mb-0 line-height-1-5'>
                        {changeTopicsWord && name.startsWith('All updates')
                          ? name.replace('All updates', `All ${transformTopicOrClientWord({ plural: true })}`)
                          : name}
                      </p>
                      {description && !hideKeywords && (
                        <p className='topic-description-field mb-0 mt-1'>{description}</p>
                      )}
                      {customItemElement && customItemElement(item)}
                    </label>
                    {topicsVariant && id === selectedTopic.id && <span className='icon-tick topic-selected-mark' />}
                  </div>
                );
              })}
            </>
          ) : (
            <p className='py-1 mb-2 main-light-text'>
              <em>You do not currently have any {transformTopicOrClientWord({ plural: true })} set up.</em>
            </p>
          )}
        </div>
      </CustomScrollbar>
      {!hideAddTopic && <AddTopicButton keywordsLists={keywordsLists} />}
    </div>
  );
};

export default WebsiteSettingsTopics;
