import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../Store.js';
import useCompileFiltersFunction from './useCompileFilters.js';
import FiltersData from './FiltersData/index.js';
import FiltersComponent from './ui/FiltersComponent';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import changeItemInsideArray from '../helpers/changeItemInsideArray.js';
import useCompileInitialFilters from './useCompileInitialFilters.js';
import useIsMobile from '../utilities/useIsMobile.js';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter.js';
import { useAuth0 } from '@auth0/auth0-react';
import useGetSectionFilters from './useGetSectionFilters.js';
import { useLocation, useParams } from 'react-router-dom';

let useFilterFromUrl = true;

const Filters = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { section, typeOfContent, useOutside, dropDownFilters, onlySort } = props;
  const {
    search,
    contentResults,
    activeResults,
    clearFilters,
    referenceState,
    contentFilters,
    activePlan,
    updateFilterFromStore,
  } = state;
  const { enableReferenceSection, subscriptionStatus } = activePlan;
  const { activeReference } = referenceState;
  const sectionName = typeOfContent ?? section;
  const sectionResults = contentResults[activeResults][sectionName];
  const { isAuthenticated } = useAuth0();

  const [filtersState, setFiltersState] = useState([]);
  const { list, activeSearch, searchState, query } = search;
  const [componentQuery, setComponentQuery] = useState(query);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const params = useParams();

  const isMobile = useIsMobile(576);
  const { inboxSection } = useMainSectionParameter(params);
  const { compileFiltersFunction, activeResultsConditional } = useCompileFiltersFunction(section);

  const { compileInitialFilters } = useCompileInitialFilters();
  const { sectionStored } = useGetSectionFilters(sectionName);

  useEffect(() => {
    let sectionToStore = sectionStored;
    let initialFilters = compileInitialFilters(sectionToStore);
    applyFilters(initialFilters);
    setIsLoading(false);
    useFilterFromUrl = false;
    //export external filters (tweets people to monitor, to confirm consultations sort)
    compileAdditionalFilters(initialFilters);

    if (useFilterFromUrl) {
      setComponentQuery(query);
    } else {
      if (query !== componentQuery) {
        setComponentQuery(query);
      }
    }
  }, [searchState, activeSearch, list, query, referenceState, location.pathname]);
  useEffect(() => {
    if (clearFilters) {
      clearFiltersFunction();
    }
  }, [clearFilters]);

  useEffect(() => {
    //OP: update filters outside of Filters component by passing to store new filters
    if (updateFilterFromStore) {
      applyFiltersFunction(updateFilterFromStore);
      //OP: reset updateFilterTrigger
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'updateFilterFromStore',
        value: null,
      });
    }
  }, [updateFilterFromStore]);

  const clearFiltersFunction = () => {
    let enforceRecency = true;
    let defaultValues = JSON.parse(JSON.stringify(FiltersData()));
    //Clear consultations reference exclude filter
    let consultationFilterPosition = defaultValues.findIndex(
      (item) => item.name === 'Exclude closed consultations' && item.searchState === 'referenceSection'
    );
    defaultValues[consultationFilterPosition].value = false;
    //Clear cesclude retweets inbox
    let excludeRetweetsFilterPosition = defaultValues.findIndex(
      (item) => item.filterName === 'isRetweet' && item.searchState === 'inbox'
    );
    defaultValues[excludeRetweetsFilterPosition].value = false;

    let filtersSection = createFiltersWithSort(defaultValues, enforceRecency);
    applyFilters(defaultValues, enforceRecency);
    filtersFunction(filtersSection);

    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'clearFilters',
      value: false,
    });
    compileAdditionalFilters(defaultValues);
  };

  const clearFunction = () => {
    let sectionFilters = compileFiltersFunction(getDefaultSectionFilters());
    const { searchWithin, unread, filters } = sectionFilters ? sectionFilters : {};
    if ((searchWithin && searchWithin.value !== '') || unread || (filters && filters.length > 0)) {
      clearFiltersFunction();
    }
  };

  const createFiltersWithSort = (filters, enforceRecency) => {
    let sortFilters = FiltersWithSort(filters, enforceRecency);
    return sortFilters;
  };

  const applyFilters = (filters, enforceRecency) => {
    let filtersWithSortProperty = createFiltersWithSort(filters, enforceRecency);
    setFiltersLocalStorage(filtersWithSortProperty);
    setFiltersState(filtersWithSortProperty);
  };

  const setFiltersLocalStorage = (filters) => {
    let storedFilters = localStorage.getItem('contentFilters');
    let finalObject = {};
    if (storedFilters) {
      let filtersParsed = JSON.parse(storedFilters);
      if (Array.isArray(filtersParsed)) {
        finalObject = {
          ...finalObject,
          initialFilters: filtersParsed,
        };
      } else {
        finalObject = filtersParsed;
      }
    }
    let sectionToStore = sectionStored;
    finalObject[sectionToStore] = filters;
    let unreadFilter = filters.find((item) => item.name === 'See unread only');
    if (unreadFilter) {
      localStorage.setItem('seeUnreadFilter', unreadFilter.value.toString());
    }
    localStorage.setItem('contentFilters', JSON.stringify(finalObject));
  };

  const FiltersWithSort = (filters) => {
    let newFiltersData = filters.slice();
    //SET SELECTED OPTION TO FALSE (NECCESSARY TO CREATE A CONTROLLED INPUT)
    newFiltersData.forEach((item) => {
      let options = item.options;
      if (options && item.type !== 'sort') {
        options.forEach((option) => {
          if (option.selected === undefined) {
            option.selected = false;
          }
        });
      }
    });
    return newFiltersData;
  };

  const getDefaultSectionFilters = (filters) => {
    let filtersObject = filters ?? filtersState;
    let filtersToRender = filtersObject.filter(
      (filter) => filter.pages.includes(section) && activeResultsConditional(filter, filtersObject)
    );
    //CHANGE FILTERS ORDER
    filtersToRender = modifyFiltersOrder(filtersToRender);

    if (activeSearch && list !== null) {
      let unreadFilter = filtersToRender.find((item) => item.name === 'See unread only');
      if (unreadFilter) {
        unreadFilter.excludeFromPopup = true;
      }
    }

    return filtersToRender;
  };

  const modifyFiltersOrder = (filters) => {
    let modifiedFilters = [...filters];
    //AE: This will only work when we need to modify one filter on each section, for multiple filters we need to review function that call itself again
    let customOrderIndex = modifiedFilters.findIndex((item) => item.customOrder !== undefined);
    if (customOrderIndex >= 0) {
      let filterElement = modifiedFilters[customOrderIndex];
      let newPosition =
        (activeSearch || activeReference) && filterElement.activeSearchCustomOrder
          ? filterElement.activeSearchCustomOrder
          : filterElement.customOrder;
      modifiedFilters = changeItemInsideArray(modifiedFilters, customOrderIndex, newPosition);
    }
    return modifiedFilters;
  };

  const filterTypeModifiers = ({ basefilters, filter, newValue }) => {
    /*AE: This part is neccesary to the external items on Tweets */
    const { type, name, pages, searchState } = filter;
    let referenceState = basefilters ?? filtersState;
    let newFilters = JSON.parse(JSON.stringify(referenceState));
    let filterPosition = newFilters.findIndex(
      (item) =>
        item.name === name &&
        !item.hidden &&
        JSON.stringify(item.pages) === JSON.stringify(pages) &&
        item.searchState === searchState
    );
    let currentFilter;
    switch (type) {
      case 'toggle':
      case 'search':
        currentFilter = { ...filter, value: newValue };
        break;
      case 'sort':
      case 'sortMode':
        currentFilter = { ...filter, optionSelected: newValue };
        //Clean sentiment options because is a dependent filter ot the sort mode, very specific case.
        let sentimentFilterPosition = newFilters.findIndex((item) => item.searchState === 'sentimentSortModeCondition');
        if (
          sentimentFilterPosition >= 0 &&
          newFilters[sentimentFilterPosition].options.filter((item) => item.selected)?.length > 0
        ) {
          let newValue = { ...newFilters[sentimentFilterPosition] };
          newValue.optionSelected = '';
          newValue?.options?.forEach((item) => (item.selected = false));
          newFilters[sentimentFilterPosition] = newValue;
        }
        break;
      case 'date':
      case 'addedOn':
        currentFilter = {
          ...filter,
          optionSelected: newValue.optionSelected,
          options: newValue.options,
        };
        break;
      default:
        if (filter.type === 'parliamentaryRecordMultipleHierarchy') {
          if (newValue.filter((option) => option.selected).length > 0) {
            let thereIsQuestionItem = newValue.find((item) => item.selectedOptions.includes('Written Q&As'));
            if (!thereIsQuestionItem) {
              let questionFilter = newFilters.find((item) => item.name === 'Written Q&As');
              questionFilter?.options.forEach((item) => (item.selected = false));
            }
          }
        }
        currentFilter = { ...filter, options: newValue };
    }

    // Nation filter case
    if (filter.name === 'Nation' && newValue !== 'Westminster') {
      currentFilter = { ...filter, optionSelected: newValue };
      let sortFilterPosition = newFilters.findIndex(
        (item) => item.name === 'Sort' && item.type === 'sortMode' && item.searchState === 'list'
      );
      if (sortFilterPosition >= 0) {
        let newValue = { ...newFilters[sortFilterPosition] };
        newValue.optionSelected = 'Most vocal';
        newValue?.options?.filter((item) => item.name !== 'Most vocal')?.forEach((item) => (item.selected = false));
        newFilters[sortFilterPosition] = newValue;
      }
    }
    if (filter.name === 'Nation' && newValue === 'Westminster') {
      currentFilter = { ...filter, optionSelected: newValue };
      let sortFilterPosition = newFilters.findIndex(
        (item) => item.name === 'Sort' && item.type === 'sortMode' && item.searchState === 'list'
      );
      if (sortFilterPosition >= 0) {
        let newValue = { ...newFilters[sortFilterPosition] };
        newValue.optionSelected = 'Most relevant';
        newValue?.options?.filter((item) => item.name !== 'Most relevant')?.forEach((item) => (item.selected = false));
        newFilters[sortFilterPosition] = newValue;
      }
    }

    newFilters[filterPosition] = currentFilter;
    return newFilters;
  };

  const createSelectOption = (basefilters) => {
    return ({ filter, newValue }) => {
      if (isAuthenticated && subscriptionStatus !== 'TrialEnded') {
        let newFilters = filterTypeModifiers({ basefilters, filter, newValue });
        //EVENTS THAT SHOULD BE DISPATCHED AFTER SELECT AN OPTION
        applyFiltersFunction(newFilters);
      }
    };
  };

  const filtersFunction = (filters) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'contentFilters',
      value: { ...contentFilters, [sectionStored]: filters },
    });
  };

  const applyFiltersFunction = (newFilters) => {
    setFiltersLocalStorage(newFilters);
    compileAdditionalFilters(newFilters);
    setFiltersState(newFilters);
    filtersFunction(newFilters);
  };

  const compileAdditionalFilters = (initialFilters) => {
    let additionalFilters = { filters: [] };
    let filters = getDefaultSectionFilters(initialFilters);
    filters.forEach((item) => {
      if (item.exportAsExternalFilter) {
        additionalFilters = {
          ...additionalFilters,
          filters: [...additionalFilters.filters, item],
        };
      }
    });
    if (Object.keys(additionalFilters).length > 0) {
      additionalFilters = {
        ...additionalFilters,
        selectOption: createSelectOption(initialFilters),
      };
    }
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'additionalFilters',
      value: additionalFilters,
    });
  };

  const compileFiltersNumber = () => {
    let filters = getDefaultSectionFilters();
    let number = 0;
    let searchFilter = filters.find((option) => option.type === 'search');
    let toggleFilter = filters.find((option) => option.type === 'toggle' && option.name !== 'See unread only');
    let dateFilter = filters.find((option) => option.type === 'date');
    let addedOnFilter = filters.find((option) => option.type === 'addedOn');
    let unreadFilter = filters.find((item) => item.name === 'See unread only');
    let additionalFilter = (item) => !isMobile && item.hiddenDesktop;

    if (unreadFilter && unreadFilter.value && isMobile) {
      number += 1;
    }

    if (toggleFilter && toggleFilter.value && !toggleFilter.hidden && !additionalFilter(toggleFilter)) {
      number += 1;
    }

    if (dateFilter && dateFilter.optionSelected !== '') {
      number += 1;
    }
    if (addedOnFilter && addedOnFilter.optionSelected !== '') {
      number += 1;
    }

    if (searchFilter && searchFilter.value !== '' && (activeSearch || activeReference)) {
      number += 1;
    }
    //normalFilters
    let filtersToCompile = filters.filter(
      (option) =>
        !option.type ||
        option.type === 'parliamentaryRecordMultipleHierarchy' ||
        option.filterName === 'tags' ||
        option.type === 'membership'
    );
    filtersToCompile.forEach((item) => {
      const { options, hidden } = item;
      if (!additionalFilter(item)) {
        for (let i = 0; i < options.length; i++) {
          let option = options[i];
          if (option.selected && !hidden) {
            number += 1;
            break;
          }
        }
      }
    });

    return number;
  };

  const totalResults = () => {
    if (sectionResults && sectionResults.totalHits !== undefined) {
      return sectionResults.totalHits;
    }
  };

  const unreadFilter = () => {
    let referenceFilters = getDefaultSectionFilters();
    let unreadFilter = referenceFilters.find((item) => item.name === 'See unread only');
    return unreadFilter && unreadFilter.value;
  };

  const disabledCondition = () => {
    if (list !== null) {
      if (location.pathname.includes('/stakeholder-mapping')) {
        return sectionResults && sectionResults.currentlyProcessing;
      }
    }
    return false;
  };

  return (
    <>
      {isLoading && sectionName !== 'Stakeholders' && isMobile && (
        <SkeletonTheme baseColor='#f2f2f2'>
          <Skeleton height={32} width={97} style={{ borderRadius: '20px' }} />
        </SkeletonTheme>
      )}
      {(!isLoading || sectionName === 'Stakeholders') && (
        <FiltersComponent
          filters={getDefaultSectionFilters()}
          list={list}
          number={compileFiltersNumber()}
          section={section}
          typeOfContent={typeOfContent}
          clearFunction={clearFunction}
          totalResults={totalResults()}
          unreadFilter={unreadFilter()}
          filterTypeModifiers={filterTypeModifiers}
          filtersState={filtersState}
          getDefaultSectionFilters={getDefaultSectionFilters}
          applyFiltersFunction={applyFiltersFunction}
          compiledSectionFilters={compileFiltersFunction(getDefaultSectionFilters())}
          FiltersWithSort={FiltersWithSort}
          disabledButton={disabledCondition()}
          searchResultsSection={!enableReferenceSection && !inboxSection}
          useOutside={useOutside}
          dropDownFilters={dropDownFilters}
          onlySort={onlySort}
        />
      )}
    </>
  );
};

export default Filters;
