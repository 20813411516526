import React, { useRef, useState, useEffect, useContext } from 'react';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import getUrlParam, { getUrlParamRaw } from '../utilities/getUrlParam';
import Sections from './Sections';
import normalize from '../utilities/normalizeString';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { store } from '../../Store';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';
import Loading from '../../Login/Loading';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions';
import returnParamValue from '../helpers/returnParamValue';
import useResetReference from '../SubNav/useResetReference';
import parliamentarianUsers from '../sidebar/parliamentarianUsers';
import useCompileInitialFilters from '../Filters/useCompileInitialFilters';
import useCompileFiltersFunction from '../Filters/useCompileFilters';
import Navigation from '../Navigation';
import addQuotesToString from '../utilities/addQuotesToString';
const JSONBigInt = require('json-bigint');

const replaceCharacter = ({ item, character }) => {
  return item !== null && item !== undefined ? String(item).replaceAll(character, '').trim() : item;
};

const SearchResults = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { search, activePlan } = state;
  const { code } = activePlan;

  const [isLoading, setIsLoading] = useState(true);
  const totalResultsCall = useRef();
  const numberOfResources = 3;

  const { generalApiCall } = useGeneralApiCall();
  const location = useLocation();
  const navigate = useNavigate();
  const { resetReference } = useResetReference();
  const { compileInitialFilters } = useCompileInitialFilters();
  const { compileFiltersFunction } = useCompileFiltersFunction();

  const searchParam = getUrlParamRaw('or') ?? getUrlParamRaw('search');
  const createSections = () => {
    let resultSections = [];
    if (parliamentarianUsers(code)) {
      let newSections = [...Sections];
      let lobbyingMaterial = newSections.findIndex((item) => item?.name === 'LobbyingMaterial');
      newSections[lobbyingMaterial].urlName = 'campaigns';
      resultSections = newSections;
    } else {
      resultSections = Sections;
    }
    return resultSections;
  };

  const detectAdvancedSearch = () => {
    let searchQuery = decodeURIComponent(searchParam);
    let advancedSearch = searchQuery.includes(',');
    let keywords = searchQuery.split(',');
    keywords = keywords.map((item) => {
      item = replaceCharacter({ item, character: '"' });
      item = replaceCharacter({ item, character: `'` });
      return item;
    });

    let urlSearch = `${location.search}${location.hash}`;
    if (advancedSearch) {
      urlSearch = `?or=${encodeURIComponent(keywords.join(','))}${getUrlParam('and') ? `&and=${getUrlParam('and')}` : ''}${getUrlParam('not') ? `&not=${getUrlParam('not')}` : ''}${location.hash}`;
    }

    return { advancedSearch, keywords, urlSearch };
  };

  const individualApiCall = async (item, sourceProp) => {
    const { type, name, filters, collapseField } = item;
    const referenceFilters = compileInitialFilters('reference').filter((filter) => filter.pages.includes(name));
    const hashFilters = compileFiltersFunction(referenceFilters);

    let apiType = type ?? name;

    let pathname = '/api/search/query';
    let method = 'post';
    //AE: CHECK HERE THE TWEETS VALIDATIONS
    let requestHeaders = {
      transformResponse: [(data) => data],
      cancelToken: sourceProp.token,
    };
    let query = apiType === 'stakeholder' ? addQuotesToString(searchParam) : decodeURIComponent(searchParam);
    let requestProperties = {
      type: apiType,
      pageSize: numberOfResources,
      pageNumber: 1,
      filters: [],
      includeHighlights: true,
      query,
    };

    if (getUrlParam('and')) {
      requestProperties = {
        ...requestProperties,
        andQueries: ChangeKeywordsExclusions(decodeURIComponent(getUrlParamRaw('and')).split(',')),
      };
    }
    if (getUrlParam('not')) {
      requestProperties = {
        ...requestProperties,
        mustNotQueries: ChangeKeywordsExclusions(decodeURIComponent(getUrlParamRaw('not')).split(',')),
      };
    }

    if (filters) {
      requestProperties = { ...requestProperties, filters: [...requestProperties.filters, ...filters] };
    }

    if (hashFilters?.filters?.length > 0) {
      requestProperties = { ...requestProperties, filters: [...requestProperties.filters, ...hashFilters?.filters] };
    }

    if (collapseField) {
      requestProperties = { ...requestProperties, collapseField };
    }

    let results = await generalApiCall({
      pathname,
      method,
      requestHeaders,
      requestProperties,
      returnCompleteRequest: true,
      notShowErrorMessage: true,
      bigInt: true,
    });
    if (results?.data) {
      return JSONBigInt.parse(results.data);
    } else {
      throw Error('bad search result');
    }
  };

  const sentimentPage = () => {
    let url = !!document.referrer ? new URL(document.referrer) : null;
    return url?.pathname === '/sentiment';
  };

  const stakeholderNameCondtion = (results) => {
    for (let i = 0; i < results.hits.length; i++) {
      let highlightedTitle = results.highlights[i].highlightedTitle.toLowerCase();
      let tags = results.hits[i].tags?.map((tag) => tag.toLowerCase());
      let value = decodeURIComponent(searchParam).toLowerCase().trim();
      if (
        tags?.some((tag) => tag.toLowerCase() === value) ||
        highlightedTitle.includes("<em class='policymogul-keyword-match'>")
      ) {
        return true;
      }
    }
    return false;
  };

  totalResultsCall.current = async (source) => {
    try {
      setIsLoading(true);
      const { urlSearch } = detectAdvancedSearch();
      if (!!searchParam) {
        let sectionsToCall = createSections();
        if (!getUrlParamRaw('or')) {
          let results = await individualApiCall(
            {
              name: 'Stakeholders',
              type: 'stakeholder',
            },
            source
          );
          if (results?.hits.length > 0) {
            if (stakeholderNameCondtion(results) && !sentimentPage()) {
              applySearch();
              navigate(`/search-results/stakeholders${urlSearch}${location.hash}`, { replace: true });
              return;
            }
          }
        }
        for (let i = 0; i < sectionsToCall.length; i++) {
          let section = sectionsToCall[i];
          const { name, urlName } = section;
          let results = await individualApiCall(section, source);
          if (results?.hits?.length > 0) {
            applySearch();
            navigate(`/search-results/${normalize(TransformFromPascalCase(urlName ?? name))}${urlSearch}`, {
              replace: true,
            });
            return;
          }
        }
        applySearch();
        navigate(`/search-results/key-updates${urlSearch}`, { replace: true });
      } else {
        resetReference();
        navigate(`/search-results/key-updates`, { replace: true });
      }
    } catch {}
  };

  const applySearch = () => {
    const { advancedSearch, keywords } = detectAdvancedSearch();
    if (advancedSearch || getUrlParam('or')) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'referenceState',
        value: {
          activeReference: true,
          keywords: advancedSearch ? keywords : returnParamValue('or'),
          andKeywords: returnParamValue('and'),
          keywordExclusions: returnParamValue('not'),
        },
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'search',
        value: {
          ...search,
          activeSearch: false,
          query: '',
        },
      });
    } else {
      let keywordsValue = returnParamValue('search').filter(
        (item) => item.startsWith('"') || item.startsWith(`'`) || item.trim().split(' ').length === 1
      );
      keywordsValue = keywordsValue.map((item) => {
        item = replaceCharacter({ item, character: '"' });
        item = replaceCharacter({ item, character: `'` });
        return item;
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'referenceState',
        value: {
          activeReference: false,
          keywords: keywordsValue,
          andKeywords: [],
          keywordExclusions: [],
        },
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'search',
        value: {
          ...search,
          activeSearch: true,
          query: decodeURIComponent(getUrlParamRaw('search')),
          list: null,
        },
      });
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    totalResultsCall.current(source);
    return () => {
      source.cancel('Traditional search tab cancelled by the user');
    };
  }, []);

  return (
    <>
      <Navigation />
      {isLoading && <Loading />}
    </>
  );
};

export { replaceCharacter };
export default SearchResults;
