import React, { useState } from 'react';
import CreateAccountPopup from '../../components/CreateAccountPopup';
import FiltersPopup from './FiltersPopUp';
import { useAuth0 } from '@auth0/auth0-react';
import FiltersOutsidePopup from './FiltersOutsidePopup';
import { useMainSectionParameter } from '../../helpers/useMainSectionParameter';
import useIsMobile from '../../utilities/useIsMobile';
import OutsideFilters from './OutsideFilters';
import DropDownFilters from './DropDownFilters/DropDownFilters';
import { PortalWithState } from 'react-portal';
import FilterRegisterPopup from './FilterRegisterPopup';
import { useContext } from 'react';
import { store } from '../../../Store';
import { useLocation, useNavigate, useParams } from 'react-router-dom/dist';

const FiltersComponent = (props) => {
  const { useOutside, dropDownFilters } = props;
  return (
    <>
      {useOutside ? (
        <OutsideFilters {...props} />
      ) : dropDownFilters ? (
        <DropDownFilters {...props} />
      ) : (
        <FiltersPopupElements {...props} />
      )}
    </>
  );
};

const FiltersPopupElements = (props) => {
  const { filters, number, disabledButton, searchResultsSection } = props;
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { subscriptionStatus, enableReferenceSection, name: planName } = activePlan;
  const { isAuthenticated } = useAuth0();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { inboxSection } = useMainSectionParameter(params);
  const stakeholdersSection =
    location.pathname.includes('/stakeholder-mapping') || location.pathname.includes('/policymakers');
  const contactsSection = location.pathname.includes('influence/directory');
  const stakeholdersInfluenceSection = location.pathname.includes('influence/stakeholder-mapping');
  const groupsSection = location.pathname.includes('influence/my-groups');

  const trialUser = subscriptionStatus === 'TrialEnded' || !enableReferenceSection || subscriptionStatus === 'InTrial';
  const influenceSections = stakeholdersInfluenceSection || contactsSection;
  const allowedUsers =
    isAuthenticated &&
    (!influenceSections ||
      (influenceSections && !trialUser) ||
      (influenceSections && trialUser && planName === 'Enterprise'));
  const isMobile = useIsMobile(576);
  const candidatesSection = location.pathname.includes('/general-election-2024');
  return (
    <PortalWithState>
      {({ openPortal, closePortal, portal }) => (
        <>
          {filters.filter((item) => item.excludeFromPopup).length > 0 && !isMobile && (
            <FiltersOutsidePopup {...props} />
          )}
          {filters.filter((item) => (isMobile ? !item.hidden : !item.excludeFromPopup && !item.hidden)).length > 0 && (
            <div
              className={`position-relative filter-main-container more-filters-container ${
                stakeholdersSection || contactsSection || groupsSection ? 'more-filters-stakeholders' : 'd-lg-none'
              } ${candidatesSection ? 'candidates-filter-btn' : ''}`}
            >
              <button
                className={`sort-button more-filters-button ${number > 0 ? 'more-filters-button-active' : ''} ${
                  stakeholdersInfluenceSection ? 'stakeholders-influence-filters-button' : ''
                } ${contactsSection || groupsSection ? 'contacts-influence-filters-button' : ''}`}
                style={{
                  height: stakeholdersSection || contactsSection ? '38px' : undefined,
                }}
                onClick={() => {
                  if (location.pathname.includes('/general-election-2024')) {
                    openPortal();
                    return;
                  }
                  if (isAuthenticated) {
                    if (searchResultsSection) {
                      navigate('/reference/upgrade');
                    } else {
                      if (allowedUsers) {
                        openPortal();
                      } else {
                        setShowRegisterPopup(true);
                      }
                    }
                  } else {
                    setShowRegisterPopup(true);
                  }
                }}
                disabled={disabledButton}
              >
                <span className='icon-filters'></span>
                <span
                  className={`${stakeholdersInfluenceSection ? 'stakeholders-influence-filters' : ''} ${
                    contactsSection ? 'contacts-filters-button' : ''
                  } d-none d-md-inline-block`}
                >
                  {inboxSection || stakeholdersSection ? 'More filters' : 'Filters'}
                </span>{' '}
                {number > 0 && <span>({number})</span>}
              </button>
            </div>
          )}
          {portal(<FiltersPopup {...props} closePortal={closePortal} />)}
          {showRegisterPopup && (stakeholdersSection || contactsSection) && isAuthenticated && (
            <FilterRegisterPopup
              identifier={'filters-register-popup'}
              setShowPopup={setShowRegisterPopup}
              additionalClass={'export-message-popup'}
              proMessage={!enableReferenceSection && isAuthenticated}
            />
          )}
          {showRegisterPopup && !isAuthenticated && (
            <CreateAccountPopup
              text={'You’ll need to create an account to filter results'}
              setShowPopup={setShowRegisterPopup}
            />
          )}
        </>
      )}
    </PortalWithState>
  );
};

export default FiltersComponent;
