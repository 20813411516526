import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import TableComponent from '../tableComponents/TableComponent';
import CrmContactsTableSkeleton from '../CrmContacts/CrmContactsTable/CrmContactsSkeleton';
import addMetaTags from '../../utilities/addMetaTags';
import { store } from '../../../Store';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import CrmGroupItem from './CrmGroupItem';
import isDummyTableItem from '../../helpers/isDummyTableItem';
import { useGroupFunctions } from './GroupFunctions';
import getUrlParam from '../../utilities/getUrlParam';
import EmptyMessage from '../../components/EmptyMessage';
import SortArrayGroups from './SortArrayGroups';
import CrmGroupsEmptyMessage from './CrmGroupsEmptyMessage';

const CrmGroups = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contentResults, activeResults, selectedGroups, group, updateGroupsList, team } = state;

  const { Groups } = contentResults[activeResults] ?? {};
  const { selectedAll, selectedIds } = selectedGroups ?? {};

  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [scrollPositionX, setScrollPositionX] = useState(0);
  const [scrollTopContainer, setScrollTopContainer] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });
  const [localGroups, setLocalGroups] = useState([]);

  const location = useLocation();
  const { listingGroups } = useGroupFunctions();
  const isDummyItem = useCallback((props) => isDummyTableItem(props), [scrollTopContainer]);

  const searchParameter = getUrlParam('search');
  const sortParameter = getUrlParam('sort');

  const scrollBarRef = useRef();
  const handleCheck = () => {
    if (selectedAll) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'selectedGroups', value: { selectedIds: [], selectedAll: false } });
    } else {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'selectedGroups',
        value: { ...selectedGroups, selectedAll: true },
      });
    }
  };
  const isSelected = selectedAll || selectedIds?.length > 0;

  const createResults = async ({ source }) => {
    try {
      setIsLoading(true);
      let results = await listingGroups({ source, includeCounts: true });
      if (!!results) {
        if (!!group) {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'group',
            value: null,
          });
        }

        const finalResults = orderingResults({ groups: results, search: searchParameter, sort: sortParameter });
        setLocalGroups(finalResults);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      await createResults({ source });
      let title = `Groups - Influence - PolicyMogul`;
      addMetaTags({ title, location, dispatch });
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    setScrollTopContainer({
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
    });
    return () => {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'selectedGroups',
        value: { selectedIds: [], selectedAll: false },
      });
      source.cancel(`Groups cancelled by the user.`);
    };
  }, [team]);

  const orderingResults = ({ groups = Groups, sort, search }) => {
    if (!!groups) {
      let newResults = [...groups];
      if (!!sort) {
        const sortValue = decodeURIComponent(sortParameter)?.split('_');
        const key = sortValue[0];
        const order = sortValue[1];
        newResults = newResults.sort(SortArrayGroups(key, order === 'descending' ? 'desc' : 'asc'));
      }
      if (!!search) {
        let queryToCompare = decodeURIComponent(search)?.trim()?.toLowerCase();
        newResults = newResults?.filter((item) => {
          const { name, description } = item;
          return (
            name.toLowerCase().includes(queryToCompare) || description?.trim()?.toLowerCase()?.includes(queryToCompare)
          );
        });
      }
      return newResults;
    }
  };

  useEffect(() => {
    if (updateGroupsList) {
      const finalResults = orderingResults({ groups: Groups, search: searchParameter, sort: sortParameter });
      setLocalGroups(finalResults);
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'updateGroupsList',
        value: false,
      });
    }
  }, [updateGroupsList]);

  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarRef.current.scrollTop = 0;
    }
    if (!!searchParameter || sortParameter) {
      setLocalGroups(orderingResults({ search: searchParameter, sort: sortParameter }));
    } else {
      setLocalGroups(Groups);
    }
  }, [searchParameter, sortParameter, team]);

  useEffect(() => {
    if (!isLoading && scrollPositionX) {
      scrollBarRef.current.scrollLeft = scrollPositionX;
    }
  }, [isLoading, localGroups]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    setScrollTopContainer({ scrollTop, scrollHeight, clientHeight });
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      setScrollPositionX(0);
      load_more_results();
    }
  };

  const load_more_results = () => {};

  const columns = [
    {
      accessorKey: 'checkbox',
      header: '',
      ref: useRef(),
      type: 'checkbox',
    },
    {
      accessorKey: 'name',
      header: 'Group name',
      ref: useRef(),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      ref: useRef(),
    },
    {
      accessorKey: 'type',
      header: 'Group type',
      ref: useRef(),
    },
    {
      accessorKey: 'currentContactCount',
      header: 'Group size',
      ref: useRef(),
    },
    {
      accessorKey: 'createdAt',
      header: 'Date created',
      ref: useRef(),
    } /* 
    {
      accessorKey: 'date',
      header: 'Last contact added',
      ref: useRef(),
    }, */,
    {
      accessorKey: 'createdByFullName',
      header: 'Created by',
      ref: useRef(),
    },
  ];

  return (
    <div className={`main-content pl-md-4 pl-3 h-100`}>
      <div className='d-flex flex-column h-100'>
        <div className={`row mx-0 h-100 ${!isLoading && localGroups?.length === 0 ? 'pr-md-4 pr-3' : ''}`}>
          <div className='flex-grow-1' style={isLoading ? { overflow: 'hidden' } : null}>
            {isLoading ? (
              <div
                style={{
                  overflow: 'hidden',
                }}
              >
                <CrmContactsTableSkeleton />
              </div>
            ) : (
              <>
                {localGroups?.length === 0 ? (
                  <>
                    <div className='stakeholder-list-content-scrollbar' />
                    {!!searchParameter ? (
                      <div className='w-100'>
                        <EmptyMessage emptyMessageResourceName={'groups'} groupsTable={true} />
                      </div>
                    ) : (
                      <CrmGroupsEmptyMessage />
                    )}
                  </>
                ) : (
                  <div className='contact-list-scrollbar-table-wrapper'>
                    <div className='stakeholder-list-content-scrollbar' />
                    <div className='contact-list-scrollbar-table' onScroll={handleScroll} ref={scrollBarRef}>
                      <TableComponent
                        setScrollPositionX={setScrollPositionX}
                        scrollBarRef={scrollBarRef}
                        columns={columns}
                        additionalClass={'crm-table-contacts crm-table-groups'}
                        activeIndexState={{ activeIndex, setActiveIndex }}
                        selectedState={{ selectedAll, isSelected, handleCheck }}
                        propertyOnFirstClick={'descending'}
                      >
                        {localGroups?.map((item, index, array) => {
                          return (
                            <CrmGroupItem
                              array={array}
                              item={item}
                              key={`item${item?.id}-${index}`}
                              index={index}
                              scrollBarRef={scrollBarRef}
                              isDummyItem={isDummyItem}
                            />
                          );
                        })}
                        {localGroups?.thereIsMoreContent && <CrmContactsTableSkeleton />}
                      </TableComponent>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmGroups;
