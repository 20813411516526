import React, { useState, useEffect, useContext, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../../Store';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import createNotification from '../../Settings/Utilities/CreateNotification';
import getUrlParam from '../../Dashboard/utilities/getUrlParam';
import getActivePlan from '../../Settings/Utilities/getActivePlan';
import getCurrentTeam from '../../Settings/Team/getCurrentTeam';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

export const userTypes = [
  { name: 'Company' },
  { name: 'Charity' },
  { name: 'Membership organisation' },
  { name: 'Trade union' },
  { name: 'Lobbying or PR agency' },
  { name: 'Parliamentarian' },
  { name: 'Parliamentary or political staff' },
  { name: 'Prospective parliamentary candidate' },
  { name: 'Civil servant' },
  { name: 'Think tank or pressure group' },
  { name: 'Local authority' },
  { name: 'Campaigner' },
  { name: 'Consultant' },
  { name: 'Media' },
  { name: 'Other' },
];

const CreateProfileFirstStep = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { userProfile, net_api_url } = state;

  const setUserTypeFromUrl = () => {
    let userType;
    let typeOfParliamentarian = getUrlParam('reg_source');
    if (typeOfParliamentarian === 'i-work-for-a-parliamentarian') {
      userType = 'Parliamentary or political staff';
    } else if (typeOfParliamentarian === 'i-am-an-mp-or-peer') {
      userType = 'Parliamentarian';
    }
    return userType;
  };

  const defineUserType = () => {
    if (getUrlParam('reg_source') && getUrlParam('reg_source').includes('agency')) {
      return 'Lobbying or PR agency';
    } else if (getUrlParam('reg_source') && getUrlParam('reg_source').includes('membership-organisation')) {
      return 'Membership organisation';
    } else {
      return userProfile.userType ?? setUserTypeFromUrl() ?? '';
    }
  };

  const [values, setValues] = useState({
    organisationType: defineUserType(),
    organisationName:
      userProfile.jobTitle !== null || userProfile.organisation !== null
        ? userProfile.userType === 'Other'
          ? userProfile.jobTitle
          : userProfile.organisation
        : '',
    phoneNumber: userProfile.phoneNumber !== null ? userProfile.phoneNumber : '',
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    fullName: userProfile.fullName,
  });

  const { organisationType, organisationName, phoneNumber, firstName, lastName, fullName } = values;
  const [error, setError] = useState({ type: '', message: '' });
  const newEmailInput = useRef(null);
  const [newEmailValues, setNewEmailValues] = useState({
    change: false,
    value: userProfile.email,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const navigate = useNavigate();

  const changeOrganisationLabel = () => {
    switch (organisationType) {
      case 'Civil servant':
        return 'Office or department name';
      case 'Parliamentary or political staff':
        return 'Office or department name';
      case 'Parliamentarian':
        return 'Office or department name';
      case 'Local authority':
        return 'Local authority name';
      case 'Other':
        return 'Your role';
      default:
        return 'Organisation name';
    }
  };

  const changeValues = (item, value) => {
    setValues({ ...values, [item]: value });
    setError({ type: '', message: '' });
    setNewEmailValues({ ...newEmailValues, change: false });
  };

  useEffect(() => {
    return () => {
      source.cancel('Update profile canceled by the user.');
    };
  }, []);

  const saveProfile = async () => {
    setIsLoading(true);
    try {
      let token = await getAccessToken();
      let requestBody = {
        userId: user.sub,
        userType: organisationType,
        phoneNumber: phoneNumber !== '' ? phoneNumber : null,
        firstName: firstName,
        lastName: lastName,
        fullName: fullName,
        organisationLogo: userProfile.organisationLogo,
      };
      if (newEmailValues.change && newEmailValues.value !== userProfile.email) {
        requestBody.email = newEmailValues.value;
      }
      let organisationBody;
      switch (organisationType) {
        case 'Parliamentarian':
        case 'Prospective parliamentary candidate':
          organisationBody = {
            ...requestBody,
            jobTitle: '',
            organisation: '',
          };
          break;
        case 'Campaigner':
          organisationBody = {
            ...requestBody,
            jobTitle: '',
            organisation: '',
          };
          break;
        case 'Other':
          organisationBody = {
            ...requestBody,
            jobTitle: organisationName,
            organisation: '',
          };
          break;
        default:
          organisationBody = {
            ...requestBody,
            jobTitle: '',
            organisation: organisationName,
          };
      }
      let updateResults = await axios.post(`${net_api_url}/api/user/update`, organisationBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      if (!!updateResults) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userProfile', value: updateResults.data.user });
      }
      let callActivePlan = await getActivePlan(getAccessToken, source);
      if (callActivePlan) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: callActivePlan });
      }

      let currentTeam = await getCurrentTeam(getAccessToken, source);
      if (!!currentTeam) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
      }

      navigate(`/create-your-profile/2`);
    } catch (error) {
      if (
        error.response.data.message ===
        'If you do not have a parliament.uk or gov.uk email address, please contact us at support@policymogul.com'
      ) {
        setError({
          type: !newEmailValues.change ? 'organisationType' : 'newEmail',
          message: () => <ParliamentarianMessage />,
        });
      } else {
        createNotification('danger', 'There was a technical problem, please try again');
      }
      setIsLoading(false);
    }
  };

  const verifyIfThereAreChanges = () => {
    const organisationState = organisationType === 'Other' ? userProfile.jobTitle : userProfile.organisation;
    const organisationConditional = organisationState === null ? true : organisationName !== organisationState;
    const phoneNumberConditional =
      userProfile.phoneNumber === null ? (phoneNumber !== '' ? true : false) : phoneNumber !== userProfile.phoneNumber;
    const usetTypeConditional = userProfile.userType === null ? true : organisationType !== userProfile.userType;
    if (organisationConditional || phoneNumberConditional || usetTypeConditional) {
      return true;
    } else {
      return false;
    }
  };

  const ParliamentarianMessage = () => {
    return (
      <>
        Please sign up with a parliament.uk or gov.uk email address.{' '}
        <span
          className='pointer simple-link-button'
          onClick={() => {
            setError({ type: '', message: '' });
            setNewEmailValues({ ...newEmailValues, change: true });
            setTimeout(() => {
              newEmailInput.current.focus();
            }, 300);
          }}
        >
          Change your email address
        </span>
        . If you do not have a parliament.uk or gov.uk email address, please{' '}
        <span className='pointer simple-link-button contact-button-launch-messenger'>contact us</span>.
      </>
    );
  };

  const notOrganisationUsersConditional =
    organisationType === 'Parliamentarian' ||
    organisationType === 'Campaigner' ||
    organisationType === 'Parliamentary or political staff' ||
    organisationType === 'Prospective parliamentary candidate';

  return (
    <>
      <h2 className='mb-5'>Create your profile</h2>
      <form>
        <div className='form-group mb-4'>
          <label htmlFor='organisation-type'>
            Organisation type <span>*</span>
          </label>
          <select
            data-hj-allow
            id='organisation-type'
            className='form-control custom-select'
            value={organisationType}
            onChange={(e) => {
              changeValues('organisationType', e.target.value);
            }}
            required
          >
            <option value='' hidden>
              Select your organisation type
            </option>
            {userTypes.map((item, index) => (
              <option value={item.name} key={`option${index}`}>
                {item.name}
              </option>
            ))}
          </select>
          {error.type === 'organisationType' && (
            <p className='mb-0 profile-error-message py-2 px-3 mt-2'>
              {typeof error.message === 'string' ? error.message : error.message()}
            </p>
          )}
        </div>
        {organisationType !== '' && !notOrganisationUsersConditional && (
          <div className='form-group mb-4'>
            <label htmlFor='organisation-type'>
              {changeOrganisationLabel()} <span>*</span>
            </label>
            <input
              data-hj-allow
              className='form-control'
              type='text'
              required
              value={organisationName}
              onChange={(e) => {
                changeValues('organisationName', e.target.value);
              }}
            />
            {error.type === 'organisationName' && (
              <p className='mb-0 profile-error-message py-2 px-3 mt-2'>{error.message}</p>
            )}
          </div>
        )}

        <div className='form-group mb-4'>
          <label htmlFor='phone-number'>Phone number </label>
          <input
            data-hj-allow
            className='form-control'
            id='phone-number'
            type='tel'
            value={phoneNumber !== null ? phoneNumber : ''}
            onChange={(e) => {
              changeValues('phoneNumber', e.target.value);
            }}
          />
        </div>

        {newEmailValues.change && (
          <div className='form-group mb-4'>
            <label htmlFor='new-email-address'>New email address</label>
            <input
              data-hj-allow
              className='form-control'
              id='new-email-address'
              type='text'
              value={newEmailValues.value}
              ref={newEmailInput}
              onChange={(e) => {
                setNewEmailValues({ ...newEmailValues, value: e.target.value });
              }}
            />
            {error.type === 'newEmail' && (
              <p className='mb-0 profile-error-message py-2 px-3 mt-2'>
                {typeof error.message === 'string' ? error.message : error.message()}
              </p>
            )}
          </div>
        )}

        <button
          className={`btn btn-general action-button pl-3 py-1 my-4  ${!isLoading ? 'create-profile-button' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            let userTypeCondition = organisationType !== '';
            let organisationCondition =
              (userTypeCondition && organisationName !== '') || notOrganisationUsersConditional;
            let newEmailCondition = newEmailValues.change & !newEmailValues.value.includes('@');
            if (!userTypeCondition) {
              setError({ type: 'organisationType', message: 'Please choose your user type' });
            } else if (!organisationCondition) {
              setError({ type: 'organisationName', message: 'Please complete this field' });
            } else if (newEmailCondition) {
              setError({ type: 'newEmail', message: 'Please enter a valid email' });
            } else if (
              organisationType === 'Parliamentarian' ||
              organisationType === 'Parliamentary or political staff'
            ) {
              navigate(`/create-your-profile/2-parliamentarian?user-type=${encodeURIComponent(organisationType)}`);
            } else if (verifyIfThereAreChanges()) {
              saveProfile();
            } else {
              navigate('/create-your-profile/2');
            }
          }}
        >
          {' '}
          {isLoading ? <i className='fas fa-spinner fa-spin'></i> : `Next`}
        </button>
      </form>
    </>
  );
};
export default CreateProfileFirstStep;
