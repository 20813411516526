import { useGroupFunctions } from '../../CrmGroups/GroupFunctions';

const CrmGroupOption = (props) => {
  const { renderOptionWrapper, optionState, data } = props;
  const { value } = data ?? {};
  const { selectedGroups, setSelectedGroups } = optionState ?? {};

  const handleAddGroupContacts = () => {
    let newSelectedGroups = [...selectedGroups];
    if (newSelectedGroups?.includes(value)) {
      const position = newSelectedGroups?.indexOf(value);
      newSelectedGroups.splice(position, 1);
    } else {
      newSelectedGroups.push(value);
    }
    setSelectedGroups(newSelectedGroups);
  };

  return (
    <div
      className={`contacts-select-local-option react-custom-select-contacts__option w-100 flex-centered justify-content-between`}
      onClick={handleAddGroupContacts}
    >
      {renderOptionWrapper()}
      {selectedGroups?.includes(value) && (
        <span
          className='icon-simple-check text-light-blue d-block paragraph-p3'
          style={{ marginRight: '0px', marginTop: '3px' }}
        ></span>
      )}
    </div>
  );
};

const useCallListOfGroups = (props) => {
  const { optionState } = props;
  const { selectedGroups } = optionState ?? {};
  const { previewGroup } = useGroupFunctions();

  const callPreviewGroups = async () => {
    try {
      if (selectedGroups?.length > 0) {
        let results = [];
        for (let i = 0; i < selectedGroups.length; i++) {
          let result = await previewGroup({ id: selectedGroups[i], pageSize: 10000 });
          if (!!result) {
            results.push(result);
          }
        }
        return results.reduce((acc, result) => {
          result.hits.forEach((hit) => {
            const { imageId, id, title, email } = hit;
            if (!acc.some((existingHit) => existingHit.id === hit.id)) {
              acc.push({ imageId, id, value: id, label: title, email });
            }
          });
          return acc;
        }, []);
      }
    } catch (error) {}
  };

  return { callPreviewGroups };
};

export { useCallListOfGroups };
export default CrmGroupOption;
