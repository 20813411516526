import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import Filters from '../../../Filters';
import MainSearchBar from '../../../MainSearchBar';
import ClearFiltersButton from '../../../SubNav/ui/ClearFiltersButton';
import useDetectActiveFilters from '../../../SubNav/useDetectActiveFilters';
import useIsMobile from '../../../utilities/useIsMobile';
import InfluenceNotIncludedFeaturesPopup from '../../CrmContacts/InfluenceNotIncludedFeaturesPopup';
import { store } from '../../../../Store';
import getUrlParam from '../../../utilities/getUrlParam';
import TotalResults from '../../../components/TotalResults';
import InfluenceSectionTitle from '../../InfluenceSectionTitle/InfluenceSectionTitle';
import parliamentarianUsers from '../../../sidebar/parliamentarianUsers';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';

function StakeholdersSubNav({ isMobile, isSmallMobile, readyKeywordLists, filtersProperties }) {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, contentResults, activeResults, activePlan } = state;
  const { list } = search;
  const { code } = activePlan ?? {};
  const { Stakeholders } = contentResults[activeResults] ?? {};
  const stakeholdersToRender = Stakeholders?.hits ?? [];

  const stakeholderHeaderRef = useRef(null);

  const { detectActiveFilters } = useDetectActiveFilters();
  const activeFilters = detectActiveFilters() || list?.id || getUrlParam('topic-id');
  const [showInfluenceCrmPopup, setShowInfluenceCrmPopup] = useState(false);

  const showSubNavFilters = useIsMobile(1280);
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      <div className='w-100 flex-centered' ref={stakeholderHeaderRef}>
        <div
          className={`single-content-width-monitor flex-centered justify-content-between stakeholder-sub-nav ${newAgencyPlan ? 'single-content-agency' : ''}`}
        >
          <div style={{ visibility: parliamentarianUsers(code) ? 'hidden' : 'visible' }}>
            <InfluenceSectionTitle />
          </div>
          {readyKeywordLists && !isMobile && <MainSearchBar />}
          <div className='flex-centered d-lg-none'>
            {showSubNavFilters && (
              <>
                <Filters section={'Stakeholders'} filtersProperties={filtersProperties} useOutside={false} />
                {!isSmallMobile && activeFilters && (
                  <div style={{ marginLeft: '10px' }}>
                    <ClearFiltersButton stakeholdersSubNav />
                  </div>
                )}
              </>
            )}
            {activeFilters && showSubNavFilters && (
              <div className='stakeholder-action-btn-container'>
                <div className='flex-centered'>
                  <div className='stakeholders-mapping-total-results'>
                    <div className='ml-auto mr-0 onlysort-filters-button'>
                      <Filters section={'Stakeholders'} dropDownFilters onlySort />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* 
        {!showSubNavFilters && activeFilters && (
          <div className='d-flex' style={{ marginLeft: '25px', columnGap: '16px' }}>
            <ClearFiltersButton stakeholdersSubNav />
          </div>
        )} */}
        <div className='d-flex justify-content-end flex-grow-1'>
          <div className='d-none d-lg-block outside-filters-container-width text-right pr-1'>
            <>
              {stakeholdersToRender.length > 0 && activeFilters && (
                <>
                  <TotalResults onlyNumber /> matching stakeholder{stakeholdersToRender?.length === 1 ? '' : 's'}
                </>
              )}
            </>
          </div>
        </div>
      </div>
      {showInfluenceCrmPopup && (
        <InfluenceNotIncludedFeaturesPopup setShowPopup={setShowInfluenceCrmPopup} type={'contacts'} />
      )}
    </>
  );
}

export default StakeholdersSubNav;
