import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { store } from '../../Store.js';
import ContentRelated from '../key_updates/ContentRelated';
import axios from 'axios';
import callContentRelated from '../utilities/callContentRelated.js';
import normalizeString from '../utilities/normalizeString';
import SkeletonLegislationContent from './SkeletonLegislationContent.js';
import callHightlightContentAPI from '../utilities/callHightlightContentAPI.js';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions.js';
import { useAuth0 } from '@auth0/auth0-react';
import { setFiltersLocalStorage } from '../utilities/setFiltersLocalStorage.js';
import addMetaTags from '../utilities/addMetaTags.js';
import { useHeightContainer } from '../utilities/useHeightContainer.js';
import LegislationNewContent from './LegislationNewContent.js';
import { createNavigationLinksOnSection, getIdPositionOnSection } from '../helpers/navigationFunctions.js';
import updateUnreadItems from '../helpers/updateUnreadItems.js';
import NavigationLinks from '../components/NavigationLinks.js';
import useGetHighlightingKeywords from '../hooks/useGetHighlighingKeywords.js';
import useCallCollectContent from '../hooks/useCallCollectContent.js';
import useCreateResults from '../ListControl/useCreateResults.js';
import useGetMatchingKeywordsListsForContent from '../hooks/useMatchingKeywordsListsForContent.js';
import CustomScrollbar from '../../Common/CustomScrollbar.js';
import useGetAccessToken from '../apiCalls/useGetAccessToken.js';

const LegislationContent = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const [heightContainer, containerRef] = useHeightContainer();
  const { contentResults, activeResults, net_api_url, search, referenceState, activePlan, loadingCreateSummary } =
    state;
  const { enableSummariser } = activePlan;
  const { searchState, activeSearch } = search;
  const { activeReference } = referenceState;
  const { Legislation } = contentResults[activeResults];
  const currentNew = Legislation?.new ?? {};

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRelatedContent, setIsLoadingRelatedContent] = useState(true);
  const [scrolling, setScrolling] = useState(false);

  const location = useLocation();
  const params = useParams();
  const { id, type } = params;
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  const getIdPosition = getIdPositionOnSection(Legislation, id);
  const createNavigationLinks = createNavigationLinksOnSection(Legislation);

  const contentContainer = useRef(null);
  const scrollbarRef = useRef(null);

  const [idPosition, setIdPosition] = useState(getIdPosition());
  const [navigationLinks, setNavigationLinks] = useState(createNavigationLinks(getIdPosition()));
  const barNavigationConditional =
    idPosition !== null && (navigationLinks.next !== null || navigationLinks.prev !== null);
  const { andKeywords, queryHighlight } = useGetHighlightingKeywords();
  const { callCollectContent } = useCallCollectContent();
  const { getMatchingKeywordsListsAndFeedback } = useGetMatchingKeywordsListsForContent();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (!isAuthenticated || searchState) {
      setIdPosition(getIdPosition());
      setNavigationLinks(createNavigationLinks(getIdPosition()));
      loadNew(source);
    }
    return () => {
      source.cancel('Current new cancelled by the user.');
    };
  }, [type, id, searchState]);

  const { load_more_results } = useCreateResults({ propsSectionName: 'Legislation' });

  const detectIfLoadMoreResults = () => {
    if (getIdPosition() !== null && getIdPosition() === Legislation.hits.length - 1) {
      return true;
    }
    return false;
  };

  const loadNew = async (source) => {
    try {
      setIsLoadingRelatedContent(true);
      setIsLoading(true);

      //LOAD THE LIST IF IT IS THE LAST ITEM
      let listResults;
      if (detectIfLoadMoreResults()) {
        listResults = await load_more_results(source);
        setNavigationLinks(createNavigationLinks(getIdPosition()));
      }

      //SET IN LOCALSTORAGE
      let section = 'LegislationContentPage';
      let filtersToApply = [];
      let token = isAuthenticated ? await getAccessToken() : '';
      setFiltersLocalStorage({ filtersToApply, section, state, dispatch, source, token, isAuthenticated });
      let contentRequest = {
        cancelToken: source.token,
      };
      if (isAuthenticated) {
        contentRequest.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      let newResult = await axios.get(`${net_api_url}/api/${type}legislation/${id}`, contentRequest);
      const { objectId, objectType } = newResult.data;

      let propertiesHighlight = [objectId, objectType, queryHighlight, ChangeKeywordsExclusions(andKeywords), source];

      let contentHightlighted;
      let highlightCondition = activeSearch || activeReference;

      if (highlightCondition) {
        contentHightlighted = await callHightlightContentAPI(propertiesHighlight);
      }
      let newData;
      if (highlightCondition) {
        if (type === 'primary') {
          newData = {
            ...newResult.data,
            billTitleHighlighted: contentHightlighted ? contentHightlighted.highlightedTitle : newResult.data.billTitle,
            summary: contentHightlighted ? contentHightlighted.highlightedHtmlBody : newResult.data.summary,
          };
        } else {
          newData = {
            ...newResult.data,
            titleHighlighted: contentHightlighted ? contentHightlighted.highlightedTitle : newResult.data.title,
            explanatoryNote: contentHightlighted
              ? contentHightlighted.highlightedHtmlBody
              : newResult.data.explanatoryNote,
          };
        }
      } else {
        newData = newResult.data;
      }

      let { feedback, keywordsFromLists, keywordsListsIds } = await getMatchingKeywordsListsAndFeedback({ objectId });
      newData = { ...newData, feedback, keywordsFromLists, keywordsListsIds };

      const updateResultsOnState = (newData) => {
        let data = {
          ...contentResults,
          [activeResults]: {
            Legislation: newData,
          },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: data });
      };

      let contentObject = !!listResults ? listResults[activeResults].Legislation : Legislation;
      let newSectionObject = contentObject;
      //REMOVE THE ITEM FROM THE LIST AND MARK AS READ
      let removeUnreadItem = updateUnreadItems({ idPosition, contentObject, getIdPosition });
      if (removeUnreadItem) {
        newSectionObject = removeUnreadItem;
      }
      let initialData = {
        ...newSectionObject,
        new: newData,
        currentId: parseInt(id),
      };
      updateResultsOnState(initialData);

      let { madeOrLaid, billTitle, descriptionMeta } = newResult.data;
      let titleToShow = madeOrLaid ? `${newResult.data.title}` : `${billTitle}`;
      let title = `${titleToShow} - Legislation - PolicyMogul`;
      let hash = '';
      let description = descriptionMeta;
      let contentUrl = `/legislation/${type}/${id}/${normalizeString(titleToShow)}`;
      addMetaTags({ title, hash, location, dispatch, contentUrl, description });
      setIsLoading(false);

      let contentRelated = await callContentRelated(objectId, objectType, source);
      let relatedData = {
        ...initialData,
        contentRelated: contentRelated,
      };
      updateResultsOnState(relatedData);
      setIsLoadingRelatedContent(false);

      await callCollectContent({ objectId, objectType, contentUrl });
    } catch (error) {}
  };

  return (
    <>
      {isLoading && <SkeletonLegislationContent />}
      {!isLoading && (
        <div ref={containerRef}>
          <div className='row px-0 piece-content-container'>
            <div className={`general-content-column padding-to-nav p-0`}>
              <div ref={contentContainer} />
              <div className='main-content-section content-container' style={{ height: `${heightContainer}px` }}>
                <CustomScrollbar
                  className={'simple-scrollbar indented-scrollbar-small'}
                  style={{ height: `${heightContainer - (barNavigationConditional ? 43 : 0)}px` }}
                  minimalThumbYSize={55}
                  maximalThumbYSize={100}
                  ref={scrollbarRef}
                  onScrollStart={() => {
                    setScrolling(true);
                  }}
                  onScrollStop={() => {
                    setScrolling(false);
                  }}
                >
                  <div className='main-update-content-container'>
                    <LegislationNewContent
                      currentNew={currentNew}
                      contentContainer={contentContainer}
                      setIsLoading={setIsLoading}
                      hideSummary={enableSummariser !== undefined && !enableSummariser}
                      scrollbarRef={scrollbarRef}
                      scrolling={scrolling}
                    />
                  </div>
                </CustomScrollbar>
                <NavigationLinks
                  barNavigationConditional={barNavigationConditional}
                  navigationLinks={navigationLinks}
                  disabled={loadingCreateSummary}
                />
              </div>
            </div>
            <div className='related-content-column show-lg px-0'>
              <ContentRelated
                data={currentNew}
                content={Legislation?.contentRelated}
                isLoadingRelatedContent={isLoadingRelatedContent}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LegislationContent;
