const SortArrayGroups = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA =
      a[key] === null || a[key] === ''
        ? null
        : key === 'createdAt' || key === 'lastModifiedAt'
          ? new Date(a[key])
          : typeof a[key] === 'string'
            ? a[key].toUpperCase()
            : a[key];
    const varB =
      b[key] === null || b[key] === ''
        ? null
        : key === 'createdAt' || key === 'lastModifiedAt'
          ? new Date(b[key])
          : typeof b[key] === 'string'
            ? b[key].toUpperCase()
            : b[key];

    if (varA === null && varB !== null) {
      return 1;
    } else if (varA !== null && varB === null) {
      return -1;
    } else if (varA === null && varB === null) {
      return 0;
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export default SortArrayGroups;
