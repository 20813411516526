const smoothScrollTo = (scrollbarRef, targetPosition, duration = 500) => {
  const start = scrollbarRef.current.getScrollTop();
  const change = targetPosition - start;
  const startTime = performance.now();

  const animateScroll = (currentTime) => {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    const easeInOutQuad = progress < 0.5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress;

    scrollbarRef.current.scrollTop(start + change * easeInOutQuad);

    if (elapsed < duration) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
};

const isVisibleVertically = ({ groupRef, scrollbarRef }) => {
  if (groupRef?.current && scrollbarRef?.current) {
    const element = groupRef.current;
    const container = scrollbarRef.current?.container;

    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const elementHeight = elementRect.height;
    const halfElementHeight = elementHeight / 2;

    const visiblePart =
      Math.min(elementRect.bottom, containerRect.bottom) - Math.max(elementRect.top, containerRect.top);

    return visiblePart >= halfElementHeight;
  }
};

const isVisibleVerticallyComplete = ({ groupRef, scrollbarRef }) => {
  if (groupRef?.current && scrollbarRef?.current) {
    const element = groupRef.current;
    const container = scrollbarRef.current.container;

    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    return elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom;
  }
  return false;
};

export { isVisibleVertically, isVisibleVerticallyComplete, smoothScrollTo };
