import React, { useEffect, useRef, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { Link } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import returnCorrectWPUrl from '../Dashboard/utilities/returnCorrectWPUrl';
import normalize from '../Dashboard/utilities/normalizeString';

const ProductsInformation = [
  {
    title: 'The UK’s most comprehensive political monitoring',
    description: 'PolicyMogul covers more sources of political updates than any other provider.',
    name: 'Political monitoring',
    icon: 'key-update',
  },
  {
    title: 'Map stakeholders by relevance, influence and sentiment',
    description: 'Identify and filter the most relevant MPs, peers, advisors and more for any issue.',
    name: 'Stakeholder mapping',
    icon: 'graph',
  },
  {
    title: 'Fully-integrated political stakeholder CRM',
    description: 'Send tracked emails, write notes, create tags, and log activity  and interactions.',
    name: 'CRM',
    icon: 'team',
  },
  {
    title: 'Lobbying platform used by hundreds of policymakers',
    description: 'Create or add research or briefing material for hundreds of policymakers who use the platform.',
    name: 'Lobbying platform',
    icon: 'associated-content',
  },
  {
    title: 'Explore instant political data on any issue',
    description: 'Our data platform allows you to explore data in relation to relevant policymakers.',
    name: 'Analytics',
    icon: 'statistics',
  },
  {
    title: 'Create beautiful PDF reports in seconds',
    description: 'PolicyMogul’s report-builder enables you to generate a custom-designed PDF reports.',
    name: 'Report builder',
    icon: 'document-bold',
  },
  {
    title: 'Bring live political updates to your website visitors',
    description:
      'PolicyMogul offers a range of ‘embeddables’ - widgets which can be easily embedded into any website or blog.',
    name: 'Embeddables',
    icon: 'code',
  },
  {
    title: 'Research platform and dedicated research team',
    description: 'In addition to the UK’s most advanced political research portal.',
    name: 'Research',
    icon: 'search-medium',
  },
];

const QuestionsElements = [
  {
    question: 'What is PolicyMogul?',
    answer:
      'PolicyMogul is an all-in-one public affairs platform used by organisations and policymakers. It includes the UK’s most comprehensive political monitoring service, as well as various features which draw on political data to enable organisations and policymakers to engage effectively.',
  },
  {
    question: 'What types of organisation subscribe to PolicyMogul?',
    answer:
      'PolicyMogul is used by a diverse range of organisations, including charities, corporations, universities, trade associations, foreign governments and public sector bodies. Our platform is designed for anyone seeking to engage effectively with policymakers and navigate the political landscape with ease.',
  },
  {
    question: 'Do you offer lower fees or free tools to non-profits?',
    answer: () => (
      <>
        We believe that access to political awareness should be open to organisations of all sizes, reflected in our
        range of price plans, which start from £195 per month. We also run{' '}
        <a
          className='link-text'
          href={`${process.env.REACT_APP_POLICYINFLUENCE_APP_URL}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          PolicyInfluence
        </a>
        , a free platform for non-profits and campaigners to make lobbying material available to relevant policymakers,
        and{' '}
        <a className='link-text' href='/data-hub' target='_blank' rel='noopener noreferrer'>
          data hub
        </a>
        , a political data site used by journalists, among others.
      </>
    ),
  },
];

const QuestionComponent = (props) => {
  const { item, index, array } = props;
  const { question, answer } = item;
  const [isExpanded, setIsExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <div
      className={`py-5 ${index === array?.length - 1 ? '' : 'border-bottom border-white'} home-question-component`}
      {...getToggleProps({
        onClick: () => {
          setIsExpanded((prev) => !prev);
        },
      })}
    >
      <div className={`d-flex`}>
        <span className='bg-main-white rounded-full rounded-circle home-questions-icon flex-centered justify-content-center title-h5-m'>
          <span className='icon-search text-main-blue' />
        </span>
        <p
          className={`pl-3 title-h3 flex-grow-1 mb-0 dropdown-item-element dropdown-item-element-10 ${isExpanded ? 'open' : ''}`}
        >
          {question}
        </p>
      </div>
      <div {...getCollapseProps()}>
        <p className='mb-0 pt-4 home-answer-padding pr-3 pr-lg-0'>{typeof answer === 'function' ? answer() : answer}</p>
      </div>
    </div>
  );
};

const Questions = () => {
  return (
    <>
      <section className='bg-main-blue pt-5 text-white '>
        <div className='py-lg-5 main-content-wrapper mx-auto px-lg-5 px-3'>
          <h3 className='title-h2-bold mb-3 mb-lg-4'>Frequently asked questions, answered</h3>
          {QuestionsElements.map((item, index, array) => {
            return <QuestionComponent index={index} array={array} key={`question-${index}`} item={item} />;
          })}
        </div>
      </section>
    </>
  );
};

const ProductComponent = (props) => {
  const { item } = props;
  const { name, description, title } = item ?? {};
  return (
    <div className='rounded bg-main-white h-100 d-flex flex-column'>
      <div className='bg-main-blue rounded-top p-4 text-white'>
        <p className='mb-0 title-h4'>{title}</p>
      </div>
      <div className='p-4 flex-grow-1 d-flex flex-column'>
        <p className='mb-3 flex-grow-1 title-h5-m'>{description}</p>
        <Link
          className='bg-yellow text-white d-block text-center py-2 general-button w-100 rounded-pill reset-link learn-more-button'
          to={`${returnCorrectWPUrl()}/product/${normalize(name)}`}
        >
          Learn more
        </Link>
      </div>
    </div>
  );
};

const Products = () => {
  const [swiperRef, setSwiperRef] = useState();
  const navigationNextRef = useRef();
  const navigationPrevRef = useRef();

  useEffect(() => {
    if (navigationPrevRef.current && navigationNextRef.current && !!swiperRef?.navigation) {
      swiperRef.navigation.init();
      swiperRef.navigation.update();
    }
  }, [swiperRef, navigationPrevRef, navigationNextRef]);

  return (
    <>
      <section className='py-5'>
        <div className='py-lg-5 main-content-wrapper mx-auto px-lg-5 px-3'>
          <h3 className='title-h2-bold mb-3 mb-lg-5'>Explore the product that powers public affairs</h3>
          <div className='border bg-salmon p-4 p-lg-5 rounded border d-flex position-relative'>
            <Swiper
              onSwiper={setSwiperRef}
              modules={[Navigation]}
              slidesPerView={'1'}
              spaceBetween={30}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              loop={true}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              breakpoints={{
                768: {
                  slidesPerView: '2',
                },
                992: {
                  slidesPerView: '3',
                },
              }}
              className={``}
            >
              {ProductsInformation.map((item, index) => {
                return (
                  <SwiperSlide key={`product-${index}`}>
                    <ProductComponent item={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className='home-swiper-navigation-button home-swiper-navigation-button-prev' ref={navigationPrevRef}>
              <span className='icon-left-triangle-filled' />
            </div>
            <div className='home-swiper-navigation-button home-swiper-navigation-button-next' ref={navigationNextRef}>
              <span className='icon-right-triangle-filled' />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const HomeLogos = () => {
  const logos = [
    { name: 'deliveroo', dimentions: { width: '63', height: '60' } },
    { name: 'hp', dimentions: { width: '41', height: '53' } },
    { name: 'london_youth', dimentions: { width: '174', height: '42' } },
    { name: 'open_democracy', dimentions: { width: '242', height: '45' } },
    { name: 'university_of_surrey', dimentions: { width: '177', height: '42' } },
    { name: 'rac', dimentions: { width: '110', height: '38' } },
    { name: 'morrisons', dimentions: { width: '107', height: '48' } },
    { name: 'too_good_to_go', dimentions: { width: '63', height: '49' } },
    { name: 'university_of_south_wales', dimentions: { width: '60', height: '60' } },
  ];
  return (
    <div className='home-logos pb-5'>
      <ul className='px-0 mb-0'>
        <li className='flex-centered flex-nowrap'>
          <div className='logo-track'>
            {Array(2)
              .fill()
              .map((item, index) => (
                <React.Fragment key={`logos-${index}`}>
                  {logos.map((item) => {
                    const { name, dimentions } = item;
                    return (
                      <img
                        style={{ width: `${dimentions?.width}px`, height: `${dimentions?.height}px` }}
                        className='home-logo-element'
                        key={`logotype-of-${normalize(name)}`}
                        src={`${process.env.REACT_APP_CDNURL}/images/home-logos/${name}.png`}
                        alt={`Logotype of ${name}`}
                      />
                    );
                  })}
                </React.Fragment>
              ))}
          </div>
        </li>
      </ul>
    </div>
  );
};

export { Questions, Products, ProductsInformation, HomeLogos };
