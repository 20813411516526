import axios from 'axios';
import { createSectionName } from '../helpers/controlActiveFilters';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';
import useReloadFeed from '../hooks/useReloadFeed';
import useCreateResults from './useCreateResults';
import GeneralList from './GeneralList';
import { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../Store';
import useGetSectionFilters from '../Filters/useGetSectionFilters';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import addMetaTags from '../utilities/addMetaTags';
import getUrlParam from '../utilities/getUrlParam';

const ListControl = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { contentResults, activeResults, contentFilters, search, enforceReloadFeed, dispatchSearchForClients } = state;

  const sectionName = createSectionName();
  const section = contentResults[activeResults][sectionName];
  const topicIdQuery = getUrlParam('topic-id');
  const searchQuery = getUrlParam('search') || getUrlParam('or');

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { page, mainSection } = params;

  const { callConditional } = useGetSectionFilters();
  const scrollCondition =
    section?.scrollPosition !== null && section?.scrollPosition >= 0 && section?.listSection === mainSection;
  const { mainSectionTitle, searchResultsSection } = useMainSectionParameter(params);

  const [localPage, setLocalPage] = useState(page);
  const [localSearch, setLocalSearch] = useState(search);

  const initialCall = useRef();
  const firstLoad = useRef(true);
  const scrollBarRef = useRef(null);

  const { createResults, isLoading, setIsLoading, currentlyProcessing, setCurrentlyProcessing } = useCreateResults({
    customTitle: props?.customTitle,
  });

  initialCall.current = (source) => {
    setIsLoading(true);
    if (enforceReloadFeed) {
      navigate(0);
    }
    setCurrentlyProcessing({ attempt: 0, state: true });
    //AE:Review if call conditional is necessary
    if (callConditional) {
      if (!scrollCondition) {
        dispatchSearch(source);
      } else {
        let title = `${mainSectionTitle}${TransformFromPascalCase(sectionName)} - PolicyMogul`;
        addMetaTags({ title, hash: window.location.hash, location, dispatch });
        setIsLoading(false);
        //AE: Necessary to have a setTimeOut to update the scrollPosition and allow continue using the list correctly. (Without this if you clear the search or change the topic it will not work.)
        setTimeout(() => {
          let sectionWithoutScroll = { ...section, scrollPosition: null };
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'contentResults',
            value: {
              ...contentResults,
              [activeResults]: {
                [sectionName]: sectionWithoutScroll,
              },
            },
          });
        }, 300);
      }
    }
  };

  const dispatchSearch = (source) => {
    const loadResults = async () => {
      let setMarker = firstLoad.current || JSON.stringify(search) !== JSON.stringify(localSearch) || page !== localPage;
      await createResults(null, setMarker, source);
    };
    loadResults();
  };

  useEffect(() => {
    if (searchResultsSection && dispatchSearchForClients === false) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'dispatchSearchForClients', value: null });
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    firstLoad.current = false;
    setLocalPage(page);
    setLocalSearch(search);
    return () => {
      source.cancel(`${TransformFromPascalCase(sectionName)} canceled by the user.'`);
    };
  }, [search, topicIdQuery, searchQuery, page, contentFilters]);

  //SCROLL RESTORATION
  useEffect(() => {
    if (!isLoading && scrollCondition) {
      scrollBarRef.current.scrollTop(section.scrollPosition);
    }
  }, [isLoading, section]);

  useReloadFeed({ createResults, currentlyProcessing });
  const elements = section?.hits ?? [];

  return (
    <>
      <GeneralList
        isLoading={isLoading}
        createResults={createResults}
        elements={elements}
        section={section}
        scrollBarRef={scrollBarRef}
        currentlyProcessing={currentlyProcessing}
      />
    </>
  );
};

export default ListControl;
