import React, { useState, useEffect } from 'react';
import DatePickerInput from 'react-day-picker/DayPickerInput';
import dayjs from 'dayjs';
import { normalizedDateUK } from '../../Dashboard/utilities/normalizedDateUK';
import ModalComponent from '../../Common/Modal';
import createNotification from '../../Settings/Utilities/CreateNotification';
import { Error } from '../../Dashboard/Influence/CrmContacts/AddContactModal';
const SetUpdateTeamTrialModal = (props) => {
  const { team, showModal, closeModal, actionTeamTrial, actionText, planCode } = props;
  const [planCodeForm, setPlanCodeForm] = useState(planCode);
  const [dateError, setDateError] = useState(null);
  const [endTeamTrialForm, setEndTeamTrialForm] = useState({});
  const handlePlanCodeDropdown = (e) => {
    setPlanCodeForm(e.target.value);
  };
  const dateFormat = 'DD MMM, YYYY';
  const dateFormatToStore = 'YYYY-MM-DD';
  const handleEndTrialDate = (e) => {
    if (new Date(e).getTime() > new Date().getTime()) {
      setEndTeamTrialForm(dayjs(e).utc().format(dateFormat));
      setDateError(null);
    } else {
      setEndTeamTrialForm(normalizedDateUK().format(dateFormat));
      createNotification('danger', 'End time of trial should be in future');
      setDateError('End time of trial should be in future');
    }
  };

  const handleActionTeamTrial = async () => {
    const endTrialFormatted = dayjs(endTeamTrialForm).format(dateFormatToStore);
    await actionTeamTrial(team.id, planCodeForm, endTrialFormatted);
    closeModal();
  };

  const loadControls = () => {
    const newPlanCodeForm = team.planCode === '' ? 'Mogul20201215' : team.planCode;
    setPlanCodeForm(newPlanCodeForm);
    const newEndTeamTrialForm =
      actionText === 'Start' || actionText === 'Extend' ? dayjs(new Date()).add(14, 'days') : team.trialEndDate;
    setEndTeamTrialForm(newEndTeamTrialForm);
  };

  useEffect(() => {
    loadControls();
  }, [team]);

  return (
    <>
      <ModalComponent isOpen={showModal} setIsOpen={closeModal}>
        <h3 className='title-h4-bold px-3 px-lg-5 py-3 mb-0'>{actionText} Team Trial</h3>
        <div className='py-4 border-top border-bottom px-3 px-lg-5'>
          <form>
            <div className='mb-3 form-group'>
              <label className='font-weight-bold'>Plan Code</label>
              <select value={planCodeForm} className='form-control' onChange={handlePlanCodeDropdown.bind(this)}>
                <option value='Mogul20201215'>Mogul</option>
                <option value='Enterprise20220314'>Enterprise</option>
                <option value='AgencyEnterprise20240429'>Enterprise (Agency)</option>
                <option value='MembershipOrganisationEnterprise20220503'>Enterprise (Membership Organisation)</option>
                {/* <option value='AgencyMogul20220512'>Mogul (Agency)</option> */}
                <option value='MembershipOrganisationMogul20220512'>Mogul (Membership Agency)</option>
              </select>
            </div>
            <div className='form-gropup mb-3 end-trial-date-picker'>
              <label className='font-weight-bold d-block'>End Time Trial</label>
              <DatePickerInput
                value={normalizedDateUK(endTeamTrialForm).format(dateFormat)}
                inputProps={{ className: 'form-control' }}
                onDayChange={handleEndTrialDate}
              />
              {!!dateError && <Error message={dateError} />}
            </div>
          </form>
        </div>
        <div className='p-3 px-lg-5 text-right'>
          <button className='btn btn-secondary' onClick={closeModal}>
            Cancel
          </button>
          <button
            className='ml-2 btn btn-info'
            variant='primary'
            onClick={handleActionTeamTrial}
            disabled={!!dateError}
          >
            {actionText}
          </button>
        </div>
      </ModalComponent>
    </>
  );
};

export default SetUpdateTeamTrialModal;
