import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import callTeamsContent from '../../apiCalls/callTeamsContent';
import { useState } from 'react';
import { store } from '../../../Store.js';
import { useContext } from 'react';
import SearchBarWithTeamContent from './SearchBarWithTeamContent.js';
import addMetaTags from '../../utilities/addMetaTags';
import camelCasetoNormalStrign from '../../utilities/camelCaseToNormalString';
import normalize from '../../utilities/normalizeString';
import transformToPascalCase from '../../../Settings/Utilities/TransformToPascalCase';
import useIsMobile from '../../utilities/useIsMobile';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useGetAccessToken from '../../apiCalls/useGetAccessToken.js';

const EngagementStatisticsDropdown = () => {
  const [teamsContent, setTeamsContent] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const params = useParams();
  let { itemId, type } = params;

  const globalState = useContext(store);
  const { getAccessToken } = useGetAccessToken();
  const { dispatch, state } = globalState;
  const { engagementStatisticItem } = state;

  const isMobile = useIsMobile(768);

  const loadTeamsContent = async (source) => {
    let token = isAuthenticated ? await getAccessToken() : '';
    try {
      //LOAD TEAMS CONTENT
      let teamsContentResult = await callTeamsContent({
        token,
        source,
        abbreviated: true,
        publishedOnly: true,
      });
      if (!!teamsContentResult) {
        setTeamsContent(teamsContentResult);
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'engagementStatisticsResult',
          value: teamsContentResult,
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'engagementStatisticsOnLoad',
          value: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // INITIAL LOAD
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadTeamsContent(source);
    return () => {
      source.cancel('Engagement statistics api call canceled by the user');
    };
  }, []);

  useEffect(() => {
    if (!itemId && !!teamsContent.length) {
      const type = normalize(camelCasetoNormalStrign(teamsContent[0].type));
      const id = teamsContent[0].id;

      let title = `Engagement statistics - Influence - PolicyMogul`;
      document.title = title;
      navigate(`/influence/engagement-statistics/${type}/${id}`, {
        replace: true,
      });
    }
  }, [teamsContent]);

  useEffect(() => {
    if (itemId) {
      let title = `Engagement statistics - Influence - PolicyMogul`;
      addMetaTags({ title, hash: null, location, dispatch });
    }
  }, [itemId]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'engagementStatisticItem',
        value: {},
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'engagementStatisticsOnLoad',
        value: true,
      });
    };
  }, []);

  const itemType = transformToPascalCase(type?.split('-').join(' ') ?? '');

  const selectedContact = teamsContent.find((item) => item.id === parseFloat(itemId) && item.type === itemType)?.title;

  return (
    teamsContent.length > 0 && (
      <>
        <SearchBarWithTeamContent
          teamsContent={teamsContent}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedContact={selectedContact}
        />
        <Link target='_blank' to={engagementStatisticItem?.canonicalUrl} className='text-decoration-none ml-lg-0 ml-2'>
          <button className='d-block sort-button open-content-type-button'>
            Open {!isMobile ? `this ${type?.split('-').join(' ')}` : ''}
            <span className='icon-external-bold' style={{ marginLeft: '8px', opacity: 0.6, fontWeight: 600 }}></span>
          </button>
        </Link>
      </>
    )
  );
};

export default EngagementStatisticsDropdown;
