import React, { useContext, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { store } from '../../Store.js';
import useIsMobile from '../utilities/useIsMobile.js';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord.js';

const TrialBanner = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activePlan, activeSubscriptions } = state;
  const { isPaidFor } = activeSubscriptions;
  const { subscriptionStatus, trialEndDateTimestamp, activeDiscountCode, name: planName } = activePlan;
  const isMobile = useIsMobile();
  const calculateDifference = () => {
    const currentTimeStamp = new Date().getTime() / 1000;
    const difference =
      currentTimeStamp < trialEndDateTimestamp ? Math.abs(trialEndDateTimestamp - currentTimeStamp) : 0;
    return Math.ceil(difference / (60 * 60 * 24));
  };
  const { newAgencyPlan } = useTopicOrClientWord();
  const location = useLocation();
  const { pathname } = location;

  const pathNamesToExclude = [''];
  const InTrialBanner = useCallback(
    (node) => {
      if (node !== null && subscriptionStatus === 'InTrial' && !isMobile) {
        let trialBannerHeight = !pathNamesToExclude.includes(pathname) ? node.getBoundingClientRect().height : 0;
        dispatch({ type: 'MODIFY_SECTION', parameter: 'trialBannerHeight', value: trialBannerHeight });
      }
    },
    [subscriptionStatus]
  );
  const returnUrl = `${location.pathname}${location.search}${location.hash}`;
  const pricinUrl = `/pricing?returnUrl=${encodeURIComponent(returnUrl)}`;

  useEffect(() => {
    if (subscriptionStatus && subscriptionStatus !== 'InTrial' && subscriptionStatus !== 'TrialEnded') {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'trialBannerHeight', value: 0 });
    }
  }, [subscriptionStatus]);

  const activeSubscriptionsValue = Object.keys(activeSubscriptions).length === 0 ? true : !isPaidFor;
  const trialBannerConditional = subscriptionStatus && subscriptionStatus === 'InTrial' && activeSubscriptionsValue;

  return (
    <>
      {trialBannerConditional && (
        <div
          className={`trial-banner ${newAgencyPlan ? 'py-2 py-lg-3' : 'py-3'}`}
          ref={InTrialBanner}
          style={{ display: !pathNamesToExclude.includes(pathname) ? 'block' : 'none' }}
        >
          <div className='main-content-wrapper px-3 px-lg-5 mx-auto'>
            <p className='mb-0'>
              You have{' '}
              <span className='left-days'>
                {calculateDifference()} day{calculateDifference() !== 1 ? 's' : ''}
              </span>{' '}
              left on your trial{!newAgencyPlan && <> of the {planName} plan</>}.
              {newAgencyPlan ? (
                <>
                  <button className='d-none d-lg-inline-block ml-1 general-button contact-button-launch-messenger choose-plan-days'>
                    Contact us to subscribe
                  </button>
                </>
              ) : (
                <>
                  {planName !== 'Enterprise' && (
                    <>
                      {activeDiscountCode !== null
                        ? ` If you choose a paid option, we'll automatically apply your ${activeDiscountCode.name} discount.`
                        : ''}
                      <Link to={pricinUrl}>
                        <button
                          className={`ml-1 general-button ${calculateDifference() <= 3 ? 'choose-plan-lessdays px-3 py-1 ml-1' : 'choose-plan-days'}`}
                        >
                          Choose your plan now
                        </button>
                      </Link>
                    </>
                  )}
                </>
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default TrialBanner;
