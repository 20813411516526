import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { store } from '../../Store.js';
import { useLocation, useParams } from 'react-router-dom';
import getCurrentTeam from '../Team/getCurrentTeam';
import EmailAddressesList from './EmailAddressesList.js';
import useEmailDomainFunctions from '../../Dashboard/hooks/useEmailDomainFunctions.js';
import SkeletonTeam from '../Team/SkeletonTeam.js';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord.js';
import useClientsFunctions from '../../Dashboard/hooks/useClientsFunctions.js';
import EditEmailSignatureModal from '../EmailDomains/ui/EditEmailSignatureModal.js';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';

const EmailAddresses = ({ isAdminPage }) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [isLoading, setIsLoading] = useState(true);
  const [showEditEmailSignatureModal, setShowEditEmailSignatureModal] = useState({ show: false, item: null });

  const [emailAddresses, setEmailAddresses] = useState([]);
  const [emailDomains, setEmailDomains] = useState([]);
  const [team, setTeam] = useState({});

  const location = useLocation();
  const params = useParams();
  const { id } = params;
  const { getAccessToken } = useGetAccessToken();

  const { getEmailDomains, syncEmailDomain, getEmailAddressesCall } = useEmailDomainFunctions();
  const { getTeams } = useClientsFunctions();
  const { newAgencyPlan } = useTopicOrClientWord();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Billing canceled by the user.');
    };
  }, []);

  const syncEmailDomainRequest = async (teamId, source) => {
    await syncEmailDomain({ id, isAdminPage, source, teamId: newAgencyPlan ? teamId ?? team?.teamId : null });
    const emailDomains = await getEmailDomains({ id, isAdminPage, getAllTeams: newAgencyPlan });

    if (emailDomains !== undefined) {
      setEmailDomains(emailDomains);
    }
  };

  const getEmailAddresses = async (source) => {
    const emailAddresses = await getEmailAddressesCall(source, newAgencyPlan);
    if (emailAddresses !== undefined) {
      setEmailAddresses(emailAddresses);
      setIsLoading(false);
    }
  };

  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      await syncEmailDomainRequest(null, source);
      await loadInitialInformation(source);
      await getEmailAddresses(newAgencyPlan, source);
      setIsLoading(false);
    } catch (error) {}
  };

  const loadInitialInformation = async (source) => {
    try {
      if (newAgencyPlan) {
        const clients = await getTeams({});
        if (!!clients) {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'clients',
            value: clients,
          });
        }
      }
      let team = await getCurrentTeam(getAccessToken, source);
      if (team !== undefined) {
        setTeam(team);
      }
      let title = `Sender Email Addresses - Settings - PolicyMogul`;
      document.title = title;
      let locationToStorage = {
        title: title,
        storageLocation: { ...location },
      };
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'locationToStorage',
        value: locationToStorage,
      });
    } catch (error) {}
  };

  const showHideEditEmailSignatureModal = (item) => {
    setShowEditEmailSignatureModal({ show: !showEditEmailSignatureModal.show, item });
  };

  const loadInitialInfoForAddresses = async (teamId) => {
    setIsLoading(true);
    await syncEmailDomainRequest(teamId);
    await getEmailAddresses(newAgencyPlan);
    setIsLoading(false);
    return true;
  };

  return (
    <>
      {isLoading && <SkeletonTeam />}
      {!isLoading && (
        <>
          <EmailAddressesList
            emailAddresses={emailAddresses}
            emailDomains={emailDomains}
            loadInitialInformation={loadInitialInfoForAddresses}
            team={team}
            isAdminPage={isAdminPage}
            showEditEmailSignatureModal={showEditEmailSignatureModal}
            setShowEditEmailSignatureModal={setShowEditEmailSignatureModal}
            showHideEditEmailSignatureModal={showHideEditEmailSignatureModal}
          />
        </>
      )}
      {showEditEmailSignatureModal.show && (
        <EditEmailSignatureModal
          loadInitialInformation={loadInitialInfoForAddresses}
          setShowPopup={showHideEditEmailSignatureModal}
          domainsList={emailDomains}
          item={showEditEmailSignatureModal.item}
          identifier={'edit-signature-modal'}
        />
      )}
    </>
  );
};

export default EmailAddresses;
