import React from 'react';
import { Link } from 'react-router-dom';
import { sectionName } from '../helpers/controlActiveFilters';
import useRememberPosition from '../hooks/useRememberPosition';
const NavigationLinks = (props) => {
  const { barNavigationConditional, navigationLinks, disabled } = props;
  const { prev, next, prevIndex, nextIndex } = navigationLinks;
  const section = sectionName();

  const { rememberPosition } = useRememberPosition();

  return (
    <>
      {barNavigationConditional && (
        <div
          className={`row px-4 mx-0 justify-content-${prev === null ? 'end' : 'between'} navigation-buttons-content`}
        >
          {!!navigationLinks.prev && (
            <div
              className='d-flex'
              data-tooltip-content={disabled ? `Summary is in progress` : ''}
              data-tooltip-id='general-tooltip'
              data-tooltip-class-name={'general-tooltip pricing-feature-tooltip'}
            >
              <Link
                className={`navigation-button prev-button ${disabled ? 'disabled' : ''}`}
                to={navigationLinks.prev}
                onClick={() => [rememberPosition(section, null, prevIndex)]}
              >
                <span className='icon-left-triangle-filled'></span>
                Previous
              </Link>
            </div>
          )}
          {!!next && (
            <div
              className='d-flex'
              data-tooltip-content={disabled ? `Summary is in progress` : ''}
              data-tooltip-id='general-tooltip'
              data-tooltip-class-name={'general-tooltip pricing-feature-tooltip'}
            >
              <Link
                className={`navigation-button next-button ${disabled ? 'disabled' : ''}`}
                to={next}
                onClick={() => {
                  rememberPosition(section, null, nextIndex);
                }}
              >
                Next
                <span className='icon-right-triangle-filled'></span>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NavigationLinks;
