import React, { useCallback, useEffect, useRef } from 'react';
import LegislationNewContent from '../Dashboard/Legislation/LegislationNewContent';
import ParliamentaryNewContent from '../Dashboard/Parliamentary_record/ParliamentaryNewContent';
import KeyUpdateNewContent from '../Dashboard/key_updates/KeyUpdateNewContent';
import ConsultationNewContent from '../Dashboard/consultations/ConsultationNewContent';
import SkeletonKeyUpdatesContent from '../Dashboard/key_updates/SkeletonKeyUpdatesContent';
import MenuItems from './MenuItems';
import TransformFromPascalCase from '../Settings/Utilities/TransformFromPascalCase';
import normalizeString from '../Dashboard/utilities/normalizeString';
import EmbedList from './EmbedList';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
import CustomScrollbar from '../Common/CustomScrollbar';
import { hex_is_light } from '../Reports/ui/ReportDesignButton';
import TitleWidget from '../EmbedAnalytics/EmbedAnalyticsTitleWidget';
import LibraryMaterialMainContent from '../Dashboard/LibraryMaterial/LibraryMaterialMainContent';
import axios from 'axios';
import { getOS } from '../../utils/getOS';

const SubtextOneResource = (finalResources) => {
  let text = TransformFromPascalCase(finalResources[0])?.toLowerCase();
  if (text) {
    switch (text) {
      case 'tweets':
        return 'Showing the latest Tweets';
      case 'events':
        return 'Showing upcoming events';
      default:
        return `Showing the latest ${text}`;
    }
  }
};

const Embed = (props) => {
  const {
    resources,
    isLoading,
    results,
    topicName,
    content,
    design,
    header,
    widgetKeywordsLists,
    topicState,
    isFromPopup,
    selectedTopics,
    userId,
    includeBrandingProperty,
  } = props;
  const { headerColour, background } = design;
  const { headerText, subheaderText } = header;
  const { topic, setTopic } = topicState;
  const finalResources = resources.split(',');

  const utmLinks = () => {
    if (!!results && Object.keys(results).length > 0) {
      return `?utm_source=${normalizeString(results.teamName)}-${normalizeString(topicName)}-embed-widget&utm_medium=referrer`;
    } else {
      return '';
    }
  };

  const { agencyUser } = useTopicOrClientWord();
  const embedElement = useRef();
  return (
    <>
      <div className='embed-content dashboard-root-container' ref={embedElement}>
        <div style={{ background: headerColour, color: hex_is_light(headerColour) ? '#00122b' : '#fff' }}>
          <TitleWidget
            isFromPopup={isFromPopup}
            selectedTopics={selectedTopics}
            topic={topic}
            setTopic={setTopic}
            analyticsKeywordsLists={widgetKeywordsLists}
            agencyUser={agencyUser}
            header={headerText}
            subheader={subheaderText}
            userId={userId}
          />
        </div>

        {content === null && (
          <>
            {finalResources.length > 1 && <MenuItems {...props} />}
            <EmbedList
              background={background}
              includeBrandingProperty={includeBrandingProperty}
              topic={topic}
              {...props}
            />
          </>
        )}
        {content !== null && <EmbedContentComponent utmLinks={utmLinks} {...props} />}
        {!isLoading && includeBrandingProperty && (
          <div className='embed-padding text-right embed-link-bottom' style={{ backgroundColor: background }}>
            <a
              className='embed-link'
              href={`${process.env.REACT_APP_APPURL}${utmLinks()}`}
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: hex_is_light(background) ? '#00122b' : '#fff' }}
            >
              Political updates powered by
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo${hex_is_light(background) ? '' : '_white'}.svg`}
                alt='PolicyMogul logo'
                width={'90px'}
                className={`d-inline-block ml-1`}
              />{' '}
            </a>
          </div>
        )}
      </div>
    </>
  );
};

const EmbedContentComponent = (props) => {
  const {
    typeOfContent,
    content,
    setContent,
    scrollbarContentContainer,
    isFromPopup,
    results,
    utmLinks,
    includeBrandingProperty,
    specificContentApiCall,
  } = props;
  const contentContainer = useRef(null);

  const embedContent = useCallback(
    (node) => {
      if (node !== null) {
        let links = document.querySelectorAll('.embed-content-container a');
        links.forEach((item) => {
          item.href += utmLinks();
          item.setAttribute('target', '_blank');
        });
      }
    },
    [results]
  );

  const callSpecificApiCall = useRef();
  callSpecificApiCall.current = (source) => {
    try {
      if (!!content?.item) {
        specificContentApiCall({ item: content?.item, source });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callSpecificApiCall.current(source);
    return () => {
      source.cancel('Embed content loading cancelled by the user');
    };
  }, [includeBrandingProperty]);

  return (
    <div className='flex-grow-1 dashboard-root-container'>
      <div className='embed-padding menu-embed-items embed-back-container mb-0'>
        <button
          className='general-button embed-button-back'
          onClick={() => {
            setContent(null);
          }}
        >
          Back to{' '}
          {typeOfContent === 'CommonsLibraryArticles' ? 'Library material' : TransformFromPascalCase(typeOfContent)}
        </button>
      </div>
      {content.isLoading ? (
        <SkeletonKeyUpdatesContent />
      ) : (
        <>
          <div className='embed-content-container flex-grow-1 main-update-content-container' ref={embedContent}>
            <div ref={contentContainer} />
            <CustomScrollbar
              ref={scrollbarContentContainer}
              className={`simple-scrollbar ${getOS() === 'Windows' ? 'title-widget-lists' : ''}`}
              maximalThumbYSize={100}
            >
              <div className='px-3'>
                {(() => {
                  const currentNew = content.currentNew;
                  const shareButtonWidget = true;
                  const contributionId = content.contributionId;
                  const handPickedNew = results.handPickedTopic;
                  const widgetContentUrl = content.currentNew.canonicalUrl;
                  const utmLink = utmLinks();
                  const props = {
                    currentNew,
                    shareButtonWidget,
                    contributionId,
                    scrollBarRef: scrollbarContentContainer,
                    scrollbarRef: scrollbarContentContainer,
                    widgetContentUrl,
                    utmLink,
                    handPickedNew,
                    contentContainer,
                    isFromPopup,
                    notIncludeBranding: !includeBrandingProperty,
                  };

                  switch (typeOfContent) {
                    case 'Legislation':
                      return <LegislationNewContent key={`${typeOfContent}-content`} {...props} hideSummary embed />;
                    case 'ParliamentaryRecord':
                      return <ParliamentaryNewContent key={`${typeOfContent}-content`} {...props} hideSummary embed />;
                    case 'KeyUpdates':
                    case 'HandPickedKeyUpdates':
                      return <KeyUpdateNewContent key={`${typeOfContent}-content`} {...props} hideSummary embed />;
                    case 'Consultations':
                      return <ConsultationNewContent key={`${typeOfContent}-content`} {...props} embed />;
                    case 'CommonsLibraryArticles':
                      return <LibraryMaterialMainContent key={`${typeOfContent}-content`} {...props} embed />;
                    default:
                      return '';
                  }
                })()}
              </div>
            </CustomScrollbar>
          </div>
        </>
      )}
    </div>
  );
};

export { SubtextOneResource };
export default Embed;
