import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Autoplay, Pagination } from 'swiper/modules';
import FsLightbox from 'fslightbox-react';
import useIsMobile from '../../utilities/useIsMobile';
import { Portal } from 'react-portal';
import 'swiper/css';
import 'swiper/css/pagination';

const EmailPerformanceEmptyScreen = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [numberOfSlide, setNumberOfSlide] = useState(0);
  const [toggler, setToggler] = useState(false);
  const slides = [
    `${process.env.REACT_APP_CDNURL}/images/20230826-email-performance-empty-1`,
    `${process.env.REACT_APP_CDNURL}/images/20230826-email-performance-empty-2`,
  ];

  useEffect(() => {
    if (!!swiperRef?.autoplay) {
      if (toggler) {
        swiperRef.autoplay.stop();
      } else {
        swiperRef.autoplay.start();
      }
    }
  }, [swiperRef, toggler]);

  const isMobile = useIsMobile(768);

  return (
    <>
      <div className='contact-list-scrollbar-table-no-data' style={{ height: 'calc(100% + 25px)' }}>
        <div
          className='crm-table-no-contacts w-100 h-100 position-relative'
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: '52px',
            gap: '5px',
          }}
        >
          <p className='crm-table-no-contacts-title'>Analyse your impact</p>
          <p className='crm-table-no-contacts-description'>
            Once you or your teammates send an email, you’ll be able to track its performance across a range of metrics
          </p>
          <div className='email-performance-emty-screen-img-container w-100 h-100'>
            <div className='email-swiper-pagination' />
            <div className='h-100 pb-0 pswp-gallery' id='email-performance-images'>
              <Swiper
                onSwiper={setSwiperRef}
                slidesPerView={1}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  el: '.email-swiper-pagination',
                  clickable: true,
                }}
                modules={[Pagination, Autoplay, Mousewheel]}
                mousewheel={true}
                speed={300}
                loop={true}
              >
                {slides.map((item, index) => {
                  return (
                    <SwiperSlide key={`empty-screen-${index}`}>
                      <img
                        className='image-render-optimized email-performance-image rounded-top'
                        src={`${item}${isMobile ? '-mobile' : ''}.png`}
                        alt='Analyse your impact'
                        onClick={() => {
                          setToggler(!toggler);
                          let position = slides.indexOf(item);
                          setNumberOfSlide(position);
                        }}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <Portal>
        <FsLightbox
          toggler={toggler}
          sources={slides.map((item) => `${item}${isMobile ? '-mobile' : ''}.png`)}
          sourceIndex={numberOfSlide}
          types={[...new Array(slides.length).fill('image')]}
        />
      </Portal>
    </>
  );
};

export default EmailPerformanceEmptyScreen;
