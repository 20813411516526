import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AddLobbyingMaterialButton from './AddLobbyingMaterialButton.js';
import normalize from '../../utilities/normalizeString.js';
import camelCasetoNormalStrign from '../../utilities/camelCaseToNormalString.js';
import { useNavigate } from 'react-router-dom';

const NavigationTeamsContents = ({ teamsContent, maxHeightContainer, selectedItem, setIsOpen }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      {isAuthenticated && (
        <div>
          <div
            className='scroll-container hover-container search-bar-lists'
            style={{ maxHeight: `${maxHeightContainer}px` }}
          >
            <ul className='policy-list px-0 mb-0 hover-content'>
              {teamsContent.map((item, index) => (
                <NavigationTeamContent
                  key={`keywordlist-${item.id}-${item.type}`}
                  item={item}
                  selectedItem={selectedItem}
                  setIsOpen={setIsOpen}
                />
              ))}
            </ul>
          </div>
          <AddLobbyingMaterialButton keywordsLists={teamsContent} />
        </div>
      )}
    </>
  );
};

const NavigationTeamContent = ({ item, selectedItem, setIsOpen }) => {
  const navigate = useNavigate();

  const applySearch = () => {
    const normalizedType = normalize(camelCasetoNormalStrign(item.type));
    navigate(`/influence/engagement-statistics/${normalizedType}/${item.id}`);
    setIsOpen(false);
  };

  return (
    <div
      style={{ display: 'block' }}
      onClick={() => {
        applySearch();
      }}
    >
      <li
        className={`d-flex dropdown-engagementstatistics dropdown-keywordlist ${
          camelCasetoNormalStrign(item.type) ? 'dropdown-keywordlist-description' : ''
        }`}
      >
        <span className={`icon-briefing-material`} />
        <div className='flex-grow-1'>
          <p className={'hidden-lines hidden-one-line dropdown-name'}>{item.title}</p>
          {camelCasetoNormalStrign(item.type) && (
            <p className='description-field dropdown-description'>{camelCasetoNormalStrign(item.type)}</p>
          )}
        </div>
        {selectedItem === item.id && <span className='icon-tick topic-selected-mark' />}
      </li>
    </div>
  );
};

export default NavigationTeamsContents;
