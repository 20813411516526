import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmailDomainList from '../Settings/EmailDomains/ui/EmailDomainList';
import useEmailDomainFunctions from '../Dashboard/hooks/useEmailDomainFunctions';
import { AdminStore } from './AdminState';
import { useAdminAgencyUser } from './ui/UserSideNav';
import axios from 'axios';

const TeamEmailDomainPage = () => {
  const adminState = useContext(AdminStore);
  const { state } = adminState;
  const { organisation } = state ?? {};

  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const { id } = params;

  const [emailDomains, setEmailDomains] = useState([]);
  const { newAgencyPlan } = useAdminAgencyUser();
  const { getEmailDomains, syncEmailDomain } = useEmailDomainFunctions();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadInitialInformation(source);
    return () => {
      source.cancel('Team domains cancelled by the user');
    };
  }, []);

  let loadInitialInformation = async (source) => {
    try {
      if (id !== undefined) {
        await syncEmailDomain({ isAdminPage: true, id, source });
        const domains = await getEmailDomains({ isAdminPage: true, organisationId: id, source });
        if (!!domains) {
          setEmailDomains(
            newAgencyPlan
              ? domains
              : Array.isArray(domains)
                ? domains?.flatMap((item) => item.emailDomains)
                : domains?.emailDomains
          );
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  return (
    <>
      {isLoading && <p className='pt-5'>Loading...</p>}
      {!isLoading && (
        <EmailDomainList
          newAgencyPlan={newAgencyPlan}
          team={organisation}
          isAdminPage={true}
          emailDomainsState={{ emailDomains, setEmailDomains }}
        />
      )}
    </>
  );
};

export default TeamEmailDomainPage;
