import React, { useState, useRef } from 'react';
import useOutsideElement from '../utilities/useOutsideElement';
import { SaveButton, ShareContent } from './Share';
import preventLink from '../TagsList/prevenLink';
import { useLocation } from 'react-router-dom';

const ItemActions = (props) => {
  const {
    item,
    listItem,
    alreadyInContacts,
    stakeholderMapping,
    setContactIdsCheck,
    contactIdsCheck,
    influenceItem,
    setShowStakeholdersCrmPopup,
  } = props;
  const [showActions, setShowActions] = useState(false);
  const actionsElement = useRef(null);
  const location = useLocation();
  const hideActions = () => {
    setShowActions(false);
  };
  useOutsideElement(actionsElement, hideActions);
  const embedWidget = location.pathname.toLowerCase().startsWith('/embed');
  const showItemActions = !embedWidget || (embedWidget && !listItem);

  return (
    <div ref={actionsElement} className='item-actions-container item-actions-container-current-content'>
      <div className='flex-centered'>
        {!embedWidget && <SaveButton {...props} hideActions={hideActions} />}
        {showItemActions && (
          <button
            className={`general-button circle-button circle-btn-with-hover ${showActions ? 'active' : ''}`}
            onClick={(e) => {
              preventLink(e);
              setShowActions(!showActions);
            }}
            style={{ visibility: item.type === 'Event' ? 'hidden' : 'visible' }} //AE: Better having visibility hidden to keep the space and the alignment of the save icon with the other items on /saved-items
          >
            {/* Item actions */}
            <span
              className='icon-three-dots title-h4 d-block'
              style={{
                color: 'rgba(0, 18, 43, 0.5)',
                marginTop: '2px',
                marginLeft: '-1px',
              }}
            ></span>
          </button>
        )}
      </div>
      {showActions && (
        <div className='box box-shadow py-2 item-actions-box '>
          <ShareContent
            item={item}
            id={item.id}
            title={item.title}
            url={item.contentUrl}
            embargoed={item.embargoed}
            listItem={listItem}
            itemShare
            influenceItem={influenceItem}
            alreadyInContacts={alreadyInContacts}
            stakeholderMapping={stakeholderMapping}
            setContactIdsCheck={setContactIdsCheck}
            contactIdsCheck={contactIdsCheck}
            setShowStakeholdersCrmPopup={setShowStakeholdersCrmPopup}
            hideActions={hideActions}
            setShowActions={setShowActions}
          />
        </div>
      )}
    </div>
  );
};

export default ItemActions;
