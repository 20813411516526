import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../../Store';
import { useChangeKeywordsListsOrder } from '../../hooks/useSearchBarUiFunctions';

/* const CrmGroupsEmptyMessage = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, keywordsLists } = state ?? {};

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({
    keywordsLists: keywordsLists?.filter((item) => item?.id !== null),
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayLength, setDisplayLength] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const texts = [
    () => (
      <>
        Show me <span className='text-yellow'>all policymakers</span> who have mentioned{' '}
        <span className='text-light-blue'>{team?.teamName}</span> in the{' '}
        <span className='empty-groups-text-green'>last year</span>
      </>
    ),
    () => (
      <>
        Show me <span className='empty-groups-text-red'>Labour MPs</span> who have spoken{' '}
        <span className='empty-groups-text-green'>positively</span>{' '}
        <span className='text-yellow'>in the last 30 days</span> about{' '}
        <span className='text-light-blue'>{changeKeywordsListsOrder()?.[0]?.name ?? 'immigration'}</span>
      </>
    ),
    () => (
      <>
        Show me <span className='text-light-blue'>Conservative MPs</span> in the{' '}
        <span className='text-yellow'>North East</span> who are{' '}
        <span className='empty-groups-text-red'>detractors</span> for <span>{team?.teamName}</span>
      </>
    ),
    () => (
      <>
        Show me <span className='text-yellow'>all policymakers</span> who have mentioned{' '}
        <span className='text-light-blue'>{team?.teamName}</span> in the{' '}
        <span className='empty-groups-text-green'>last year</span>
      </>
    ),
  ];

  useEffect(() => {
    const currentText = texts[currentIndex]();
    const flatText = flattenText(currentText);
    const typingSpeed = isDeleting ? 30 : 50;

    if (isPaused) return;

    if (!isDeleting && displayLength === flatText.length) {
      if (currentIndex < texts.length - 1) {
        setTimeout(() => setIsDeleting(true), 4500);
      } else {
        setIsPaused(true);
      }
    } else if (isDeleting && displayLength === 0) {
      setIsDeleting(false);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      const nextLength = isDeleting ? displayLength - 1 : displayLength + 1;
      const timeoutId = setTimeout(() => {
        setDisplayLength(nextLength);
      }, typingSpeed);

      return () => clearTimeout(timeoutId);
    }
  }, [displayLength, isDeleting, currentIndex, texts, isPaused]);

  const flattenText = (element) => {
    if (typeof element === 'string') {
      return element.split(''); // Split strings into individual characters
    } else if (React.isValidElement(element) && element.props.children) {
      const children = React.Children.toArray(element.props.children).flatMap(flattenText);
      return children.map((child, index) =>
        React.cloneElement(element, { key: `${element.key}-${index}`, children: child })
      );
    }
    return [];
  };

  const renderText = (text) => {
    const characters = flattenText(text);
    return characters.slice(0, displayLength);
  };

  return (
    <div className='h-100 d-flex flex-column w-100 box-top-rounded pt-5'>
      <div className='pt-lg-5 mb-5'>
        <h3 className='title-h3-bold text-center mb-2'>You haven’t created any groups yet</h3>
        <p className='text-center light-50-text title-h5-m'>
          Once you or your colleagues create groups, they will appear here
        </p>
      </div>
      <div className='empty-groups-message-container rounded'>
        <p className='bg-main-blue position-absolute mb-0 rounded py-1 px-4 text-white paragraph-p1 inspired-message'>
          Inspired by other PolicyMogul users
        </p>
        <p className={`empty-group-message mb-0`}>
          {isPaused ? texts[currentIndex]() : renderText(texts[currentIndex]())}
          {!isPaused && <span className='blinking-cursor'>|</span>}
        </p>
      </div>
    </div>
  );
};
 */
const CrmGroupsEmptyMessage = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, keywordsLists } = state ?? {};

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({
    keywordsLists: keywordsLists?.filter((item) => item?.id !== null),
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayLength, setDisplayLength] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const texts = [
    () => (
      <>
        Show me <span className='text-yellow'>all policymakers</span> who have mentioned{' '}
        <span className='text-light-blue'>{team?.teamName}</span> in the{' '}
        <span className='empty-groups-text-green'>last year</span>
      </>
    ),
    () => (
      <>
        Show me <span className='empty-groups-text-red'>Labour MPs</span> who have spoken{' '}
        <span className='empty-groups-text-green'>positively</span>{' '}
        <span className='text-yellow'>in the last 30 days</span> about{' '}
        <span className='text-light-blue'>{changeKeywordsListsOrder()?.[0]?.name ?? 'immigration'}</span>
      </>
    ),
    () => (
      <>
        Show me <span className='text-light-blue'>Conservative MPs</span> in the{' '}
        <span className='text-yellow'>North East</span> who are{' '}
        <span className='empty-groups-text-red'>detractors</span> for{' '}
        <span className='empty-groups-text-green'>{team?.teamName}</span>
      </>
    ),
  ];

  useEffect(() => {
    const currentText = texts[currentIndex]();
    const flatText = flattenText(currentText);
    const typingSpeed = isDeleting ? 30 : 50;

    if (displayLength === flatText.length && !isDeleting) {
      // After completing one text, delete it and move to the next one
      setTimeout(() => setIsDeleting(true), 4500);
    } else if (isDeleting && displayLength === 0) {
      // Once deletion completes, move to the next text
      setIsDeleting(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length); // Loop back to first text
    } else {
      const nextLength = isDeleting ? displayLength - 1 : displayLength + 1;
      const timeoutId = setTimeout(() => {
        setDisplayLength(nextLength);
      }, typingSpeed);

      return () => clearTimeout(timeoutId);
    }
  }, [displayLength, isDeleting, currentIndex, texts]);

  const flattenText = (element) => {
    if (typeof element === 'string') {
      return element.split(''); // Split strings into individual characters
    } else if (React.isValidElement(element) && element.props.children) {
      const children = React.Children.toArray(element.props.children).flatMap(flattenText);
      return children.map((child, index) =>
        React.cloneElement(element, { key: `${element.key}-${index}`, children: child })
      );
    }
    return [];
  };

  const renderText = (text) => {
    const characters = flattenText(text);
    return characters.slice(0, displayLength);
  };

  return (
    <div className='h-100 d-flex flex-column w-100 box-top-rounded pt-5 px-3 px-lg-0'>
      <div className='pt-lg-5 mb-5'>
        <h3 className='title-h3-bold text-center mb-2'>You haven’t created any groups yet</h3>
        <p className='text-center light-50-text title-h5-m'>
          Once you or your colleagues create groups, they will appear here
        </p>
      </div>
      <div className='empty-groups-message-container '>
        <p className='bg-main-blue position-absolute mb-0 rounded py-1 px-2 px-lg-4 text-white paragraph-p1 inspired-message'>
          Inspired by other PolicyMogul users
        </p>
        <p className={`empty-group-message mb-0`}>
          {renderText(texts[currentIndex]())}
          <span className='blinking-cursor'>|</span>
        </p>
      </div>
    </div>
  );
};
export default CrmGroupsEmptyMessage;
