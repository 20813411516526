import React, { useEffect, useState, useContext } from 'react';
import { store } from '../../Store.js';
import getTeamKeywordLists from '../Utilities/getTeamKeywordLists';
import getCurrentTeam from '../Team/getCurrentTeam';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import TopicsLists from './TopicsLists.js';
import TopicComponent from './TopicComponent.js';
import NoTopicsView from './NoTopicsView.js';
import OverLimitBanner from './OverLimitBanner.js';
import SkeletonTopic from './SkeletonTopic.js';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord.js';
import useClientsFunctions from '../../Dashboard/hooks/useClientsFunctions.js';
import useCreateEmailAlerts from '../EmailAlerts/useCreateEmailAlerts.js';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';

const Topics = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { keywordsLists, activeSubscriptions } = state;
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { getAccessToken } = useGetAccessToken();
  const params = useParams();

  const { list } = params;
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadInitialInformation(source);
    return () => {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'listPosition', value: null });
      source.cancel('Keywords canceled by the user.');
    };
  }, []);

  const { transformTopicOrClientWord, newAgencyPlan } = useTopicOrClientWord();
  const { getTeams } = useClientsFunctions();
  const { loadAlerts } = useCreateEmailAlerts();

  const loadInitialInformation = async (source) => {
    try {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'readyKeywordLists', value: false });
      const teams = newAgencyPlan ? await getTeams({ includeKeywordsList: true, source }) : null;
      let keywordsLists = newAgencyPlan
        ? {
            myKeywordLists: teams.map((item) => {
              let teamId = item?.teamId;
              return item.myKeywordLists.flat()?.map((list) => {
                return { ...list, teamId };
              });
            }),
            teamMemberKeywordLists: teams.map((item) => {
              let teamId = item?.teamId;
              return item.teamMemberKeywordLists.flat()?.map((list) => {
                return { ...list, teamId };
              });
            }),
          }
        : await getTeamKeywordLists(getAccessToken, source, true);

      await loadAlerts(source);
      let currentTeam = await getCurrentTeam(getAccessToken, source);
      if (!!keywordsLists) {
        const { myKeywordLists, teamMemberKeywordLists } = keywordsLists;
        const { maxNumKeywordsPerTeam } = activeSubscriptions;
        const [keywordsListsToRender, remainingKeywords] = createFirstData({
          myKeywordLists,
          teamMemberKeywordLists,
          maxNumKeywordsPerTeam,
        });
        dispatch({ type: 'MODIFY_SECTION', parameter: 'clients', value: teams });
        dispatch({ type: 'MODIFY_SECTION', parameter: 'remainingKeywords', value: remainingKeywords });
        dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: keywordsListsToRender });
        dispatch({ type: 'MODIFY_SECTION', parameter: 'InitialKeywordsState', value: keywordsListsToRender });
        dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
        let title = `${transformTopicOrClientWord({ plural: true, uppercase: true })} - Settings - PolicyMogul`;
        document.title = title;
        let locationToStorage = {
          title: title,
          storageLocation: { ...location },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createFirstData = (props) => {
    const { teamMemberKeywordLists, myKeywordLists, maxNumKeywordsPerTeam } = props;
    const finalMyKeywordsLists = myKeywordLists?.flat()?.map((item) => {
      return { ...item, isMyList: true };
    });
    const finalTeamMembedLists = teamMemberKeywordLists?.flat()?.map((item) => {
      return { ...item, isMyList: false };
    });
    let keywordsListsToRender = [...finalMyKeywordsLists, ...finalTeamMembedLists];

    let remainingKeywords;
    if (maxNumKeywordsPerTeam !== null) {
      let keywords = 0;
      keywordsListsToRender.forEach((item) => {
        keywords += item.keywords.length;
      });
      remainingKeywords = maxNumKeywordsPerTeam - keywords;
    } else {
      remainingKeywords = 'unlimited';
    }
    keywordsListsToRender = keywordsListsToRender.map((item) => {
      return {
        ...item,
        isSaved: true,
        index: item?.index,
        andKeywords: item?.andKeywords ?? [],
        countries: item?.countries ?? 'UK, Scotland, Wales, NorthernIreland',
      };
    });
    return [keywordsListsToRender, remainingKeywords];
  };

  return (
    <>
      {isLoading && <SkeletonTopic />}
      {!isLoading && (
        <>
          <OverLimitBanner />
          <div className={`pt-4 pt-lg-5`}>
            <div className='settings-box'>
              <div className='keyword-category-container'>
                <div className='keyword-category-box'>
                  <div className='main-settings-content-container'>
                    {list !== undefined && list !== '' && <TopicComponent />}
                    {(list === undefined || list === '') && (
                      <>
                        {keywordsLists.length === 0 && <NoTopicsView />}
                        {keywordsLists.length > 0 && <TopicsLists />}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Topics;
