import React, { useEffect, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
const dayjs = require('dayjs');

const CalendarPicker = (props) => {
  const { setMonth, month, setCurrentButtonToday, setShowPicker } = props;
  const pickerElement = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const { startdate, title } = params;
  const defineDate = () => {
    if (startdate) {
      return dayjs(startdate).toDate();
    } else if (title) {
      return dayjs(title).toDate();
    } else {
      return new Date();
    }
  };
  const [currentDay, setCurrentDay] = useState(defineDate());

  const handleDayClick = (day) => {
    setCurrentDay(day);
    navigate(`/calendar/${dayjs(day).format('YYYY-MM-DD')}`);
    if (setShowPicker) {
      setShowPicker();
    }
  };

  const updateCurrentDay = useRef();
  updateCurrentDay.current = () => {
    let dateChanged = defineDate();
    if (dateChanged !== currentDay) {
      setCurrentDay(dateChanged);
      setMonth(dateChanged);
    }
    setCurrentButtonToday(dayjs(dateChanged).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD'));
  };

  useEffect(() => {
    updateCurrentDay.current();
  }, [startdate, title]);

  const changeMonth = (day) => {
    setCurrentButtonToday(
      dayjs(day).get('month') === dayjs().get('month') &&
        dayjs(currentDay).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
    );
    setMonth(day);
  };
  return (
    <div className='tweets-calendar calendar-picker reference-calendar-picker' ref={pickerElement}>
      <div className='px-4 pb-2'>
        <DayPicker
          month={month}
          className='Selectable'
          onMonthChange={changeMonth}
          onDayClick={handleDayClick}
          selectedDays={currentDay}
          firstDayOfWeek={1}
          showOutsideDays
          weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        />
      </div>
    </div>
  );
};

const GoToTodayButton = (props) => {
  const { currentButtonToday, setCurrentButtonToday, setMonth } = props;
  return (
    <div className='date-link-padding'>
      <Link
        to={`/calendar/${dayjs().format('YYYY-MM-DD')}`}
        className={`mr-3 ${currentButtonToday ? 'outline-button' : 'third-button text-white'}  w-100 d-block text-center py-1 today-button`}
        onClick={() => {
          setMonth(new Date());
          setCurrentButtonToday(true);
        }}
      >
        Go to today
      </Link>
    </div>
  );
};

export { GoToTodayButton };
export default CalendarPicker;
