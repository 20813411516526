//setHashes

import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { store } from '../../Store';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import ChangeSearchQuery from '../utilities/changeSearchQuery';
import { setFilterHashes } from './filtersHashes';
const dayjs = require('dayjs');

const useCompileFiltersFunction = (section) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, referenceState, activePlan } = state;
  const { enableSentiment } = activePlan;
  const { activeReference } = referenceState;
  const { activeSearch, list } = search;

  const params = useParams();
  const { inboxSection, referenceSection, searchResultsSection } = useMainSectionParameter(params);

  const compileFiltersFunction = (filtersToApply) => {
    let sort,
      searchWithin,
      filters = [],
      unread,
      hash,
      sortMode,
      phrase = [];
    //sort
    let sortModeFilter = filtersToApply.find((option) => option.type === 'sortMode');
    let sortFilter = filtersToApply.find((option) => option.type === 'sort' && option.name !== 'Nation');

    // Nations
    let nationsFilter = filtersToApply.find((option) => option.name === 'Nation');

    //unread
    let unreadFilter = filtersToApply.find((option) => option.name === 'See unread only');

    //dateFilters
    let dateFilter = filtersToApply.find((option) => option.type === 'date' || option.type === 'addedOn');
    if (dateFilter && dateFilter.optionSelected !== '') {
      let dateItem = dateFilter.options.find((item) => item.name === dateFilter.optionSelected);
      dateItem.filter.forEach((item) => {
        if (typeof item === 'object') {
          filters.push(item);
        } else {
          filters.push({
            field: dateFilter.filterName,
            value: item.replace('Z', ''),
            operator: 'DATE_GTE',
          });
        }
      });
      //PHRASE
      if (dateItem.showCalendar) {
        let dates = dateItem.dates.split(',');
        phrase.push(
          dateItem.name === 'Custom'
            ? `${dayjs(dates[0]).format('D MMM')} - ${dayjs(dates[1]).format('D MMM')}`
            : `${dateItem.name.toLowerCase()} ${dayjs(dates[0]).format('D MMM')}`
        );
      } else {
        phrase.push(dateItem.name.toLowerCase());
      }
    }

    //searchFilter
    let searchFilter = filtersToApply.find((option) => option.type === 'search');
    //toggle filter (for now it only is present once for section)
    let toggleFilter = filtersToApply.find((option) => option.type === 'toggle' && option.name !== 'See unread only');
    //parliamentary record filters
    let parliamentaryFilters = filtersToApply.find((option) => option.type === 'parliamentaryRecordMultipleHierarchy');

    //normalFilters
    let filtersToCompile = filtersToApply.filter(
      (option) => !option.type || option.type === 'membership' || option.filterName === 'tags'
    );

    //compile sort
    if (sortFilter) {
      let sortOptions = sortFilter.options;
      sortOptions.forEach((item) => {
        if (item.name === sortFilter.optionSelected && item.filter && section) {
          let pageSort = item.filter.find((item) => item.pages.includes(section));
          if (pageSort) {
            sort = pageSort.property;
          }
        }
      });
    }

    if (sortModeFilter) {
      let sortModeOptions = sortModeFilter.options;
      sortModeOptions.forEach((item) => {
        if (item.name === sortModeFilter.optionSelected) {
          sortMode = item.filterValue;
        }
      });
      if (list === null && activeSearch) {
        sortModeFilter.optionSelected = 'Most influential';
      }
    }
    //compile unread and search
    if (unreadFilter) {
      unread = unreadFilter.value;
      if (unreadFilter.value && activeSearch) {
        phrase.push('unread only');
      }
    }

    if (searchFilter && searchFilter.value !== '' && (activeSearch || activeReference)) {
      searchWithin = {
        searchWithinQuery: ChangeSearchQuery(searchFilter.value),
      };
      phrase.push(`‘${searchFilter.value}’`);
    }

    //compile retweets
    if (toggleFilter && toggleFilter.value) {
      filters.push({
        field: toggleFilter.filterName,
        value: toggleFilter.filterValue ?? 'false',
        operator: toggleFilter.operator ?? 'str_eq',
        hidden: toggleFilter.hidden ?? false,
      });
      if (toggleFilter.phrase) {
        phrase.push(`excluding retweets`);
      }
    }

    //compile parliamentary record filters
    if (parliamentaryFilters) {
      const { options, filterName } = parliamentaryFilters;
      options.forEach((option) => {
        const { selectedOptions, selected, options, houseFilter, houseFilterSelectedOptions } = option;
        if (selected) {
          //PUSH PLAIN CONTENT
          selectedOptions.forEach((selectedOption) => {
            let option = options.find((item) => item.name === selectedOption);
            filters.push({
              field: filterName,
              value: option.filterValue,
              operator: 'str_eq',
            });
          });

          //HOUSES
          if (houseFilter) {
            if (houseFilterSelectedOptions.length === 0) {
              houseFilter.options.forEach((houseOption) => {
                filters.push({
                  field: houseFilter.filterName,
                  value: houseOption.filterValue,
                  operator: 'str_eq',
                });
              });
            } else {
              houseFilterSelectedOptions.forEach((houseOption) => {
                let houseSelected = houseFilter.options.find((item) => item.name === houseOption);
                filters.push({
                  field: houseFilter.filterName,
                  value: houseSelected.filterValue,
                  operator: 'str_eq',
                });
              });
            }
          } else {
            filters.push({ field: 'house', value: 'n/a', operator: 'str_eq' });
          }
        }
      });
    }
    //compileFilters
    filtersToCompile.forEach((item) => {
      const { filterName, options, additionalFilter, notSendIfOptionsAreSelected } = item;
      const allOptionsAreSelectedCondition =
        notSendIfOptionsAreSelected && options.filter((item) => item.selected).length === options.length;

      options.forEach((option) => {
        const { selected, filterValue, name, suboptions, optionSelected, operator } = option;
        if (selected && !allOptionsAreSelectedCondition) {
          let value = filterValue ?? name;
          filters.push({
            field: filterName,
            value: value,
            operator: item?.operator ?? operator ?? 'str_eq',
          });
          let subFilters = compileSuboptions({
            options,
            optionSelected,
            suboptions,
          });
          if (subFilters.length > 0) {
            subFilters.forEach((subfilter) => filters.push(subfilter));
          }
          if (additionalFilter) {
            filters.push(additionalFilter);
          }
        }
      });

      //PHRASE
      let selectedOptions = options.filter((item) => item.selected);
      if (selectedOptions.length > 0) {
        let firstItem = selectedOptions[0];
        let firstItemName = firstItem.phrase ? firstItem.phrase : firstItem.name;
        if (firstItem.optionSelected) {
          if (!firstItem.optionSelected.startsWith('All')) {
            let optionSelected = firstItem.suboptions.find((suboption) => suboption.name === firstItem.optionSelected);
            firstItemName = optionSelected.phrase ? optionSelected.phrase : optionSelected.name;
          }
        }
        if (selectedOptions.length === 1) {
          phrase.push(`${firstItemName}${firstItem.optionSelected ? ' only' : ''}`);
        } else if (selectedOptions.length > 1) {
          phrase.push(`${firstItemName} + ${selectedOptions.length - 1}`);
        }
      }
    });

    hash = setFilterHashes({
      sortFilter,
      unreadFilter,
      filtersToCompile,
      searchFilter,
      sortModeFilter,
      toggleFilter,
      dateFilter,
      parliamentaryFilters,
      nationsFilter,
    });

    let contentFilters = {
      sort,
      searchWithin,
      filters,
      unread,
      hash,
      sortMode,
      nationsFilter,
      phrase: phrase.length > 0 ? `(${phrase.join(', ')})` : '',
    };
    return contentFilters;
  };

  const compileSuboptions = ({ options, optionSelected, suboptions }) => {
    let filters = [];
    if (suboptions) {
      let suboptionSelected = suboptions.find((suboption) => optionSelected === suboption.name);
      let { filter } = suboptionSelected;
      if (filter) {
        filter.forEach((subOptionFilter) => {
          options.forEach((option) => {
            if (option.selected && subOptionFilter.contentType === option.filterValue) {
              filters.push(subOptionFilter.filter);
            }
          });
        });
      }
    }
    return filters;
  };

  const activeResultsConditional = (filter, filters) => {
    const { searchState, activePlanProperty } = filter;
    switch (searchState) {
      case 'list':
        return list !== null && list.id !== null && list !== 'noListsCreated';
      case 'freeSearch':
        return list === null && (activeSearch || activeReference);
      case 'inbox':
        return list !== null && inboxSection;
      case 'not-inbox':
        return list === null || !inboxSection;
      case 'referenceSection':
        return (
          (referenceSection && activeReference) ||
          (activeReference && searchResultsSection) ||
          (activeSearch && searchResultsSection)
        );
      case 'activeSearch':
        return activeSearch || activeReference;
      case 'allResults':
        return !activeSearch && !activeReference;
      case 'questionsCondition':
        let parliamentaryRecordFilters = filters?.find((item) => item.type === 'parliamentaryRecordMultipleHierarchy');
        let questionItem = parliamentaryRecordFilters?.options.find((option) =>
          option.selectedOptions.includes('Written Q&As')
        );
        let noFiltersOptions = parliamentaryRecordFilters?.options.filter((option) => !option.selected);
        return (
          parliamentaryRecordFilters &&
          (noFiltersOptions.length === parliamentaryRecordFilters.options.length || questionItem)
        );
      case 'sentimentSortModeCondition':
        let sortMode = filters.find((option) => option.type === 'sortMode');
        return (
          !!list?.id &&
          enableSentiment &&
          (!sortMode || (sortMode.optionSelected !== 'Most positive' && sortMode.optionSelected !== 'Most negative'))
        );
      case 'disabledPlanFieldOption':
        return !activePlan[activePlanProperty];
      case 'SeneddOrHolyroodCondition':
        let nationFilter = filters.find((option) => option.name === 'Nation');
        return !nationFilter || nationFilter.optionSelected === 'Westminster';
      default:
        return true;
    }
  };

  return { compileFiltersFunction, activeResultsConditional };
};

export default useCompileFiltersFunction;
