import ModalComponent from '../../Common/Modal';
import { useReportsDefaults } from '../ReportsDefaults';
import { useCreateReportsWidgets } from './useCreateReportsWidgets';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import { useNavigate, useLocation } from 'react-router-dom';

const ReportEmptyState = (props) => {
  const [heightContainer, containerRef] = useHeightContainer();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div
        className='w-100 reports-editor-container'
        ref={containerRef}
        style={{ height: `${heightContainer - 24}px` }}
      >
        {Array(1)
          .fill()
          .map((item, index) => (
            <div className='rounded reports-editor-item-placeholder reports-editor-item-placeholder-empty' key={index}>
              {index < 1 && (
                <div className='text-center report-empty-state-paragraph'>
                  <p className='mb-0 main-light-text title-h5-m'>Drag and drop widgets from the list on the left</p>
                  <p className='rounded-pill bg-grey d-inline-block pl-4 pr-5 py-2 text-subtle-text mb-0 mt-4 report-empty-bottom'>
                    <span className='icon-drag paragraph-p3 mr-3' />
                    <span className='icon-team paragraph-p2 mr-2 d-inline-block-centered ' />
                    Relevant policymakers
                  </p>
                  <p className='main-light-text title-h5-m report-empty-or-word'>or</p>
                  <button
                    className='general-button action-button rounded-button px-5 py-2'
                    onClick={() => {
                      let queryParameters = new URLSearchParams(location.search);
                      queryParameters.set('open', true);
                      queryParameters.set('emptyState', true);
                      navigate({ search: queryParameters.toString() }, { replace: true });
                    }}
                  >
                    Choose a pre-built report
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

const ReportCreateStep = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLayout, reportTitle, setReportTitle, setIsOpen, isOpen, isFirstTime, setDefaultProperty } = props;

  const { createReportName } = useCreateReportsWidgets({});
  const { createReportsDefaults } = useReportsDefaults();

  const setReportDefault = ({ type }) => {
    let items = createReportsDefaults({ type });
    let reportName = createReportName({ type });
    setLayout(items);
    setDefaultProperty(type);
    setReportTitle({ ...reportTitle, title: reportName, subtext: '' });
    closeModal(false);
  };

  const reportDefaultFunction = ({ type }) => {
    setReportDefault({ type });
  };

  const closeModal = (value) => {
    setIsOpen(value);
    setTimeout(() => {
      let queryParameters = new URLSearchParams(location.search);
      queryParameters.delete('open');
      queryParameters.delete('emptyState');
      navigate({ search: queryParameters.toString() }, { replace: true });
    }, 200);
  };

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={closeModal} maxWidth={830}>
      <div className='pt-4 pb-5 px-3 px-lg-5'>
        {isFirstTime && (
          <div className='py-4'>
            <h3 className='title-h4-bold'>Start from scratch</h3>
            <button
              className='action-button rounded-button px-5 py-2 my-3'
              onClick={() => {
                closeModal(false);
              }}
            >
              Build a report from scratch
            </button>
          </div>
        )}
        <h3 className='title-h4-bold mb-4 '>{isFirstTime ? 'Or choose' : 'Choose'} a quick starter</h3>
        <div className='reports-default-popup'>
          <button
            onClick={() => reportDefaultFunction({ type: 'weekly' })}
            className='general-button d-block text-main-blue link-main-blue text-decoration-none'
          >
            <div className='border rounded p-3 text-center hovered-button h-100'>
              <h4 className='title-h5-bold'>Weekly report</h4>
              <p className='main-light-text paragraph-p2'>
                Relevant political updates, stakeholders and analytics from the last 7 days
              </p>
            </div>
          </button>

          <button
            onClick={() => reportDefaultFunction({ type: 'monthly' })}
            className='general-button d-block text-main-blue link-main-blue text-decoration-none'
          >
            <div className='border rounded p-3 text-center hovered-button h-100'>
              <h4 className='title-h5-bold'>Monthly report</h4>
              <p className='main-light-text paragraph-p2'>
                Relevant political updates, stakeholders and analytics from the last 30 days
              </p>
            </div>
          </button>

          <button
            onClick={() => reportDefaultFunction({ type: 'quarterly' })}
            className='general-button d-block text-main-blue link-main-blue text-decoration-none'
          >
            <div className='border rounded p-3 text-center hovered-button h-100'>
              <h4 className='title-h5-bold'>Quarterly report</h4>
              <p className='main-light-text paragraph-p2'>
                Relevant political updates, stakeholders and analytics from the last 90 days
              </p>
            </div>
          </button>

          <button
            onClick={() => reportDefaultFunction({ type: 'annual' })}
            className='general-button d-block text-main-blue link-main-blue text-decoration-none'
          >
            <div className='border rounded p-3 text-center hovered-button h-100'>
              <h4 className='title-h5-bold'>Annual report</h4>
              <p className='main-light-text paragraph-p2'>
                Relevant political updates, stakeholders and analytics from the last 12 months
              </p>
            </div>
          </button>

          {/* <button onClick={()=>reportDefaultFunction({type:'mentions'})} className="general-button d-block text-main-blue link-main-blue text-decoration-none">
            <div className="border rounded p-3 text-center hovered-button h-100">
              <h4 className="title-h5-bold">Mentions</h4>
              <p className="main-light-text paragraph-p2">Mentions of {teamName} in the political landscape</p>
            </div>
          </button> */}

          <button
            onClick={() => reportDefaultFunction({ type: 'commentary' })}
            className='general-button d-block text-main-blue link-main-blue text-decoration-none'
          >
            <div className='border rounded p-3 text-center hovered-button h-100'>
              <h4 className='title-h5-bold'>Commentary</h4>
              <p className='main-light-text paragraph-p2'>
                Add your own commentary
                <br /> to any political update
              </p>
            </div>
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export { ReportCreateStep };
export default ReportEmptyState;
