import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from '../../Store';
import getUrlParam from '../utilities/getUrlParam';
import normalize from '../utilities/normalizeString';
import SentimentOverTime from './ui/SentimentOverTime';
import useCallSentimentOverTime from './useCallSentimentOverTime';
import useUpdateTitleAnalytics from './useUpdateTitleAnalytics';
import { chartDataOptions } from './createAnalyticsState';
import axios from 'axios';

const SentimentOverTimeContainer = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists, analyticsState } = state;
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const topicParameter = getUrlParam('topic-id');
  const searchParameter = getUrlParam('search');
  const noTopicParameter = topicParameter === 'none';

  const { customTopic, teamId, embedWidget } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    labels: [],
    values: [],
    rawData: {},
    totalValuesPerSection: {},
    initialResults: {},
  });
  const location = useLocation();
  const isLoadedFirstTime = useRef(false);
  const initialHash = location.hash.replace('#', '').split('~');

  const { callSentimentOverTime } = useCallSentimentOverTime({ teamId });
  const { updateTitleAnalytics } = useUpdateTitleAnalytics();

  const functionToUpdateData = useRef();
  functionToUpdateData.current = async (source) => {
    try {
      if (!noTopicParameter || searchParameter) {
        setIsLoading(true);
        let chartDataOption = chartDataOptions.find((item) => normalize(item.name) === initialHash[1])?.name;
        let topic =
          customTopic ??
          (topicParameter
            ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
            : keywordsListsToUse[0]);
        let results = await callSentimentOverTime({
          topic,
          chartDataOption,
          freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
          source,
        });
        if (!!results) {
          setData(results);
          updateTitleAnalytics();
          if (isLoadedFirstTime.current) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'changeOfGraphFilters', value: true });
          }
          isLoadedFirstTime.current = true;
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (location.hash !== '') {
      functionToUpdateData.current(source);
    }
    return () => {
      source.cancel('Mentions cancelled by the user');
    };
  }, [location.hash, topicParameter, searchParameter, location.pathname, customTopic]);

  return (
    <SentimentOverTime
      isLoading={isLoading}
      data={data}
      analyticsState={analyticsState}
      isLoadedFirstTime={isLoadedFirstTime}
      embedWidget={embedWidget}
    />
  );
};

export default SentimentOverTimeContainer;
