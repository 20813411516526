import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { loadKeyWords } from '../../Dashboard/utilities/loadKeyWords';
import { useLocation } from 'react-router-dom';
import getCurrentTeam from '../Team/getCurrentTeam';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { store } from '../../Store';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import EmbedWebsiteButton from './EmbedWebsiteButton';
import EmbedAnalyticsButton from '../../EmbedAnalytics/EmbedAnalyticsButton';
import TransformTopicsList from '../../Dashboard/MainSearchBar/TransformTopicsList';
import StakeHolderSharePopUp from '../../Dashboard/StakeHolders/StakeHolderSharePopUp';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import useClientsFunctions from '../../Dashboard/hooks/useClientsFunctions';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

const WeebsiteFeedsPage = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { keywordsLists, activePlan, team } = state;

  const [isLoading, setIsLoading] = useState(true);
  const { getAccessToken } = useGetAccessToken();
  const { newAgencyPlan } = useTopicOrClientWord();
  const { getTeams } = useClientsFunctions();

  const location = useLocation();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callInitialInformation(source);
    return () => {
      source.cancel('Website feeds cancel by the user');
    };
  }, []);

  const callInitialInformation = async (source) => {
    try {
      if (newAgencyPlan) {
        const teams = await getTeams({ includeKeywordsList: true, source });
        if (!!teams) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'clients', value: teams });
        }
      }
      let keywordsListsResults = await loadKeyWords(getAccessToken, source);
      if (!!keywordsListsResults) {
        let keywordLists =
          keywordsListsResults.length > 1
            ? TransformTopicsList(keywordsListsResults, activePlan)
            : keywordsListsResults;

        if (Object.keys(team)?.length === 0) {
          let currentTeam = await getCurrentTeam(getAccessToken, source);
          if (!!currentTeam) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
          }
        }

        dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: keywordLists });
        let title = `Embeddables - Settings - PolicyMogul`;
        document.title = title;
        let locationToStorage = {
          title: title,
          storageLocation: { ...location },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const [heightContainer, containerRef] = useHeightContainer();
  const options = ['Tailored news feed', 'Live political data'];
  const [selectedOption, setSelectedOption] = useState('Tailored news feed');
  const filteredLists = keywordsLists?.filter((item) => item?.id !== null);

  return (
    <>
      {isLoading && <WebsiteFeedsSkeleton />}
      {!isLoading && (
        <>
          <div className='pt-3 pt-lg-5 settings-content-component email-alerts-page'>
            <div
              className='settings-box pb-lg-0 pt-lg-4 position-relative d-flex flex-column'
              ref={containerRef}
              style={{ height: `${heightContainer}px` }}
            >
              <div className='pt-3 px-lg-5'>
                <h3 className='main-title topics-title mb-0'>Embeddables</h3>
                <p className='main-light-text mb-4'>
                  Select an option to embed a real-time feed of political updates on{' '}
                  {newAgencyPlan ? 'any website' : 'your website'} or intranet
                </p>
                <nav className='mb-0'>
                  {options.map((item) => {
                    return (
                      <button
                        onClick={() => {
                          setSelectedOption(item);
                        }}
                        key={`option-${item}`}
                        className={` ${item === selectedOption ? 'bg-main-blue text-white' : 'bg-main-white action-state-hover'} border rounded-top px-3 py-2 general-button  mr-3 border-bottom-none`}
                      >
                        {item}
                      </button>
                    );
                  })}
                </nav>
              </div>

              <div className='h-100 row mx-0 border-top bg-light-blue-hovered '>
                <div className='h-100 col-lg-8 px-0 pt-4 p-lg-5'>
                  {selectedOption === 'Tailored news feed' && (
                    <>
                      <h3 className='title-h3-bold mb-3'>Tailored news feed</h3>
                      <p className='mb-4'>
                        Embed a feed of political developments directly on{' '}
                        {newAgencyPlan ? 'any website' : 'your website'}, featuring real-time updates related to any
                        issue
                      </p>
                      <EmbedWebsiteButton
                        settingsPage={true}
                        listToUse={filteredLists?.length > 0 ? [filteredLists?.[0]] : null}
                      />
                    </>
                  )}
                  {selectedOption === 'Live political data' && (
                    <>
                      <h3 className='title-h3-bold mb-3'>Live political data</h3>
                      <p className='mb-4'>
                        Embed specific charts, or a full analytics dashboard directly on{' '}
                        {newAgencyPlan ? 'any website' : 'your website'}, featuring real-time political data related to
                        any issue
                      </p>
                      <EmbedAnalyticsButton
                        listToUse={filteredLists?.length > 0 ? [filteredLists?.[0]] : null}
                        settingsPage={true}
                      />
                    </>
                  )}
                </div>
                <div className={`d-none d-lg-block h-100 col-lg-8 bg-main-blue text-white pt-4 px-4`}>
                  <p className='text-uppercase paragraph-p4 mb-3'>Example</p>
                  {selectedOption === 'Tailored news feed' && (
                    <>
                      <img
                        className='img-fluid w-100 image-render-optimized rounded border'
                        src={`${process.env.REACT_APP_CDNURL}/images/20240521-feed-screenshot.jpg`}
                        alt='feed screenshot'
                      />
                    </>
                  )}
                  {selectedOption === 'Live political data' && (
                    <>
                      <img
                        className='w-100 image-render-optimized rounded border'
                        src={`${process.env.REACT_APP_CDNURL}/images/20240509-analytics-screenshot.jpg`}
                        alt='feed screenshot'
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <StakeHolderSharePopUp />
        </>
      )}
    </>
  );
};

const WebsiteFeedsSkeleton = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='pt-4 pt-lg-5'>
        <div className='settings-box' ref={containerRef} style={{ height: `${heightContainer}px` }}>
          <div className='row mx-0 h-100'>
            <div className='col-lg-8 p-lg-5 p-3'>
              <div className='webfeeds-container'>
                <div className='mb-4'>
                  <Skeleton width={'90%'} height={19} />
                </div>
                <div>
                  <Skeleton width={'65%'} height={19} />
                </div>
                <div>
                  <Skeleton width={'75%'} height={19} />
                </div>
                <div>
                  <Skeleton width={'60%'} height={19} />
                </div>
                <div className='mt-5'>
                  <Skeleton width={'50%'} height={19} />
                </div>
              </div>
            </div>
            <div className='col-lg-8 p-lg-5 p-3 web-feed-images h-100'></div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default WeebsiteFeedsPage;
