import { useState } from 'react';
import Filters from '../../Filters';
import { useContext } from 'react';
import { store } from '../../../Store';
import useIsMobile from '../../utilities/useIsMobile';
import { createFiltersProperties } from '../../helpers/controlActiveFilters';
import EmailComponentNav from './components/EmailComponentNav';
import StakeholdersSubNav from './components/StakeholdersSubNav';
import CrmContactsSubNav, { CrmGroupsSelectedSubNav } from './components/CrmContactsSubNav';
import AddContactButton from '../CrmContacts/AddContactButton';
import CreateEmailLink from './components/CreateEmailLink';
import SubnavSearchBar from './components/SubnavSearchBar';
import EngagementStatisticsNav from './components/EngagementStatisticsNav';
import AddContactModal from '../CrmContacts/AddContactModal';
import EmailPerformanceNav from './components/EmailPerformanceNav';
import ClearFiltersButton from '../../SubNav/ui/ClearFiltersButton';
import useDetectActiveFilters from '../../SubNav/useDetectActiveFilters';
import InfluenceNotIncludedFeaturesPopup from '../CrmContacts/InfluenceNotIncludedFeaturesPopup';
import { Link, useLocation, useParams } from 'react-router-dom';
import { AIAssistantCreateButtons } from '../../../WritingAssistant/ui/MyContent';
import { CreateGroupButton } from '../CrmGroups/CreateGroupButton';
import GroupViewSubnav from './components/GroupViewSubnav';
import InfluenceSectionTitle from '../InfluenceSectionTitle/InfluenceSectionTitle';
import LobbyingMaterialSubnav from './components/LobbyingMaterialSubnav';
import MainSearchBar from '../../MainSearchBar';

const InfluenceCrmSubNav = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { readyKeywordLists, selectedCrmContacts, showAddContactModal, activePlan, selectedGroups } = state;

  const [isStakeholdersSelected, setIsStakeholdersSelected] = useState(false);

  const location = useLocation();
  const params = useParams();
  const { crmEmailId, groupId } = params;

  const isMobile = useIsMobile(992);
  const isSmallMobile = useIsMobile(480);

  const { enableCrm } = activePlan;
  const filtersProperties = createFiltersProperties();
  const innerPath = location.pathname.split('/')[2];

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const isContactsSelected = selectedIds.length > 0 || selectedAll || selectedAllVisible.length > 0;
  const contactsBarCondition =
    (location.pathname.includes('/directory') ||
      location.pathname.includes('/my-groups') ||
      location.pathname === '/influence/stakeholder-mapping') &&
    isContactsSelected;

  const groupsCondition = selectedGroups?.selectedAll || selectedGroups?.selectedIds?.length > 0;
  const activeSubNavCondition =
    groupsCondition || contactsBarCondition || (isStakeholdersSelected && innerPath?.includes('stakeholder'));

  const showHideAddContactModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddContactModal',
      value: !showAddContactModal,
    });
  };
  const getSubNavTitle = () => {
    if (location.pathname.includes('/policymakers')) {
      return 'Policymakers';
    } else {
      return 'Influence';
    }
  };

  const searchBarSubNavCondition =
    innerPath === 'directory' ||
    innerPath === 'emails' ||
    innerPath === 'my-lobbying-material' ||
    (innerPath === 'my-groups' && !groupId && !groupsCondition);

  return (
    <>
      {showAddContactModal && (
        <>
          {enableCrm ? (
            <AddContactModal showHideAddContactModal={showHideAddContactModal} fromContactsSelect />
          ) : (
            <InfluenceNotIncludedFeaturesPopup type={'contacts'} setShowPopup={showHideAddContactModal} />
          )}
        </>
      )}
      <div
        className={`contact-crm-subnav inbox-items-bar monitor-items-bar trends-subnav-bar crm-contacts-subnav-bar ${
          activeSubNavCondition ? 'subnav-contacts-selected' : ''
        }`}
      >
        <div className='mx-auto main-content-wrapper flex-centered justify-content-between'>
          {innerPath === 'emails' && (crmEmailId || location.pathname.split('/')[3] === 'create') ? (
            <EmailComponentNav />
          ) : (
            <>
              <div className='side-left-element pl-md-5 pl-3 d-none d-lg-block'>
                <p className='mb-0 title-h4 nowrap-item influence-subnav-tab-title'>
                  <>
                    {contactsBarCondition || groupsCondition ? (
                      ''
                    ) : (
                      <>
                        {groupId ? (
                          <Link
                            className='general-button action-button rounded-pill px-4 paragraph-p1 paragraph-p2 d-inline-block'
                            to={`/influence/my-groups`}
                          >
                            <span className='icon-long-arrow-left mr-2 paragraph-p4' /> Back to groups
                          </Link>
                        ) : (
                          <>{getSubNavTitle()}</>
                        )}
                      </>
                    )}
                  </>
                </p>
              </div>

              <div
                className={`main-content flex-centered  filters-general-container justify-content-between pr-xl-5 ${groupId && !contactsBarCondition ? '' : 'px-md-4 px-3'}`}
              >
                {searchBarSubNavCondition && (
                  <>
                    <RenderSearchNav
                      filtersProperties={filtersProperties}
                      contactsBarCondition={contactsBarCondition}
                    />
                  </>
                )}

                {(innerPath?.includes('stakeholder') || location.pathname.includes('/policymakers')) &&
                  !contactsBarCondition && (
                    <>
                      <StakeholdersSubNav
                        isMobile={isMobile}
                        isSmallMobile={isSmallMobile}
                        readyKeywordLists={readyKeywordLists}
                        filtersProperties={filtersProperties}
                        setIsStakeholdersSelected={setIsStakeholdersSelected}
                      />
                    </>
                  )}

                <div className={`crm-contacts-select-subnav ${!contactsBarCondition ? 'd-none' : ''}`}>
                  <CrmContactsSubNav isSmallMobile={isSmallMobile} />
                </div>

                <div className={`flex-centered justify-content-lg-between w-100 ${!groupsCondition ? 'd-none' : ''}`}>
                  <CrmGroupsSelectedSubNav />
                </div>

                {innerPath?.includes('engagement-statistics') && <EngagementStatisticsNav />}
                {innerPath?.includes('email-performance') && <EmailPerformanceNav />}
                {innerPath?.includes('lobbying-material') && <LobbyingMaterialSubnav />}
                {innerPath?.includes('ai-assistant') && (
                  <div className='influence-ai-assistant-container'>
                    <AIAssistantCreateButtons influencePage={true} />
                  </div>
                )}
                {groupId && !contactsBarCondition && <GroupViewSubnav />}
              </div>
            </>
          )}
        </div>
      </div>
      {isMobile && (
        <>
          {(location.pathname.includes('/stakeholder-mapping') ||
            location.pathname.includes('/policymakers') ||
            searchBarSubNavCondition) && (
            <div className='w-100 px-3 bg-main-white border-bottom pb-3'>
              <div className={`w-100 pt-3 d-block`}>
                {(location.pathname.includes('/stakeholder-mapping') ||
                  location.pathname.includes('/policymakers')) && <MainSearchBar />}
                {searchBarSubNavCondition && <SubnavSearchBar />}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

//SUBNAV FOR CONTACTS AND EMAILS
const RenderSearchNav = (props) => {
  const { contactsBarCondition, filtersProperties } = props;
  const location = useLocation();

  const directoryPath = location.pathname.includes('/directory');
  const groupsPath = location.pathname.includes('/my-groups');
  const isMobile = useIsMobile();

  return (
    <>
      {!contactsBarCondition && (
        <>
          <div className={`w-100 flex-centered`}>
            <div className='flex-grow-1 flex-centered'>
              <InfluenceSectionTitle />
            </div>
            <div className={`flex-centered `}>
              {!isMobile && <SubnavSearchBar />}
              {(directoryPath || groupsPath) && (
                <>
                  <Filters
                    section={directoryPath ? 'InfluenceContacts' : 'Groups'}
                    filtersProperties={filtersProperties}
                  />
                </>
              )}
            </div>
            <RenderActionButton />
          </div>
        </>
      )}
    </>
  );
};

const RenderActionButton = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { listDomainsVerified } = state;

  const location = useLocation();
  const params = useParams();
  const { groupId } = params;

  const { detectActiveFilters } = useDetectActiveFilters();
  const activeFilters = detectActiveFilters();

  if (location.pathname.includes('/directory')) {
    return <>{activeFilters ? <ClearFiltersButton contactsSubNav /> : <AddContactButton />}</>;
  } else if (location.pathname.includes('/emails')) {
    if (!!listDomainsVerified.verifiedDomains.length || !!listDomainsVerified.verifiedEmailAddresses.length) {
      return (
        <CreateEmailLink
          showToolTip={
            !listDomainsVerified.verifiedDomains.length && !listDomainsVerified.verifiedEmailAddresses.length
          }
        />
      );
    } else {
      return (
        <a
          href='http://support.policymogul.com/en/articles/7936585-sending-emails-from-policymogul'
          target='_blank'
          rel='noopener noreferrer'
        >
          <button className='setup-guide-emails-btn'>Setup guide</button>
        </a>
      );
    }
  } else if (location.pathname.includes('/my-lobbying-material')) {
    return <button className='influence-team-content-fake-btn' />;
  } else if (location.pathname.includes('/my-groups') && !groupId) {
    return (
      <>
        {activeFilters && <ClearFiltersButton contactsSubNav />}
        <CreateGroupButton />
      </>
    );
  }
};

export default InfluenceCrmSubNav;
