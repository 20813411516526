import { useContext, useEffect, useState } from 'react';
import handleChangeKeywords from '../../Settings/Topics/Helpers/handeChangeKeywords';
import createNotification from '../../Settings/Utilities/CreateNotification';
import { store } from '../../Store';
import CreateATopicPage from './CreateATopicPage';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

const SignUpCreateATopic = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { parliamentarianOnboarding, activePlan, net_api_url, team } = state;
  const { maxNumKeywordsPerTeam } = activePlan;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const { generalApiCall } = useGeneralApiCall();

  const [keywords, setKeywords] = useState([]);
  const [topicName, setTopicName] = useState('');
  const [clientName, setClientName] = useState(team?.teamName ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ type: '', message: '' });
  const [remainingKeywords, setRemainingKeywords] = useState(
    maxNumKeywordsPerTeam === null ? 'unlimited' : maxNumKeywordsPerTeam
  );

  const navigate = useNavigate();

  const { getAccessToken } = useGetAccessToken();

  useEffect(() => {
    return () => {
      source.cancel('Create topic cancelled by the user.');
    };
  }, []);

  const createFreeTrialPolicyInfluence = async () => {
    let pathname = `/api/subscription/start-trial`;
    let method = 'post';
    let requestProperties = {
      website: 'PolicyMogul',
    };
    if (activePlan?.name === 'Policy Influence') {
      let results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        returnCompleteRequest: true,
      });
      if (results?.status !== 200) {
        return false;
      }
    }
    return true;
  };

  const createTeam = async () => {
    setIsLoading(true);
    try {
      const pathname = '/api/team';
      const method = 'post';
      const requestProperties = {
        teamName: clientName,
      };
      let results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        returnCompleteRequest: true,
      });
      if (results?.status !== 200) {
        return false;
      }
      return true;
    } catch (error) {}
  };

  const createFirstList = async () => {
    setIsLoading(true);
    try {
      let token = await getAccessToken();
      let verifyTrialPolicyInfluence = await createFreeTrialPolicyInfluence();
      if (verifyTrialPolicyInfluence) {
        let createList = await axios.post(
          `${net_api_url}/api/keyword-list/create`,
          {
            name: topicName,
            keywords: keywords,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );

        if (createList.status === 200) {
          navigate('/create-your-profile/3');
        } else {
          createNotification('danger', 'There was a technical problem, please try again');
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      createNotification('danger', 'There was a technical problem, please try again');
    }
  };

  const handleChange = (newKeywords, changed, changedIndexes) => {
    setError({ type: '', message: '' });
    //SEPARATED BY COMMAS
    const trimmedKeywords = handleChangeKeywords({ newKeywords, changed, changedIndexes });

    setKeywords(trimmedKeywords);
    //CALCULATE REMAINING KEYWORDS
    if (remainingKeywords !== 'unlimited') {
      let totalKeywords = trimmedKeywords.length;
      setRemainingKeywords(maxNumKeywordsPerTeam - totalKeywords);
    }
  };

  return (
    <CreateATopicPage
      stateProperties={{ parliamentarianOnboarding, keywords, activePlan }}
      errorProperties={{ error, setError }}
      topicProperties={{ topicName, setTopicName }}
      clientProperties={{ clientName, setClientName }}
      remainingKeywords={remainingKeywords}
      handleChange={handleChange}
      createFirstList={createFirstList}
      createTeam={createTeam}
      disabledClientName={team?.teamName}
      isLoading={isLoading}
    />
  );
};

export default SignUpCreateATopic;
