import ReportComponent from './ReportComponent';
import ReportList from './ReportsList';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Login/Loading';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { store } from '../Store';

const Reports = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableReports } = activePlan;
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(activePlan).length > 0) {
      if (!enableReports) {
        navigate({ pathname: '/' });
      }
      setIsLoading(false);
    }
  }, [activePlan]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          <Route index element={<ReportList />} />
          <Route path={`create`} element={<ReportComponent />} />
          <Route path={`create/:action`} element={<ReportComponent />} />
          <Route path={`:id`} element={<ReportComponent />} />
          <Route path={`:id/:action`} element={<ReportComponent />} />
        </Routes>
      )}
    </>
  );
};

export default withAuthenticationRequired(Reports, {
  onRedirecting: () => <Loading />,
});
