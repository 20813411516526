import { Portal } from 'react-portal';
import Popup from '../../../../Common/PopUp';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const ContentPopup = (props) => {
  const location = useLocation();
  const { showContactsPopup } = props ?? {};
  return (
    <div className='bg-white rounded-lg text-center relative'>
      <div className='d-lg-flex'>
        <div className='bg-main-blue pl-lg-2 py-5 text-left d-none d-lg-block'>
          <img
            className='statistics-popup-logo'
            alt='logo'
            src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
          />
          <ul className='policy-list text-white text-left title-h5-m pt-5 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
            <li className='pl-4 pr-20 mb-4 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
              <span className='d-inline-block title-h4 mr-3 icon-small-check' />
              Political stakeholder CRM
            </li>
            <li className='pl-4 pr-20 mb-4 py-2 nowrap-item '>
              <span className='d-inline-block title-h4 mr-3 icon-small-check' />
              Tailored political monitoring
            </li>
            <li className='pl-4 pr-20 mb-4 py-2 nowrap-item '>
              <span className='d-inline-block title-h4 mr-3 icon-small-check' />
              Analytics on any policy area
            </li>
          </ul>
        </div>
        <div className='grow px-4 px-lg-5 pb-5 mb-3'>
          <p className='title-h3 text-center mt-3 pt-3 pt-lg-5 px-lg-5 mb-4'>
            Try PolicyMogul’s political stakeholder CRM for free to create notes, send emails and more
          </p>
          <img
            className='w-100'
            alt='stakeholder'
            src={`${process.env.REACT_APP_CDNURL}/images/influence/20230129-stakeholder-image.png`}
          />
          <div className='text-center text-lg-right mt-4 mt-lg-0'>
            <Link
              className='action-button rounded-button py-2 px-3 px-lg-5 title-h5 nowrap-item'
              onClick={(e) => {
                if (showContactsPopup?.url) {
                  e.preventDefault();
                  e.stopPropagation();
                  window.location.assign(showContactsPopup?.url);
                }
              }}
              to={`/register?utm_source=stakeholder-page-crm-action&returnUrl=${location.pathname}`}
            >
              <span className='px-4'>
                Try PolicyMogul for free
                <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2' />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const StakeholderCrmPopup = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <ContentPopup {...props} />
      </Popup>
    </Portal>
  );
};

export default StakeholderCrmPopup;
