import { useContext, useRef, useState } from 'react';
import ModalComponent from '../../Common/Modal';
import useClientsFunctions from '../../Dashboard/hooks/useClientsFunctions';
import CreateClient from './CreateClient';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';
import preventLink from '../../Dashboard/TagsList/prevenLink';
import { store } from '../../Store';
import createNotification from '../Utilities/CreateNotification';
import getActivePlan from '../Utilities/getActivePlan';
import getCurrentTeam from '../Team/getCurrentTeam';
import { useLocation } from 'react-router-dom';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

const ClientActions = (props) => {
  const { activeClientState, clientsListState, setIsOpenPopup, adminPage } = props;
  const { clientsList } = clientsListState ?? {};

  const [isOpenActions, setIsOpenActions] = useState(false);
  const [isOpenEditClient, setIsOpenEditClient] = useState(false);
  const [isOpenDeleteClient, setIsOpenDeleteClient] = useState(false);

  const { ownerAdminRole } = useClientsFunctions();

  const actionsElement = useRef(null);
  const hideActions = () => {
    setIsOpenActions(false);
  };

  useOutsideElement(actionsElement, hideActions);

  const { top, height, left, width } = actionsElement?.current?.getBoundingClientRect() ?? {};
  const needsOpenToTop = () => {
    const heightOfBox = 100;
    return top + height + heightOfBox > window.innerHeight;
  };

  const allowUserCondition = adminPage || ownerAdminRole;
  return (
    <>
      <div className={`position-relative`} ref={actionsElement}>
        <button
          className={`border-general border-main-blue-50 action-state-hover reset-link px-4 py-1 rounded-button general-button ml-3 bg-main-white paragraph-p2`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsOpenActions(!isOpenActions);
          }}
        >
          <span className={`dropdown-item-element dropdown-item-element-9`}>More</span>
        </button>
        {isOpenActions && (
          <>
            <div
              className={`position-${needsOpenToTop() ? 'fixed' : 'absolute'} box-shadow rounded py-2 z-max client-actions`}
              style={needsOpenToTop() ? { top: `${top - 100}px`, left: `${left + width - 200}px` } : null}
            >
              <button
                data-tooltip-content={!allowUserCondition ? 'Please contact your team admin' : ''}
                data-tooltip-id={'settings-tooltip'}
                className={`w-100 d-block text-left general-button list-action-button px-3 py-2 ${allowUserCondition ? '' : 'main-light-text'}`}
                onClick={(e) => {
                  preventLink(e);
                  if (allowUserCondition) {
                    setIsOpenEditClient(true);
                    setIsOpenActions(false);
                    setIsOpenPopup(true);
                  }
                }}
              >
                <span className='icon-edit-pencil-thick d-inline-block-centered' />
                Edit client details
                {!allowUserCondition && <span className='icon-padlock ml-2' />}
              </button>
              {clientsList?.length > 1 && (
                <button
                  data-tooltip-content={!allowUserCondition ? 'Please contact your team admin' : ''}
                  data-tooltip-id={'settings-tooltip'}
                  onClick={(e) => {
                    preventLink(e);
                    if (allowUserCondition) {
                      setIsOpenDeleteClient(true);
                      setIsOpenPopup(true);
                      setIsOpenActions(false);
                    }
                  }}
                  className='w-100 d-block text-left general-button list-action-button px-3 py-2 text-main-danger'
                  style={!allowUserCondition ? { opacity: 0.6 } : null}
                >
                  <span className='icon-delete d-inline-block-centered mr-2' /> Delete this client
                  {!allowUserCondition && <span className='icon-padlock ml-2' />}
                </button>
              )}
            </div>
          </>
        )}
      </div>
      <CreateClient
        activeClientState={activeClientState}
        isOpen={isOpenEditClient}
        setIsOpen={setIsOpenEditClient}
        clientsListState={clientsListState}
        edit={true}
        setIsOpenPopup={setIsOpenPopup}
      />
      <ConfirmDeleteClient
        activeClientState={activeClientState}
        clientsListState={clientsListState}
        isOpen={isOpenDeleteClient}
        setIsOpen={setIsOpenDeleteClient}
        setIsOpenPopup={setIsOpenPopup}
      />
    </>
  );
};

const ConfirmDeleteClient = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const { isOpen, setIsOpen, activeClientState, clientsListState, setIsOpenPopup } = props;
  const { clientsList, setClientList } = clientsListState ?? {};
  const { activeClient } = activeClientState ?? {};
  const [isLoading, setIsLoading] = useState(false);

  const { getAccessToken } = useGetAccessToken();
  const { deleteTeam, getTeams } = useClientsFunctions();
  const location = useLocation();

  const adminPage = location?.pathname?.startsWith('/admin');

  const closeModal = () => {
    setIsOpen(false);
    setIsOpenPopup(false);
    setIsLoading(false);
  };

  return (
    <ModalComponent
      maxWidth={500}
      isOpen={isOpen}
      overlayClassName='react-modal-overlay report-extra-index-modal'
      setIsOpen={closeModal}
    >
      <div>
        <h3 className='topic-title topic-title-email-alerts mb-0 section-title py-4 px-lg-5 pl-3 pr-5'>
          Are you sure you want to delete this client?
        </h3>
        <div className='px-lg-5 px-3'>
          <div className='remove-contacts-wrapper pt-4'>
            <p className='remove-contacts-title main-subtle-text'>
              By deleting this client, you will lose any information associated with them, including topics, alerts,
              contacts and reports.
            </p>
          </div>
        </div>
        <p className='remove-contacts-helper-text px-lg-5 px-3 mt-2'>
          <span className='remove-contacts-helper-text-icon mr-2' />
          These changes impact all members of your team
        </p>
        <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white pt-4'>
          <button onClick={closeModal} className='danger-button-outlined px-3 py-2 mr-3' disabled={isLoading}>
            Cancel
          </button>
          <button
            onClick={async () => {
              try {
                setIsLoading(true);
                let deleteTeamRes = await deleteTeam({ teamId: activeClient?.teamId });
                if (deleteTeamRes?.status === 200) {
                  if (!adminPage) {
                    const teams = await getTeams({ includeKeywordsList: true });
                    if (!!teams) {
                      dispatch({ type: 'MODIFY_SECTION', parameter: 'clients', value: teams });
                    }
                    //AE:Necessary to update master topic
                    const activePlan = await getActivePlan(getAccessToken);
                    if (!!activePlan) {
                      dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
                    }
                    const currentTeam = await getCurrentTeam(getAccessToken);
                    if (!!currentTeam) {
                      dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
                    }
                  }
                  let newClientList = [...clientsList];
                  let position = newClientList.findIndex((item) => item.teamId === activeClient?.teamId);
                  newClientList.splice(position, 1);
                  setClientList(newClientList);
                  createNotification('success', 'Client deleted successfully');
                  dispatch({ type: 'MODIFY_SECTION', parameter: 'readyKeywordLists', value: false });
                  closeModal();
                }
              } catch (error) {
                createNotification('danger', `An error ocurred.`);
              } finally {
                setIsLoading(false);
              }
            }}
            className='danger-button px-3 py-2'
            disabled={isLoading}
          >
            {isLoading ? (
              <i
                className='fas fa-spinner fa-spin'
                style={{
                  margin: '0 41.2px',
                  color: '#fcfcfc',
                  marginTop: '2px',
                  width: '33.7px',
                }}
              ></i>
            ) : (
              'Delete client'
            )}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ClientActions;
