import React, { useContext, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Share from '../../components/Share';
import TagsLists from '../../TagsList';
import useLoadCompanyImage from '../../UserContent/useLoadCompanyImage';
import useIsMobile from '../../utilities/useIsMobile';
import TransformFromPascalCase from '../../../Settings/Utilities/TransformFromPascalCase';
import useRememberPosition from '../../hooks/useRememberPosition';
import { contentItemSearchQuery } from '../../helpers/locationSearchDescriptor';
import { store } from '../../../Store';
import ThumbsDown from '../../../Common/ThumbsDown';
import FeedbackOnItem from '../../FeedbackItem/FeedbackOnItem';
import getUrlParam from '../../utilities/getUrlParam';
import getSearchWithinHash from '../../utilities/getSearchWithinHash';
import { useLocation } from 'react-router-dom';
import ColorThief from 'colorthief/dist/color-thief.umd.js';

const InfluenceItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, lastSeenMarkers, itemsViewed } = state;
  const { list } = search;

  const location = useLocation();
  const locationSearch = contentItemSearchQuery(location, list);

  const { item, array, index, feedItemFlags, scrollBarRef } = props;
  const {
    title,
    organisation = item?.creatorOrganisation,
    link = item?.contentUrl,
    draft,
    contentType,
    type,
    clientKeywordListName,
  } = item;
  const imageUrl = item?.creatorOrganisationLogo ?? item?.clientLogo ?? item?.ownerOrganisationLogo;
  const { rememberPosition } = useRememberPosition();

  const feedItem = feedItemFlags?.[index];
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  //type: item.contentType
  const unseen =
    feedItem &&
    !!lastSeenMarkers['userContentFeedItemId'] &&
    lastSeenMarkers['userContentFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition;
  const matchingKeywordLists = feedItem?.matchingKeywordLists ?? null;

  const isMobile = useIsMobile();
  const alreadyMarkedFeedback = feedItem?.ratingScored === 'Negative';

  const locationDescriptor = (path, locationSearch) => {
    let locationSearchToReturn = locationSearch;
    if (getUrlParam('topic-id') === 'all') {
      locationSearchToReturn = `?topic-id=${feedItemFlags?.[index]?.matchingKeywordLists[0]?.id}`;
    }
    let url = {
      pathname: path,
      search: `${locationSearchToReturn}`,
      hash: getSearchWithinHash() ? getSearchWithinHash(true) : '',
    };
    return `${url.pathname}${url.search}${url.hash}`;
  };

  return (
    <Link
      className={`box-rounded main-link-item influence-list-item box-list-item content-item-link`}
      to={locationDescriptor(link ?? item.contentUrl, locationSearch)}
      style={{ zIndex: array ? array.length - index : 1 }}
      onClick={(e) => {
        if (scrollBarRef) {
          rememberPosition('Influence', scrollBarRef, index);
        }
      }}
    >
      <div className='main-link-item-padding'>
        {unseen && (
          <>
            <span className='unseen-dot' />
          </>
        )}

        <div className='d-flex align-items-start'>
          {!isMobile && <OrganisationLogo imageUrl={imageUrl} index={index} />}
          <div className={`influence-item_title ${isMobile ? 'w-100' : ''}`}>
            <div className='flex-centered justify-content-between influence-item_title_container'>
              <h4 className='content-item-title mb-0 pr-lg-4'>
                {draft && <span className='text-red font-weight-bold'>Draft: </span>}
                {title}
              </h4>
              <div className='flex-centered'>
                {!isMobile && (
                  <p className='paragraph-p4-bold text-light-blue mb-0 mr-2 text-right influence-type-text'>
                    {TransformFromPascalCase(contentType ?? type)}
                  </p>
                )}
                <ThumbsDown item={item} index={index} />
                <Share item={item} listItem influenceItem={true} />
              </div>
            </div>

            <div className='d-flex d-lg-block align-items-start'>
              {isMobile && <OrganisationLogo imageUrl={imageUrl} index={index} />}
              <div className='pl-3 pl-lg-0 flex-grow-1'>
                <p className='paragraph-p2 mb-0'>
                  <strong>Lead organisation: </strong>
                  <span>{clientKeywordListName ?? organisation}</span>
                </p>
                {!!matchingKeywordLists && matchingKeywordLists.length > 0 && (
                  <div className='pt-2'>
                    <TagsLists {...props} feedItemFlags={feedItemFlags} index={index} section={'Influence'} />
                  </div>
                )}
                {isMobile && (
                  <p className='paragraph-p4-bold text-light-blue mt-1'>
                    {TransformFromPascalCase(contentType ?? type)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {alreadyMarkedFeedback && <FeedbackOnItem item={item} index={index} />}
    </Link>
  );
};

const OrganisationLogo = (props) => {
  const { imageUrl, size, className = '', style = null } = props;
  const imageState = useLoadCompanyImage(imageUrl, size);
  const [whiteImage, setWhiteImage] = useState(false);

  const imageElement = useRef();
  const getLogoData = useRef();

  getLogoData.current = () => {
    if (!!imageUrl && imageElement?.current) {
      setTimeout(() => {
        const ct = new ColorThief();
        const img = imageElement.current;
        if (img.complete) {
          try {
            ct.getColor(img);
          } catch (error) {
            setWhiteImage(true);
          }
        } else {
          img.addEventListener('load', () => {
            try {
              ct.getColor(img);
            } catch (error) {
              setWhiteImage(true);
            }
          });
        }
      }, 500);
    }
  };

  useEffect(() => {
    if (!imageState.loading) {
      getLogoData.current();
    }
  }, [imageState.loading]);

  return (
    <>
      {!imageState.loading && (
        <div className={`${whiteImage ? 'influence-white-image-container' : ''} ${className}`}>
          <img
            style={style}
            className='influence-list-image'
            ref={imageElement}
            crossOrigin='anonymous'
            src={`${imageState.src}${imageState.src?.toLowerCase().startsWith('https://logo.clearbit.com') ? '' : '?v=1'}`}
            alt='logotype'
          />
        </div>
      )}
    </>
  );
};

export { OrganisationLogo };
export default InfluenceItem;
