import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const CrmContactsTableSkeleton = (props) => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <table
        style={{
          width: 'calc(100% + 2.5rem)',
          border: '1px solid rgba(212, 212, 212, 0.6)',
          borderRight: 'none',
          borderRadius: '5px 0 0 0',
        }}
        className='my-lg-2'
      >
        <tbody>
          {Array(12)
            .fill()
            .map((item, index) => (
              <CrmContactItemSkeleton key={index} header={index === 0} />
            ))}
        </tbody>
      </table>
    </SkeletonTheme>
  );
};

const CrmContactItemSkeleton = ({ header }) => {
  const values = ['100', ' 75', '55', '50', '90', '10', '10'];
  return (
    <tr className='crm-table-item' style={{ width: '100%' }}>
      <td
        style={{
          paddingLeft: '14px',
          paddingRight: '10px',
          width: '48px',
          backgroundColor: '#f9f9f9',
          position: 'relative',
        }}
      >
        <Skeleton height={18} width={18} />
      </td>
      <td className='crm-table-item-name' style={{ padding: '14px 23px 11px 20px', position: 'relative' }}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Skeleton circle={!header} height={!header ? 30 : 18} width={!header ? 30 : 50} />
          {!header && <Skeleton height={18} width={'100px'} />}
        </div>
      </td>
      {values.map((item, index) => {
        return (
          <td
            key={`${item}-${index}`}
            style={{
              padding: '14px 23px 11px 20px',
              position: 'relative',
              backgroundColor: '#f9f9f9',
            }}
          >
            <Skeleton height={18} width={`${item}%`} />
          </td>
        );
      })}
    </tr>
  );
};

export { CrmContactItemSkeleton };
export default CrmContactsTableSkeleton;
