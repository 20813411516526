import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const WebsiteFeaturePopupContent = (props) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className='bg-white rounded-lg relative'>
      <div className='d-lg-flex'>
        <div className='bg-main-blue py-5 text-left'>
          <h3 className='title-h3 pl-lg-5 report-prospecting-title'>
            <img
              src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
              alt='Logo'
              className='policy-logo mr-2'
            />
            Pro plan
          </h3>

          <ul className='policy-list text-white text-left title-h5-m py-4 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
            <li className='pl-4 mb-3 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
              <span className='inline-block title-h3 mr-3 icon-small-check' />
              Website feeds
            </li>
            <li className='pl-4 mb-3 py-2 nowrap-item '>
              <span className='inline-block title-h3 mr-3 icon-small-check' />
              Unlimited keywords
            </li>
            <li className='pl-4 mb-3 py-2 nowrap-item '>
              <span className='inline-block title-h3 mr-3 icon-small-check' />
              AI summaries
            </li>
            <li className='pl-4 mb-3 py-2 nowrap-item '>
              <span className='inline-block title-h3 mr-3 icon-small-check' />
              650 CRM contacts
            </li>
          </ul>
        </div>
        <div className='grow px-5 pb-5 mb-3'>
          <p className='title-h2-bold mt-3 pt-3 pt-lg-5 mb-4'>Feed your website</p>

          <div className='title-h4 main-subtle-text'>
            <p>
              Website feeds show real-time political updates in relation to any (or all) of your topics, directly on
              your website.
            </p>

            <p>
              Drive online traffic, and show visitors to any webpage that you’re an authority when it comes to political
              information.
            </p>
          </div>

          <div className='nowrap-item text-lg-right pt-5 mt-5'>
            <a
              href='https://meet.intercom.com/elliot1/30min'
              target='_blank'
              rel='noreferrer'
              className='downgrade-pricing-button btn btn-general outline-active-button rounded-button px-4 px-xxl-5 py-2 mr-0 mr-lg-3 title-h5-m'
            >
              Arrange demo
            </a>

            <Link
              className='action-button rounded-button py-2 px-5 d-inline-block title-h5-m'
              to={!isAuthenticated ? '/register?utm_source=content-summary-button' : '/pricing'}
            >
              <span className='px-4'>
                {isAuthenticated ? 'Compare plans' : 'Try PolicyMogul for free'}
                <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2' />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteFeaturePopupContent;
