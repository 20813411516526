import { useRef, useState, useContext, useEffect } from 'react';
import ModalComponent from '../../../Common/Modal';
import DropdownComponent from '../../../Settings/WebsiteFeeds/DropdownComponent';
import normalize from '../../utilities/normalizeString';
import GroupFilters from './GroupFilters';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../Store';
import { useGroupFunctions } from './GroupFunctions';
import thousandCommas from '../../helpers/thousandCommas';
import useIsMobile from '../../utilities/useIsMobile';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';

const CreateGroupButton = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, contentResults, activeResults } = state ?? {};
  const { Groups } = contentResults[activeResults];
  const { maxNumberOfGroups } = activePlan;
  const allowCreateGroups = maxNumberOfGroups === null || Groups?.length < maxNumberOfGroups;
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile(768);
  return (
    <>
      {!!Groups && (
        <button
          data-tooltip-id={'general-tooltip'}
          data-tooltip-content={!allowCreateGroups ? 'Upgrade your subscription to create more groups' : ''}
          data-tooltip-place={'bottom'}
          className={`${isMobile ? 'add-to-contact-mobile-button' : `general-button sort-button d-lg-block top-nav-create-contact-button px-2`} ${!allowCreateGroups ? 'button-blocked' : ''}`}
          onClick={() => {
            if (allowCreateGroups) {
              setIsOpen(true);
            }
          }}
        >
          Create new group {!allowCreateGroups && <span className='icon-padlock ml-2 d-inline-block-centered' />}
        </button>
      )}
      <ModalComponent
        overlayClassName={'react-modal-overlay modal-close-position-adjustment'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        maxWidth={680}
      >
        <CreateGroupModal openState={{ setIsOpen, isOpen }} />
      </ModalComponent>
    </>
  );
};

const CreateGroupModal = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, contentResults, activeResults, team } = state;
  const { enableDynamicGroups } = activePlan;

  const { Groups, CrmContacts } = contentResults[activeResults];

  const { setModalContent, addContactsToGroup, group, defaultView, openState, setIsOpenDelete } = props;
  const { setIsOpen } = openState ?? {};
  const [groupInformation, setGroupInformation] = useState(
    group ?? {
      name: '',
      description: '',
      type: setModalContent || !enableDynamicGroups ? 'Static' : 'Dynamic',
      filters: [{ groupFilters: [] }],
    }
  );

  const [scrollBarPosition, setScrollbarPosition] = useState(0);
  const { name, description, type, filters } = groupInformation ?? {};
  const navigate = useNavigate();

  const handleChange = ({ property, newValue }) => {
    let newGroupInfo = { ...groupInformation };
    newGroupInfo = {
      ...newGroupInfo,
      [property]: newValue,
    };
    setGroupInformation(newGroupInfo);
  };

  const [isLoading, setIsLoading] = useState(false);
  const typeOptions = ['Dynamic', 'Static'];
  const [currentStep, setCurrentStep] = useState('create');
  const [filtersNumber, setFiltersNumber] = useState(CrmContacts && group ? CrmContacts?.totalHits : 0);
  const scrollbarRef = useRef();
  const { saveGroup, createDynamicQueries, getGroupInformation } = useGroupFunctions();
  const isMobile = useIsMobile();
  const { newAgencyPlan } = useTopicOrClientWord();

  useEffect(() => {
    if (defaultView) {
      setCurrentStep(defaultView);
    }
  }, [defaultView]);

  const createDisabledCondition = () => {
    if (group) {
      if (currentStep === 'filters') {
        return JSON.stringify(filters) === JSON.stringify(group?.filters);
      } else {
        return name === group?.name && description === group?.description;
      }
    } else {
      return (
        (type === 'Dynamic' && createDynamicQueries(filters)?.flatMap((item) => item.filters)?.length === 0) ||
        (type === 'Static' && name === '')
      );
    }
  };

  return (
    <>
      <div className='border-bottom pt-4  pb-3 px-lg-5 px-3'>
        <h3 className='mb-0 pr-4 w-100 hidden-lines hidden-one-line title-h4-bold'>
          <span className='font-weight-bold'>
            {currentStep === 'create'
              ? `${group ? name : `Create group ${newAgencyPlan ? `for ${team?.teamName}` : ''}`}`
              : `${group ? 'Edit' : 'Set'} your filters`}
          </span>
        </h3>
      </div>
      {currentStep === 'create' && (
        <div className='px-lg-5 px-3 pt-4 pb-0 position-relative'>
          <div className='form-group mb-4'>
            <label className='font-weight-bold' htmlFor='groupName'>
              Group name
            </label>
            <input
              className='main-input w-100 form-control'
              value={name}
              onChange={(e) => {
                handleChange({ property: 'name', newValue: e.target?.value });
              }}
              autoFocus={true}
              type={'text'}
              id='groupName'
              autoComplete='off'
            />
          </div>

          <div className='form-group mb-4'>
            <label className='font-weight-bold' htmlFor='groupTags'>
              <span className='font-weight-bold'>Group type</span>
              <span
                data-tooltip-class-name='general-tooltip pricing-feature-tooltip report-popup-tooltip'
                data-tooltip-id='general-tooltip'
                data-tooltip-content={
                  'Dynamic groups update automatically over time when contacts match the filter conditions you create. Static groups do not update automatically, and instead represent a single moment in time'
                }
                className='ml-1 icon-info-circle-filled title-h3 d-inline-block-centered light-50-text '
              />
            </label>
            <DropdownComponent
              mainItem={() => (
                <>
                  <span className={`group-type group-type-${normalize(type)}`} />
                  {type}
                </>
              )}
            >
              <ul className='policy-list px-0 pt-1 m-0 pb-2'>
                {typeOptions?.map((item) => {
                  const notDynamicEnabledCondition = item === 'Dynamic' && !enableDynamicGroups;
                  return (
                    <li
                      className={`list-action-button px-3 py-2 flex-centered justify-content-between pointer ${notDynamicEnabledCondition ? 'main-light-text' : ''}`}
                      key={`item-${item}`}
                      onClick={() => {
                        if (item === 'Static' || (item === 'Dynamic' && enableDynamicGroups)) {
                          handleChange({ property: 'type', newValue: item });
                        }
                      }}
                      data-tooltip-id={'modal-tooltip'}
                      data-tooltip-content={
                        notDynamicEnabledCondition ? 'Upgrade to Mogul or Enterprise to create dynamic groups' : ''
                      }
                      data-tooltip-float={true}
                    >
                      <span>
                        <span className={`group-type group-type-${normalize(item)}`} />
                        {item}
                        {notDynamicEnabledCondition && (
                          <span className='icon-padlock ml-2 d-inline-block-centered main-light-text' />
                        )}
                      </span>
                      {type === item && <span className='icon-tick paragraph-p5' />}
                    </li>
                  );
                })}
              </ul>
            </DropdownComponent>
          </div>

          <div className='form-group mb-0'>
            <label htmlFor='groupTags'>
              <span className='font-weight-bold'>Description</span> (optional){' '}
            </label>
            <textarea
              autoComplete='off'
              className='main-input writing-textarea '
              value={description}
              onChange={(e) => {
                handleChange({ property: 'description', newValue: e.target?.value });
              }}
            />
          </div>
        </div>
      )}
      {currentStep === 'filters' && (
        <>
          <CustomScrollbar
            autoHeight
            autoHeightMin='100%'
            autoHeightMax='55vh'
            className='simple-scrollbar'
            maximalThumbYSize={100}
            ref={scrollbarRef}
            onScroll={(scrollValues) => {
              setScrollbarPosition(scrollValues?.scrollTop);
            }}
          >
            <div className='px-lg-5 px-3 pt-4 pb-0 position-relative'>
              <GroupFilters
                scrollbarRef={scrollbarRef}
                scrollBarPosition={scrollBarPosition}
                filtersState={{
                  filters,
                  setFilters: (value) => {
                    handleChange({ property: 'filters', newValue: value });
                  },
                  setFiltersNumber,
                }}
              />
            </div>
          </CustomScrollbar>
        </>
      )}

      <div
        className={`bg-main-white ${currentStep === 'create' ? 'pt-4 pb-5' : 'create-group-actions-bar'} px-3 px-lg-5 flex-centered justify-content-${(setModalContent || group) && currentStep === 'create' ? 'between' : 'end'} rounded-bottom`}
      >
        {group && currentStep === 'create' && (
          <button
            className='nowrap-item general-button text-red'
            onClick={() => {
              if (setIsOpenDelete) {
                setIsOpenDelete(true);
              }
            }}
          >
            Delete group
          </button>
        )}
        {!!setModalContent && currentStep === 'create' && (
          <button
            className='general-button d-inline-block-centered text-light-blue '
            onClick={() => {
              setModalContent('addToGroup');
            }}
          >
            <span className='icon-long-arrow-left paragraph-p6 mr-2' /> Back
          </button>
        )}
        <div className='flex-centered'>
          {currentStep === 'filters' && !group && (
            <button
              className='general-button outline-active-button px-4 mr-3 py-2'
              onClick={() => {
                setCurrentStep('create');
              }}
            >
              <span className='icon-long-arrow-left text-light-blue paragraph-p6 mr-2 d-inline-block-centered' />
              Back
            </button>
          )}
          {(currentStep === 'filters' || group || type === 'Static') && (
            <button
              className='general-button action-button px-4 py-2'
              disabled={createDisabledCondition()}
              onClick={async () => {
                setIsLoading(true);
                if (group) {
                  const result = await saveGroup({ groupInformation, update: true });
                  if (!!result) {
                    dispatch({
                      type: 'MODIFY_SECTION',
                      parameter: 'group',
                      value: getGroupInformation({ group: result }),
                    });
                    setIsOpen(false);
                  }
                } else {
                  const result = await saveGroup({ groupInformation });
                  if (!!result) {
                    if (addContactsToGroup) {
                      await addContactsToGroup({ selectedGroup: result });
                    } else {
                      const newGroups = [...Groups, result];
                      const GroupsResults = {
                        ...contentResults,
                        [activeResults]: {
                          Groups: newGroups,
                        },
                      };

                      dispatch({
                        type: 'MODIFY_SECTION',
                        parameter: 'contentResults',
                        value: GroupsResults,
                      });
                    }
                    navigate({ pathname: `/influence/my-groups/${result?.id}` });
                  }
                }
              }}
            >
              {isLoading && <i className='fas fa-spinner fa-spin mr-2' />} Save {group ? 'changes' : 'group'}
              {addContactsToGroup ? ' and add contacts' : ''}
            </button>
          )}
        </div>
        {currentStep === 'create' && type !== 'Static' && !group && (
          <button
            className='action-button px-4 py-2'
            onClick={() => {
              setCurrentStep('filters');
            }}
            disabled={name === ''}
          >
            Set your filters <span className='icon-long-arrow-right paragraph-p5 ml-2' />
          </button>
        )}
      </div>
      {currentStep === 'filters' && (
        <div
          className={`border-top  pt-3 flex-centered px-lg-5 px-3 justify-content-${currentStep === 'create' ? 'end' : 'between'}`}
          style={{ paddingBottom: '18px' }}
        >
          <p className='mb-0 nowrap-item'>
            <span className='d-none d-lg-inline-block'>This is a</span>{' '}
            <span className='font-weight-bold'>{isMobile ? type : type.toLowerCase()} group</span> containing{' '}
            {thousandCommas(filtersNumber)} contact{filtersNumber === 1 ? '' : 's'}
          </p>
        </div>
      )}
    </>
  );
};

export { CreateGroupButton, CreateGroupModal };
