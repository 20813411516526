import { FilterItem } from '../CommonFilter';
import ToggleFilter from '../ToggleFilter';
import SortFilter from '../SortFilter';
import { DateFilter } from '../DateFilter';
import SortMode from '../SortMode';
import React, { useContext, useEffect, useRef, useState } from 'react';
import normalize from '../../../utilities/normalizeString';
import useIsMobile from '../../../utilities/useIsMobile';
import FilterRegisterPopup from '../FilterRegisterPopup';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../../../../Store';
import CreateAccountPopup from '../../../components/CreateAccountPopup';
import { useMainSectionParameter } from '../../../helpers/useMainSectionParameter';
import ResearchPopUp from '../../../SubNav/ui/ResearchPopUp';
import ParliamentaryRecordMultipleHierarchy from '../ParliamentaryRecordMultipleHierarchy';
import styles from './DropDownFilters.module.scss';
import useOutsideElement from '../../../utilities/useOutsideElement';
import removeHyphenFromString from '../../../utilities/removeHyphenFromString';
import { useLocation, useParams } from 'react-router-dom';

const OutsideFilters = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, search, referenceState, keywordsLists, openResearchPopup } = state;
  const { activeSearch } = search;
  const { activeReference } = referenceState;
  const { subscriptionStatus, code, enableReferenceSection } = activePlan;
  const { getDefaultSectionFilters, filtersState, applyFiltersFunction, filterTypeModifiers, section, onlySort } =
    props;
  const searchMode = activeSearch || activeReference;

  const sectionFilters = getDefaultSectionFilters(filtersState);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);

  const { isAuthenticated } = useAuth0();
  const params = useParams();

  const { inboxSection } = useMainSectionParameter(params);

  const trialUser =
    (subscriptionStatus === 'InTrial' && code.includes('Mogul')) ||
    subscriptionStatus === 'TrialEnded' ||
    !enableReferenceSection;
  const allowedUsers = (isAuthenticated && !trialUser) || inboxSection;
  const selectOption = () => {
    return ({ filter, newValue }) => {
      if (allowedUsers) {
        let newFilters = filterTypeModifiers({ filter, newValue });
        applyFiltersFunction(newFilters);
      } else {
        setShowRegisterPopup(true);
      }
    };
  };

  //REFERENCE ELEMENTS NEW WIDGET ON THE OUTSIDE FILTERS
  const [showPopup, setShowPopup] = useState(false);
  const [keywordsSection, setKeywordsSection] = useState('keywords');

  const showPopupFromEmptyMessage = useRef();
  showPopupFromEmptyMessage.current = () => {
    if (openResearchPopup) {
      setShowPopup(true);
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'openResearchPopup',
        value: false,
      });
    }
  };
  useEffect(() => {
    showPopupFromEmptyMessage.current();
  }, [openResearchPopup]);

  const closeKeywordsSection = () => {
    setKeywordsSection('keywords');
  };

  return (
    <>
      <div className={styles.DropDownFilters} style={{ gap: onlySort ? '0' : '15px' }}>
        {sectionFilters
          .filter((item) => (onlySort ? item.type === 'sortMode' : item.type !== 'sortMode'))
          .filter((item) => !item.excludeFromPopup)
          .map((item, index) => {
            const { hidden, name } = item;
            const showFilter = !hidden;
            const renderFilter = showFilter;
            const filterProps = {
              filter: item,
              selectOption: selectOption(),
            };
            return (
              <React.Fragment key={`section-filter-${index}-${normalize(name)}`}>
                {renderFilter && (
                  <OutsideFilter
                    {...filterProps}
                    section={section}
                    allowedUsers={allowedUsers}
                    searchMode={searchMode}
                    onlySort
                  />
                )}
              </React.Fragment>
            );
          })}
      </div>
      {showRegisterPopup && isAuthenticated && (
        <FilterRegisterPopup
          identifier={'filters-register-popup'}
          setShowPopup={setShowRegisterPopup}
          additionalClass={'export-message-popup'}
          proMessage={!enableReferenceSection && isAuthenticated}
        />
      )}
      {showRegisterPopup && !isAuthenticated && (
        <CreateAccountPopup
          text={'You’ll need to create an account to filter results'}
          setShowPopup={setShowRegisterPopup}
        />
      )}
      {showPopup && (
        <ResearchPopUp
          keywordsLists={keywordsLists.filter((item) => item.id !== null)}
          identifier={'research-popup'}
          setShowPopup={setShowPopup}
          additionalCloseAction={closeKeywordsSection}
          keywordsSection={keywordsSection}
          referenceState={referenceState}
        />
      )}
    </>
  );
};

const OutsideFilter = (props) => {
  const { filter, list, allowedUsers, setIsAnotherOpen, isAnotherOpen } = props;
  const { name, type } = filter;

  const [isExpanded, setExpanded] = useState(false);
  const location = useLocation();
  const searchBarElement = useRef(null);
  const isMobile = useIsMobile(1280);
  const transformName = () => {
    if (isMobile) {
      switch (name) {
        case 'Exclude closed consultations':
          return 'Exclude closed';
        case 'Search within results':
          return 'Search within';
        default:
          return name;
      }
    }
    return name;
  };

  const renderSortHeader = () => {
    const selectedInDropdown =
      location.hash.length > 0
        ? removeHyphenFromString(
            location.hash
              .split('~')
              .find((str) => str.startsWith('#'))
              .substring(1)
          )
        : filter.optionSelected;
    return (
      <span className='filter-sort-dropdown-header'>
        Sort by: <span className='filter-sort-dropdown-header-active-stakeholders'>{selectedInDropdown}</span>
      </span>
    );
  };
  const showDependency = (isAnotherOpen && isExpanded) || !isAnotherOpen;
  useOutsideElement(searchBarElement, () => {
    setExpanded(false);
    if (setIsAnotherOpen) {
      setIsAnotherOpen(false);
    }
  });
  return (
    type !== 'search' &&
    showDependency && (
      <>
        <div>
          {isExpanded && <div className='close-tips'></div>}
          <div
            className={` ${
              type !== 'sortMode' ? 'dropdown-keywords-lists dropdown-keywords-lists-stakeholders' : ''
            }  ${isExpanded && type !== 'sortMode' ? 'dropdown-keywords-lists-open' : ''} transition-200`}
            ref={searchBarElement}
            data-intercom-target='Topics dropdown'
            style={{
              width: type === 'sortMode' ? 'auto' : isExpanded ? '300px' : '155px',
            }}
          >
            <div
              className='dropdown-keywords-activeitem dropdown-keywords-activeitem-sort-button dropdown-filter-activeItem flex-centered'
              onClick={() => {
                setExpanded(true);
                if (setIsAnotherOpen) {
                  setIsAnotherOpen(true);
                }
              }}
            >
              {/* TYPE ICON */}
              {type !== 'sortMode' && <span className={'main-search-icon'} />}
              {/*INPUT SEARCHBAR */}
              <p className={`search-main-text-value`}>
                <span className={`d-block`}>{type !== 'sortMode' ? transformName() : renderSortHeader()}</span>
              </p>

              {/* ARROW BUTTON */}
              <span
                className={`search-dropdown search-dropdown-stakeholders ${
                  isExpanded ? 'search-dropdown-open-stakeholders' : ''
                }`}
                data-tooltip-content={`${!isExpanded ? (type !== 'sortMode' ? `Filter by ${transformName()}` : 'Sort') : ''}`}
                data-tooltip-id='general-tooltip'
                data-tooltip-place='right'
                onClick={(e) => {
                  if (isExpanded) {
                    e.stopPropagation();
                    setExpanded(false);
                    if (setIsAnotherOpen) {
                      setIsAnotherOpen(false);
                    }
                  }
                }}
              ></span>
            </div>
            <div
              className={`open-dropdown ${
                type === 'sortMode' ? 'open-dropdown-sort-stakeholders' : ''
              } d-${isExpanded ? 'block' : 'none'}`}
            >
              <div className='open-dropdown-container'>
                {type === 'sort' && <SortFilter {...props} notIncludeName />}
                {type === 'date' && <DateFilter {...props} notIncludeName notShowCalendar={!allowedUsers} />}
                {type === 'sortMode' && <SortMode {...props} list={list} notIncludeName inDropdown />}
                {type === 'parliamentaryRecordMultipleHierarchy' && (
                  <ParliamentaryRecordMultipleHierarchy {...props} notIncludeName />
                )}
                {!type && <OutsideCommonFilter {...props} />}
              </div>
            </div>
          </div>
        </div>

        <div>{type === 'toggle' && <ToggleFilter {...props} />}</div>
      </>
    )
  );
};

const OutsideCommonFilter = (props) => {
  const { filter, selectOption, allowedUsers } = props;
  const { options, name } = filter;
  let splitItemValue = 4;

  const changeFilter = (selected, index, name) => {
    let newValue = [...options];
    let currentOption = newValue[index];
    if (allowedUsers) {
      if (name) {
        if (!selected) {
          currentOption.selected = true;
        }
        currentOption.optionSelected = name;
      } else {
        currentOption.selected = selected ? !selected : true;
      }
    }
    selectOption({ filter, newValue });
  };

  const moreOptions = () => {
    let seeMoreOptions = [];
    if (name === 'Party') {
      seeMoreOptions = options.slice(splitItemValue, options.length);
    }
    return seeMoreOptions;
  };

  const optionsToRender = () => {
    if (name === 'Party') {
      return options.slice(0, splitItemValue);
    }
    return options;
  };

  return (
    <div className='common-outside-filter dropdown-filter-options'>
      {optionsToRender().map((option, index) => (
        <FilterItem
          option={option}
          options={options}
          key={`option-filter${index}`}
          index={index}
          changeFilter={changeFilter}
          notRow
          insideDropDown
        />
      ))}

      {moreOptions().length > 0 && (
        <>
          <div>
            {moreOptions().map((option, index) => (
              <FilterItem
                option={option}
                options={options}
                key={`option-filter${index + splitItemValue}`}
                index={index + splitItemValue}
                changeFilter={changeFilter}
                notRow
                insideDropDown
              />
            ))}
          </div>

          <button className={`d-inline-block simple-link-button general-button showitem-button pl-3`}>Show more</button>
        </>
      )}
    </div>
  );
};

export default OutsideFilters;
