import React, { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../Store.js';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import getCurrentTeam from './getCurrentTeam';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import InviteUsersPopUp from './InviteUsersPopUp.js';
import RoleSelect from './roleSelect';
import OpenInvitationItem from './openInvitationItem';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement.js';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SkeletonTeam from './SkeletonTeam.js';
import useIsMobile from '../../Dashboard/utilities/useIsMobile.js';
import SortArray from '../../Dashboard/utilities/SortArray.js';
import DeleteUserPopup from './DeleteUserPopup.js';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall.js';
import createNotification from '../Utilities/CreateNotification.js';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord.js';
import SelectOfHiddenItems from './selectOfHiddenItems.js';
import CustomScrollbar from '../../Common/CustomScrollbar.js';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';

const TeamScreen = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activeSubscriptions } = state;

  const [heightContainer, containerRef] = useHeightContainer();
  const [isLoading, setIsLoading] = useState(true);
  const [team, setTeam] = useState({});
  const [showPopUpInvites, setShowPopUpInvites] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [userToDeleteId, setUserToDeleteId] = useState(null);

  const { owner, myself, allMembers, openInvitations } = team;
  const { allowMultiUser } = activeSubscriptions;

  const { agencyUser, transformTopicOrClientWord, newAgencyPlan } = useTopicOrClientWord();

  const location = useLocation();
  const isMobile = useIsMobile();

  const { getAccessToken } = useGetAccessToken();
  const { generalApiCall } = useGeneralApiCall();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadCurrentTeam(source);
    return () => {
      source.cancel('Teams canceled by the user.');
    };
  }, []);

  const loadCurrentTeam = async (source) => {
    try {
      let currentTeam = await getCurrentTeam(getAccessToken, source);
      if (!!currentTeam) {
        setTeam(currentTeam);
        let title = `Team - Settings - PolicyMogul`;
        document.title = title;
        let locationToStorage = {
          title: title,
          storageLocation: { ...location },
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'locationToStorage',
          value: locationToStorage,
        });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const orderTeamMembers = () => {
    if (!!allMembers) {
      const admins = allMembers.filter((item) => item.role === 'Admin').sort(SortArray('fullName'));
      const members = allMembers.filter((item) => item.role === 'Member').sort(SortArray('fullName'));
      return [...admins, ...members];
    }
  };

  const deleteUser = async () => {
    try {
      if (!!userToDeleteId) {
        const pathname = `/api/team/team-member/${userToDeleteId}`;
        const method = 'delete';
        const results = await generalApiCall({
          pathname,
          method,
          needsAuthentication: true,
          returnCompleteRequest: true,
        });
        if (results?.status === 200) {
          let newTeam = { ...team };
          let userPosition = newTeam.allMembers.findIndex((item) => item.id === userToDeleteId);
          newTeam.allMembers.splice(userPosition, 1);
          setTeam(newTeam);
          createNotification('success', `User deleted successfully`);
        }
      }
    } catch (error) {
      createNotification('danger', `An error ocurred.`);
    }
  };

  return (
    <>
      {isLoading && <SkeletonTeam />}
      {!isLoading && (
        <div className='pt-lg-5'>
          <div className='settings-box pb-lg-5 pb-3'>
            {/* FREE USERS BANNER */}
            {!allowMultiUser && (
              <div className='px-lg-5 px-3 py-4 team-upgrade-message'>
                <p className='m-0'>
                  Your plan includes <strong>1</strong> user. For unlimited users,{' '}
                  <Link to={`/pricing?returnUrl=${encodeURIComponent(location.pathname)}`}>upgrade now</Link>
                </p>
              </div>
            )}

            <div className='row mx-0 justify-content-between align-items-center padding-topic-component sticky-item pt-4'>
              <h3 className='main-title topics-title mb-0'>
                Team
                <ReactTooltip
                  id={'billing-tooltip'}
                  effect={'solid'}
                  type={'info'}
                  className={'pricing-feature-tooltip'}
                  backgroundColor={'#00122B'}
                  textColor={'#fff'}
                  multiline={true}
                  arrowColor={'transparent'}
                  place={'right'}
                />
              </h3>
              {(myself.role === 'Owner' || myself.role === 'Admin') && (
                <div className='col-8 col-lg-5 text-right px-0'>
                  <button
                    className={`team-invite-button general-button px-3 action-button py-2 ${
                      !allowMultiUser ? 'button-block' : ''
                    }`}
                    onClick={() => {
                      if (allowMultiUser) {
                        setShowPopUpInvites(true);
                      }
                    }}
                  >
                    Invite colleagues
                  </button>
                </div>
              )}
            </div>

            <div className='px-lg-5 p-3 py-lg-2'>
              <div className='row mx-0 align-items-center align-items-lg-end topic-list-titles team-titles pt-4 pb-2 mb-3 d-none d-lg-flex justify-content-start'>
                <div className={myself.role === 'Admin' || myself.role === 'Owner' ? 'col-lg-5' : 'col-lg-6'}>
                  <h3>Name</h3>
                </div>
                <div className={myself.role === 'Admin' || myself.role === 'Owner' ? 'col-lg-4' : 'col-lg-5'}>
                  <h3>Role</h3>
                </div>
                {(myself.role === 'Admin' || myself.role === 'Owner') && (
                  <div className='col-lg-5'>
                    <h3>
                      Accessible{' '}
                      {newAgencyPlan ? 'clients' : transformTopicOrClientWord({ uppercase: false, plural: true })}
                      <span
                        onClick={(e) => e.stopPropagation()}
                        className='icon-info main-light-text d-inline-block pointer ml-2 paragraph-p4'
                        data-tooltip-content={`If a ${agencyUser || newAgencyPlan ? 'client' : 'topic'} is marked non-accessible for a user, it will be hidden from that user throughout the platform, including on drop-down menus and in email alert settings`}
                        data-tooltip-id={'settings-tooltip'}
                        data-tooltip-class-name={'pricing-feature-tooltip feed-tooltip billing-tooltip'}
                      />
                    </h3>
                  </div>
                )}
              </div>
              <div ref={containerRef}>
                <CustomScrollbar
                  className={'main-content-scrollbar setting-scrollbar'}
                  style={{ height: `${heightContainer}px` }}
                  maximalThumbYSize={100}
                >
                  <div className='team-content'>
                    <TeamItem
                      name={owner.fullName}
                      email={owner.emailAddress}
                      role={() => {
                        return <p className={`mb-0 hour-select px-3 disabled-role-select`}>Owner</p>;
                      }}
                      enableActions={myself.role === 'Owner' ? true : false}
                      actions={() => {
                        return (
                          <Link to='/settings/account' className='change-email-button'>
                            <button className='general-button team-action-item'>Change email address</button>
                          </Link>
                        );
                      }}
                      selectOfHiddenItems={() => {
                        return <SelectOfHiddenItems userItem={myself} disabled={true} agencyUser={agencyUser} />;
                      }}
                      myself={myself}
                    />
                    {orderTeamMembers().map((item, index) => {
                      if (item.id !== owner.id) {
                        return (
                          <TeamItem
                            key={`all-members${index}`}
                            name={item.fullName}
                            email={item.emailAddress}
                            role={() => {
                              return (
                                <RoleSelect
                                  item={item}
                                  team={team}
                                  setTeam={setTeam}
                                  teamPage
                                  disabled={
                                    (myself.role === 'Admin' || myself.role === 'Owner') && myself.id !== item.id
                                      ? false
                                      : true
                                  }
                                />
                              );
                            }}
                            enableActions={myself.id === item.id || myself.role === 'Owner'}
                            actions={() => {
                              if (myself.role === 'Owner') {
                                return (
                                  <button
                                    className='general-button team-action-item text-red'
                                    onClick={() => {
                                      setUserToDeleteId(item.id);
                                      setShowDeleteUser(true);
                                    }}
                                  >
                                    Delete user
                                  </button>
                                );
                              } else {
                                return (
                                  <Link to='/settings/account' className='change-email-button'>
                                    <button className='general-button team-action-item'>Change email address</button>
                                  </Link>
                                );
                              }
                            }}
                            selectOfHiddenItems={() => {
                              return (
                                <SelectOfHiddenItems
                                  userItem={item}
                                  disabled={
                                    ((myself.role === 'Admin' || myself.role === 'Owner') && myself.id !== item.id
                                      ? false
                                      : true) ||
                                    (item.role === 'Admin' && item.newRole !== 'Member') ||
                                    (item.newRole === 'Admin' && item.role === 'Member')
                                  }
                                  agencyUser={agencyUser}
                                  key={item.id}
                                />
                              );
                            }}
                            myself={myself}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                    {openInvitations && (
                      <>
                        {openInvitations.map((item, index) => {
                          return (
                            <OpenInvitationItem
                              key={`open-invitation${index}`}
                              item={item}
                              team={team}
                              setTeam={setTeam}
                              index={index}
                              allowMultiUser={allowMultiUser}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                </CustomScrollbar>
              </div>
            </div>
          </div>
          <ReactTooltip
            offset={5}
            effect={'solid'}
            id={'team-tooltip'}
            className={'header-tooltip'}
            arrowColor={'transparent'}
            place={'top'}
            globalEventOff={isMobile ? 'click' : undefined}
          />
        </div>
      )}
      {showPopUpInvites && <InviteUsersPopUp team={team} setTeam={setTeam} setShowPopUpInvites={setShowPopUpInvites} />}
      {showDeleteUser && (
        <DeleteUserPopup
          identifier={'delete-user-popup'}
          setShowPopup={setShowDeleteUser}
          deleteUser={deleteUser}
          additionalCloseAction={() => setUserToDeleteId(null)}
        />
      )}
    </>
  );
};

const TeamItem = (props) => {
  const { name, email, role, enableActions, actions, selectOfHiddenItems, myself } = props;
  const [showActions, setShowActions] = useState(false);
  const hideItem = () => {
    setShowActions(false);
  };
  const itemElement = useRef(null);
  useOutsideElement(itemElement, hideItem);

  return (
    <div className='row mx-lg-0 team-item py-4 align-items-center align-items-lg-start'>
      <div className={`${myself.role === 'Admin' || myself.role === 'Owner' ? 'col-lg-5' : 'col-lg-6'} mb-4 mb-lg-0`}>
        <p>
          <strong>{name}</strong>
        </p>
        <p className='team-list-email'>{email}</p>
      </div>
      <div className={`col-14 col-lg-4 align-self-lg-center pr-lg-3 pr-0`}>{role()}</div>
      {(myself.role === 'Admin' || myself.role === 'Owner') && (
        <div className='col-14 col-lg-5 align-self-lg-center pr-lg-5 pr-0'>{selectOfHiddenItems()}</div>
      )}
      <div
        className={`col-2 col-lg-${
          myself.role === 'Admin' || myself.role === 'Owner' ? '2' : '5'
        } text-right position-relative pr-lg-2 px-0`}
      >
        {enableActions && (
          <>
            <span
              className={`team-action-selector ${showActions ? 'team-action-selector-selected' : ''}`}
              ref={itemElement}
              data-tooltip-content={'Manage user'}
              data-tooltip-id={'team-tooltip'}
              onClick={() => {
                setShowActions(!showActions);
              }}
            />
            {showActions && (
              <div className='team-actions py-2 px-3 filters-container' ref={itemElement}>
                {actions()}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TeamScreen;
