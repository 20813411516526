import normalize from '../../utilities/normalizeString';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useMainSectionParameter } from '../../helpers/useMainSectionParameter';

const ParliamentaryRecordMultipleHierarchy = (props) => {
  const { filter, selectOption, notIncludeName } = props;
  const { name, options } = filter;
  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);

  const changeFilter = ({ option, suboption, index, parentIndex, customOption }) => {
    let newValue = JSON.parse(JSON.stringify(options));

    if (option) {
      let newOption = newValue[index];
      let selectedStatus = newOption.selected;
      newOption.selected = !selectedStatus;
      if (selectedStatus) {
        newOption.selectedOptions = [];
        if (newOption.houseFilter) {
          newOption.houseFilterSelectedOptions = [];
        }
      } else {
        if (newOption.houseFilter) {
          newOption.houseFilterSelectedOptions = newOption.houseFilter.options.map((item) => item.name);
        }
        newOption.selectedOptions = newOption.options.map((item) => item.name);
      }
    }

    if (suboption || customOption) {
      let newOption = newValue[parentIndex];

      if (!newOption.selected) {
        newOption.selected = true;
      }
      if (customOption && newOption.selectedOptions.length === 0) {
        newOption.selectedOptions = newOption.options.map((item) => item.name);
      }
      if (suboption && newOption.houseFilterSelectedOptions?.length === 0) {
        newOption.houseFilterSelectedOptions = newOption.houseFilter.options.map((item) => item.name);
      }

      let newSuboption = customOption ? newOption.houseFilter.options[index] : newOption.options[index];
      let param = customOption ? 'houseFilterSelectedOptions' : 'selectedOptions';
      let selectedOptions = newOption[param];

      let optionName = newSuboption.name;
      if (selectedOptions.includes(optionName)) {
        let position = selectedOptions.indexOf(optionName);
        if (suboption || (customOption && selectedOptions.length === newOption?.houseFilter?.options.length)) {
          selectedOptions.splice(position, 1);
        }

        if (suboption) {
          //Deselect houseFilter when no options are selected
          if (selectedOptions.length === 0 && newOption.houseFilterSelectedOptions) {
            newOption.houseFilterSelectedOptions = [];
          }
        }
      } else {
        selectedOptions.push(optionName);
      }
      if (selectedOptions.length === 0 && suboption) {
        newOption.selected = false;
      }
    }
    selectOption({ filter, newValue });
  };

  return (
    <>
      {!notIncludeName && <h3>{name}</h3>}
      <div className={`common-outside-filter ${inboxSection ? 'common-outside-filter-inbox' : ''}`}>
        {options.map((option, index) => {
          const { name, disabled, options, selected, selectedOptions, houseFilter, houseFilterSelectedOptions } =
            option;
          const identifier = `checkbox-item-${normalize(name)}-${filter.name}-${index}`;
          return (
            <React.Fragment key={identifier}>
              <div className={`filter-item-container`} key={identifier}>
                <div
                  className={`checkbox-item filter-item-${normalize(name)} ${disabled ? 'checkbox-item-disabled' : ''}`}
                >
                  <input
                    id={identifier}
                    onChange={() => {
                      changeFilter({ option, index });
                    }}
                    type={`checkbox`}
                    disabled={disabled}
                    checked={selected}
                  />
                  <label htmlFor={identifier}>{name}</label>
                </div>
              </div>
              {houseFilter && (
                <div className='d-flex box-rounded custom-filter-house'>
                  {houseFilter.options.map((houseFilter, customIndex) => {
                    const identifier = `custom-filter-${normalize(houseFilter.name)}`;
                    return (
                      <div className={`p-2 ${identifier}-container`} key={identifier}>
                        <div className={`checkbox-item flex-grow-1 my-0 filter-item-${normalize(houseFilter.name)}`}>
                          <input
                            id={identifier}
                            onChange={() => {
                              changeFilter({ parentIndex: index, customOption: houseFilter, index: customIndex });
                            }}
                            type={`checkbox`}
                            disabled={disabled}
                            checked={houseFilterSelectedOptions.includes(houseFilter.name)}
                          />
                          <label className={`mb-0 ${identifier}`} htmlFor={identifier}>
                            {houseFilter.name}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className='indented-filters parliamentary-hierarchy-filter'>
                {options.map((optionItem, optionIndex) => {
                  const { name } = optionItem;
                  const identifier = `${normalize(option.name)}-${optionIndex}-${normalize(name)}`;
                  return (
                    <React.Fragment key={identifier}>
                      <div className={`checkbox-item filter-item-${normalize(option.name)}`}>
                        <input
                          id={identifier}
                          onChange={() => {
                            changeFilter({ parentIndex: index, suboption: optionItem, index: optionIndex });
                          }}
                          type={`checkbox`}
                          disabled={disabled}
                          checked={selectedOptions.includes(name)}
                        />
                        <label htmlFor={identifier}>
                          <span className='label-name'>{name}</span>
                        </label>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default ParliamentaryRecordMultipleHierarchy;
