import { Navigate, Route, useParams, generatePath } from 'react-router-dom';

const Redirect = ({ to, replace, state }) => {
  const params = useParams();
  const redirectWithParams = generatePath(to, params);

  return <Navigate to={redirectWithParams} replace={replace} state={state} />;
};

const Redirections = (location) => {
  return (
    <>
      <Route path={`/monitor-inbox`} element={<Navigate to={`/monitor-inbox/key-updates${location.search}`} />} />
      <Route path={`/reference`} element={<Navigate to={`/reference/key-updates${location.search}`} />} />

      <Route
        path={`/monitor-inbox/calendar`}
        element={<Navigate replace to={`/monitor-inbox/upcoming-events${location.search}`} />}
      />
      {/* Redirecting monitor stakeholder urls */}
      <Route exact path={`/monitor/stakeholders`} element={<Navigate to='/stakeholder-mapping' />} />

      {/* REDIRECT LIST ROUTES */}
      <Route
        exact
        path={`/monitor/key-updates`}
        element={<Navigate to={`/monitor-inbox/key-updates${location.search}`} />}
      ></Route>

      <Route
        exact
        path={`/monitor/consultations`}
        element={<Navigate to={`/monitor-inbox/consultations${location.search}`} />}
      />

      <Route
        exact
        path={`/monitor/legislation`}
        element={<Navigate to={`/monitor-inbox/legislation${location.search}`} />}
      />

      <Route
        exact
        path={`/monitor/parliamentary-record`}
        element={<Navigate to={`/monitor-inbox/parliamentary-record${location.search}`} />}
      />

      <Route
        exact
        path={`/monitor/calendar`}
        element={<Navigate to={`/monitor-inbox/upcoming-events${location.search}`} />}
      />

      <Route
        exact
        path={`/monitor-inbox/calendar`}
        element={<Navigate to={`/monitor-inbox/upcoming-events${location.search}`} />}
      />

      <Route exact path={`/monitor/tweets`} element={<Navigate to={`/monitor-inbox/tweets${location.search}`} />} />

      {/* REDIRECT CONTENT ROUTES */}
      <Route
        exact
        path={`/monitor/key-updates/:id`}
        element={<Redirect to={`/key-updates/:id${location.search}${location.hash}`} />}
      />
      <Route
        exact
        path={`/monitor/key-updates/:id/:title`}
        element={<Redirect to={`/key-updates/${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/monitor/consultations/:id`}
        element={<Redirect to={`/consultations/:id${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/monitor/consultations/:id/:title`}
        element={<Redirect to={`/consultations/:id/:title${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/monitor/legislation/:type/:id`}
        element={<Redirect to={`/legislation/:type/:id${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/monitor/legislation/:type/:id/:title`}
        element={<Redirect to={`/legislation/:type/:id/:title${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/monitor/parliamentary-record/:type/:id`}
        element={<Redirect to={`/parliamentary-record/:type/:id${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/monitor/parliamentary-record/:type/:id/:title`}
        element={<Redirect to={`/parliamentary-record/:type/:id/:title${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/influence/stakeholders/:id`}
        element={<Redirect to={`/stakeholders/:id${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/influence/stakeholders/:id/:name`}
        element={<Redirect to={`/stakeholders/:id/:name${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/influence/stakeholders/:id/:name/:contentType`}
        element={<Redirect to={`/stakeholders/:id/:name/:contentType${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/monitor/stakeholders/:id`}
        element={<Redirect to={`/stakeholders/:id${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/monitor/stakeholders/:id/:name`}
        element={<Redirect to={`/stakeholders/:id/:name${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/monitor/stakeholders/:id/:name/:contentType`}
        element={<Redirect to={`/stakeholders/:id/:name/:contentType${location.search}${location.hash}`} />}
      />

      <Route exact path={`/influence/contacts`} element={<Navigate to={`/influence/directory`} />} />
      <Route
        exact
        path={`/influence/contacts/update/:id`}
        element={<Redirect to={`/influence/directory/update/:id`} />}
      />

      <Route
        exact
        path={`/have-your-voice-heard`}
        element={<Navigate to={`/influence/lobbying-material${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/influence/have-your-voice-heard/add-content`}
        element={<Navigate to={`/influence/add-content${location.search}${location.hash}`} />}
      />

      <Route
        exact
        path={`/influence/have-your-voice-heard/:contentType/:id/:title`}
        element={<Redirect to={`/:contentType/:id/:title${location.search}${location.hash}`} />}
      />

      {/* REDIRECT SETTINGS PAGES */}
      <Route path='/keywords-and-alerts' element={<Navigate replace to='/settings/topics' />} />

      <Route path='/keywords' element={<Navigate replace to='/settings/topics' />} />

      <Route path='/keywords/:action' element={<Navigate replace to='/settings/topics' />} />

      <Route path='/email-alerts' element={<Navigate replace to='/settings/alerts' />} />

      <Route path='/my-topics' element={<Navigate replace to='/settings/topics' />} />

      <Route path='/my-team' element={<Navigate replace to='/settings/team' />} />

      {/* <Route exact path={`/influence/:contentType/:id/:title`} element={<Redirect to={`/:contentType/:id/:title${location.search}${location.hash}`} />} /> */}
    </>
  );
};

const RedirectionsNoAuthenticated = (location) => {
  const loginUrl = `/login?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`;
  return (
    <>
      <Route path={`/monitor-inbox`} element={<Navigate to={loginUrl} />} />
      <Route path={`/stakeholder-mapping`} element={<Navigate to={loginUrl} />} />
      <Route path={`/monitor-inbox/:page`} element={<Navigate to={loginUrl} />} />
      <Route path={`/analytics/:section`} element={<Navigate to={loginUrl} />} />
      <Route path={`/ai-assistant`} element={<Navigate to={loginUrl} />} />
      <Route path={`/ai-assistant/:section`} element={<Navigate to={loginUrl} />} />
      <Route path={`/ai-assistant/:section/:title`} element={<Navigate to={loginUrl} />} />
    </>
  );
};

export { Redirect, RedirectionsNoAuthenticated };
export default Redirections;
