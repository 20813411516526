import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import CreateClient from './CreateClient';
import useClientsFunctions from '../../Dashboard/hooks/useClientsFunctions';
import { store } from '../../Store';
import getCurrentTeam from '../Team/getCurrentTeam';
import ClientComponent from './ClientComponent';
import SkeletonLists from '../Topics/SkeletonLists';
import useCreateEmailAlerts from '../EmailAlerts/useCreateEmailAlerts';
import getUrlParam from '../../Dashboard/utilities/getUrlParam';
import { useLocation } from 'react-router-dom';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

const Clients = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { team, activeSubscriptions } = state;
  const { maxNumberOfTeams } = activeSubscriptions;

  const createParameter = getUrlParam('create');
  const teamParameter = getUrlParam('teamId');

  const [clientsList, setClientList] = useState([]);
  const [isOpen, setIsOpen] = useState(!!createParameter);
  const [activeClient, setActiveClient] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [stateExpanded, setStateExpanded] = useState(true);
  const [currentlyHiding, setCurrentlyHiding] = useState(false);

  const { getAccessToken } = useGetAccessToken();

  const { getTeams } = useClientsFunctions();
  const { loadAlerts } = useCreateEmailAlerts();
  const [heightContainer, containerRef] = useHeightContainer();
  const location = useLocation();

  const allowAddClients = maxNumberOfTeams === null || clientsList?.length < maxNumberOfTeams;
  const callClientsListAndTopics = useRef();
  callClientsListAndTopics.current = async ({ source }) => {
    try {
      const results = await getTeams({ includeKeywordsList: true, source, includeOption: true });
      let currentTeam = team;
      if (!!team && Object.keys(team)?.length === 0) {
        currentTeam = await getCurrentTeam(getAccessToken, source);
        if (!!currentTeam) {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'team',
            value: currentTeam,
          });
        }
      }
      await loadAlerts(source);
      let title = `Clients - Settings - PolicyMogul`;
      document.title = title;
      let locationToStorage = {
        title: title,
        storageLocation: { ...location },
      };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
      if (!!results) {
        let resultsOrdered = results.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        if (!!teamParameter) {
          let teamItemPosition = resultsOrdered.findIndex((item) => item?.teamId === parseInt(teamParameter));
          let teamElement = resultsOrdered[teamItemPosition];
          resultsOrdered?.splice(teamItemPosition, 1);
          resultsOrdered = [teamElement, ...resultsOrdered];
        }
        setClientList(resultsOrdered);
        setActiveClient(results?.find((item) => item.teamId === currentTeam?.teamId));
        setIsLoading(false);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'clients', value: resultsOrdered });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callClientsListAndTopics.current({ source });

    return () => {
      source.cancel('Clients cancelled by the user');
    };
  }, []);

  useEffect(() => {
    if (!!createParameter) {
      setIsOpen(true);
    }
  }, [createParameter]);

  const enabledClients = clientsList?.filter((item) => item?.myState === 'Enabled');
  const hiddenClients = clientsList?.filter((item) => item?.myState !== 'Enabled');

  return (
    <>
      <div className={`pt-4 pt-lg-5`}>
        <div className='settings-box'>
          <div ref={containerRef} style={{ height: `${heightContainer}px` }} className='scroll-container rounded'>
            <div className='row mx-0 justify-content-between align-items-center padding-topic-component pt-lg-4 border-bottom pb-4 sticky-top background-white rounded'>
              <h3 className='main-title topics-title mb-0'>
                Clients {!isLoading ? <>({enabledClients?.length})</> : ''}
              </h3>
              {!isLoading && (
                <div className='flex-centered'>
                  {clientsList?.length > 1 && (
                    <button
                      className={`general-button border collapse-expand-button action-state-hover mr-3 main-subtle-text ${
                        stateExpanded ? 'collapse-button' : ''
                      }`}
                      onClick={() => {
                        setStateExpanded(!stateExpanded);
                      }}
                    >
                      {stateExpanded ? 'Expand' : 'Collapse'} all
                    </button>
                  )}
                  <CreateClientButton allowAddClients={allowAddClients} setIsOpen={setIsOpen} />
                </div>
              )}
            </div>
            {isLoading ? (
              <SkeletonLists />
            ) : (
              <>
                {enabledClients?.map((item, index, array) => {
                  const { teamId } = item;
                  return (
                    <ClientComponent
                      key={`client-${teamId}`}
                      client={item}
                      clientsListState={{ clientsList, setClientList }}
                      hidingState={{ currentlyHiding, setCurrentlyHiding }}
                      index={index}
                      array={array}
                      stateExpanded={stateExpanded}
                    />
                  );
                })}
                {hiddenClients?.length > 0 && (
                  <>
                    <div className='py-4 border-bottom padding-topic-component'>
                      <h3 className='main-title topics-title mb-0'>Hidden clients ({hiddenClients?.length})</h3>
                    </div>
                    {hiddenClients?.map((item, index, array) => {
                      const { teamId } = item;
                      return (
                        <ClientComponent
                          key={`client-${teamId}`}
                          client={item}
                          clientsListState={{ clientsList, setClientList }}
                          hidingState={{ currentlyHiding, setCurrentlyHiding }}
                          index={index}
                          array={array}
                          stateExpanded={stateExpanded}
                        />
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <CreateClient
        activeClientState={{ activeClient, setActiveClient }}
        clientsListState={{ clientsList, setClientList }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

const CreateClientButton = (props) => {
  const { allowAddClients, setIsOpen } = props ?? {};
  const { ownerAdminRole } = useClientsFunctions();
  return (
    <button
      data-tooltip-content={
        !allowAddClients
          ? `You have reached the limit of clients you can add. Contact us to add more`
          : `${!ownerAdminRole ? 'Please contact your team admin' : ''}`
      }
      data-tooltip-id={'settings-tooltip'}
      className='general-button action-button px-3 py-2'
      disabled={!allowAddClients || !ownerAdminRole}
      onClick={() => {
        setIsOpen(true);
      }}
    >
      Add a client{' '}
      {(!allowAddClients || !ownerAdminRole) && <span className='icon-padlock d-inline-block-centered ml-1' />}
    </button>
  );
};

export { CreateClientButton };
export default Clients;
