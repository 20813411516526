import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import { store } from '../../../Store';
import useIsMobile from '../../utilities/useIsMobile';
import useDetectActiveFilters from '../../SubNav/useDetectActiveFilters';
import InfluenceCrmSideBar from '../InfluenceCrmSideBar/InfluenceCrmSideBar';
import { useLocation, useParams } from 'react-router-dom';
import thousandCommas from '../../helpers/thousandCommas';
import { useNoTopicsCondition } from '../../components/EmptyMessage';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import { MobileMenu } from '../../components/SectionTitle';

const InfluenceSectionTitle = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults, listDomainsVerified, crmContactsQtty, team } = state;

  const { CrmContacts } = contentResults[activeResults];

  const { detectActiveFilters } = useDetectActiveFilters();
  const { newAgencyPlan } = useTopicOrClientWord();

  const isMobile = useIsMobile();
  const [showBox, setShowBox] = useState(false);

  const actionsElement = useRef(null);
  const titleElement = useRef(null);

  const location = useLocation();
  const params = useParams();
  const { groupId } = params;

  const hideActions = () => {
    setShowBox(false);
  };

  const needsDomainVerification =
    (!listDomainsVerified.verifiedDomains.length ||
      (!listDomainsVerified.allDomains.length && !listDomainsVerified.verifiedEmailAddresses.length) ||
      !listDomainsVerified.verifiedEmailAddresses.length) &&
    !listDomainsVerified.domainsProccessing;

  const firstTimeContactsAdded = useMemo(() => {
    return JSON.parse(localStorage.getItem('firstTimeContactsAdded'));
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      if (firstTimeContactsAdded?.animationShown === false) {
        localStorage.setItem(
          'firstTimeContactsAdded',
          JSON.stringify({ firstTimeContactsAdded: true, animationShown: true })
        );
      }
    };
  }, [location.pathname]);

  const influenceRoute = location.pathname.split('/')?.[1];
  const innerInfluenceRoute = location.pathname.split('/')?.[2];
  const emailComponentPage =
    (innerInfluenceRoute === 'emails' && location.pathname.split('/')?.[3]) ||
    location.pathname.includes('/email-performance');

  const influenceSectionMapping = {
    'stakeholder-mapping': 'Stakeholder mapping',
    directory: 'Directory',
    'lobbying-material': newAgencyPlan ? `Content for ${team?.teamName}` : "My team's content",
    emails: ((needsDomainVerification && isMobile) || !needsDomainVerification) && 'Email campaigns',
    inbox: 'Inbox',
    'engagement-statistics': 'Engagement statistics',
    policymakers: 'Policymakers',
    'ai-assistant': 'AI assistant',
    'my-groups': newAgencyPlan ? `Groups for ${team?.teamName}` : 'My groups',
  };

  const showItemDropdown = isMobile;
  const { newAgencyNoTopicsCondition } = useNoTopicsCondition();

  return (
    <>
      <div>
        {((crmContactsQtty && innerInfluenceRoute === 'contacts') || innerInfluenceRoute !== 'contacts') && (
          <>
            <div className='section-title-left-side' ref={actionsElement}>
              <div className='position-relative'>
                {!emailComponentPage && (
                  <h3
                    ref={titleElement}
                    className='my-lg-0 main-title mb-0 nowrap-item'
                    onClick={() => {
                      if (showItemDropdown) {
                        setShowBox(!showBox);
                      }
                    }}
                  >
                    <span
                      className={`font-weight-bold ${
                        showItemDropdown ? 'dropdown-item-element pointer dropdown-item-element-main-title' : ''
                      } ${showBox ? 'open' : ''}`}
                    >
                      {!!groupId ? <></> : <>{influenceSectionMapping[innerInfluenceRoute ?? influenceRoute]}</>}
                    </span>
                    {detectActiveFilters() && location.pathname === '/influence/directory' && (
                      <span className='paragraph-p1 main-ligh-text ml-1'>
                        ({thousandCommas(CrmContacts?.totalHits)} record
                        {CrmContacts?.totalHits === 1 ? '' : 's'})
                      </span>
                    )}
                  </h3>
                )}
                {showBox && (
                  <>
                    <MobileMenu
                      showBoxState={{ showBox, setShowBox }}
                      titleElement={titleElement}
                      additionalClass='influence-section-picker'
                    >
                      <InfluenceCrmSideBar closeMenu={hideActions} mobile />
                    </MobileMenu>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {/* {!isSmallMobile &&
        innerInfluenceRoute === 'stakeholder-mapping' &&
        stakeholdersToRender.length > 0 &&
        activeFilters &&
       (
          <div className={`section-title-helper-text stakeholder-mapping-helper-text`}>
            <TotalResults onlyNumber />
            matching stakeholders
          </div>
        )} */}
    </>
  );
};

export default InfluenceSectionTitle;
