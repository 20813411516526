import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import { store } from '../../../Store';

const AddLobbyingMaterialButton = ({ influenceSubnav }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const location = useLocation();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();

  return influenceSubnav ? (
    <Link
      to={`/influence/add-content?returnUrl=${encodeURIComponent(location.pathname)}`}
      className='action-button filter-action-button d-none d-sm-flex nowrap-item align-items-center justify-content-center'
      style={{ padding: '0px 1.5em', zIndex: 1, minHeight: '38px' }}
    >
      Add lobbying material{' '}
      {agencyUser ? (
        ' for a client'
      ) : (
        <span className='d-none d-lg-inline-block ml-1'>{newAgencyPlan ? ` for ${team?.teamName}` : ''}</span>
      )}
    </Link>
  ) : (
    <Link
      className='add-more-button add-more-lists mt-0'
      to={`/influence/add-content?returnUrl=${encodeURIComponent(location.pathname)}`}
    >
      <button className='general-button add-keyword-list-button'>
        <span className='icon-square-plus' /> Add lobbying material
      </button>
    </Link>
  );
};

export default AddLobbyingMaterialButton;
