import { useRef, useState } from 'react';
import useIsMobile from '../../utilities/useIsMobile';
import useUpdateTitleAnalytics from '../useUpdateTitleAnalytics';
import AnalyticsSidebar from './AnalyticsSidebar';
import { useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';

const AnalyticsTitle = (props) => {
  const isMobile = useIsMobile();
  const [showBox, setShowBox] = useState(false);
  const containerElement = useRef(null);
  const location = useLocation();

  const { title } = props;
  const { sectionTitle } = useUpdateTitleAnalytics();

  const mobileCondition = isMobile && !location.pathname.startsWith('/embed-analytics');
  const titleElement = useRef();
  const { top, height, left } = titleElement.current?.getBoundingClientRect() ?? {};
  return (
    <>
      {showBox && <div className='general-close-background z-extra-max' onClick={() => setShowBox(false)}></div>}
      <div className='position-relative ' ref={containerElement}>
        <h3
          className={`trends-title title-h4-bold mb-0 mr-1 ${mobileCondition ? 'dropdown-item-element pointer' : ''} ${showBox ? 'open' : ''} nowrap-item`}
          onClick={() => {
            if (mobileCondition) {
              setShowBox(!showBox);
            }
          }}
          ref={titleElement}
        >
          {title ?? sectionTitle()}
        </h3>
        {showBox && (
          <Portal>
            <div
              className='login-navbar py-2 z-extra-max'
              style={{ position: 'fixed', top: `${top + height + 10}px`, left: `${left}px`, width: '260px' }}
            >
              <AnalyticsSidebar />
            </div>
          </Portal>
        )}
      </div>
    </>
  );
};

export default AnalyticsTitle;
