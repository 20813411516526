import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { store } from '../../Store.js';
import callContentRelated from '../utilities/callContentRelated.js';
import normalizeString from '../utilities/normalizeString';
import ContentRelated from '../key_updates/ContentRelated';
import axios from 'axios';
import SkeletonParliamentaryContent from '../Parliamentary_record/SkeletonParliamentaryContent.js';
import { useAuth0 } from '@auth0/auth0-react';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions.js';
import callHightlightContentAPI from '../utilities/callHightlightContentAPI.js';
import addMetaTags from '../utilities/addMetaTags.js';
import { useHeightContainer } from '../utilities/useHeightContainer.js';
import changeStakeholderLinks from '../helpers/changeStakeholderLinks.js';
import NavigationLinks from '../components/NavigationLinks.js';
import { createNavigationLinksOnSection, getIdPositionOnSection } from '../helpers/navigationFunctions.js';
import updateUnreadItems from '../helpers/updateUnreadItems.js';
import getUrlParam from '../utilities/getUrlParam.js';
import ResearchPopUp from '../SubNav/ui/ResearchPopUp';
import useGetHighlightingKeywords from '../hooks/useGetHighlighingKeywords.js';
import useCallCollectContent from '../hooks/useCallCollectContent.js';
import useCallRelatedStakeholders from '../key_updates/useCallStakeholders.js';
import useCreateResults from '../ListControl/useCreateResults.js';
import useGetMatchingKeywordsListsForContent from '../hooks/useMatchingKeywordsListsForContent.js';
import CustomScrollbar from '../../Common/CustomScrollbar.js';
import useGetAccessToken from '../apiCalls/useGetAccessToken.js';
import CommitteePublicationNewContent from './CommitteePublicationNewContent.js';

const CommitteePublicationContent = (props) => {
  const numberOfStakeHoldersToShow = parseInt(process.env.REACT_APP_RELATED_STAKEHOLDERS_TO_SHOW);
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const [heightContainer, containerRef] = useHeightContainer();
  const {
    contentResults,
    activeResults,
    net_api_url,
    search,
    activePlan,
    referenceState,
    keywordsLists,
    loadingCreateSummary,
  } = state;
  const { enableSummariser } = activePlan;
  const { searchState, activeSearch } = search;
  const { activeReference } = referenceState;
  const { CommitteePublications } = contentResults[activeResults];
  const currentNew = CommitteePublications?.new ?? '';
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRelatedContent, setIsLoadingRelatedContent] = useState(true);
  const location = useLocation();
  const params = useParams();
  const { id, type } = params;

  const navigate = useNavigate();
  const { getAccessToken } = useGetAccessToken();
  const { isAuthenticated } = useAuth0();

  const getIdPosition = getIdPositionOnSection(CommitteePublications, id);
  const createNavigationLinks = createNavigationLinksOnSection(CommitteePublications);

  const [idPosition, setIdPosition] = useState(getIdPosition());
  const [navigationLinks, setNavigationLinks] = useState(createNavigationLinks(getIdPosition()));
  const [isScrolling, setIsScrolling] = useState(false);
  const [updatingContribution, setUpdatingContribution] = useState(false);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  const barNavigationConditional =
    idPosition !== null && (navigationLinks.next !== null || navigationLinks.prev !== null);
  const topicIdParam = getUrlParam('topic-id');
  const { queryHighlight, andKeywords } = useGetHighlightingKeywords();
  const { callCollectContent } = useCallCollectContent();
  const { getMatchingKeywordsListsAndFeedback } = useGetMatchingKeywordsListsForContent();
  const { callRelatedStakeholders } = useCallRelatedStakeholders({});

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    // if (searchState) {
    setIdPosition(getIdPosition());
    setNavigationLinks(createNavigationLinks(getIdPosition()));
    loadNew(source);
    // }
    return () => {
      source.cancel('Current new cancelled by the user.');
    };
  }, [type, id, searchState, referenceState, topicIdParam]);

  const { load_more_results } = useCreateResults({ propsSectionName: 'CommitteePublications' });

  const detectIfLoadMoreResults = () => {
    if (getIdPosition() !== null && getIdPosition() === CommitteePublications.hits.length - 1) {
      return true;
    }
    return false;
  };

  const loadNew = async (source) => {
    try {
      setIsLoadingRelatedContent(true);
      setIsLoading(true);

      //LOAD THE LIST IF IT IS THE LAST ITEM
      let listResults;
      if (detectIfLoadMoreResults()) {
        listResults = await load_more_results(source);
        setNavigationLinks(createNavigationLinks(getIdPosition()));
      }

      let token = isAuthenticated ? await getAccessToken() : '';
      let contentRequest = {
        cancelToken: source.token,
      };
      if (isAuthenticated) {
        contentRequest.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      let newResult = await axios.get(`${net_api_url}/api/committee-publication/${id}`, contentRequest);
      const { contactIds, answerByContactId, questionByContactId, objectType, objectId } = newResult.data;

      const createHighlightProperties = (objectType) => {
        let propertiesHighlight = [objectId, objectType, queryHighlight, ChangeKeywordsExclusions(andKeywords), source];
        return propertiesHighlight;
      };
      let newData;
      if (activeSearch || activeReference || getUrlParam('search')) {
        const propertiesHighlight = createHighlightProperties(objectType);
        let contentHightlighted = await callHightlightContentAPI(propertiesHighlight);
        const { highlightedTitle, highlightedHtmlBody } = contentHightlighted;
        newData = {
          ...newResult.data,
          titleHighlighted: highlightedTitle ?? newResult.data.title,
          body: highlightedHtmlBody ?? newResult.data.body,
        };
      } else {
        newData = newResult.data;
      }

      let { feedback, keywordsFromLists, keywordsListsIds } = await getMatchingKeywordsListsAndFeedback({ objectId });
      newData = { ...newData, feedback, keywordsFromLists, keywordsListsIds };

      const updateResultsOnState = (newData) => {
        let data = {
          ...contentResults,
          [activeResults]: {
            CommitteePublications: newData,
          },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: data });
      };

      let contentObject = listResults?.[activeResults]?.CommitteePublications ?? CommitteePublications;
      let newSectionObject = contentObject;
      //REMOVE THE ITEM FROM THE LIST AND MARK AS READ
      let removeUnreadItem = updateUnreadItems({ idPosition, contentObject, getIdPosition });
      if (removeUnreadItem) {
        newSectionObject = removeUnreadItem;
      }
      let initialData = {
        ...newSectionObject,
        new: newData,
        currentId: parseInt(id),
      };
      updateResultsOnState(initialData);

      let { descriptionMeta } = newResult.data;
      let titleToShow = newResult.data.title;
      let title = `${titleToShow} - Committee publications - PolicyMogul`;
      let hash = '';
      let description = descriptionMeta;
      let contentUrl = `/parliamentary-record/${type}/${id}/${normalizeString(titleToShow)}`;
      addMetaTags({ title, hash, location, dispatch, contentUrl, description });
      setIsLoading(false);

      //CALLS NECESSARY TO DO AFTER THE CONTENT IS SHOWN TO SPEED UP THE PAGE
      let totalContributions;
      let contactIdInformation;
      let contentRelated = await callContentRelated(objectId, objectType, source);

      let callStakeConditional = contactIds?.length > 0 || !!answerByContactId || !!questionByContactId;
      let answersIds = [];
      let contactsIdsCalled = [];

      if (callStakeConditional) {
        let stakeholderFilters = [];
        for (var i = 0; i < numberOfStakeHoldersToShow; i++) {
          let contactId = contactIds?.[i];
          if (contactId !== undefined) {
            contactsIdsCalled.push(contactId);
            stakeholderFilters.push({ field: 'id', value: `${contactId}`, operator: 'str_eq' });
          }
        }
        let stakeHolderResults = await callRelatedStakeholders({ stakeholderFilters, source });
        let stakeholdersRendered = stakeHolderResults?.data?.hits;

        contentRelated = {
          ...contentRelated,
          hits: { ...contentRelated['hits'], StakeHolders: stakeholdersRendered },
        };
      }

      let relatedData = {
        ...initialData,
        totalContributions,
        contactIdInformation: contactIdInformation?.hits?.[0],
        contentRelated: contentRelated,
        relatedStakeholders: callStakeConditional
          ? (contactIds ?? answersIds).filter((id) => !contactsIdsCalled.includes(id))
          : null,
        relatedStakeholdersRendered: callStakeConditional ? contentRelated.hits.StakeHolders.length : 0,
      };
      updateResultsOnState(relatedData);
      setIsLoadingRelatedContent(false);

      await callCollectContent({ objectId, objectType, contentUrl });
    } catch (error) {}
  };
  const scrollBarRef = useRef(null);
  const parliamentaryContentContainer = useCallback((node) => {
    if (node !== null) {
      let links = document.querySelectorAll('.parliamentary-content-container .explanatory-container a');
      changeStakeholderLinks(links, navigate);
    }
  }, []);

  const contentContainer = useRef(null);

  const handleScroll = (scrollValues) => {
    const { scrollTop, scrollHeight, clientHeight } = scrollValues;
    const scrolledToBottom = scrollTop === scrollHeight - clientHeight;
    setReachedTheEnd(scrolledToBottom);

    if (CommitteePublications?.totalContributions) {
      let contributions = CommitteePublications.totalContributions.hits.map((item) => item.contributionId);
      let elements = document.querySelectorAll('.contribution');
      for (let i = 0; i < elements.length; i++) {
        let el = elements[i];
        const { top } = el.getBoundingClientRect();
        const value = contentContainer.current.getBoundingClientRect().top;
        if (top >= value && top < value + 100) {
          let contributionId = el.dataset.contributionId;
          if (contributionId && contributions.includes(contributionId) && !updatingContribution) {
            let className = 'debate-item-contributiondebateitem-highlighted';
            let currentElement = document.getElementsByClassName(`${className}`)[0];
            if (currentElement) {
              currentElement.classList.remove(className);
            }
            el.parentElement.classList.add(className);
            navigate(
              {
                search: location.search,
                hash: `contribution-${contributionId}`,
              },
              { replace: true }
            );
            break;
          }
        }
      }
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [keywordsSection, setKeywordsSection] = useState('keywords');
  const referencePopupProps = {
    showPopup,
    setShowPopup,
    keywordsSection,
    setKeywordsSection,
  };

  return (
    <>
      {isLoading && <SkeletonParliamentaryContent />}
      {!isLoading && (
        <>
          <div ref={containerRef}>
            <div className='row px-0 piece-content-container'>
              <div
                className={`general-content-column p-0`}
                style={{
                  zIndex: 100,
                }}
              >
                <div ref={contentContainer} />
                <div className='main-content-section content-container' style={{ height: `${heightContainer}px` }}>
                  <CustomScrollbar
                    className={'simple-scrollbar indented-scrollbar-small'}
                    style={{ height: `${heightContainer - (barNavigationConditional ? 43 : 0)}px` }}
                    minimalThumbYSize={55}
                    maximalThumbYSize={100}
                    ref={scrollBarRef}
                    onScroll={(scrollValues) => {
                      handleScroll(scrollValues);
                    }}
                    onScrollStart={() => {
                      setIsScrolling(true);
                    }}
                    onScrollStop={() => {
                      setIsScrolling(false);
                    }}
                  >
                    <div className='parliamentary-content-container' ref={parliamentaryContentContainer}>
                      <div className='main-update-content-container'>
                        <CommitteePublicationNewContent
                          currentNew={currentNew}
                          scrollBarRef={scrollBarRef}
                          contentContainer={contentContainer}
                          isScrolling={isScrolling}
                          setUpdatingContribution={setUpdatingContribution}
                          setIsLoading={setIsLoading}
                          hideSummary={enableSummariser !== undefined && !enableSummariser}
                        />
                      </div>
                    </div>
                  </CustomScrollbar>
                  <NavigationLinks
                    barNavigationConditional={barNavigationConditional}
                    navigationLinks={navigationLinks}
                    disabled={loadingCreateSummary}
                  />
                </div>
              </div>
              <div className='related-content-column show-lg px-0'>
                <ContentRelated
                  data={currentNew}
                  reachedTheEnd={reachedTheEnd}
                  content={CommitteePublications?.contentRelated ?? {}}
                  relatedStakeholders={CommitteePublications?.relatedStakeholders}
                  relatedStakeholdersRendered={CommitteePublications?.relatedStakeholdersRendered}
                  totalContributions={CommitteePublications?.totalContributions}
                  section={'parliamentary record'}
                  mainSection={'CommitteePublications'}
                  height={heightContainer}
                  isLoadingRelatedContent={isLoadingRelatedContent}
                  referencePopupProps={referencePopupProps}
                  keywordsListsIds={currentNew?.keywordsListsIds}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {showPopup && (
        <ResearchPopUp
          keywordsLists={keywordsLists.filter((item) => item.id !== null)}
          identifier={'research-popup'}
          setShowPopup={setShowPopup}
          keywordsSection={keywordsSection}
          referenceState={referenceState}
        />
      )}
    </>
  );
};

export default CommitteePublicationContent;
