import React, { useState, useRef } from 'react';
import useOutsideElement from '../Dashboard/utilities/useOutsideElement';
import TransparentBackgroundToPreventClick from '../Dashboard/components/TransparentBackgroundToPreventClick';
import useIsMobile from '../Dashboard/utilities/useIsMobile';
import { Election2024Links, ElectionSideBar, PoliticalLandscapeLinks } from './Election2024';
import { useLocation } from 'react-router-dom';

const ElectionSectionTitle = (props) => {
  const [showBox, setShowBox] = useState(false);
  const actionsElement = useRef(null);
  const { title, enableDropdown } = props;
  const hideActions = () => {
    setShowBox(false);
  };
  const isMobile = useIsMobile();
  const showItemDropdown = isMobile && enableDropdown;

  useOutsideElement(actionsElement, hideActions);

  const location = useLocation();

  const getTitleFromLocation = () => {
    const links = [...Election2024Links, ...PoliticalLandscapeLinks];
    const currentPage = links.find((item) => location.pathname.endsWith(item.path));
    return currentPage?.label;
  };

  return (
    <div className={`main-content election-section-title position-relative`}>
      <TransparentBackgroundToPreventClick popupOpened={showBox} />
      <div className='section-title-left-side' ref={actionsElement}>
        <div>
          <div
            className={`my-lg-0 main-title mb-0 ${showItemDropdown ? 'd-flex justify-content-center' : ''}`}
            onClick={() => {
              if (showItemDropdown) {
                setShowBox(!showBox);
              }
            }}
          >
            <h3
              className={`${
                showItemDropdown
                  ? 'title-h2-bold dropdown-item-element pointer dropdown-item-element-main-title'
                  : 'title-h3-bold'
              } ${showBox ? 'open' : ''}`}
            >
              {title ?? getTitleFromLocation()}
            </h3>
          </div>
          {showBox && (
            <>
              <div className='position-relative'>
                <ElectionSideBar closeMenu={hideActions} mobile />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElectionSectionTitle;
