import React, { useContext, useState } from 'react';
import { store } from '../../Store.js';
import axios from 'axios';
import createNotification from '../Utilities/CreateNotification';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall.js';

const PauseEmailAlert = (props) => {
  const { item, disabled, pauseEmailAlert, teamId } = props;
  const { pauseAlert } = usePauseEmailAlert();
  const [loading, setLoading] = useState(false);

  const pauseFunction = async (item, value, teamId) => {
    if (!disabled) {
      setLoading(true);
      if (value === 'on' && item.paused) {
        if (pauseEmailAlert) {
          await pauseEmailAlert(item, teamId);
        } else {
          await pauseAlert({ value: false, item, teamId });
        }
      }
      if (value === 'off' && !item.paused) {
        if (pauseEmailAlert) {
          await pauseEmailAlert(item, teamId);
        } else {
          await pauseAlert({ value: true, item, teamId });
        }
      }
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className={`mr-2 mr-sm-0 ml-sm-2 general-button outline-button main-light-text paragraph-p3 rounded-button email-alerts-button-padding d-flex justify-content-between`}
        onClick={(e) => {
          e.stopPropagation();
          pauseFunction(item, item.paused ? 'on' : 'off', teamId);
        }}
        disabled={loading}
      >
        {item.paused || disabled ? 'Paused' : 'Active'}
        <span className={`ml-2 toggle-button-${item.paused || disabled || loading ? 'inactive' : 'active'}`}>
          <span className='toggle-button'></span>
        </span>
      </button>
    </>
  );
};

export default PauseEmailAlert;

export const usePauseEmailAlert = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { emailAlerts, clientsAlerts, activePlan } = state;
  const { enableHandpickedContent } = activePlan;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const { generalApiCall } = useGeneralApiCall();

  const pauseAlert = async ({ value, item, teamId }) => {
    const { frequency, contentTypesToReceive, sendEmbargoedKeyUpdatesImmediately } = item ?? {};
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'IsSavingKeywordList',
      value: true,
    });
    const pathname = '/api/alert-settings/update';
    const method = 'post';

    let requestProperties = {
      ...item,
      paused: value,
      sendEmbargoedKeyUpdatesImmediately:
        frequency !== 'Immediate' && enableHandpickedContent && contentTypesToReceive?.includes('KeyUpdates')
          ? sendEmbargoedKeyUpdatesImmediately
          : null,
    };
    let response = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
    });
    if (!!response) {
      let clientPosition;
      if (teamId) {
        clientPosition = clientsAlerts?.findIndex((item) => item.teamId === teamId);
      }

      if (clientPosition >= 0) {
        let newClientsAlerts = [...clientsAlerts];
        let newClient = { ...clientsAlerts[clientPosition] };
        let alerts = [...newClient?.alertSettings];
        let position = alerts?.findIndex((alert) => alert.id === item?.id);
        alerts[position] = response;
        newClient = {
          ...newClient,
          alertSettings: alerts,
        };
        newClientsAlerts[clientPosition] = newClient;
        dispatch({ type: 'MODIFY_SECTION', parameter: 'clientsAlerts', value: newClientsAlerts });
      }

      let newEmailAlerts = [...emailAlerts];
      let position = emailAlerts.findIndex((alert) => alert.id === item.id);
      newEmailAlerts[position] = response;
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'emailAlerts',
        value: newEmailAlerts,
      });
      let notificationMessage = `Email alert has been ${value ? 'paused' : 'enabled'}`;
      createNotification('success', notificationMessage);
    }
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'IsSavingKeywordList',
      value: false,
    });
    return response;
  };
  return { pauseAlert };
};
