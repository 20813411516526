import React, { useEffect, useState, useRef } from 'react';
import Embed from './EmbedContent';
import axios from 'axios';
import { net_api_url } from '../Store';
import { useLocation } from 'react-router-dom';
import GeneralTooltips from '../Dashboard/components/GeneralTooltips';
import normalize from '../Dashboard/utilities/normalizeString';
import highlightWidget from '../Dashboard/apiCalls/callHighlightWidget';
import ReactGA from 'react-ga4';
import createTypeOfResource from '../Dashboard/helpers/createTypeOfResource';
import createFiltersForTypes from '../Dashboard/helpers/createFiltersForTypes';
import { useHeightContainer } from '../Dashboard/utilities/useHeightContainer';
import validateParameter from '../../utils/validateParameter';
import StakeHolderSharePopUp from '../Dashboard/StakeHolders/StakeHolderSharePopUp';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import Preloader from '../Dashboard/Analytics/ui/AnalyticsDashboard/Preloader';
import * as Sentry from '@sentry/react';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
const JSONBigInt = require('json-bigint');

const EmbedPage = (props) => {
  const {
    keywordsLists,
    resources: propsResources,
    uponClickOption,
    isFromPopup,
    userId: propsUserId,
    customResults,
    customListName,
    designState,
    variableWidth,
    headerState,
    selectedTopics,
  } = props;
  const { headerValues, setHeaderValues } = headerState ?? {};
  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState({});
  const [switchingResults, setSwitchingResults] = useState(false);
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';
  const { agencyUserBase } = useTopicOrClientWord();

  const embedState = {
    topicsIds: selectedTopics
      ? selectedTopics?.map((item) => item?.id)
      : getUrlParam('topicsIds')
        ? validateParameter('topicsIds')
            ?.split(',')
            ?.map((item) => parseInt(item))
        : getUrlParam('keywordListId')
          ? [validateParameter('keywordListId')]
          : null,
    resources: propsResources ?? validateParameter('resources'),
    openItem: uponClickOption ?? validateParameter('openItem'),
    userId: propsUserId ?? parseInt(validateParameter('userId')),
    headerColour:
      designState?.headerColour ??
      (!!validateParameter('headerColour') ? validateParameter('headerColour') : '#ffffff'),
    background:
      designState?.backgroundColour ??
      (!!validateParameter('backgroundColour') ? validateParameter('backgroundColour') : '#ffffff'),
    includeBranding:
      designState?.includeBranding ??
      (validateParameter('includeBranding') !== '' ? validateParameter('includeBranding') : true),
    barColour:
      designState?.barColour ?? (!!validateParameter('barColour') ? validateParameter('barColour') : '#f9f9f9'),
    headerText:
      headerValues?.header ??
      (!!validateParameter('headerText') ? validateParameter('headerText') : `Latest political updates`),
    subheaderText:
      headerValues?.subheader ??
      (!!validateParameter('subheaderText')
        ? validateParameter('subheaderText')
        : `Showing the latest political developments ${agencyUserBase ? 'relevant to' : 'related to'} ${results?.teamName ?? ''}`),
    teamId: props?.teamId ?? validateParameter('teamId'),
  };

  const {
    topicsIds,
    resources,
    openItem,
    userId,
    headerColour,
    background,
    barColour,
    includeBranding,
    headerText,
    subheaderText,
    teamId,
  } = embedState;

  const { generalApiCall } = useGeneralApiCall();
  const [mainIsLoading, setMainIsLoading] = useState(!!teamId && !!topicsIds);
  const [widgetKeywordsLists, setWidgetKeywordsLists] = useState(
    getUrlParam('keywordListId')
      ? [{ id: parseInt(getUrlParam('keywordListid')) }]
      : keywordsLists
        ? keywordsLists.filter((list) => topicsIds.includes(list.id))
        : []
  );
  const [topic, setTopic] = useState(
    getUrlParam('keywordListId')
      ? { id: parseInt(getUrlParam('keywordListId')) }
      : keywordsLists
        ? keywordsLists.find((item) => parseInt(item.id) === parseInt(topicsIds[0]))
        : {}
  );
  const includeBrandingProperty = typeof includeBranding === 'string' ? includeBranding === 'true' : includeBranding;

  useEffect(() => {
    if (keywordsLists) {
      setWidgetKeywordsLists(keywordsLists.filter((list) => topicsIds.includes(list.id)));
      setTopic(keywordsLists.find((item) => parseInt(item.id) === parseInt(topicsIds[0])));
    }
  }, [selectedTopics]);

  const createContentType = () => {
    if (location.search !== '' || resources !== '') {
      let resourcesToCall = resources.split(',');
      if (!showTweets) {
        let tweetsPosition = resourcesToCall.indexOf('Tweets');
        if (tweetsPosition >= 0) {
          resourcesToCall.splice(tweetsPosition, 1);
        }
      }
      return resourcesToCall[0];
    }
    return '';
  };

  const [typeOfContent, setTypeOfContent] = useState(createContentType());
  const [content, setContent] = useState(null);
  const scrollbarContentContainer = useRef();

  useEffect(() => {
    loadLists.current();
    document.getElementsByTagName('body')[0].classList.add('embed-widget-body');
    ReactGA.initialize(`${process.env.REACT_APP_WIDGET_ANALYTICSCODE}`);
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    let finalResources = !!resources ? resources?.split(',') : [];
    if (!showTweets) {
      let tweetsPosition = finalResources.indexOf('Tweets');
      if (tweetsPosition >= 0) {
        finalResources.splice(tweetsPosition, 1);
      }
    }
    if (!!resources && finalResources !== typeOfContent) {
      setTypeOfContent(finalResources[0]);
    }
  }, [resources]);

  const parliamentarianId = parseInt(validateParameter('parliamentarianId'));
  const appgId = parseInt(validateParameter('appgId'));

  const loadLists = useRef();
  loadLists.current = async () => {
    try {
      if (teamId && topicsIds) {
        setMainIsLoading(true);
        if (!keywordsLists) {
          const pathname = `/api/widget/team-topics-for-analytics?teamId=${teamId}&topicIds=${topicsIds.join(',')}`;
          const method = 'get';
          const results = await generalApiCall({ pathname, method, notShowErrorMessage: true });
          if (!!results) {
            let lists = results.map((item) => {
              return { ...item, name: item.label, id: item.value };
            });
            setWidgetKeywordsLists(lists);
            setTopic(lists.find((item) => parseInt(item.id) === parseInt(topicsIds[0])));
            setMainIsLoading(false);
          }
        } else {
          setMainIsLoading(false);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const apiCallPerContent = async (type, pageNumber, sourceProp) => {
    let url = `${net_api_url}/api/widget/query`;
    let requestOptions = {
      transformResponse: [(data) => data],
      cancelToken: sourceProp?.token ?? source.token,
    };

    let requestBody = {
      type: type,
      pageNumber: pageNumber ?? 1,
      pageSize: type === 'Event' ? 10000 : pageSize,
    };

    if (topic && !userId) {
      requestBody = { ...requestBody, keywordListId: topic?.id };
    }

    const { filters, sort } = createFiltersForTypes(type);

    requestBody.sort = sort;

    if (type === 'ParliamentaryRecord') {
      requestBody.collapseField = 'contentUrl';
    }

    if (filters) {
      requestBody.filters = filters;
    }

    if (userId) {
      if (!(type.toLowerCase() === 'event' || type.toLowerCase() === 'consultation')) {
        requestBody = {
          ...requestBody,
          userId,
          sort: {
            field: 'feedItemId',
            order: 'DESC',
          },
        };
      } else {
        requestBody = { ...requestBody, userId };
      }
      if (teamId) {
        requestBody = { ...requestBody, teamId };
      }
    }

    if (parliamentarianId) {
      requestBody = { ...requestBody, parliamentarianId };
    }

    if (appgId) {
      requestBody = { ...requestBody, appgId };
    }

    try {
      let results = await axios.post(url, requestBody, requestOptions);
      let finalResults = !!results ? JSONBigInt.parse(results.data) : null;
      if (!!finalResults?.highlights) {
        finalResults?.hits.forEach((item, index) => {
          item.highlights = finalResults?.highlights[index];
          item.keywordListId =
            userId && finalResults?.matchedKeywordListIds[index] ? finalResults?.matchedKeywordListIds[index] : null;
        });
      }
      return finalResults;
    } catch (error) {}
  };

  const load_more_results = async () => {
    if (results) {
      let { totalHits, pageNumber } = results;
      let number = pageNumber * pageSize;
      if (number < totalHits) {
        let newResults = await apiCallPerContent(createTypeOfResource(typeOfContent), pageNumber + 1);
        const resultsJoined = results?.hits.concat(newResults.hits);
        const matchedKeywordListIdsJoined = results?.matchedKeywordListIds?.concat(
          newResults.matchedKeywordListIdsJoined
        );

        let finalResults = resultsJoined?.map((item, index) => {
          return {
            ...item,
            keywordListId: !!userId && matchedKeywordListIdsJoined[index] ? matchedKeywordListIdsJoined[index] : null,
          };
        });
        if (newResults) {
          setResults({
            ...results,
            hits: finalResults,
            explanations: results.explanations.concat(newResults.explanations),
            pageNumber: newResults.pageNumber,
          });
        }
      }
    }
  };

  const scrollbarContainer = useRef();

  const clickResources = async (item) => {
    if (scrollbarContainer.current) {
      scrollbarContainer.current.scrollToTop();
      scrollbarContainer.current.scrollToLeft();
    }
    source.cancel('Resources cancelled');
    setSwitchingResults(true);
    setTypeOfContent(item);
    //setSwitchingResults(false);
  };

  const transformParliamentaryRecordApi = ({ contentType }) => {
    if (contentType) {
      switch (contentType) {
        case 'QuestionAndAnswer':
          return 'question-and-answer';
        case 'ScotlandCountrySpecificParliamentaryContribution':
          return 'country-specific-parliamentary-record/scotland';
        case 'WalesCountrySpecificParliamentaryContribution':
          return 'country-specific-parliamentary-record/wales';
        case 'ScotlandQuestionAndAnswer':
          return 'country-specific-question-and-answer/scotland';
        case 'WalesQuestionAndAnswer':
          return 'country-specific-question-and-answer/wales';
        case 'NorthernIrelandQuestionAndAnswer':
          return 'country-specific-question-and-answer/northernireland';
        case 'Edm':
          return 'edm';
        case 'SeneddCommitteeTranscriptContribution':
          return 'senedd-committee-transcript';
        default:
          return 'parliamentaryrecord';
      }
    }
  };

  const createFunctionOnClick = (item) => {
    return async (e) => {
      e.preventDefault();
      if (openItem === 'withinEmbed' && results.openContentInWidgetAllowed) {
        //specificContentApiCall({ item });
        setContent({ isLoading: true, item });
      } else {
        window.open(
          `${item.contentUrl}?utm_source=${normalize(results.teamName)}-${normalize(results.topicName)}-embed-widget&utm_medium=referrer${item.contentType === 'ParliamentaryContribution' ? `#contribution-${item.contributionId}` : ''}`
        );
      }
    };
  };

  const specificContentApiCall = async ({ item, source }) => {
    try {
      if (item) {
        const TransformApi = (type) => {
          switch (type) {
            case 'Legislation':
              return `${item.contentType.toLowerCase()}legislation`;
            case 'ParliamentaryRecord':
              return transformParliamentaryRecordApi({ contentType: item?.contentType });
            case 'CommonsLibraryArticle':
              return 'commons-library-article';
            default:
              return type.toLowerCase();
          }
        };

        const FirstChildToHighlight = (type) => {
          switch (type) {
            case 'Legislation':
              return item.contentType === 'Primary' ? 'billTitleHighlighted' : 'titleHighlighted';
            case 'KeyUpdate':
              return 'subjectHighlighted';
            case 'ParliamentaryRecord':
              return item.contentType.includes('QuestionAndAnswer') ? 'questionForHighlighted' : 'titleHighlighted';
            default:
              return 'titleHighlighted';
          }
        };

        const SecondChildToHighlight = (type, currentNew) => {
          switch (type) {
            case 'Legislation':
              return item.contentType === 'Primary' ? 'summary' : 'explanatoryNote';
            case 'ParliamentaryRecord':
              return item.contentType.includes('QuestionAndAnswer')
                ? 'questionText'
                : `${currentNew?.content ? 'content' : 'body'}`;
            default:
              return 'body';
          }
        };

        setContent(!!content ? { ...content, isLoading: true } : { isLoading: true });
        let contentRequest = {
          cancelToken: source?.token,
        };

        let currentNew = await axios.get(
          `${net_api_url}/api/${TransformApi(item.type)}/${item.id}${item?.type === 'KeyUpdate' && !includeBrandingProperty ? '?removeRelativeLinks=true' : ''}`,
          contentRequest
        );

        const { objectId, objectType } = currentNew.data;
        let objectTypeToApi = item.contentType?.includes('QuestionAndAnswer') ? 'WrittenQuestion' : objectType;
        let handpickedTopic = item.type === 'KeyUpdate' && results.handPickedTopic;
        let highlightedData;
        if (!handpickedTopic) {
          highlightedData = await highlightWidget(
            objectTypeToApi,
            objectId,
            item?.keywordListId ?? topic?.id,
            includeBrandingProperty
          );
        }

        let highlightedAnswer;
        if (currentNew.questionHasAnswer) {
          highlightedAnswer = await highlightWidget('WrittenAnswer', objectId, item?.keywordListId ?? topic?.id);
        }
        let newData = { ...currentNew.data };

        if (!!highlightedData) {
          if (!!highlightedData.highlightedTitle) {
            newData[FirstChildToHighlight(item.type)] = highlightedData.highlightedTitle;
          }
          if (!!highlightedData.highlightedHtmlBody) {
            newData[SecondChildToHighlight(item.type, newData)] = highlightedData.highlightedHtmlBody;
          }
        }

        //AE: THIS PART ONLY IS NECESSARY FOR QUESTIONS AND ANSWERS
        if (!!highlightedAnswer) {
          if (!!highlightedAnswer.highlightedHtmlBody) {
            newData['answerText'] = highlightedAnswer.highlightedHtmlBody;
          }
        }
        setContent({
          item: content?.item,
          isLoading: false,
          currentNew: newData,
          contributionId: item.contentType === 'ParliamentaryContribution' ? item.contributionId : null,
        });
      }
    } catch (error) {}
  };

  const createResources = () => {
    const tweetsEnabled = !!results ? results.tweetsEnabled : false;
    let finalResources = resources;
    if ((resources.includes('Tweets') && !tweetsEnabled) || !showTweets) {
      finalResources = resources.replace('Tweets', '');
    }
    return (!!results && Object.keys(results).length > 0) || !!customResults ? finalResources : '';
  };

  const clickOnArrow = (position) => {
    const resources = createResources();
    const items = resources.split(',');
    clickResources(items[position]);
  };

  const [heightContainer, containerRef] = useHeightContainer();
  const createTopicName = () => {
    if (customListName) {
      return customListName;
    } else {
      return !!results ? (userId ? results.teamName : results.topicName) : '';
    }
  };

  return (
    <>
      <div ref={containerRef} style={{ height: isFromPopup ? '100%' : `${heightContainer - 2}px` }}>
        {mainIsLoading ? (
          <div className='flex-centered justify-content-center bg-white w-100 h-100'>
            <Preloader />
          </div>
        ) : (
          <Embed
            scrollbarContainer={scrollbarContainer}
            resources={createResources()}
            clickResources={clickResources}
            isLoading={!!customResults ? false : isLoading}
            results={results}
            typeOfContent={typeOfContent}
            topicName={createTopicName()}
            load_more_results={load_more_results}
            createFunctionOnClick={createFunctionOnClick}
            content={content}
            setContent={setContent}
            clickOnArrow={clickOnArrow}
            setIsLoading={setIsLoading}
            setResults={setResults}
            apiCallPerContent={apiCallPerContent}
            source={source}
            isFromPopup={isFromPopup}
            customResults={customResults}
            scrollbarContentContainer={scrollbarContentContainer}
            design={{
              headerColour,
              background,
              barColour,
              includeBranding: results?.removeBrandingAllowed ? includeBranding : true,
            }}
            variableWidth={variableWidth}
            header={{
              headerText,
              subheaderText,
              headerValues,
              setHeaderValues,
            }}
            widgetKeywordsLists={widgetKeywordsLists}
            topicState={{ topic, setTopic }}
            selectedTopics={selectedTopics ?? widgetKeywordsLists}
            userId={propsUserId}
            switchingResultsState={{ switchingResults, setSwitchingResults }}
            includeBrandingProperty={includeBrandingProperty}
            specificContentApiCall={specificContentApiCall}
          />
        )}
      </div>
      {location.pathname.startsWith('/embed') && <StakeHolderSharePopUp />}
      <GeneralTooltips />
    </>
  );
};

export default EmbedPage;
