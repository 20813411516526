import React, { useState, useEffect, useContext } from 'react';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import axios from 'axios';
import { store } from '../../Store.js';
import { Link, useLocation } from 'react-router-dom';
import getCurrentTeam from '../Team/getCurrentTeam';
import getActivePlan from '../Utilities/getActivePlan';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import createNotification from '../Utilities/CreateNotification';
import SkeletonBilling from './SkeletonBilling';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import getCurrentUser from '../Account/getCurrentUser';
import TotalPlanTable from '../../Pricing/TotalPlanTable';
import { AddOrRemoveItemsController } from '../../Pricing/PopUpCheckout';
import useUpdatePaidSubscriptions from './useUpdatePaidSubscriptions';
import { formatGeneralDate } from '../../Dashboard/helpers/formatGeneralDate';
import { useCollapse } from 'react-collapsed';
import callBillingPortal from '../../Dashboard/apiCalls/callBillingPortal';
import CustomScrollbar from '../../Common/CustomScrollbar.js';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';

const Billing = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { userProfile, activeSubscriptions, crmContactsQtty, activePlan: activePlanFromState, team } = state;
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const additionalCrmContacts = Number(query.get('additionalCrmContacts'));

  const {
    maxListsPerTeam,
    planName,
    subscriptionStatus,
    billingPlanName,
    totalCrmContacts,
    isPaidFor,
    isPaperInvoicing,
    includedCrmContacts,
    isLegacyPlan,
    billingPlanCode,
    scheduledSubscription,
  } = activeSubscriptions;
  const [topicsSelected, setTopicsSelected] = useState(maxListsPerTeam ?? 0);
  const [contactsSelected, setContactsSelected] = useState(
    !!additionalCrmContacts ? additionalCrmContacts + totalCrmContacts : totalCrmContacts
  );
  const [billingPlanPricing, setBillingPlanPricing] = useState({});

  const [loaders, setLoaders] = useState({ Manage: false, update: false });
  const { myself } = team;

  const { getAccessToken } = useGetAccessToken();

  const { transformTopicOrClientWord, agencyUser } = useTopicOrClientWord();
  const [heightContainer, containerRef] = useHeightContainer();
  const { updatePaidSubscriptionCall, bannerText, getPlanPricing } = useUpdatePaidSubscriptions();

  useEffect(() => {
    const loadResults = async (source) => {
      try {
        await loadInitialInformation(source);
      } catch (error) {}
    };
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadResults(source);
    return () => {
      source.cancel('Billing canceled by the user.');
    };
  }, []);

  const loadInitialInformation = async (source) => {
    try {
      setIsLoading(true);
      let currentTeam = await getCurrentTeam(getAccessToken, source);
      let activePlan = await getActivePlan(getAccessToken, source);
      let currentUserProfile = userProfile;
      let billingPlanPricing;
      if (billingPlanCode) {
        billingPlanPricing = await getPlanPricing({ billingPlanCode });
      }

      if (!!userProfile) {
        currentUserProfile = await getCurrentUser(getAccessToken, source);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userProfile', value: currentUserProfile });
      }
      if (billingPlanPricing) {
        setBillingPlanPricing(billingPlanPricing);
      }
      if (activePlan !== undefined) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
      }
      if (currentTeam) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });

        let title = `Billing - Settings - PolicyMogul`;
        document.title = title;
        let locationToStorage = {
          title: title,
          storageLocation: { ...location },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
        setIsLoading(false);
      }
    } catch (error) {
      createNotification('danger', `An error ocurred.`);
    }
  };

  const callBillingPortalCall = async (plan, source) => {
    setLoaders({ ...loaders, Manage: true });
    try {
      let token = await getAccessToken();
      await callBillingPortal(token, location, source, plan);
    } catch (error) {}
  };

  const userConditionalComparePlans = () => {
    return (
      myself.role === 'Owner' &&
      subscriptionStatus !== 'OnFreePlan' &&
      subscriptionStatus !== 'OnFreePlanOverLimit' &&
      planName !== 'Enterprise'
    );
  };

  const updatePaidSubscription = async () => {
    setLoaders({ ...loaders, update: true });
    let response = await updatePaidSubscriptionCall({ topicsSelected, contactsSelected, includedCrmContacts });
    if (response) {
      let activeSubscription = response[0];
      const { maxListsPerTeam, totalCrmContacts } = activeSubscription ?? {};
      dispatch({ type: 'MODIFY_SECTION', parameter: 'activeSubscriptions', value: activeSubscription });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'activePlan',
        value: {
          ...activePlanFromState,
          totalCrmContacts: activeSubscription?.totalCrmContacts,
          enableCrm: activeSubscription?.enableCrm,
        },
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'contactsOversubscribed',
        value: activeSubscription?.totalCrmContacts - crmContactsQtty,
      });
      setTopicsSelected(maxListsPerTeam ?? 0);
      setContactsSelected(totalCrmContacts);
      createNotification('success', 'Plan updated successfully');
    }
    setLoaders({ ...loaders, update: false });
  };

  const conditionToEdit = isPaidFor && planName !== 'Enterprise';
  const bannerConditions =
    isPaperInvoicing ||
    planName === 'Enterprise' ||
    subscriptionStatus === 'SubscriptionEnded' ||
    subscriptionStatus === 'OnFreePlan' ||
    subscriptionStatus === 'OnFreePlanOverLimit' ||
    myself?.role !== 'Owner';

  const createButtonText = () => {
    if (subscriptionStatus === 'SubscriptionEnded') {
      return 'Reactivate your plan';
    } else if (isLegacyPlan || isPaperInvoicing || planName === 'Enterprise') {
      return 'See pricing page';
    } else {
      return 'Choose your plan';
    }
  };

  return (
    <>
      {isLoading && <SkeletonBilling />}
      {!isLoading && (
        <div className='pt-lg-5 billing-page-main-container'>
          <div className='settings-box'>
            {bannerConditions && (
              <div className='px-lg-5 px-3 py-4 team-upgrade-message'>
                <p className='m-0'>
                  {bannerText()}.{' '}
                  {subscriptionStatus !== 'SubscriptionEnded' && myself?.role === 'Owner' && (
                    <>
                      <span className='contact-button-launch-messenger pointer text-yellow text-underline-hover'>
                        {' '}
                        Contact us
                      </span>{' '}
                      to amend your subscription.
                    </>
                  )}
                </p>
              </div>
            )}

            <div className='pt-4 px-3 px-lg-5 row mx-0 justify-content-between align-items-center pb-3'>
              <h3 className='main-title topics-title mb-0'>Billing</h3>
              {userConditionalComparePlans() && (
                <>
                  {subscriptionStatus === 'Subscribed' && (
                    <>
                      <a href={`/pricing`} target='_blank' rel='noopener noreferrer'>
                        <button className='general-button py-2 px-4 outline-active-button mr-3 mb-2 mb-lg-0'>
                          {'See pricing page'}
                        </button>
                      </a>
                    </>
                  )}
                  {subscriptionStatus !== 'Subscribed' && (
                    <Link to={`/pricing?returnUrl=${encodeURIComponent(location.pathname)}`}>
                      <button className='general-button py-2 px-4 billing-button action-button'>
                        {createButtonText()}
                      </button>
                    </Link>
                  )}
                </>
              )}
            </div>
            <div ref={containerRef} />
            <CustomScrollbar
              className={'main-content-scrollbar setting-scrollbar'}
              style={{ height: `${heightContainer}px` }}
              maximalThumbYSize={100}
            >
              <div className='px-3 px-lg-5 '>
                <div className='mb-5 pt-2'>
                  <p className='mb-2'>
                    You are currently subscribed to:{' '}
                    <strong>
                      {planName} plan
                      {subscriptionStatus === 'InTrial' || subscriptionStatus === 'TrialEnded' ? ' trial' : ''}
                      {billingPlanName ? `, ${billingPlanName.toLowerCase()}` : ''}
                    </strong>
                  </p>
                </div>
                <div className='row mx-0 pb-5'>
                  <div className='col-lg-8 pl-0 billing-page-select'>
                    <div
                      className={`flex-centered pb-5 ${!conditionToEdit || bannerConditions ? 'billing-select-disabled' : ''}`}
                    >
                      <p className='mb-0 billing-item-select'>
                        {transformTopicOrClientWord({ plural: true, uppercase: true })} {agencyUser ? '' : 'to monitor'}
                        {maxListsPerTeam === 1 && !agencyUser && (
                          <span
                            className='icon-info main-light-text d-inline-block pointer'
                            data-tooltip-content={`Upgrade to Mogul or Enterprise for multiple ${transformTopicOrClientWord({ plural: true })}`}
                            data-tooltip-id={'settings-tooltip'}
                            data-tooltip-class-name={'pricing-feature-tooltip feed-tooltip billing-tooltip'}
                          />
                        )}
                      </p>
                      {maxListsPerTeam === null ? (
                        <p className='border mb-0 main-subtle-text rounded px-4 py-1'>
                          <span className='px-2'>Unlimited</span>
                        </p>
                      ) : (
                        <AddOrRemoveItemsController
                          value={topicsSelected}
                          setValue={setTopicsSelected}
                          defaultValue={billingPlanPricing?.includedTopics ?? 3}
                          tooltipPlace={'top'}
                          disabled={
                            !conditionToEdit || planName === 'Starter' || planName === 'Campaigner' || bannerConditions
                          }
                        />
                      )}
                    </div>
                  </div>
                  {((maxListsPerTeam !== null && topicsSelected !== maxListsPerTeam) ||
                    contactsSelected !== totalCrmContacts) &&
                    billingPlanName !== null && (
                      <div className='col-lg-8 px-0 pt-5 pt-lg-0 px-lg-5 billing-page-calculation'>
                        <TotalPlanTable
                          keywordsCategories={topicsSelected}
                          defaultCategories={billingPlanPricing?.includedTopics ?? 3}
                          targetPlan={planName}
                          period={billingPlanPricing?.recurringInterval === 'year' ? 'annually' : 'monthly'}
                          contactsSelected={contactsSelected}
                          defaultContacts={includedCrmContacts}
                          customTopicPrice={billingPlanPricing?.additionalTopicUnitPrice}
                          basePlanFlatPrice={billingPlanPricing?.basePlanFlatPrice}
                        />
                        <button
                          className='general-button action-button px-4 general-spacing-button'
                          onClick={() => {
                            updatePaidSubscription();
                          }}
                          disabled={loaders.update}
                        >
                          {loaders.update ? (
                            <i
                              className='fas fa-spinner fa-spin mr-2 d-inline-block'
                              style={{ verticalAlign: 'middle', marginTop: '-4px' }}
                            ></i>
                          ) : (
                            ''
                          )}
                          Confirm changes
                        </button>
                      </div>
                    )}
                </div>
                {!!scheduledSubscription && <ScheduledDowngrade scheduledSubscription={scheduledSubscription} />}
              </div>

              {subscriptionStatus !== 'OnFreePlan' && myself?.role === 'Owner' && (
                <div className='border-top border-bottom py-4 px-3 px-lg-5 '>
                  <button
                    className='simple-link-button general-button text-left'
                    disabled={loaders.Manage}
                    onClick={() => {
                      callBillingPortalCall();
                    }}
                  >
                    {loaders.Manage ? (
                      <i
                        className='fas fa-spinner fa-spin mr-2 d-inline-block'
                        style={{ verticalAlign: 'middle', marginTop: '-4px' }}
                      ></i>
                    ) : (
                      ''
                    )}
                    Download invoices, change payment methods and manage your subscription
                  </button>
                </div>
              )}
            </CustomScrollbar>
          </div>
          <ReactTooltip
            id={'billing-tooltip'}
            effect={'solid'}
            type={'info'}
            className={'pricing-feature-tooltip'}
            backgroundColor={'#00122B'}
            textColor={'#fff'}
            multiline={true}
            arrowColor={'transparent'}
            place={'right'}
          />
        </div>
      )}
    </>
  );
};

const ScheduledDowngrade = (props) => {
  const { scheduledSubscription } = props;
  const { dateTimeEffective, planName, maxListsPerTeam, totalCrmContacts, billingPlanName } = scheduledSubscription;
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const { agencyUser, transformTopicOrClientWord } = useTopicOrClientWord();
  const unlimitedTopics = maxListsPerTeam === null;
  return (
    <>
      <div className='border rounded px-3 mb-5 bg-light-red-40 main-subtle-text'>
        <p className='py-2 mb-0'>
          You have a scheduled downgrade on{' '}
          <span className='font-weight-bold'>{formatGeneralDate(dateTimeEffective)}.</span>{' '}
          <span
            className='simple-link-button'
            {...getToggleProps({
              onClick: () => setExpanded((prevExpanded) => !prevExpanded),
            })}
          >
            {isExpanded ? 'Hide' : 'See'} details
          </span>
        </p>
        <div className='' {...getCollapseProps()}>
          <p className='mb-2'>On {formatGeneralDate(dateTimeEffective)} your plan will change to:</p>
          <ul className='policy-list px-0'>
            <li className='mb-1'>
              - {planName} plan{billingPlanName ? `, ${billingPlanName.toLowerCase()}` : ''}
            </li>
            <li className='mb-1'>
              - {unlimitedTopics ? 'unlimited' : maxListsPerTeam}{' '}
              {transformTopicOrClientWord({ plural: !unlimitedTopics && maxListsPerTeam > 1 })}{' '}
              {agencyUser ? '' : 'to monitor'}
            </li>
            <li>- {totalCrmContacts} CRM contacts</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Billing;
