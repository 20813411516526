import { useAuth0 } from '@auth0/auth0-react';
import { useRef } from 'react';
import useOutsideHoverElement from '../../Dashboard/hooks/useOutsideHoverElement';
import useSearchBarUiFunctions from '../../Dashboard/hooks/useSearchBarUiFunctions';
import AddTopicButton from '../../Dashboard/Navigation/AddTopicButton';
import TopicItem from './TopicItem';
import TopicListNoAuthenticatedUsers from './TopicListNoAuthenticatedUsers';
import { useLocation } from 'react-router-dom';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';

const TopicPickerList = (props) => {
  const location = useLocation();
  const {
    keywordsLists = [],
    applyTopic,
    inputSearch,
    showDropdownContent,
    topic,
    searchValue,
    showTopicSelected,
    dataTooltip,
    showActions,
  } = props;
  const widget = location.pathname.startsWith('/embed-analytics');
  const { isAuthenticated } = useAuth0();

  const keywordListsElement = useRef(null);
  const scrollContainerElement = useRef(null);
  const topicsListElement = useRef(null);

  const { startMoveLists, listPosition, setStartMoveLists, setListPosition, scrollList } = useSearchBarUiFunctions({
    inputSearch,
    keywordListsElement,
    scrollContainerElement,
    topicsListElement,
    showDropdownContent,
  });
  const { newAgencyPlan } = useTopicOrClientWord();

  const unselectTopic = () => {
    setStartMoveLists(false);
  };
  useOutsideHoverElement(topicsListElement, unselectTopic);
  const agencyPlanCondition = newAgencyPlan && keywordsLists?.length > 0;

  return (
    <>
      {(isAuthenticated || widget) && (
        <div
          ref={topicsListElement}
          onKeyDown={(e) => {
            scrollList(e);
          }}
        >
          <div className='scroll-container hover-container search-bar-lists' ref={scrollContainerElement}>
            <ul className='policy-list px-0 mb-0 hover-content' ref={keywordListsElement}>
              {keywordsLists.map((item, index) => (
                <TopicItem
                  key={`keywordlist${item?.id === null ? 'master-topic' : item.id}`}
                  item={item}
                  index={index}
                  applyTopic={applyTopic}
                  listPosition={listPosition}
                  setListPosition={setListPosition}
                  startMoveLists={startMoveLists}
                  setStartMoveLists={setStartMoveLists}
                  topic={topic}
                  searchValue={searchValue}
                  showTopicSelected={showTopicSelected}
                  showActions={showActions}
                />
              ))}
            </ul>
          </div>
          {!widget && !agencyPlanCondition && (
            <AddTopicButton keywordsLists={keywordsLists} dataTooltip={dataTooltip} />
          )}
        </div>
      )}
      {!widget && <TopicListNoAuthenticatedUsers />}
    </>
  );
};

export default TopicPickerList;
