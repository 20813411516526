import React from 'react';
import { useContext } from 'react';
import { store } from '../../../../Store';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

function NoContactsGroupEmptyMessage() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { group } = state;
  const { type } = group ?? {};

  const groupTitle = () => {
    if (type === 'Static') {
      return `This group is currently empty`;
    } else {
      return `No contacts match this group's filters`;
    }
  };

  const groupSubtext = () => {
    if (type === 'Static') {
      return (
        <>
          This is a static group and does not have any filters. You can add contacts manually from the{' '}
          <Link className='simple-link-button' to='/influence/directory'>
            directory
          </Link>{' '}
          page
        </>
      );
    } else {
      return `Once contacts match the filters created for this group, they’ll automatically be displayed here`;
    }
  };

  return (
    <div className='mx-auto text-center pt-5 px-3 ' style={{ maxWidth: `550px` }}>
      <div className='pt-lg-5'>
        <h3 className='title-h2-bold mb-2'>{groupTitle()}</h3>
        <p className='title-h5-m main-light-text mb-5 mx-auto' style={{ maxWidth: '400px' }}>
          {groupSubtext()}
        </p>

        <div className='pt-2'>
          <div className='pt-lg-4'>
            <SkeletonTheme baseColor='#fff'>
              {Array(6)
                .fill()
                .map((item, index) => (
                  <div
                    className='empty-message-contacts-element border rounded bg-main-blue pl-3 pr-4 py-3 flex-centered mb-3'
                    key={index}
                  >
                    <div
                      className='rounded-circle border bg-main-white-50 flex-centered justify-content-center'
                      style={{ width: '50px', height: '50px' }}
                    >
                      <span className='icon-profile text-white' />
                    </div>
                    <div className='flex-grow-1 pl-3' style={{ opacity: '0.4' }}>
                      <Skeleton height={15} width={`100%`} />
                      <Skeleton height={15} width={`100%`} />
                    </div>
                  </div>
                ))}
            </SkeletonTheme>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoContactsGroupEmptyMessage;
