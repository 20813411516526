import React from 'react';
import Popup from '../../Common/PopUp';
import useEmailDomainFunctions from '../../Dashboard/hooks/useEmailDomainFunctions';

const ContentPopup = ({
  closeMicroModal,
  activeItem,
  loadInitialInformation,
  domainsList,
  team,
  isAdminPage,
  teamId,
}) => {
  const { deleteEmailAddressRequest, deleteEmailDomainRequest } = useEmailDomainFunctions();
  const activeDomain = domainsList.find((domain) => domain.id === activeItem.crmDomainId);

  const handleDelete = async (e) => {
    let deleteAction = await deleteEmailAddressRequest({
      activeItem,
      loadInitialInformation: activeDomain?.associatedSenderEmailAddressCount <= 1 ? () => {} : loadInitialInformation,
    });
    if (activeDomain?.associatedSenderEmailAddressCount <= 1) {
      await deleteEmailDomainRequest({
        emailDomainId: activeDomain?.id,
        isAdminPage,
        teamId: teamId ?? team.id,
        loadInitialInformation,
      });
    }
    if (deleteAction) {
      closeMicroModal();
    }
  };
  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
      <div className='text-center'>
        <p className='pr-2'>
          Are you sure? This cannot be undone and will remove all email records and stats from PolicyMogul
        </p>

        <div className='my-4'>
          <button className='general-button danger-button px-4 py-2' onClick={handleDelete}>
            Delete Email address
          </button>
        </div>
      </div>
    </div>
  );
};

const DeleteEmailAddressModal = (props) => {
  return (
    <Popup {...props} maxWidth={500}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default DeleteEmailAddressModal;
