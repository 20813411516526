import React, { useState, useEffect, useRef } from 'react';
import { net_api_url } from '../../Store';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Loading from '../Loading';
import ChangeEmail from '../ChangeEmail';
import { useNavigate } from 'react-router-dom';
import getActivePlan from '../../Settings/Utilities/getActivePlan';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

const CreateProfileAwaits = () => {
  const { user } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const [resendVerificationState, setResendVerificationState] = useState({ isSending: false, resendEmail: false });
  const { isSending, resendEmail } = resendVerificationState;
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  const resendVerificationEmail = async () => {
    try {
      setResendVerificationState({ ...resendVerificationState, isSending: true });
      var token = await getAccessToken();
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ emailAddress: user.email }),
      };

      await fetch(`${net_api_url}/api/user/sendverificationemail`, requestOptions);
      setResendVerificationState({ ...resendVerificationEmail, isSending: false, resendEmail: true });
    } catch (error) {
      console.error(error);
    }
  };
  const verifyingUser = useRef();
  verifyingUser.current = async (source) => {
    try {
      let token = await getAccessToken({ cacheMode: 'off' });
      let response = await axios.get(`${net_api_url}/api/user/getcurrent`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      let activePlan = await getActivePlan(getAccessToken, source);
      let result = response.data;
      if (!!result) {
        setUserEmail(result.email);
        if (!activePlan.userOnboarded) {
          return navigate('/create-your-profile/2');
        }
        if (result?.emailVerified) {
          setIsVerified(true);
          return window.location.assign('/monitor-inbox/key-updates');
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    verifyingUser.current(source);
    return () => {
      source.cancel('Update profile canceled by the user.');
    };
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className='pt-lg-4 mt-5 create-profile-awaits'>
          <h2>{isVerified ? 'Thanks for verifying your email' : 'Please check your email'}</h2>
          {!isVerified && (
            <>
              <div className='pr-lg-5'>
                <p>
                  We’ve {resendEmail ? <strong>resent</strong> : <>sent</>} an email to <strong>{userEmail}</strong>.
                </p>

                <p className='mb-0'>
                  Please verify your email to access your PolicyMogul account (it might be in your spam folder)
                </p>
              </div>

              <button
                className='general-button resend-button mb-2'
                onClick={() => {
                  if (!isSending) {
                    resendVerificationEmail();
                  }
                }}
              >
                {isSending ? (
                  <>
                    <i className='fas fa-spinner fa-spin'></i> resending...
                  </>
                ) : (
                  `Resend the verification email`
                )}
              </button>
              <ChangeEmail setUserEmail={setUserEmail} />
            </>
          )}

          {isVerified && (
            <div>
              <button
                className='general-button action-button py-2 px-4 mt-5'
                onClick={() => {
                  window.location.assign('/monitor-inbox/key-updates');
                }}
              >
                Go to platform
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CreateProfileAwaits;
