import React, { useEffect, useState, useContext } from 'react';
import useSectionFunctions from './SectionFunctions';
import { useStepsStructure } from './StepsStructure';
import CreateComponent from './ui/CreateComponent';
import axios from 'axios';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import useTransformEditInfluenceResults from './TransformEditInfluenceResults';
import { store } from '../../Store.js';
import addMetaTags from '../utilities/addMetaTags';
import InitialValidations from '../../InitialValidations';
import DeleteInfluenceItemFunction from './DeleteInfluenceItemFunction';
import Loading from '../../Login/Loading';
import getCurrentTeam from '../../Settings/Team/getCurrentTeam.js';
import SkeletonCreateComponent from './ui/SkeletonCreateComponent';
import getActivePlan from '../../Settings/Utilities/getActivePlan';
import { loadKeyWords } from '../utilities/loadKeyWords';
import callTeamsContent from '../apiCalls/callTeamsContent';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import useGetTweetsInfo from './useGetTweetsInfo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useCreateSummaryFromWritingAssistant from './useCreateSummaryFromWritingAssistant';
import CreatingSummaryLoading from './ui/CreatingSummaryLoading';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord.js';
import useGetAccessToken from '../apiCalls/useGetAccessToken.js';

const InfluenceCreate = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { team, userProfile, keywordsLists, activePlan, readyKeywordLists, promptFromWritingAssistant } = state;
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { createMarkdownFromWritingAssistant } = useCreateSummaryFromWritingAssistant();

  const { StepsStructure, createStepsStructure } = useStepsStructure();
  const { TransformEditInfluenceResults } = useTransformEditInfluenceResults();
  const { generalApiCall } = useGeneralApiCall();
  const { getTweetsInfo } = useGetTweetsInfo();

  const editPage = location.pathname.includes('/edit');

  const [isLoading, setIsLoading] = useState(true);
  const [itemInformation, setItemInformation] = useState({});
  const [initialEditResults, setInitialEditResults] = useState({});
  const [InfluenceState, setInfluenceState] = useState(JSON.parse(JSON.stringify(StepsStructure)));
  const [disabledNavigation, setDisabledNavigation] = useState(false);
  const [createdFromTheApi, setCreatedFromTheApi] = useState(false);
  const [associatedContent, setAssociatedContent] = useState([]);
  const [teamContent, setTeamContent] = useState([]);

  const baseStep = params.step?.split('?')?.[0];
  const step = baseStep === 'Preview' ? null : parseInt(baseStep);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  const { agencyUser } = useTopicOrClientWord();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callInitialInformation(source);
    return () => {
      source.cancel('Create function cancel by the user');
    };
  }, []);

  useEffect(() => {
    let title = `${editPage ? 'Editing' : 'Creating'} ${
      params.page === 'policy-asks' ? 'a policy ask' : 'briefing material'
    } - PolicyMogul`;
    addMetaTags({ title, location, dispatch });
  }, [step]);

  const callCurrentUser = async (sourceProp) => {
    let method = 'get';
    let pathname = `/api/user/getcurrent`;
    let token = isAuthenticated ? await getAccessToken() : '';
    let requestHeaders = {
      cancelToken: sourceProp ? sourceProp.token : source.token,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let results = await generalApiCall({ method, pathname, requestHeaders, notShowErrorMessage: true });
    return results;
  };

  const callInitialInformation = async (sourceProp) => {
    try {
      const { page, id } = params;
      //AE: THESE CALLS ARE NECESSARY FOR DIRECT LOADS WHERE THIS ITEMS ARE NOT LOADED
      let plan = activePlan;
      if (Object.keys(activePlan).length === 0 && isAuthenticated) {
        plan = await getActivePlan(getAccessToken, sourceProp);
        if (!!plan) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: plan });
        }
      }
      let currentTeam = team;
      if (Object.keys(currentTeam).length === 0 && isAuthenticated) {
        currentTeam = await getCurrentTeam(getAccessToken, sourceProp);
        if (!!currentTeam) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
        }
      }

      let user = userProfile;
      if (Object.keys(userProfile).length === 0) {
        user = await callCurrentUser(sourceProp);
        if (!!user) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'userProfile', value: user });
        }
      }
      let topics = keywordsLists;
      let token = isAuthenticated ? await getAccessToken() : '';
      const isAgency = agencyUser;
      if (!readyKeywordLists) {
        topics = await loadKeyWords(getAccessToken, sourceProp);
        if (!!topics) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: topics });
        }
      }

      if (editPage) {
        let method = 'get';
        let pathname = `/api/user-generated-content/${page === 'policy-asks' ? 'policy-ask' : page}/${parseInt(id)}`;
        let results = await generalApiCall({
          method,
          pathname,
          needsAuthentication: true,
          requestSource: sourceProp,
          notShowErrorMessage: true,
        });
        if (!!results) {
          results.relatedContent = await getTweetsInfo({
            relatedContent: results?.relatedContent,
            requestSource: sourceProp,
          });
          let finalResults = TransformEditInfluenceResults(results, isAgency, user?.organisationWebsite);
          setItemInformation(results);
          setInitialEditResults(finalResults);
          setInfluenceState(JSON.parse(JSON.stringify(finalResults)));
          setAssociatedContent(finalResults?.find((item) => item.name === 'Associated content')?.fields?.[0]?.value);
          let title = `${'Editing'} ${
            params.page === 'policy-asks' ? 'a policy ask' : params.page.replace('-', ' ')
          } - PolicyMogul`;
          addMetaTags({ title, location, dispatch });
          setIsLoading(false);
        }
      } else {
        let userContent = await callTeamsContent({ token, source: sourceProp });
        if (!!userContent) {
          setTeamContent(userContent);
          let descriptionValue;
          if (promptFromWritingAssistant || location?.state?.writingAssistantId) {
            let writingAssistantItems = await createMarkdownFromWritingAssistant({
              id: location?.state?.writingAssistantId,
            });
            if (!!writingAssistantItems) {
              descriptionValue = writingAssistantItems?.description;
              setCreatedFromTheApi(true);
            }
          }
          let firstTopic = location?.state?.['client']
            ? topics?.find((item) => item.id === parseInt(location?.state?.['client']))
            : topics?.filter((item) => item.id !== null)?.[0];
          let organisationWebsite = userContent?.filter((item) =>
            isAgency ? item?.clientKeywordListId === firstTopic?.id : true
          )?.[0]?.websiteLink;
          let steps = createStepsStructure({
            agency: isAgency,
            topics,
            organisationWebsite: !!organisationWebsite ? organisationWebsite : user?.organisationWebsite,
            descriptionValue,
          });
          //AE: Passing the headline when you're on the creation process
          let finalResults = JSON.parse(JSON.stringify(steps));
          finalResults = updateFromState({ results: finalResults, propertyName: 'Headline', field: 'title' });
          finalResults = updateFromState({
            results: finalResults,
            propertyName: 'Client',
            field: 'clientKeywordListId',
          });
          finalResults = updateFromState({
            results: finalResults,
            propertyName: 'Client',
            stateProperty: 'websiteLink',
            field: 'websiteLink',
          });
          setInitialEditResults(finalResults);
          setInfluenceState(finalResults);
          setAssociatedContent(finalResults?.find((item) => item.name === 'Associated content')?.fields?.[0]?.value);

          let title = `${'Creating'} ${
            params.page === 'policy-asks' ? 'a policy ask' : params.page.replace('-', ' ')
          } - PolicyMogul`;
          addMetaTags({ title, location, dispatch });
          setIsLoading(false);

          if (step !== 1 && !location?.state?.headline) {
            let activeItemPosition = verifyIfParamIsAccesible();
            navigate(`/influence/${params.page}/create/${activeItemPosition ?? 'preview'}`);
          }
        }
      }
    } catch (error) {}
  };

  const updateFromState = ({ results, propertyName, stateProperty, field }) => {
    let localStateProperty = stateProperty ?? propertyName.toLowerCase();
    if (location?.state?.[localStateProperty]) {
      let position = results.findIndex((item) => item.name === propertyName);
      let fieldPosition = results[position]?.fields?.findIndex((item) => item?.name === field);
      if (fieldPosition >= 0) {
        results[position].complete = true;
        results[position].fields[fieldPosition].value = location?.state?.[localStateProperty];
      }
    }
    return results;
  };

  const compileSteps = () => {
    const compiledStepsState = JSON.parse(JSON.stringify(InfluenceState));
    let isReadyToPreview = true;
    compiledStepsState.forEach((item, index) => {
      item.active = step === index + 1;
      if (!editPage) {
        if (!item.complete && !item.isAPreviewItem) {
          isReadyToPreview = false;
        }

        if (index > 0) {
          let disabled = false;
          for (let i = 0; i < index; i++) {
            if (!InfluenceState[i].complete) {
              disabled = true;
            }
          }
          item.disabled = disabled;
        } else {
          item.disabled = false;
        }
      } else {
        item.complete = true;
      }
      //HIDE ON THE SIDE NAV
      if (item.isAPreviewItem) {
        item.notShow = true;
      }
    });
    return { compiledStepsState, isReadyToPreview };
  };

  const verifyIfParamIsAccesible = () => {
    let steps = compileSteps()['compiledStepsState'];
    let isReadyToPreview = compileSteps()['isReadyToPreview'];
    let activeItemPosition;
    if (baseStep === 'preview') {
      activeItemPosition = null;
      if (!isReadyToPreview) {
        activeItemPosition = 1;
      }
    } else {
      activeItemPosition = step;
      if (steps[activeItemPosition - 1]?.disabled) {
        activeItemPosition = 1;
      }
    }
    return activeItemPosition;
  };

  const SectionName = () => {
    const compiledSteps = compileSteps()['compiledStepsState'];
    const activeItemPosition = verifyIfParamIsAccesible();
    const activeItem = !!activeItemPosition ? compiledSteps[activeItemPosition - 1] : null;
    const name = activeItem === null ? 'Preview' : activeItem?.name;
    return name;
  };

  const allowOrganisationWebsite = () => {
    if (Object.keys(userProfile).length > 0 && !!team?.myself && team?.myself?.role !== 'Member') {
      const { organisationWebsite } = userProfile;
      return organisationWebsite === null || organisationWebsite.trim() === '';
    }
  };

  const sectionFunctionsProps = {
    InfluenceState,
    setInfluenceState,
    setDisabledNavigation,
    setItemInformation,
    setInitialEditResults,
    sectionName: SectionName(),
    source,
    itemInformation,
    associatedContent,
  };
  let SectionValues = useSectionFunctions(sectionFunctionsProps);

  const NextFunction = (publish = baseStep === 'preview') => {
    if (!disabledNavigation && validateFields()) {
      SectionValues.saveSection(publish);
    }
  };

  const PrevFunction = () => {
    if (step === 1) {
      return null;
    } else {
      return () => {
        let newStep = !!step ? step - 1 : 3;
        navigate({
          pathname: `/influence/${params.page}/${params.id ? 'edit' : 'create'}${
            params?.id ? `/${params?.id}` : ''
          }/${newStep}`,
          search: location?.search,
        });
      };
    }
  };

  const nextText = () => {
    let text = 'Next';
    if (InfluenceState[step - 1]?.name === 'Associated content') {
      text = 'Preview';
    } else if (baseStep === 'preview' && itemInformation.publishedAt === null) {
      text = 'Publish';
    }
    return text;
  };

  const validateFields = () => {
    let validateFields = true;
    if (baseStep !== 'preview') {
      const activeSection = InfluenceState[parseInt(step) - 1];
      const { fields } = activeSection ?? {};
      const fieldToCompare = fields?.filter((field) => field.type === params.page || !field.type);
      fieldToCompare?.forEach((field) => {
        const { value, required } = field;
        if (required) {
          let newValue = !!value && typeof value === 'string' ? value.replace(/<\/?[^>]+(>|$)/g, '') : '';
          if (typeof value === 'string' && newValue.trim() === '') {
            validateFields = false;
          }

          if (!!value && typeof value === 'object' && Object.keys(value).length === 0) {
            validateFields = false;
          }
        }
      });
    }

    return validateFields;
  };

  const validateEditChanges = () => {
    let validate = true;
    const createNewObject = (obj) => {
      let newState = JSON.parse(JSON.stringify(obj));
      newState.forEach((item) => {
        delete item.disabled;
        delete item.active;
      });
      return newState;
    };
    const drafItem = editPage && itemInformation.publishedAt === null;
    if (editPage && !drafItem) {
      let newInfluenceState = createNewObject(InfluenceState);
      let newInitialEditResults = createNewObject(initialEditResults);
      if (JSON.stringify(newInfluenceState) === JSON.stringify(newInitialEditResults)) {
        validate = false;
      }
    }
    return validate;
  };

  const DeleteFunction = () => {
    if (editPage) {
      return async () => {
        setDisabledNavigation(true);
        DeleteInfluenceItemFunction({
          navigate,
          params,
          getAccessToken,
          source,
          itemInformation,
        });
      };
    }
    return null;
  };

  return (
    <>
      {isLoading && <>{promptFromWritingAssistant ? <CreatingSummaryLoading /> : <SkeletonCreateComponent />}</>}
      {!isLoading && (
        <>
          <InitialValidations />
          <CreateComponent
            type={params.page}
            compiledSteps={compileSteps()['compiledStepsState']}
            isReadyToPreview={compileSteps()['isReadyToPreview']}
            sectionName={SectionName()}
            sectionValues={SectionValues}
            nextFunction={NextFunction}
            nextText={nextText()}
            prevFunction={PrevFunction()}
            disabledNavigation={disabledNavigation}
            itemInformation={itemInformation}
            validateFields={validateFields()}
            editPageId={editPage ? params.id : null}
            deleteFunction={DeleteFunction()}
            validateEditChanges={validateEditChanges()}
            InfluenceState={InfluenceState}
            allowOrganisationWebsite={allowOrganisationWebsite()}
            keywordsLists={keywordsLists}
            createdFromTheApi={createdFromTheApi}
            associatedContent={associatedContent}
            setAssociatedContent={setAssociatedContent}
            teamContent={teamContent}
          />
        </>
      )}
    </>
  );
};

export default withAuthenticationRequired(InfluenceCreate, {
  onRedirecting: () => <Loading />,
});
