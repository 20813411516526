import { useEffect, useState, useContext, useRef } from 'react';
import DropdownComponent from '../../../Settings/WebsiteFeeds/DropdownComponent';
import useGroupFiltersData from './GroupsFiltersData';
import normalize from '../../utilities/normalizeString';
import MembershipSelectFilter from '../../Filters/ui/MembershipSelectFilter';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import TagsFilter from '../../Filters/ui/TagsFilter';
import { useGroupFunctions } from './GroupFunctions';
import { store } from '../../../Store';
import WebsiteSettingsTopicsLocal from '../../../Settings/WebsiteFeeds/WebsiteSettingsTopicsLocal';
import { useChangeKeywordsListsOrder } from '../../hooks/useSearchBarUiFunctions';
import useIsMobile from '../../utilities/useIsMobile';
import { isVisibleVerticallyComplete, smoothScrollTo } from './GroupScrollFunctions';

const SelectFilterComponent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state ?? {};

  const { GroupsFiltersData } = useGroupFiltersData();
  const { categories, operators, topicDates } = GroupsFiltersData;
  const [isLoading, setIsLoading] = useState(false);

  const { addingState, item, modifyItem, filtersState, groupPosition, scrollBarPosition, groupFilters, scrollbarRef } =
    props;
  const { filters, setFilters, setFiltersNumber } = filtersState ?? {};

  const [addingFilter, setAddingFilter] = useState(addingState);
  const [filterInformation, setFilterInformation] = useState(
    item ?? {
      category: null,
      value: null,
      operator: operators.find((item) => item.name === 'is equal to any of'),
      topicDate: topicDates?.find((item) => item?.name === '365 days'),
      sentimentTopic: null,
    }
  );

  const { category, value, operator, sentimentTopic } = filterInformation ?? {};
  const { previewGroup } = useGroupFunctions();
  const filterContainer = useRef();
  const isMobile = useIsMobile();

  const listsToUse = keywordsLists
    ?.filter((item) => item?.id !== null)
    ?.map((item) => {
      return { ...item, filterValue: `${item?.id}` };
    });

  const modifyFilterInformation = ({ property, value }) => {
    let newFilterInformation = { ...filterInformation };
    newFilterInformation[property] = value;
    setFilterInformation(newFilterInformation);
  };

  const addFilterValue = async ({ localValue = value }) => {
    try {
      setIsLoading(true);
      if (modifyItem) {
        await modifyItem({ ...filterInformation, value: localValue });
      } else {
        let newFilters = [...filters];
        let newGroupFilters = [...newFilters[groupPosition]?.groupFilters];
        newGroupFilters.push({ ...filterInformation, value: localValue });
        newFilters[groupPosition] = {
          ...newFilters[groupPosition],
          groupFilters: newGroupFilters,
        };
        let results = await previewGroup({ filters: newFilters });
        if (!!results) {
          setFiltersNumber(results?.totalHits);
          setFilters(newFilters);
        }
      }
      setFilterInformation({
        category: null,
        value: null,
        operator: operators.find((item) => item.name === 'is equal to any of'),
      });
      setIsLoading(false);
      setAddingFilter(false);
    } catch (error) {}
  };

  useEffect(() => {
    setAddingFilter(addingState);
  }, [addingState]);

  useEffect(() => {
    //AE: category!== null condition removed
    if (
      addingFilter &&
      filterContainer?.current &&
      scrollbarRef?.current &&
      !isVisibleVerticallyComplete({ groupRef: filterContainer, scrollbarRef })
    ) {
      smoothScrollTo(scrollbarRef, filterContainer.current?.offsetTop - 32);
    }
  }, [addingFilter, category]);

  const elementsComponentsProperties = {
    filterInformation,
    setFilterInformation,
    modifyFilterInformation,
    listsToUse,
    scrollBarPosition,
  };

  return (
    <>
      {/* AE: When adding filters */}
      {groupFilters?.length > 0 && (
        <>
          <p className='paragraph-p5 filter-item-and-text px-3 light-70-text'>
            <span className='py-1 px-3 font-weight-bold text-uppercase rounded border bg-light-blue-hovered'>and</span>
          </p>
        </>
      )}
      <div className='pt-3 px-3'>
        {addingFilter ? (
          <>
            <div ref={filterContainer}>
              <div className='pb-2 flex-centered justify-content-between mb-0'>
                <label className='font-weight-bold main-subtle-text mb-0'>Filter</label>
                <div style={{ visibility: groupFilters?.length > 0 ? 'visible' : 'hidden' }}>
                  <span
                    data-tooltip-id={'general-tooltip'}
                    data-tooltip-content={'Delete filter'}
                    onClick={() => {
                      setAddingFilter(false);
                    }}
                    className='pointer icon-delete paragraph-p3 d-inline-block-centered'
                  />
                </div>
              </div>

              <div className='shadow-dropdown position-relative'>
                {/*AE: This item is to load the italic font before to open the options and avoid a movement on the italic*/}
                <p className='ml-1 d-inline-block font-italic light-30-text position-absolute invisible'>
                  Dummy text on italic
                </p>
                <DropdownComponent
                  fixedDropdown={true}
                  mainItemClass={`${!category ? 'shadow-dropdown-highlight' : ''}`}
                  dropdownClass={`shadow-open-dropdown`}
                  scrollBarPosition={scrollBarPosition}
                  waitOnOpen={false}
                  mainItem={() => {
                    return (
                      <>
                        {!!category ? (
                          <span>{category?.name}</span>
                        ) : (
                          <span className='main-light-text'>Select a filter</span>
                        )}
                      </>
                    );
                  }}
                >
                  <CustomScrollbar
                    contentStyle={{ height: 'auto' }}
                    style={{ height: '185px' }}
                    className='simple-scrollbar'
                    maximalThumbYSize={100}
                  >
                    <ul className='policy-list px-0 pt-1 m-0 pb-2'>
                      {categories?.map((item) => {
                        const { name, subtext, filterName } = item;
                        const categoryOptionsWithNotExists = groupFilters?.filter((item) => {
                          return item?.category?.name === name && item?.operator?.type === 'not_value_filter';
                        });
                        const disabledOption = categoryOptionsWithNotExists?.length > 0;

                        return (
                          <li
                            className={`list-action-button px-3 py-2 flex-centered justify-content-between ${disabledOption ? '' : 'pointer'}`}
                            key={`item-${name}`}
                            onClick={() => {
                              if (!disabledOption) {
                                const valueWithTopic =
                                  filterName === 'topicMatchedKeywordListIds' && keywordsLists?.length === 1
                                    ? keywordsLists[0]
                                    : null;
                                setFilterInformation({
                                  ...filterInformation,
                                  category: item,
                                  value: valueWithTopic,
                                  operator: operators.find((item) => item.name === 'is equal to any of'),
                                  sentimentTopic: valueWithTopic,
                                });
                              }
                            }}
                          >
                            <span className={`flex-grow-1 flex-centered ${disabledOption ? 'light-35-text' : ''}`}>
                              <span>{name}</span>
                              {subtext && !isMobile && (
                                <span
                                  className='ml-1 font-italic light-30-text text-left hidden-lines hidden-one-line'
                                  style={{ width: '300px' }}
                                >
                                  {subtext}
                                </span>
                              )}
                            </span>
                            <span
                              className={`icon-tick paragraph-p5 ${category?.name === name ? 'visible' : 'invisible'}`}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </CustomScrollbar>
                </DropdownComponent>
              </div>
              {category !== null && (
                <>
                  <div className='row pt-3 groups-columns-component'>
                    {category?.type === 'picker' && <TopicSelectorElements {...elementsComponentsProperties} />}
                    {category?.type === 'selectSentiment' && (
                      <>
                        <SentimentFilterElements {...elementsComponentsProperties} />
                      </>
                    )}
                    {category?.type !== 'selectSentiment' && category?.type !== 'picker' && (
                      <NormalFiltersElements {...elementsComponentsProperties} />
                    )}
                  </div>
                  <div className='col-lg-7 px-0'>
                    <button
                      className={`${(operator?.type !== 'not_value_filter' && !value) || isLoading ? 'outline-active-button' : 'action-button'} general-button mt-3 save-filter-button py-1`}
                      disabled={
                        (operator?.type !== 'not_value_filter' && !value) ||
                        isLoading ||
                        (category?.type === 'selectSentiment' && !sentimentTopic)
                      }
                      onClick={() => {
                        addFilterValue({});
                      }}
                    >
                      {isLoading && (
                        <i className='fas fa-spinner fa-spin mx-1 paragraph-p5 d-inline-block-centered centered-4' />
                      )}{' '}
                      Save filter
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <button
            className='border rounded py-1 px-3 general-button bg-grey-50 groups-add-filter-button'
            onClick={() => setAddingFilter(true)}
          >
            + Add filter
          </button>
        )}
      </div>
    </>
  );
};

const GroupsTopicSelector = (props) => {
  const { modifyFilterInformation, filterInformation, scrollBarPosition, listsToUse, property = 'value' } = props;
  const { category, value, operator, sentimentTopic } = filterInformation ?? {};

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({ keywordsLists: listsToUse });
  return (
    <div className='keywords-website-settings'>
      <label className='font-weight-bold main-subtle-text'>Topic</label>
      <DropdownComponent
        mainItemClass={`${(!value && operator?.type !== 'not_value_filter' && property === 'value') || (property === 'sentimentTopic' && !sentimentTopic) ? 'shadow-dropdown-highlight' : ''}`}
        disabled={operator?.type === 'not_value_filter'}
        dropdownClass={'groups-filters-dropdown'}
        scrollBarPosition={scrollBarPosition}
        mainItem={() => (
          <>
            {!filterInformation[property] || filterInformation[property]?.length === 0 ? (
              <span className='main-light-text'>
                {operator?.type === 'not_value_filter' ? 'Not applicable' : category.placeholder}
              </span>
            ) : (
              <span className='hidden-lines hidden-one-line w-100'>{filterInformation[property]?.name}</span>
            )}
          </>
        )}
        fixedDropdown={true}
      >
        <WebsiteSettingsTopicsLocal
          selectedTopic={filterInformation[property]}
          setSelectedTopic={(selectedTopic) => {
            modifyFilterInformation({
              property: property,
              value: selectedTopic,
            });
          }}
          heightReference={140}
          completeHeightForItems={true}
          changeTopicsWord={true}
          keywordsLists={changeKeywordsListsOrder()}
        />
      </DropdownComponent>
    </div>
  );
};

const TopicSelectorElements = (props) => {
  const { filterInformation, modifyFilterInformation, scrollBarPosition, listsToUse } = props;
  const { topicDate } = filterInformation;
  const { GroupsFiltersData } = useGroupFiltersData();
  const { topicDates } = GroupsFiltersData;

  return (
    <>
      <div className='col-lg-8'>
        <GroupsTopicSelector
          filterInformation={filterInformation}
          modifyFilterInformation={modifyFilterInformation}
          scrollBarPosition={scrollBarPosition}
          listsToUse={listsToUse}
        />
      </div>
      <div className='col-lg-8 pt-3 pt-lg-0'>
        <div>
          <label className='font-weight-bold main-subtle-text'>In the last...</label>

          <DropdownComponent fixedDropdown={true} mainItem={topicDate?.name} scrollBarPosition={scrollBarPosition}>
            <ul className='policy-list px-0 pt-1 m-0 pb-2'>
              {topicDates?.map((item) => {
                const { name } = item;
                return (
                  <li
                    className={`list-action-button px-3 py-2 flex-centered justify-content-between pointer`}
                    key={`item-${name}`}
                    onClick={() => {
                      modifyFilterInformation({ property: 'topicDate', value: item });
                    }}
                  >
                    <span>{name}</span>
                    {topicDate?.name === name && <span className='icon-tick paragraph-p5' />}
                  </li>
                );
              })}
            </ul>
          </DropdownComponent>
        </div>
      </div>
    </>
  );
};

const SentimentFilterElements = (props) => {
  const { filterInformation, modifyFilterInformation, scrollBarPosition, listsToUse } = props;
  const { value, category } = filterInformation;
  return (
    <>
      <div className='col-lg-9'>
        <GroupsTopicSelector
          filterInformation={filterInformation}
          modifyFilterInformation={modifyFilterInformation}
          scrollBarPosition={scrollBarPosition}
          listsToUse={listsToUse}
          property={'sentimentTopic'}
        />
      </div>
      <div className={`group-select-container col-lg-7 z-50 pt-3 pt-lg-0`}>
        <label className='font-weight-bold main-subtle-text'>Value</label>
        <DropdownComponent
          fixedDropdown={true}
          mainItemClass={`${!value ? 'shadow-dropdown-highlight' : ''}`}
          scrollBarPosition={scrollBarPosition}
          mainItem={() => (
            <span className='hidden-lines hidden-one-line pr-3'>
              {Array.isArray(value) ? (
                <>{value?.map((item) => item.name)?.join(', ')}</>
              ) : (
                value?.name ?? <span className='main-light-text'>{'Select sentiment'} </span>
              )}
            </span>
          )}
          notCloseOnSelect={Array.isArray(value)}
        >
          <CustomScrollbar
            className='simple-scrollbar'
            maximalThumbYSize={100}
            autoHeight
            autoHeightMin='100%'
            autoHeightMax='185px'
          >
            <ul className='policy-list px-0 pt-1 m-0 pb-2'>
              <>
                {category?.options?.map((item) => {
                  const { name } = item;
                  return (
                    <li
                      className={`pointer list-action-button px-3 py-2 flex-centered justify-content-between`}
                      key={`item-${normalize(name)}`}
                      onClick={() => {
                        modifyFilterInformation({ property: 'value', value: item });
                      }}
                    >
                      {name}
                      {value?.name === name && <span className='icon-tick paragraph-p5' />}
                    </li>
                  );
                })}
              </>
            </ul>
          </CustomScrollbar>
        </DropdownComponent>
      </div>
    </>
  );
};

const NormalFiltersElements = (props) => {
  const { filterInformation, setFilterInformation, modifyFilterInformation, scrollBarPosition } = props;
  const { operator, category, value } = filterInformation;
  const { GroupsFiltersData } = useGroupFiltersData();
  const { operators } = GroupsFiltersData;

  return (
    <>
      <div className='col-lg-7'>
        <div>
          <label className='font-weight-bold main-subtle-text'>Condition</label>
          <DropdownComponent
            fixedDropdown={true}
            mainItem={operator?.format ? operator.format() : operator?.name}
            scrollBarPosition={scrollBarPosition}
          >
            <ul className='policy-list px-0 pt-1 m-0 pb-2'>
              {operators?.map((item) => {
                const { name, format } = item;
                return (
                  <li
                    className={`list-action-button px-3 py-2 flex-centered justify-content-between pointer`}
                    key={`item-${name}`}
                    onClick={() => {
                      setFilterInformation({ ...filterInformation, operator: item, value: null });
                    }}
                  >
                    <span>{format ? format() : name}</span>
                    {operator?.name === name && <span className='icon-tick paragraph-p5' />}
                  </li>
                );
              })}
            </ul>
          </DropdownComponent>
        </div>
      </div>
      <div className={`group-select-container col-lg-9 z-50 pt-3 pt-lg-0`}>
        <label className='font-weight-bold main-subtle-text'>Value</label>
        {category?.type === 'select' && (
          <DropdownComponent
            fixedDropdown={true}
            mainItemClass={`${!value && operator?.type !== 'not_value_filter' ? 'shadow-dropdown-highlight' : ''}`}
            scrollBarPosition={scrollBarPosition}
            disabled={operator?.type === 'not_value_filter'}
            mainItem={() => (
              <span className='hidden-lines hidden-one-line pr-3'>
                {operator?.type === 'not_value_filter' ? (
                  <span className='main-light-text'>Not applicable</span>
                ) : (
                  <>
                    {Array.isArray(value) ? (
                      <>{value?.map((item) => item.name)?.join(', ')}</>
                    ) : (
                      value?.name ?? <span className='main-light-text'>{category.placeholder ?? 'Select a value'}</span>
                    )}
                  </>
                )}
              </span>
            )}
            notCloseOnSelect={Array.isArray(value)}
          >
            <CustomScrollbar
              className='simple-scrollbar'
              maximalThumbYSize={100}
              autoHeight
              autoHeightMin='100%'
              autoHeightMax='185px'
            >
              <ul className='policy-list px-0 pt-1 m-0 pb-2'>
                {operator.type === 'single' ? (
                  <>
                    {category?.options?.map((item) => {
                      const { name } = item;
                      return (
                        <li
                          className={`pointer list-action-button px-3 py-2 flex-centered justify-content-between`}
                          key={`item-${normalize(name)}`}
                          onClick={() => {
                            modifyFilterInformation({ property: 'value', value: item });
                          }}
                        >
                          {name}
                          {value?.name === name && <span className='icon-tick paragraph-p5' />}
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {category?.options?.map((item) => {
                      return (
                        <CheckboxFilterItem
                          key={`item-checkbox-${item?.name}`}
                          item={item}
                          value={value}
                          modifyFilterInformation={modifyFilterInformation}
                        />
                      );
                    })}
                  </>
                )}
              </ul>
            </CustomScrollbar>
          </DropdownComponent>
        )}

        {category?.type === 'membership' && (
          <div className='groups-membership'>
            <MembershipSelectFilter
              filter={{
                ...category,
                options: !!value ? (Array.isArray(value) ? value : [value]) : [],
              }}
              selectOption={({ filter, newValue }) => {
                modifyFilterInformation({
                  property: 'value',
                  value: newValue,
                });
              }}
              useInPopUp={true}
              singleItem={operator.type === 'single'}
            />
          </div>
        )}

        {category?.type === 'tags' && (
          <div className='group-tags'>
            <TagsFilter
              filter={{
                ...category,
                options: !!value ? (Array.isArray(value) ? value : [value]) : [],
              }}
              selectOption={({ filter, newValue }) => {
                modifyFilterInformation({
                  property: 'value',
                  value: newValue,
                });
              }}
              menuPosition='fixed'
            />
          </div>
        )}

        {(category?.type === 'membership' || category?.type === 'tags') && (
          <div className=''>
            {!!value && (
              <>
                {operator.type === 'single' ? (
                  <p className='mb-0'>{value?.name}</p>
                ) : (
                  <>
                    {value.map((item) => {
                      return (
                        <CheckboxFilterItem
                          key={`item-checkbox-${item?.name}`}
                          item={item}
                          value={value}
                          modifyFilterInformation={modifyFilterInformation}
                        />
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const CheckboxFilterItem = (props) => {
  const { modifyFilterInformation, item, value } = props;

  const { name } = item;
  const position = value?.findIndex((item) => item?.name === name);

  return (
    <>
      <div className='px-3' key={`item-checkbox-${name}`}>
        <div className={`checkbox-item flex-centered`}>
          <input
            id={`${normalize(name)}`}
            onChange={() => {
              let newValue = value ? [...value] : [];
              if (position >= 0) {
                if (newValue?.length > 1) {
                  newValue.splice(position, 1);
                } else {
                  newValue = null;
                }
              } else {
                newValue.push(item);
              }
              modifyFilterInformation({ property: 'value', value: newValue });
            }}
            type={'checkbox'}
            checked={position >= 0}
          />
          <label htmlFor={`${normalize(name)}`} className='flex-growcreate-email-alert-label w-100'>
            <p className='mb-0 flex-centered'>
              <span className='flex-grow-1'>{name}</span>{' '}
            </p>
          </label>
        </div>
      </div>
    </>
  );
};

export default SelectFilterComponent;
