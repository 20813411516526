import BioElements from './BioElements';
import ActivityElements from './ActivityElements';
import StakeholderPageSkeleton from './StakeholderPageSkeleton';
import { useHeightContainer } from '../../../utilities/useHeightContainer';
import { useContext, useRef, useState } from 'react';
import useIsMobile from '../../../utilities/useIsMobile';
import MobileUI from './MobileUI';
import { useAuth0 } from '@auth0/auth0-react';
import CreateAccountPopup from '../../../components/CreateAccountPopup';
import NoEnableUsersPopup from './NoEnableUsersPopup';
import StakeholderCrmPopup from './StakeholderCrmPopup';
import InfluenceNotIncludedFeaturesPopup from '../../../Influence/CrmContacts/InfluenceNotIncludedFeaturesPopup';
import CustomScrollbar from '../../../../Common/CustomScrollbar';
import { store } from '../../../../Store';

const Stakeholder = (props) => {
  const { isLoading, load_more_results, activityContainer } = props;
  const [activeTab, setActiveTab] = useState('Profile');
  const [heightContainer, containerRef] = useHeightContainer();

  const [showPopup, setShowPopup] = useState(false);
  const [contentScrolled, setContentScrolled] = useState(false);
  const [bioElementsScrollBarPosition, setBioElementsScrollBarPosition] = useState(0);

  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  const { showStakeholderCrmPopup, showContactsPopup } = state;

  let setShowStakeholderCrmPopup = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showStakeholderCrmPopup',
      value: val,
    });
  };

  let setShowContactsPopup = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showContactsPopup',
      value: val,
    });
  };

  const setShowContactsPopupHandler = (val) => {
    if (!!val?.url) {
      setShowContactsPopup({ show: val?.show, url: val?.url });
    } else {
      setShowContactsPopup({ show: val });
    }
  };

  const scrollElement = useRef(null);
  const BioContent = useRef();
  const ActivityContent = useRef();

  const isMobile = useIsMobile();
  const { isAuthenticated } = useAuth0();

  const ScrollContent = (scrollValues) => {
    const { scrollTop, scrollHeight, clientHeight } = scrollValues;
    setContentScrolled(scrollTop > 0);
    const checkMoreResults = () => {
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      if (scrolledToBottom) {
        load_more_results();
      }
    };
    checkMoreResults();
  };

  return (
    <div className={`pt-lg-4 px-lg-5 h-100`}>
      {isLoading && <StakeholderPageSkeleton />}
      {!isLoading && (
        <>
          {isMobile && (
            <>
              <div ref={containerRef} />
              <MobileUI
                {...props}
                setShowPopup={setShowPopup}
                ScrollContent={ScrollContent}
                setShowContactsPopup={setShowContactsPopupHandler}
                setShowStakeholderCrmPopup={setShowStakeholderCrmPopup}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                heightContainer={heightContainer}
              />
            </>
          )}
          {!isMobile && (
            <div ref={scrollElement}>
              <div ref={containerRef} />
              <div className='d-lg-flex'>
                <div className='biography-general-container'>
                  <CustomScrollbar
                    className={'simple-scrollbar profile-scrollbar'}
                    style={{ height: `${heightContainer}px` }}
                    maximalThumbYSize={100}
                    onScroll={(scrollValues) => {
                      const { scrollTop } = scrollValues;
                      setBioElementsScrollBarPosition(scrollTop);
                    }}
                  >
                    <div className='pr-4'>
                      <BioElements
                        {...props}
                        ref={BioContent}
                        setShowContactsPopup={setShowContactsPopupHandler}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        setShowStakeholderCrmPopup={setShowStakeholderCrmPopup}
                        bioElementsScrollBarPosition={bioElementsScrollBarPosition}
                      />
                    </div>
                  </CustomScrollbar>
                </div>
                <div className='flex-grow-1'>
                  <CustomScrollbar
                    className={'main-content-scrollbar top-content-scrollbar'}
                    style={{ height: `${heightContainer}px` }}
                    maximalThumbYSize={100}
                    onScroll={(scrollValues) => {
                      ScrollContent(scrollValues);
                    }}
                    ref={activityContainer}
                  >
                    <ActivityElements
                      scrollBarRef={activityContainer}
                      {...props}
                      ref={ActivityContent}
                      contentScrolled={contentScrolled}
                      setShowPopup={setShowPopup}
                    />
                  </CustomScrollbar>
                </div>
              </div>
            </div>
          )}
          {showPopup && (
            <>
              {isAuthenticated ? (
                <NoEnableUsersPopup
                  identifier={'noenable-users-register-popup'}
                  setShowPopup={setShowPopup}
                  additionalClass={'search-restricted-popup'}
                />
              ) : (
                <CreateAccountPopup
                  text={'You’ll need to create an account to do that search'}
                  setShowPopup={setShowPopup}
                />
              )}
            </>
          )}
          {showContactsPopup.show && (
            <StakeholderCrmPopup
              identifier={'crm-contacts-rpopup'}
              setShowPopup={setShowContactsPopupHandler}
              showContactsPopup={showContactsPopup}
              additionalClass={'stakeholder-statistics-popup'}
            />
          )}
          {showStakeholderCrmPopup && (
            <InfluenceNotIncludedFeaturesPopup type={'contacts'} setShowPopup={setShowStakeholderCrmPopup} />
          )}
        </>
      )}
    </div>
  );
};

export default Stakeholder;
