import { useAuth0 } from '@auth0/auth0-react';
import createUtmParameters from '../../Dashboard/helpers/createUtmParameters';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useLocation } from 'react-router-dom';
import { useAllowRightPanel } from '../../Dashboard/Analytics/ui/MentionsByTopic/ChartDataTable';

const TopicListNoAuthenticatedUsers = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const analyticsPage = location.pathname === '/data-hub' || location.pathname.includes('/analytics');
  const { showPanelWithTitle } = useAllowRightPanel();
  return (
    <>
      {!isAuthenticated && (
        <>
          <button
            className='general-button add-keyword-list-button'
            onClick={() => {
              if (analyticsPage) {
                showPanelWithTitle('Dig deeper, with advanced search');
              } else {
                window.location.assign(`/register?reg_source=searchbar-add-topic${createUtmParameters('&')}`);
              }
            }}
          >
            <span className='icon-square-plus' /> {analyticsPage ? 'Advanced search' : 'Create a topic'}
            <span
              className='text-main-blue-40 ml-2 icon-info d-none d-lg-inline-block'
              style={{ verticalAlign: 'middle', marginTop: '-3px' }}
              data-tooltip-html={
                analyticsPage
                  ? 'Save searches, set up advanced<br/> keyword rules, and enable email<br/> and Slack alerts'
                  : 'Topics allow you to save searches,<br/>  set up advanced keyword rules, and <br/>enable email and Slack alerts'
              }
              data-tooltip-place={'right'}
              data-tooltip-id={'topic-list-no-authenticated-tooltip'}
            ></span>
          </button>
          <ReactTooltip
            id={'list-no-authenticated-tooltip'}
            effect={'solid'}
            type={'info'}
            className={'pricing-feature-tooltip feed-tooltip'}
            arrowColor={'transparent'}
            backgroundColor={'#00122B'}
            multiline={true}
          />
        </>
      )}
    </>
  );
};

export default TopicListNoAuthenticatedUsers;
