import React, { useState, useEffect, useRef, useContext } from 'react';
import CrmContactsTable from './CrmContactsTable';
import { store } from '../../../Store';
import RemoveContactsModal from './RemoveContactsModal/RemoveContactsModal';
import useValidateContactsOversubscribed from '../../utilities/useValidateContactsOversubscribed';
import { useLocation, useNavigate } from 'react-router-dom';
import RemoveContactTagsModal from './CrmContactsTable/CrmContactsListBar/RemoveContactTagsModal';
import ChampionStatusModal from './CrmContactsTable/CrmContactsListBar/ChampionStatusModal';
import CrmContactsTableSkeleton from './CrmContactsTable/CrmContactsSkeleton';
import getUrlParam from '../../utilities/getUrlParam';

function CrmContacts(props) {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    selectedCrmContacts,
    crmContactsTotalHits,
    showRemoveContactsModal,
    showRemoveContactTagsModal,
    showChangeChampionStatusModal,
  } = state;

  const { filters, groupPage } = props;

  const [mainLoading, setMainLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const initialCall = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const { setShowContactsOversubscribedModal, shownInnitiallyContactsOversubscribed, contactsOversubscribed } =
    useValidateContactsOversubscribed();

  useEffect(() => {
    if (contactsOversubscribed < 0 && !shownInnitiallyContactsOversubscribed) {
      setShowContactsOversubscribedModal(true);
    }
  }, [contactsOversubscribed]);

  const setShowAddTagsModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddTagsModalCrmContacts',
      value,
    });
  };

  const setSelectedCrmContacts = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedCrmContacts',
      value,
    });
  };

  const setTotalHits = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'crmContactsTotalHits',
      value,
    });
  };

  const resetSelectedContacts = () => {
    setSelectedCrmContacts({
      selectedAll: false,
      selectedIds: [],
      selectedAllVisible: [],
    });
  };

  useEffect(() => {
    return () => {
      resetSelectedContacts();
    };
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      if (!getUrlParam('sort')) {
        const query = new URLSearchParams(window.location.search);
        query.set('sort', 'lastName_asc');
        navigate({ search: query.toString() }, { replace: true });
      }
      setMainLoading(false);
    }, 300);
  }, []);

  return (
    <>
      {showRemoveContactsModal && (
        <RemoveContactsModal
          selectedCrmContacts={selectedCrmContacts}
          setSelectedCrmContacts={setSelectedCrmContacts}
          resetSelectedContacts={resetSelectedContacts}
          totalHits={crmContactsTotalHits}
          initialCall={initialCall}
        />
      )}
      {showRemoveContactTagsModal && (
        <RemoveContactTagsModal
          selectedCrmContacts={selectedCrmContacts}
          setSelectedCrmContacts={setSelectedCrmContacts}
          resetSelectedContacts={resetSelectedContacts}
          totalHits={crmContactsTotalHits}
          initialCall={initialCall}
        />
      )}
      <div className={`main-content pl-md-4 pl-3 h-100 ${groupPage ? 'pt-4' : ''}`}>
        <div className='d-flex flex-column h-100'>
          {mainLoading ? (
            <div className={`row mx-0 h-100`}>
              <div className='flex-grow-1' style={{ overflow: 'hidden' }}>
                <CrmContactsTableSkeleton />
              </div>
            </div>
          ) : (
            <CrmContactsTable
              selectedCrmContacts={selectedCrmContacts}
              setSelectedCrmContacts={setSelectedCrmContacts}
              setTotalHits={setTotalHits}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              initialCall={initialCall}
              setShowAddTagsModal={setShowAddTagsModal}
              filters={filters}
            />
          )}
        </div>
      </div>
      <ChampionStatusModal
        isOpen={showChangeChampionStatusModal}
        totalHits={crmContactsTotalHits}
        setIsOpen={(value) => {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'showChangeChampionStatusModal',
            value,
          });
        }}
      />
    </>
  );
}

export default CrmContacts;
