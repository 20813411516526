import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { store } from '../../../Store';
import EmailComponent from '../CrmEmails/CrmEmailComponent';
import EmailPerformanceEmptyScreen from './EmailPerformanceEmptyScreen';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

function EmailPerformance() {
  const params = useParams();
  const { crmEmailId } = params;

  const globalState = useContext(store);
  const { state } = globalState;
  const { emailPerformanceResult, emailPerformanceOnLoad } = state;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let title = `Email performance - Influence - PolicyMogul`;
    navigate(`${location.pathname}`);
    document.title = title;
  }, []);

  return (
    <div className='engagement-statistics-influence-section main-content pr-xl-5 px-md-4 px-3 h-100'>
      {emailPerformanceResult.length > 0 || emailPerformanceOnLoad ? (
        <div className='h-100' style={{ marginTop: '-42px' }}>
          <EmailComponent emailId={crmEmailId} />
        </div>
      ) : (
        <EmailPerformanceEmptyScreen />
      )}
    </div>
  );
}

export default EmailPerformance;
