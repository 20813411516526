import { useParams } from 'react-router-dom';
import uppercaseFirstLetter from '../../utilities/uppercaseFirstLetter';

const useFilterFromType = (props) => {
  const { stakeholderPage } = props;
  const params = useParams();
  const { id } = params;

  const idFilter = [{ field: 'contactIds', value: `${id}`, operator: 'STR_EQ' }];
  const parliamentaryFilters = () => {
    let initialFilter = idFilter;
    let parliamentaryFilter = [
      { field: 'contentType', value: 'ParliamentaryContribution', operator: 'STR_EQ' },
      { field: 'contentType', value: 'ScotlandCountrySpecificParliamentaryContribution', operator: 'STR_EQ' },
      { field: 'contentType', value: 'WalesCountrySpecificParliamentaryContribution', operator: 'STR_EQ' },
    ];
    return initialFilter.concat(parliamentaryFilter);
  };
  const tweetFilter = (ptId) => {
    return [{ field: stakeholderPage ? 'ptUserId' : 'screenName', value: `${ptId}`, operator: 'STR_EQ' }];
  };
  const questionsAnsweredFilters = () => {
    return [{ field: 'answerByContactId', value: `${id}`, operator: 'STR_EQ' }];
  };

  const filterFromType = (type, data) => {
    switch (type) {
      case 'tweet':
        return tweetFilter(data?.ptId);
      case 'ParliamentaryRecord':
        return parliamentaryFilters();
      case 'WrittenAnswer':
        return questionsAnsweredFilters();
      default:
        return idFilter;
    }
  };

  const changeContentType = (type) => {
    switch (type) {
      case 'key-updates':
        return 'KeyUpdate';
      case 'parliamentary-record':
      case 'spoken-parliamentary-contributions':
        return 'ParliamentaryRecord';
      case 'written-parliamentary-contributions':
        return 'WrittenQuestion';
      case 'tweets':
        return 'tweet';
      case 'answers-to-written-parliamentary-questions':
        return 'WrittenAnswer';
      case 'early-day-motions':
        return 'EdmPrimary';
      case 'early-day-motions-supported':
        return 'EdmSupported';
      case 'written-parliamentary-contributions-holyrood':
        return 'HolyroodCountrySpecificQuestion';
      case 'written-parliamentary-contributions-senedd':
        return 'SeneddCountrySpecificQuestion';
      case 'country-specific-answer-holyrood':
        return 'HolyroodCountrySpecificAnswer';
      case 'country-specific-answer-senedd':
        return 'SeneddCountrySpecificAnswer';
      default:
        return type;
    }
  };

  const changeContentypeFromUrl = (type) => {
    switch (type) {
      case 'key-updates':
        return 'Key updates ';
      case 'parliamentary-record':
      case 'spoken-parliamentary-contributions':
        return 'Spoken parliamentary contributions ';
      case 'written-parliamentary-contributions':
        return 'Written parliamentary contributions ';
      case 'tweets':
        return 'Tweets ';
      case 'answers-to-written-parliamentary-questions':
        return 'WrittenAnswer';
      case 'early-day-motions':
        return 'Early Day Motions as primary sponsor';
      case 'early-day-motions-supported':
        return 'Early Day Motions supported';
      case 'written-parliamentary-contributions-holyrood':
      case 'written-parliamentary-contributions-senedd':
        return 'CountrySpecificWrittenQuestion';
      case 'country-specific-answer-holyrood':
      case 'country-specific-answer-senedd':
        return 'CountrySpecificWrittenAnswer';
      default:
        return uppercaseFirstLetter(type.replaceAll('-', ' '));
    }
  };

  return { filterFromType, changeContentType, changeContentypeFromUrl };
};

export default useFilterFromType;
