import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import { AdminStore } from '../AdminState';
import { useNavigate } from 'react-router-dom';
import CustomScrollbar from '../../Common/CustomScrollbar';

const UserTeamTopics = (props) => {
  const adminState = useContext(AdminStore);
  const { state } = adminState;
  const { organisation } = state ?? {};
  const { maxListsAllowed } = organisation ?? {};

  const { teamTopics, organisationId } = props;
  const [heightContainer, containerRef] = useHeightContainer();

  const navigate = useNavigate();
  const agencyUser = organisation?.planCode?.startsWith('Agency');

  const advancedRulesButton = (item) => {
    const { keywordExclusions, andKeywords } = item;
    let numberOfItems = 0;
    if (keywordExclusions.length > 0 && andKeywords.length > 0) {
      numberOfItems = 2;
    } else if (keywordExclusions.length > 0 || andKeywords.length > 0) {
      numberOfItems = 1;
    }
    if (numberOfItems > 0) {
      return (
        <div className='keywords-adjustment py-2 border-top advanced-rules-button'>
          <p className='mb-0 paragraph-p3 mr-3'>
            + {numberOfItems} advanced rule{numberOfItems > 1 ? 's' : ''}
          </p>
        </div>
      );
    }
  };

  const allowAddTopic = maxListsAllowed === null || maxListsAllowed > teamTopics.length;

  const handleAddTopic = async () => {
    if (allowAddTopic && !organisation?.planCode?.startsWith('PolicyInfluence')) {
      navigate(`/admin/organisation/${organisationId}/topics/new-topic`, { replace: true });
    }
  };

  return (
    <>
      <div className='row mx-0 justify-content-between py-4 align-items-center'>
        <div className='d-flex  align-items-center'>
          <h3 className='my-lg-0 main-title mb-0 mr-3'>Team topics</h3>
        </div>
        <button
          className='action-button general-button px-3 filter-action-button'
          onClick={handleAddTopic}
          disabled={!allowAddTopic}
          data-tooltip-content={
            organisation?.planCode?.startsWith('PolicyInfluence')
              ? 'Cannot add topics for influence accounts'
              : allowAddTopic
                ? undefined
                : `Account has reached its topic limit`
          }
          data-tooltip-id='settings-tooltip'
          data-tooltip-place={'top'}
        >
          Add new topic
        </button>
      </div>
      <div className='box-top-rounded bg-white admin-general-content'>
        <div ref={containerRef} />
        <CustomScrollbar
          className={'topics-scrollbar'}
          style={{ height: `${heightContainer}px` }}
          maximalThumbYSize={100}
        >
          <div>
            {teamTopics.length > 0 && (
              <div>
                {teamTopics.map((item) => {
                  const { name, keywords, id, ptHandPickedContentKeywords, description } = item ?? {};
                  const handPickedConditional =
                    ptHandPickedContentKeywords !== null && ptHandPickedContentKeywords?.length > 0;
                  return (
                    <div className='topic-list-item py-4' key={`topic-${id}`}>
                      <div
                        className={`d-flex justify-content-between align-items-center flex-wrap topic-individual-padding ${
                          !description ? 'mb-3' : ''
                        }`}
                      >
                        <h4 className={`topic-list-item-name mb-0`}>
                          <span className='icon-topics-thin'></span>
                          <span className={`hidden-lines hidden-one-line`}>{name}</span>
                        </h4>

                        <div className='d-flex align-items-center email-alerts-badges topic-badges-margin-adjustment'>
                          {handPickedConditional && (
                            <p className='paragraph-p3 rounded-button badge-spacing highlighted-badge mr-lg-3 mb-3 mb-lg-0 d-inline-block'>
                              Hand-picked
                            </p>
                          )}
                          <div className='d-flex'>
                            {agencyUser && (
                              <Link
                                to={`/admin/organisation/${organisationId}/topics/${id}/organisation`}
                                className='d-block text-right mr-3'
                              >
                                <button className='general-button outline-active-button main-light-text paragraph-p3 edit-email-alert-button'>
                                  AI summaries <span className='icon-edit-pencil'></span>
                                </button>
                              </Link>
                            )}
                            <Link
                              to={`/admin/organisation/${organisationId}/topics/${id}`}
                              className='d-block text-right'
                            >
                              <button className='general-button outline-active-button main-light-text paragraph-p3 edit-email-alert-button'>
                                Edit topic <span className='icon-edit-pencil'></span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className='keywords-description-topic overflow-hidden topic-individual-padding'>
                        {!!description && <p className='main-light-text mb-3 mt-1'>{description}</p>}

                        <div className='email-alert-information-box'>
                          <div className='keywords-adjustment individual-items-vertical-padding'>
                            <p className='mb-1 no-select-item individual-topic-subtitle'>Keywords and phrases</p>
                            <p className='mb-3 mb-lg-0 individual-topic-actual-text'>{keywords.join(', ')}</p>
                            {handPickedConditional && (
                              <>
                                <p className='mt-2 mb-1 no-select-item individual-topic-subtitle'>
                                  Parliament Today hand-picked keywords and phrases
                                </p>
                                <p className='mb-3 mb-lg-0 individual-topic-actual-text'>
                                  {ptHandPickedContentKeywords.join(', ')}
                                </p>
                              </>
                            )}
                          </div>
                          {advancedRulesButton(item)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {teamTopics.length === 0 && <p className='p-5 text-center'>No lists created yet</p>}
          </div>
        </CustomScrollbar>
      </div>
    </>
  );
};

export default UserTeamTopics;
