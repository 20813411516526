import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from '../../Store';
import getUrlParam from '../utilities/getUrlParam';
import normalize from '../utilities/normalizeString';
import OverallSentiment from './ui/OverallSentiment';
import useCallOverallSentiment from './useCallOverallSentiment';
import useUpdateTitleAnalytics from './useUpdateTitleAnalytics';
import { chartDataOptions } from './createAnalyticsState';
import axios from 'axios';

const OverallSentimentContainer = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { analyticsState, keywordsLists } = state;
  const { selectedCountries } = analyticsState;
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const topicParameter = getUrlParam('topic-id');
  const noTopicParameter = topicParameter === 'none';
  const searchParameter = getUrlParam('search');

  const { customTopic, teamId, embedWidget } = props;

  const [isLoading, setIsLoading] = useState(!noTopicParameter);
  const [data, setData] = useState({
    labels: [],
    value: [],
    colours: [],
    rawData: {},
  });
  const [initialData, setInitialData] = useState({
    labels: [],
    value: [],
    colours: [],
    rawData: {},
  });

  const isLoadedFirstTime = useRef(noTopicParameter);

  const { callOverallSentiment } = useCallOverallSentiment({ teamId });
  const { updateTitleAnalytics } = useUpdateTitleAnalytics();
  const location = useLocation();
  const initialHash = location.hash.replace('#', '').split('~');

  const functionToUpdateData = useRef();
  functionToUpdateData.current = async (source) => {
    if (initialHash.length > 1 && (!noTopicParameter || searchParameter)) {
      setIsLoading(true);
      let chartDataOption = chartDataOptions.find((item) => normalize(item.name) === initialHash[1])?.name;
      let topic =
        customTopic ??
        (topicParameter
          ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
          : keywordsListsToUse[0]);
      let results = await callOverallSentiment({
        topic,
        chartDataOption,
        source,
        freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
      });
      if (!!results) {
        setData(results);
        setInitialData(results);
        updateTitleAnalytics();
        if (isLoadedFirstTime.current) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'changeOfGraphFilters', value: true });
        }
        isLoadedFirstTime.current = true;
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    functionToUpdateData.current(source);
    return () => {
      source.cancel('Mentions cancelled by the user');
    };
  }, [location.hash, customTopic, topicParameter, searchParameter, location.pathname, selectedCountries]);

  return (
    <OverallSentiment
      isLoading={isLoading}
      isLoadedFirstTime={isLoadedFirstTime}
      data={data}
      analyticsState={analyticsState}
      initialData={initialData}
      setData={setData}
      embedWidget={embedWidget}
    />
  );
};

export default OverallSentimentContainer;
