import React from 'react';
import { Link } from 'react-router-dom';
import TrialBanner from '../Dashboard/Navigation/TrialBanner';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useIsMobile from '../Dashboard/utilities/useIsMobile';
const SettingsHeader = (props) => {
  const param = getUrlParam('returnUrl');
  const returnUrl = `${param !== undefined ? decodeURIComponent(param) : '/monitor-inbox/key-updates'}`;

  const isMobile = useIsMobile();
  return (
    <>
      <TrialBanner />
      <div className='py-3 px-3 px-lg-0 settings-header'>
        <div className='mx-0 justify-content-between align-items-center row mx-auto main-content-wrapper w-100'>
          <div className='pl-md-5 pl-3 side-left-element'>
            <div className='position-relative'>
              <Link to={returnUrl} className='settings-logo d-inline-block' />
              <Link to={returnUrl} className='close-name-settings d-inline-block'>
                Close settings
              </Link>
            </div>
          </div>
          <div
            className={`main-content setting-clients-picker-container d-flex justify-content-end align-items-center pr-xl-5 px-md-4 px-3`}
          >
            <Link
              className='close-settings d-none d-lg-block'
              to={returnUrl}
              data-tooltip-content='Close settings'
              data-tooltip-id='settings-navbar-tooltip'
            />
          </div>
        </div>
      </div>
      <ReactTooltip
        id='settings-navbar-tooltip'
        effect={'solid'}
        className={'header-tooltip'}
        multiline={true}
        arrowColor={'transparent'}
        place={'right'}
        globalEventOff={isMobile ? 'click' : undefined}
        delayShow={300}
      />
    </>
  );
};
export default SettingsHeader;
