const CommitteePublicationFilters = [
  {
    name: 'House',
    pages: ['CommitteePublications'],
    filterName: 'house',
    optionSelected: '',
    options: [
      {
        name: 'Commons',
        filterValue: 'commons',
      },
      {
        name: 'Lords',
        filterValue: 'lords',
      },
      {
        name: 'Joint',
        filterValue: 'joint',
      },
    ],
  },
  {
    name: 'Select Committees',
    pages: ['CommitteePublications'],
    type: 'membership',
    subtype: 'CommitteeForPublication',
    searchState: 'SeneddOrHolyroodCondition',
    filterName: 'committee',
    options: [],
    prefix: 'select',
    optionSelected: '',
    updateFilter: true,
  },
  {
    name: 'Publication type',
    pages: ['CommitteePublications'],
    filterName: 'publicationType',
    optionSelected: '',
    options: [
      {
        name: 'Reports, special reports and government responses',
      },
      {
        name: 'Oral evidence transcripts',
      },
      {
        name: 'Written evidence',
      },
      {
        name: 'Correspondence',
      },
    ],
  },
];

export default CommitteePublicationFilters;
