import React, { useEffect, useRef, useState } from 'react';
import { ElectionContentBox } from './Election2024';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import createMarkup from '../Dashboard/helpers/createMarkup';
import ElectionSectionTitle from './ElectionSectionTitle';
import createNotification from '../Settings/Utilities/CreateNotification';
import { useHeightContainer } from '../Dashboard/utilities/useHeightContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import normalize from '../Dashboard/utilities/normalizeString';
import useIsMobile from '../Dashboard/utilities/useIsMobile';
import addMetaTags from '../Dashboard/utilities/addMetaTags';
import { useContext } from 'react';
import { store } from '../Store';
import ModalComponent from '../Common/Modal';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import copyLinkFunction from '../Dashboard/utilities/copyLinkFunction';
import useOutsideElement from '../Dashboard/utilities/useOutsideElement';
import { TwitterShareButton } from 'react-share';

const manifestos = [
  {
    label: 'Conservative',
    value: 'Conservatives',
    image: 'conservative',
    hash: 'conservative',
    selectable: true,
    link: 'https://manifesto.conservatives.com/',
    default: true,
  },
  {
    label: 'Labour',
    image: 'labour',
    hash: 'labour',
    value: 'Labour',
    link: 'https://labour.org.uk/change/',
    selectable: true,
    default: true,
  },
  {
    label: 'Lib Dem',
    value: 'LiberalDemocrats',
    image: 'libdem',
    hash: 'lib-dem',
    selectable: true,
    link: 'https://www.libdems.org.uk/manifesto',
    default: true,
  },
  {
    label: 'Reform UK',
    image: 'reformuk',
    hash: 'reform-uk',
    value: 'Reform',
    selectable: true,
    link: 'https://www.reformparty.uk/policies',
  },
  {
    label: 'SNP',
    image: 'snp',
    hash: 'snp',
    value: 'SNP',
    selectable: true,
    link: 'https://www.snp.org/manifesto',
  },
  {
    label: 'Plaid Cymru',
    image: 'plaidcymru',
    hash: 'plaid-cymru',
    value: 'PlaidCymru',
    selectable: true,
    link: 'https://www.partyof.wales/manifesto',
  },
  {
    label: 'Green Party',
    image: 'greenparty',
    hash: 'green-party',
    value: 'GreenParty',
    link: 'https://greenparty.org.uk/about/our-manifesto/',
    selectable: true,
  },
];

const Manifestos = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const location = useLocation();

  const [activeTab, setActiveTab] = useState('Analysis');
  const [comparison, setComparison] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    addMetaTags({ title: 'Explore manifestos - Election hub - PolicyMogul', hash: location.hash, location, dispatch });
  }, []);

  return (
    <>
      {' '}
      <ElectionContentBox
        header={
          <ManifestosHeader
            comparisonState={{ comparison, setComparison }}
            activeTabState={{ activeTab, setActiveTab }}
          />
        }
      >
        <div className='manifestos-container'>
          {activeTab === 'Analysis' ? (
            <ManifestosSearch
              openState={{ isOpen, setIsOpen }}
              comparisonState={{ comparison, setComparison }}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          ) : (
            <div className='py-4 px-3 px-lg-5 pb-3'>
              {manifestos.map((item) => {
                const { label, link, image } = item;
                return (
                  <div className={`manifestos-item`} key={label}>
                    <img
                      src={`${process.env.REACT_APP_CDNURL}/images/manifesto/${image}-manifesto.png`}
                      alt={label}
                      className={`manifesto-image manifesto-${image}`}
                    />
                    <div className='flex-column'>
                      <h5 className='title-h5-bold'>{label} manifesto</h5>
                      <p className='paragraph-p1'>
                        {link ? (
                          <a href={link} className='simple-link-text' target={'_blank'} rel='noopener noreferrer'>
                            The {label} manifesto is available here
                          </a>
                        ) : (
                          <span>When the {label} manifesto is announced it will be available here.</span>
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </ElectionContentBox>
      <RateLimitManifestos openState={{ isOpen, setIsOpen }} />
    </>
  );
};

const ManifestosHeader = (props) => {
  const { activeTabState, comparisonState } = props;
  const { comparison } = comparisonState ?? {};
  const { activeTab, setActiveTab } = activeTabState ?? {};
  const tabs = ['Analysis', 'Read manifestos'];

  return (
    <div className='election-keyupdates-header'>
      <ElectionSectionTitle title={'Explore manifestos'} />
      {!!comparison ? (
        <ManifestoShare />
      ) : (
        <div className='flex-centered manifesto-tabs'>
          {tabs.map((item) => {
            return (
              <button
                key={`tab-${item}`}
                className={`general-button px-2 px-lg-4 nowrap-item rounded mr-2 ml-lg-2 mr-lg-0 ${item === activeTab ? 'action-button' : 'bg-main-white border action-state-hover'}`}
                onClick={() => {
                  setActiveTab(item);
                }}
              >
                {item}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ManifestoShare = () => {
  const location = useLocation();
  const isMobile = useIsMobile();

  const [showActions, setShowActions] = useState(false);
  const actionsElement = useRef();
  const hideActions = () => {
    setShowActions(false);
  };
  useOutsideElement(actionsElement, hideActions);
  const { width, top, left, height } = actionsElement?.current?.getBoundingClientRect() ?? {};

  return (
    <>
      <div className='position-relative' ref={actionsElement}>
        <button
          className={`general-button rounded action-button text-white px-lg-4 analytics-logged-out-share-button ml-2 ml-lg-0`}
          onClick={() => {
            setShowActions(!showActions);
          }}
        >
          {isMobile ? (
            <span className='icon-share paragraph-p3' />
          ) : (
            <span
              className={`d-inline-block dropdown-item-element dropdown-item-element-10 ${showActions ? 'open' : ''}`}
              style={{ marginTop: '-8px', verticalAlign: 'middle' }}
            >
              Share
            </span>
          )}
        </button>
        {showActions && (
          <div
            className='position-fixed rounded border bg-main-white box-outstanding adjustment-of-position py-2'
            style={{
              width: '320px',
              top: `${top + height + 5}px`,
              left: `${left + width - 320}px`,
            }}
          >
            <div
              className='d-flex px-4 py-3 pointer action-state-hover'
              onClick={(e) => {
                const url = `${process.env.REACT_APP_APPURL}${location.pathname}${location.search}${location.search.includes('utm-source') ? '' : '&utm-source=share-button'}${location.hash}`;
                copyLinkFunction({ e, url, closeFunction: () => setShowActions(false) });
              }}
            >
              <div className='pt-1 analytics-share-icon-container'>
                <span className='icon-share-link title-h5-m' />
              </div>
              <div className='pl-3'>
                <p className='font-weight-bold mb-1'>Copy link to this search</p>
                <p className='main-light-text mb-0'>Share a link to this specific search with anyone</p>
              </div>
            </div>
            <TwitterShareButton
              url={`${process.env.REACT_APP_APPURL}/general-election-2024/manifestos?utm-source=twitter-share-button`}
              title={'Compare UK party manifestos on any issue:'}
              className='w-100 text-left'
            >
              <div className='d-flex px-4 py-3 pointer action-state-hover'>
                <div className='pt-1 analytics-share-icon-container'>
                  <span className='icon-twitter-x title-h5-m' />
                </div>
                <div className='pl-3'>
                  <p className='font-weight-bold mb-1'>Share election hub on X</p>
                  <p className='main-light-text mb-0'>Share election hub (without a specific search term) on X</p>
                </div>
              </div>
            </TwitterShareButton>
          </div>
        )}
      </div>
    </>
  );
};

const ManifestosSearch = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state ?? {};
  const { subscriptionStatus } = activePlan ?? {};

  const { comparisonState, openState, isLoading, setIsLoading } = props;
  const { comparison, setComparison } = comparisonState ?? {};
  const { setIsOpen } = openState ?? {};

  const location = useLocation();
  const navigate = useNavigate();

  const getPartiesFromHash = () => {
    let parties = [];
    let hashParties = location.hash?.replace('#', '')?.split('~');
    for (let i = 0; i <= hashParties.length; i++) {
      let party = manifestos.find((item) => item.hash === hashParties[i]);
      if (party) {
        parties = [...parties, party];
      }
    }
    return parties.length > 0 ? parties : manifestos.filter((item) => item.default);
  };

  const [manifestosSelected, setManifestosSelected] = useState(getPartiesFromHash());

  const [searchValue, setSearchValue] = useState('');

  const isSmallMobile = useIsMobile(480);

  const inputSearch = useRef(null);
  const { generalApiCall } = useGeneralApiCall();
  const [heightContainer, containerRef] = useHeightContainer();

  const interestQuery = getUrlParam('interest');
  const { isAuthenticated } = useAuth0();
  const searchResults = async (value = searchValue) => {
    try {
      if (value.trim() === '') {
        setComparison(null);
      } else {
        setIsLoading(true);
        const subscribedPlan =
          isAuthenticated && (subscriptionStatus === 'Subscribed' || subscriptionStatus === 'OnFreePlan');
        const pathname = `/api/manifesto/${subscribedPlan ? 'signedin-compare' : 'compare'}`;
        const method = 'post';
        const requestProperties = {
          interest: value,
          manifestos: manifestosSelected?.map((item) => `_2024_${item.value ?? item.label}`),
          format: 'html',
        };
        const results = await generalApiCall({
          pathname,
          method,
          requestProperties,
          returnError: true,
          needsAuthentication: subscribedPlan,
        });
        if (results?.status === 429) {
          if (subscribedPlan) {
            createNotification('danger', 'You have reached the limit of 15 searches in the past 24 hours');
          } else {
            setIsOpen(true);
          }
          setIsLoading(false);
          setComparison(null);
        } else {
          if (!!results) {
            setComparison(results);
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (inputSearch?.current && searchValue === '' && !isSmallMobile) {
      inputSearch?.current?.focus();
    }
  }, [comparison]);

  useEffect(() => {
    if (interestQuery && comparison === null) {
      const interestVal = decodeURIComponent(interestQuery);
      if (searchValue === '') {
        setSearchValue(interestVal);
      }
      searchResults(interestVal);
    }
  }, []);

  useEffect(() => {
    if (!interestQuery && comparison != null) {
      setComparison(null);
      setSearchValue('');
    }
  }, [interestQuery]);

  const setSearchQuery = () => {
    let urlToHistory = `${location.pathname}?interest=${encodeURIComponent(searchValue.trim())}${`#${manifestosSelected.map((party) => normalize(party.label)).join('~')}`}`;
    navigate(urlToHistory, { replace: true });
  };

  return (
    <>
      {isLoading ? (
        <div
          ref={containerRef}
          style={{ minHeight: `${heightContainer}px` }}
          className='p-lg-5 py-4 px-3 d-flex flex-column justify-content-center'
        >
          <div>
            <div className='text-center pb-5 mx-auto waiting-screen'>
              <>
                <p className='mb-2'>
                  <strong>{`PolicyMogul is analysing manifestos for you`}</strong>
                </p>
                <p>This typically takes 10 - 20 seconds, but may take longer</p>
              </>
            </div>
            <div className='waiting-bar mx-auto'>
              <div className='waiting-bar-indicator' />
            </div>
          </div>
        </div>
      ) : (
        <div className='dashboard-root-container' ref={containerRef} style={{ minHeight: `${heightContainer}px` }}>
          {!!comparison ? (
            <>
              <div className='px-3 px-lg-5 py-4'>
                <div className='flex-centered justify-content-between pb-3'>
                  <h4 className='mb-0 title-h5-m-bold'>Your search</h4>
                  <button
                    className='general-button nowrap-item link-button mt-0 rounded px-2 px-lg-4 try-another-search-button action-state-hover'
                    onClick={() => {
                      setComparison(null);
                    }}
                  >
                    <span>Try another search</span>
                  </button>
                </div>
                <p className='mb-1'>
                  <span className=''>Manifestos to compare: </span>
                  {manifestosSelected.map((manifesto, i) => (
                    <React.Fragment key={`${manifesto.label}-${i}`}>
                      {manifesto?.link ? (
                        <a
                          href={manifesto?.link}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='simple-link-text mr-1'
                        >
                          {manifesto.label}
                          {i !== manifestosSelected.length - 1 ? ',' : ''}
                        </a>
                      ) : (
                        <span className='main-light-text mr-1'>
                          {manifesto.label}
                          {i !== manifestosSelected.length - 1 ? ',' : ''}
                        </span>
                      )}
                    </React.Fragment>
                  ))}
                </p>
                <p>
                  <span className='main-light-text'>"{searchValue}"</span>
                </p>
              </div>
              <div className='bg-blue-hovered flex-grow-1 px-3 px-lg-5 py-4'>
                <div className='d-flex justify-content-between'>
                  <h4 className='mb-0 title-h5-m-bold'>Analysis</h4>
                  <p
                    className='mb-0 title-h5-m text-dark-red'
                    data-tooltip-content={
                      'This analysis was created by PolicyMogul’s AI assistant. This feature is in Beta and output may contain factual errors.'
                    }
                    data-tooltip-id={`tooltip-mobile`}
                    data-tooltip-class-name={`header-tooltip disclaimer-tooltip`}
                  >
                    <span
                      className='icon-info-circle-filled title-h3 mr-2'
                      style={{
                        display: 'inline-block',
                        transform: 'translateY(2px)',
                        lineHeight: 0.8,
                      }}
                    ></span>
                    Disclaimer
                  </p>
                </div>
                <div className='manifesto-table' dangerouslySetInnerHTML={createMarkup(comparison)} />
              </div>
            </>
          ) : (
            <>
              <div className='border-bottom py-4 px-3 px-lg-5'>
                <div>
                  <label className='title-h5-m-bold mb-1 d-block'>Choose manifestos to compare</label>
                  <div className='flex-centered flex-wrap'>
                    {manifestos.map((item) => {
                      const { label, selectable } = item;
                      return (
                        <div key={`party-${label}`} className='common-outside-filter manifesto-filter'>
                          <div
                            className={`checkbox-item position-relative mb-0 ${!selectable ? 'checkbox-item-disabled' : ''}`}
                          >
                            <input
                              id={`${label}`}
                              onChange={() => {
                                if (selectable) {
                                  let newItems = [...manifestosSelected];
                                  let itemPosition = newItems.findIndex((item) => item.label === label);
                                  let newParties = [];
                                  if (itemPosition >= 0) {
                                    newItems.splice(itemPosition, 1);
                                    newParties = [...newItems];
                                  } else {
                                    newParties = [...manifestosSelected, item];
                                  }
                                  if (newParties.length >= 1 && newParties.length <= 3) {
                                    setManifestosSelected(newParties);
                                    navigate(
                                      {
                                        search: window.location.search,
                                        hash: `#${newParties.map((party) => normalize(party.label)).join('~')}`,
                                      },
                                      { replace: true }
                                    );
                                  } else if (newParties.length < 1) {
                                    createNotification('danger', 'You must have at least 1 party selected');
                                  } else if (newParties.length > 3) {
                                    createNotification('danger', 'You can compare up to three manifestos at a time');
                                  }
                                }
                              }}
                              type={'checkbox'}
                              checked={!!manifestosSelected.find((item) => item?.label === label)}
                              disabled={!selectable}
                            />
                            <label htmlFor={label} className={`create-email-alert-label font-weight-normal`}>
                              <p className='mb-0 line-height-1-5'>{label}</p>
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='bg-blue-hovered py-4 px-3 px-lg-5 flex-grow-1'>
                <div>
                  <label className='title-h5-m-bold mb-3 d-block'>Describe your interests</label>
                  <textarea
                    ref={inputSearch}
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setSearchQuery();
                        searchResults();
                      }
                    }}
                    className='writing-textarea p-3 border rounded'
                    placeholder='Tell us about one of your policy interests using natural language '
                  />
                  <div className='text-right pt-4'>
                    <button
                      onClick={() => {
                        setSearchQuery();
                        searchResults();
                      }}
                      className='action-button px-2 px-lg-4 rounded'
                      disabled={searchValue === ''}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const RateLimitManifestos = (props) => {
  const { openState } = props;
  const { isOpen, setIsOpen } = openState ?? {};
  const { isAuthenticated } = useAuth0();

  return (
    <ModalComponent
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      maxWidth={900}
      overlayClassName='react-modal-overlay report-extra-index-modal'
    >
      <div className='bg-grey rounded-lg relative'>
        <div className='d-lg-flex'>
          <div className='bg-main-blue py-5 text-left d-none d-lg-block rounded-left-lg'>
            <h3 className='title-h3 pl-lg-5 report-prospecting-title'>
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                alt='Logo'
                className='policy-logo mr-2'
              />
            </h3>

            <ul className='policy-list text-white text-left title-h5-m py-4 mt-4 manifesto-popup-list statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
              <li className='pl-4 mb-3 py-2 nowrap-item bg-grey text-main-blue statistics-list-active-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                15 manifesto searches / day
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Powerful political monitoring
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Advanced stakeholder mapping
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Fully integrated CRM
              </li>
            </ul>
          </div>
          <div className='grow px-5 pb-5 mb-3'>
            <p className='title-h2 mt-3 pt-3 pt-lg-5 mb-4 flex-centered'>
              <span className='font-weight-bold d-inline-block pr-lg-3'>
                {'You have reached the limit of 3 searches in the past 24 hours'}
              </span>
            </p>

            <div className='title-h3 main-light-text pr-lg-4'>
              <p>
                To continue using the manifesto tracking tool today, please subscribe to{' '}
                <span className='main-subtle-text font-weight-bold'>any of our paid subscription plans</span>
              </p>
            </div>

            <div className='nowrap-item text-lg-right pt-5 mt-lg-5 d-lg-flex align-items-center justify-content-end'>
              <Link
                className='downgrade-pricing-button mt-3 mt-lg-0 action-button rounded-button py-2 px-3 px-xxl-5 d-inline-block title-h5-m text-center'
                to={`/${isAuthenticated ? 'pricing' : 'register?reg_source=election-hub-manifesto-compare'}`}
              >
                <span className='px-4'>
                  {isAuthenticated ? 'Compare plans' : 'Try PolicyMogul for free'}
                  <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2 ml-lg-3' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export { RateLimitManifestos };
export default Manifestos;
