import React, { useContext, useEffect, useRef, useState } from 'react';
import ExportMessage from '../../StakeHolders/ExportMessage';
import useOutsideElement from '../../utilities/useOutsideElement';
import normalize from '../../utilities/normalizeString';
import useGetSectionFilters from '../../Filters/useGetSectionFilters';
import axios from 'axios';
import { store } from '../../../Store';
import thousandCommas from '../../helpers/thousandCommas';
import useIsMobile from '../../utilities/useIsMobile';
import { useLocation } from 'react-router-dom';
import useValidateContactsOversubscribed from '../../utilities/useValidateContactsOversubscribed';
import useGetAccessToken from '../../apiCalls/useGetAccessToken';

const ExportContactsButton = ({ contactListBar, isLoading, onlyIcon }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, net_api_url, contentResults, activeResults, selectedCrmContacts } = state;
  const { subscriptionStatus, code } = activePlan;
  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const searchQuery = query.get('search') ? decodeURIComponent(query.get('search')) : '';

  const sortQuery = query.get('sort') ? query.get('sort').split('_') : null;
  const sort = () => {
    if (sortQuery) {
      return { field: sortQuery[0], order: sortQuery[1] };
    }
    return null;
  };

  const [exportMessage, setExportMessage] = useState(false);
  const hideExportMessage = () => {
    setExportMessage(false);
  };
  const exportMessageContainer = useRef(null);
  useOutsideElement(exportMessageContainer, hideExportMessage);
  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const sectionFilters = getSectionFilters();
  const { filters } = sectionFilters;

  const { getAccessToken } = useGetAccessToken();

  useEffect(() => {
    return () => {
      source.cancel('Export function cancelled');
    };
  }, []);

  const isSelectedCrmContacts = selectedIds.length > 0 || selectedAll || selectedAllVisible.length > 0;

  const contactFilter = (id) => {
    return { field: 'id', operator: 'str_eq', value: `${id}` };
  };

  const exportResultsFunction = async () => {
    try {
      let token = await getAccessToken();
      let requestBody = {};

      if (filters) {
        requestBody = { ...requestBody, filters };
      }

      if (sortQuery) {
        requestBody = { ...requestBody, sort: sort() };
      }

      if (isSelectedCrmContacts) {
        if (selectedIds.length > 0) {
          requestBody = {
            ...requestBody,
            filters: selectedIds.map((id) => contactFilter(id)),
          };
        } else if (selectedAllVisible.length > 0) {
          requestBody = {
            ...requestBody,
            filters: selectedAllVisible.map((id) => contactFilter(id)),
          };
        } else {
          requestBody = { ...requestBody, query: searchQuery };
        }
      }
      requestBody = { ...requestBody, query: searchQuery };

      let url = `${net_api_url}/api/crm-contact/export-to-csv`;
      let exportResults = await axios.post(url, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      let csvContent = `data:text/csv;charset=utf-8,${exportResults.data}`;
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement('a');
      let fileName = () => {
        return 'Contacts';
      };
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${normalize(fileName().trim())}.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
    } catch (error) {}
  };

  const CrmContacts = contentResults[activeResults].CrmContacts;
  const isMobile = useIsMobile(768);

  const numberOfResults = () => {
    if (isSelectedCrmContacts && CrmContacts) {
      if (selectedIds.length > 0) {
        return !isMobile ? `${thousandCommas(selectedIds.length)}` : '';
      } else if (selectedAllVisible.length > 0) {
        return !isMobile ? `${thousandCommas(selectedAllVisible.length)}` : '';
      } else {
        return !isMobile ? `${thousandCommas(CrmContacts.totalHits)}` : '';
      }
    }
    if (CrmContacts) {
      return !isMobile && CrmContacts && CrmContacts.totalHits ? `${thousandCommas(CrmContacts.totalHits)}` : '';
    }
  };
  const onClickHandler = () => {
    validateContactsOversubscribed(() => {
      if (subscriptionStatus === 'Subscribed' || subscriptionStatus === 'OnFreePlan') {
        exportResultsFunction();
      } else {
        setExportMessage(!exportMessage);
      }
    }, 'export');
  };
  const disabled = !CrmContacts || (CrmContacts && CrmContacts.currentlyProcessing) || !CrmContacts?.totalHits;
  return (
    <>
      {contactListBar ? (
        <button
          className={`contacts-list-bar-action-container-btn ${!onlyIcon ? 'action-container-btn-expanded' : ''}`}
          onClick={() => {
            if (!onlyIcon) {
              onClickHandler();
            }
          }}
          disabled={disabled || isLoading}
        >
          <span className={`icon-download ${disabled || isLoading ? 'icon-download-disabled' : ''}`} />
          {onlyIcon ? '' : 'Export'}
        </button>
      ) : (
        <div className={`ml-2 filter-main-container position-relative`} ref={exportMessageContainer}>
          <button
            onClick={onClickHandler}
            disabled={disabled}
            className={`sort-button button-export-results ${
              disabled && !contactListBar ? 'button-export-contacts' : ''
            }`}
          >
            <span className='filter-name'>
              Export {numberOfResults()} result
              {numberOfResults() === 1 ? '' : 's'}
            </span>
          </button>
        </div>
      )}

      {exportMessage && (
        <ExportMessage
          code={code}
          identifier={'export-message-popup'}
          setShowPopup={setExportMessage}
          additionalClass={'export-message-popup'}
          contactsPage={true}
        />
      )}
    </>
  );
};

export default ExportContactsButton;
