import { useRef, useState } from 'react';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import CustomScrollbar from '../Common/CustomScrollbar';
import { getOS } from '../../utils/getOS';

const TitleWidget = (props) => {
  const { analyticsKeywordsLists, topic, setTopic, header, subheader, selectedTopics, userId, isFromPopup } = props;
  const [openLists, setOpenLists] = useState(false);
  const topicsContainer = useRef(null);
  const topicName = useRef(null);

  const topicParameter = getUrlParam('topic-id');
  const topicNameToShow = !!topic
    ? topic.name
    : topicParameter
      ? analyticsKeywordsLists.find((item) => item.id === parseInt(topicParameter))?.name
      : analyticsKeywordsLists[0]?.name;

  const multipleKeywordLists = analyticsKeywordsLists.length > 1;
  const left = topicName.current?.offsetLeft ?? 0;
  const top = topicName.current?.offsetTop ?? 0;
  const width = topicsContainer?.current?.getBoundingClientRect()?.width - left;
  const finalWidth = width > 300 ? width : 300;

  return (
    <div className='position-relative pt-5 pb-3 embed-analytics-topic-picker embed-analytics-widget-title'>
      <div className='embed-padding'>
        <div className='widget-title-container' style={{ paddingLeft: '8px' }}>
          {!!header && <h3 className='main-title mb-2'>{header}</h3>}
          <p
            className={`${!!header ? ' main-description' : 'main-title font-weight-bold'} mb-0 pr-1`}
            style={{ lineHeight: '1.5' }}
          >
            <span className='main-description-text'>{subheader} </span>
            {selectedTopics?.length > 1 && !userId && (
              <span
                ref={topicName}
                className={`${
                  multipleKeywordLists
                    ? `pr-3 dropdown-item-element dropdown-item-element-11 text-underline-hover pointer ${openLists ? 'open text-underline' : ''}`
                    : ''
                }`}
                onClick={() => {
                  if (multipleKeywordLists) {
                    setOpenLists(!openLists);
                  }
                }}
              >
                {topicNameToShow}
              </span>
            )}
          </p>
        </div>
        {openLists && (
          <>
            <div className='general-close-background' onClick={() => setOpenLists(false)} />
            <div
              ref={topicsContainer}
              className={`box-shadow position-${isFromPopup ? 'absolute' : 'fixed'} rounded`}
              style={{
                minWidth: '300px',
                width: `${finalWidth}px`,
                top: `${top + 28}px`,
                left: `${left + finalWidth > window.innerWidth ? window.innerWidth - (finalWidth + 20) : left}px`,
                zIndex: 10000,
              }}
            >
              <CustomScrollbar
                autoHeight
                autoHeightMin='100%'
                autoHeightMax={`${400}px`}
                className={`simple-scrollbar ${getOS() === 'Windows' ? 'title-widget-lists' : ''}`}
                maximalThumbYSize={100}
              >
                {analyticsKeywordsLists
                  ?.filter((item) => !!selectedTopics.find((topic) => item.id === topic.id))
                  ?.map((list) => {
                    const { name, id } = list;
                    return (
                      <div
                        key={`topic-${id}`}
                        className={`list-action-button`}
                        onClick={() => {
                          setTopic(list);
                          setOpenLists(false);
                        }}
                      >
                        <li className={`dropdown-keywordlist d-flex title-h5-m`}>
                          <div className='flex-grow-1'>
                            <p className='hidden-lines hidden-one-line dropdown-name mb-0'>{name}</p>
                            {topic?.id === id && (
                              <span className='icon-tick topic-selected-mark text-main-blue paragraph-p2' />
                            )}
                          </div>
                        </li>
                      </div>
                    );
                  })}
              </CustomScrollbar>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TitleWidget;
