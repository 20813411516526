import React, { useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import TeamPlan from './ui/TeamPlan';
import CreateNotification from '../Settings/Utilities/CreateNotification';
import { AdminStore } from './AdminState';
import { useGetTeamTopics } from './useGetTeamTopics';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';

const TeamPlanPage = () => {
  const adminState = useContext(AdminStore);
  const { state, dispatch } = adminState;
  const { organisation } = state ?? {};
  const { generalApiCall } = useGeneralApiCall();

  const params = useParams();
  const { id } = params;

  const { getTeamTopics } = useGetTeamTopics();
  const generalSource = useRef();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    generalSource.current = source;
    return () => {
      source.cancel('Admin plan page cancelled by the user');
    };
  }, []);

  const endTeamTrialRequest = async (teamId) => {
    let method = 'post';
    let pathname = '/api/admin/end-team-trial';
    let requestProperties = {
      organisationId: parseInt(teamId),
    };
    await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: generalSource.current,
    });
    const organisation = await getTeamTopics({ organisationId: id });
    if (!!organisation) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'organisation', value: organisation });
    }
    CreateNotification('success', `Team Trial Ended Successfully`);
  };

  const setTeamTrialRequest = async (teamId, planCode, trialEndDateTime) => {
    let method = 'post';
    let pathname = '/api/admin/set-team-trial';
    let requestProperties = {
      organisationId: parseInt(teamId),
      PlanCode: planCode,
      TrialEndDateTime: trialEndDateTime,
    };
    let result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: generalSource.current,
    });
    if (result) {
      const organisation = await getTeamTopics({ organisationId: id });
      if (!!organisation) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'organisation', value: organisation });
      }
      CreateNotification('success', `Team Trial Set Successfully`);
    }
  };

  const updateTeamTrialRequest = async (teamId, planCode, trialEndDateTime) => {
    let method = 'post';
    let pathname = '/api/admin/update-team-trial';
    let requestProperties = {
      organisationId: parseInt(teamId),
      PlanCode: planCode,
      TrialEndDateTime: trialEndDateTime,
    };
    let result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: generalSource.current,
    });
    if (result) {
      const organisation = await getTeamTopics({ organisationId: id });
      if (!!organisation) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'organisation', value: organisation });
      }
      CreateNotification('success', `Team Trial Updated Successfully`);
    }
  };

  return (
    <>
      <TeamPlan
        team={organisation}
        endTeamTrialRequest={endTeamTrialRequest}
        setTeamTrialRequest={setTeamTrialRequest}
        updateTeamTrialRequest={updateTeamTrialRequest}
      />
    </>
  );
};

export default TeamPlanPage;
