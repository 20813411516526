import MicroModal from 'micromodal';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import createNotification from '../../../../../Settings/Utilities/CreateNotification';
import { store } from '../../../../../Store';
import thousandCommas from '../../../../helpers/thousandCommas';
import { useRef } from 'react';
import normalize from '../../../../utilities/normalizeString';
import useContactsApiCalls from '../../useContactsApiCalls';
import useGetContactsTags from '../../../../Filters/useGetContactsTags';

const createTagFilterOption = (array) => {
  return array.map((tag) => {
    return { label: tag, value: tag };
  });
};

function RemoveContactTagsModal({ totalHits = 0 }) {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { selectedCrmContacts, contentResults, activeResults, selectedContactTags } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const { removeTagsFromContacts } = useContactsApiCalls();
  const options = useRef([...createTagFilterOption(selectedContactTags)]);
  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;

  const { upadateContactTags } = useGetContactsTags({ notCallInitially: true });

  const hideRemoveContactTagsModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'deleteStakeholderId',
      value: null,
    });
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showRemoveContactTagsModal',
      value: false,
    });
  };

  useEffect(() => {
    MicroModal.show('resume-topic-email-alert', {
      awaitCloseAnimation: true,
    });
  }, []);

  const closeMicroModal = () => {
    MicroModal.close('resume-topic-email-alert');
    setTimeout(() => {
      hideRemoveContactTagsModal();
    }, 300);
  };

  const contactsCount =
    selectedAllVisible.length > 0 ? selectedAllVisible.length : selectedIds.length > 0 ? selectedIds.length : totalHits;

  const handleChange = (e) => {
    if (e.target.checked) {
      setTags([...tags, e.target.value]);
    } else {
      setTags([...tags.filter((tag) => tag !== e.target.value)]);
    }
  };

  const handleRemoveTags = async () => {
    try {
      const contactIds = selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds;
      setIsLoading(true);
      let result = await removeTagsFromContacts({ tags, contactIds });
      if (result || result?.status === 200) {
        createNotification(
          'success',
          `Tag${tags.length > 1 ? 's' : ''} removed from ${contactsCount > 1 ? `${thousandCommas(contactsCount)} contacts` : 'contact'} successfully`
        );
        //update contactsTags value in store in case if there is no more such tag
        upadateContactTags();
        let newCrmContacts;
        if (selectedAll) {
          newCrmContacts = contentResults[activeResults].CrmContacts?.hits.map((stakeholder) => {
            return {
              ...stakeholder,
              tags: [...stakeholder.tags.filter((item) => !tags.includes(item))],
            };
          });
        } else {
          newCrmContacts = contentResults[activeResults].CrmContacts?.hits.map((stakeholder) => {
            if (contactIds.includes(stakeholder.id)) {
              return {
                ...stakeholder,
                tags: [...stakeholder.tags.filter((item) => !tags.includes(item))],
              };
            } else {
              return stakeholder;
            }
          });
        }

        // ADD TAG TO GLOBAL STORE TOO, TO AVOID RELOAD CONTACTS
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: {
            ...contentResults,
            [activeResults]: {
              CrmContacts: {
                ...contentResults[activeResults].CrmContacts,
                hits: newCrmContacts,
              },
            },
          },
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedCrmContacts',
          value: {
            selectedAll: false,
            selectedIds: [],
            selectedAllVisible: [],
          },
        });
        setTags([]);
        closeMicroModal();
      }
      setIsLoading(false);
    } catch (error) {}
  };
  return (
    <div
      className={`modal micromodal-slide topic-modal create-download-chart-simple-modal`}
      id={`resume-topic-email-alert`}
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div
          className='transparent-close-modal'
          onClick={() => {
            closeMicroModal();
          }}
        />
        <div
          className='modal__container pt-4'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-alerts-title'
          style={{ minHeight: 'unset', maxWidth: '558px' }}
        >
          <div>
            <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 pl-3 pr-5'>
              Select tags to remove them from{' '}
              {contactsCount === 1 ? `this contact` : `these ${thousandCommas(contactsCount)} contacts`}
            </h3>
            <div className='remove-contacts-wrapper pt-2 px-3 px-lg-5'>
              {options.current.map((tag) => (
                <div
                  className={`checkbox-item filter-item-${normalize(tag.value)}`}
                  key={`filter-item-${normalize(tag.value)}`}
                >
                  <input
                    id={normalize(tag.value)}
                    onChange={handleChange}
                    type={`checkbox`}
                    checked={tags.includes(tag.value)}
                    value={tag.value}
                  />
                  <label htmlFor={normalize(tag.value)}>{tag.value}</label>
                </div>
              ))}
            </div>
            <p className='remove-contacts-helper-text px-lg-5 px-3 mt-2'>
              <span className='remove-contacts-helper-text-icon mr-2' />
              These changes impact all members of your team
            </p>
            <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white pt-4'>
              <button onClick={closeMicroModal} className='danger-button-outlined px-3 py-2 mr-3' disabled={isLoading}>
                Cancel
              </button>
              <button
                onClick={handleRemoveTags}
                className='danger-button px-3 py-2'
                disabled={isLoading || tags.length === 0}
              >
                {isLoading ? (
                  <i
                    className='fas fa-spinner fa-spin'
                    style={{
                      margin: '0 41.2px',
                      color: '#fcfcfc',
                      marginTop: '2px',
                      width: '33.7px',
                    }}
                  ></i>
                ) : tags.length === 1 ? (
                  `Remove tag`
                ) : (
                  `Remove ${tags.length > 0 ? thousandCommas(tags.length) : ''} tags`
                )}
              </button>
            </div>
          </div>
          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default RemoveContactTagsModal;
