import { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { store } from '../../Store';
import getUrlParam from '../utilities/getUrlParam';
import returnParamValue from '../helpers/returnParamValue';
import useEditReferenceState from './useEditReferenceState';

const useModifyReferencePopupItems = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { referenceState } = state;

  const navigate = useNavigate();
  const location = useLocation();

  const initialReferenceState = JSON.parse(JSON.stringify(referenceState));
  const [currentKeywordCategory, setCurrentKeywordCategory] = useState({
    ...initialReferenceState,
    andKeywords: returnParamValue('and'),
    keywordExclusions: returnParamValue('not'),
  });

  const { parliamentaryRecordWidgetSection } = useEditReferenceState();

  const setValue = ({ property, newValue }) => {
    let newIformation = { ...currentKeywordCategory, activeReference: true, [property]: newValue };
    setCurrentKeywordCategory(newIformation);
  };

  const applyTopic = (topic) => {
    const { keywords, andKeywords, keywordExclusions } = topic;
    setCurrentKeywordCategory({
      ...currentKeywordCategory,
      activeReference: true,
      keywords: keywords,
      andKeywords: andKeywords,
      keywordExclusions: keywordExclusions,
    });
  };

  const applySearch = () => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'referenceState', value: currentKeywordCategory });
    modifyHistorySearch(currentKeywordCategory);
  };

  const modifyHistorySearch = (props) => {
    //params startdate
    const { keywords, andKeywords, keywordExclusions } = props;
    let pathname = `${parliamentaryRecordWidgetSection ? location.pathname : '/search-results'}`;

    navigate(
      `${pathname}?or=${encodeURIComponent(keywords.join(','))}${andKeywords.length > 0 ? `&and=${encodeURIComponent(andKeywords.join(','))}` : ''}${keywordExclusions.length > 0 ? `&not=${encodeURIComponent(keywordExclusions.join(','))}` : ''}${getUrlParam('contact-id') && parliamentaryRecordWidgetSection ? `&contact-id=${getUrlParam('contact-id')}` : ''}`
    );
  };

  const clearSearch = () => {
    setCurrentKeywordCategory({
      activeReference: false,
      keywords: [],
      andKeywords: [],
      keywordExclusions: [],
    });
  };

  const changeCurrentKeywordCategory = ({ property, value }) => {
    setCurrentKeywordCategory({
      ...currentKeywordCategory,
      [property]: value,
    });
  };

  return { currentKeywordCategory, setValue, applySearch, applyTopic, clearSearch, changeCurrentKeywordCategory };
};

export default useModifyReferencePopupItems;
