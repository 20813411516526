import generatePDF, { Resolution } from 'react-to-pdf';
import { useEffect, useRef, useState } from 'react';
import { IconPicker } from './Macros';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { formatSimpleGeneralDate } from '../../Dashboard/helpers/formatGeneralDate';
import ReportDesignButton, { DesignPopupInsideMoreButton } from './ReportDesignButton';
import ModalComponent from '../../Common/Modal';
import dayjs from 'dayjs';
import createNotification from '../../Settings/Utilities/CreateNotification';
import { Portal } from 'react-portal';
import { CalendarPopup } from '../../Dashboard/Filters/ui/DateFilter';

const ReportsTopNav = (props) => {
  const {
    targetRef,
    layoutState,
    reportTitle,
    setReportTitle,
    previewModeState,
    reportsFunctions,
    report,
    isLoading,
    refreshContentUpdated,
    scrollBarRef,
    changesCondition,
    designProps,
  } = props;
  const { title, icon } = reportTitle ?? {};
  const { deleteReport, saveOrCreateReport } = reportsFunctions;
  const { previewMode, setPreviewMode } = previewModeState;
  const titleToShow = !!title ? title : 'Untitled report';
  const [loadingStates, setLoadingStates] = useState({
    loadingPdf: false,
    saveReport: false,
    deleteReport: false,
    previewReport: false,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [properties, setProperties] = useState({ width: 0, height: 0 });
  const { width, height } = properties;

  const options = {
    filename: `${titleToShow}.pdf`,
    resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: 0,
      // default is 'A4'
      format: [Math.ceil(width * 0.26458), Math.ceil(height * 0.26458) + 0.1],
      orientation: width > height ? 'landscape' : 'portrait',
    },
  };

  useEffect(() => {
    const { width, height } = targetRef?.current?.getBoundingClientRect() ?? {};
    setProperties({ width, height });
  }, [previewMode, targetRef?.current]);

  useEffect(() => {
    //trigger on click browser 'back' button and reset preview mode
    if (previewMode && !location.pathname.endsWith('/preview')) {
      setPreviewMode(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div className={`bg-white monitor-items-bar inbox-items-bar z-200 position-relative reports-top-nav`}>
        <div className='mx-auto main-content-wrapper flex-centered justify-content-between resize-elements-container'>
          <div className='side-left-element pl-md-5 pl-3'>
            <Link className='general-button action-button rounded-pill px-4 py-2 paragraph-p1' to={`/reports`}>
              <span className='icon-long-arrow-left mr-2 paragraph-p4' /> Back to reports
            </Link>
          </div>
          <div className='main-content flex-centered justify-content-between pr-xl-5 px-md-4 px-3'>
            <div>
            {isLoading ? (
              <SkeletonTheme baseColor='#fff'>
                <Skeleton height={'24px'} width={'400px'} />
              </SkeletonTheme>
            ) : (
              <div className='position-relative mr-3 flex-centered'>
                <IconPicker
                  icon={icon}
                  element={icon ? null : () => <span className='icon-document title-h3' />}
                  iconClass={'report-top-nav-selector mr-2'}
                  onEmojiSelect={(emoji) => {
                    setReportTitle({ ...reportTitle, icon: emoji.id });
                  }}
                />
                <p className={`mb-0 title-h4 monitor-dropdown-item report-title`}>
                  <span className='hidden-lines hidden-one-line'>{titleToShow}</span>
                </p>
              </div>
            )}
          </div>
            <div className={``}>
            <div className='d-flex justify-content-end'>
              {previewMode ? (
                <>
                  <button
                    className='general-button rounded-pill border px-4 mr-3 hovered-button nowrap-item'
                    onClick={() => {
                      setPreviewMode(false);
                      navigate({ pathname: `${location.pathname?.replace('/preview', '')}` });
                    }}
                  >
                    <span className='icon-long-arrow-left mr-2 paragraph-p5' />
                    Continue editing
                  </button>

                  <button
                    className='general-button action-button rounded-pill px-4 nowrap-item'
                    disabled={loadingStates?.loadingPdf}
                    onClick={async () => {
                      setLoadingStates({ ...loadingStates, loadingPdf: true });
                      await saveOrCreateReport({});
                      await generatePDF(targetRef, options);
                      setLoadingStates({ ...loadingStates, loadingPdf: false });
                    }}
                  >
                      {loadingStates?.loadingPdf && (
                        <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />
                      )}{' '}
                    Download PDF
                  </button>
                </>
              ) : (
                <>
                  {!report?.id && <ReportDesignButton designProps={designProps} />}

                  <button
                    className={`general-button rounded-pill border px-4 mr-3 hovered-button nowrap-item`}
                    disabled={loadingStates?.saveReport || !changesCondition}
                    onClick={async () => {
                      setLoadingStates({ ...loadingStates, saveReport: true });
                      await saveOrCreateReport({});
                      navigate('/reports');
                    }}
                  >
                      {loadingStates?.saveReport && (
                        <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />
                      )}{' '}
                    Save and close
                  </button>

                  <button
                    className='general-button action-button rounded-pill px-4 nowrap-item'
                    onClick={() => {
                      setLoadingStates({ ...loadingStates, previewReport: true });
                      setTimeout(() => {
                        setLoadingStates({ ...loadingStates, previewReport: false });
                        scrollBarRef.current.scrollToTop();
                        navigate({ pathname: `${location.pathname}/preview`, search: location.search });
                        setPreviewMode(true);
                      }, 300);
                    }}
                  >
                    {loadingStates?.previewReport && (
                      <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />
                    )}{' '}
                    Preview and share
                  </button>
                  {!!report?.id && (
                    <>
                      <ReportMoreButton
                        deleteReport={deleteReport}
                        report={report}
                        layoutState={layoutState}
                        refreshContentUpdated={refreshContentUpdated}
                        designProps={designProps}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

const ReportMoreButton = (props) => {
  const { deleteReport, report, refreshContentUpdated, layoutState, designProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [isOpenDesign, setIsOpenDesign] = useState(false);

  const actionsElement = useRef();
  const { top, left, width, height } = actionsElement?.current?.getBoundingClientRect() ?? {};

  return (
    <>
      {isOpen && <div className='general-close-background' onClick={() => setIsOpen(false)} />}
      <div className='position-relative' ref={actionsElement}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`ml-3 general-button rounded-pill border px-4 hovered-button nowrap-item`}
        >
          {<span className='dropdown-item-element dropdown-item-element-10'>More</span>}{' '}
        </button>
        {isOpen && (
          <Portal>
            <div
              className={`bg-main-white rounded border py-3 position-fixed z-extra-max report-more-options-popup`}
              style={{ top: `${top + height + 2}px`, left: `${left + width - 330}px` }}
            >
              <div className='d-flex align-items-start pointer report-popup-specific-option mb-1'>
                <span className='icon-calendar title-h5-m mt-1' />
                <div className='pl-3' onClick={() => setIsOpenCalendar(true)}>
                  <p className='title-h5-bold mb-0'>
                    Modify data cut-off date{' '}
                    <span
                      className='icon-info main-light-text d-inline-block-centered ml-2'
                      data-tooltip-content={
                        'Widgets which contain updates or analysis display content or data up until the time this report was created. You can choose to display older or newer content by modifying the cut-off date.'
                      }
                      data-tooltip-id={'general-tooltip'}
                      data-tooltip-class-name={'header-tooltip digest-tooltip'}
                      data-tooltip-place={'bottom'}
                    />
                  </p>
                  <p className='light-50-text mb-0'>Data currently up to {formatSimpleGeneralDate(report?.updateTo)}</p>
                </div>
              </div>
              <p
                className='my-1 pointer flex-centered report-popup-specific-option report-popup-specific-option-change-design font-weight-bold'
                onClick={() => setIsOpenDesign(true)}
              >
                <img
                  style={{ width: '18px', marginLeft: '-2px', marginRright: '-1px' }}
                  src={`${process.env.REACT_APP_CDNURL}/images/20240121-reports-design-icon.png`}
                  alt='Change design'
                />
                <span className='pl-3'>Change design</span>
              </p>

              <p
                className='text-main-danger mb-0 pointer flex-centered report-popup-specific-option report-popup-specific-option-delete font-weight-bold'
                onClick={() => setIsOpenConfirmDelete(true)}
              >
                <span className='icon-delete text-main-danger' />
                <span className='pl-3'>Delete report</span>
              </p>
            </div>
          </Portal>
        )}
      </div>
      <ConfirmDeletePopup
        isOpen={isOpenConfirmDelete}
        setIsOpen={setIsOpenConfirmDelete}
        deleteReport={deleteReport}
        report={report}
      />
      <CalendarSelectReportUpdateTo
        isOpen={isOpenCalendar}
        setIsOpen={setIsOpenCalendar}
        refreshContentUpdated={refreshContentUpdated}
        layoutState={layoutState}
        report={report}
      />
      <DesignPopupInsideMoreButton isOpen={isOpenDesign} setIsOpen={setIsOpenDesign} designProps={designProps} />
    </>
  );
};

const CalendarSelectReportUpdateTo = (props) => {
  const { isOpen, setIsOpen, refreshContentUpdated, report, layoutState } = props;
  const { layout } = layoutState;
  const [selectedDay, setSelectedDay] = useState(report?.updateTo ? dayjs(report?.updateTo).toDate() : new Date());
  const [isLoading, setIsLoading] = useState(false);

  const ApplyCalendarFilters = async () => {
    try {
      setIsLoading(true);
      await refreshContentUpdated({ layout, updateDate: selectedDay });
      createNotification('success', 'Content updated');
      setIsOpen(false);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <CalendarPopup
          calendarState={{
            active: isOpen,
            filterProperty: '',
            selectedDay: new Date(selectedDay),
            name: '',
            range: { from: '', to: '' },
            isLoading,
          }}
          setCalendarState={(obj) => {
            setSelectedDay(dayjs(obj?.selectedDay).toDate());
          }}
          ApplyCalendarFilters={ApplyCalendarFilters}
          setShowPopup={(val) => setIsOpen(val)}
        />
      )}
    </>
  );
};

const ConfirmDeletePopup = (props) => {
  const { isOpen, setIsOpen, deleteReport, report } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ModalComponent
      maxWidth={500}
      isOpen={isOpen}
      overlayClassName='react-modal-overlay report-extra-index-modal'
      setIsOpen={setIsOpen}
    >
      <div className='p-5 text-center'>
        <p className='title-h5-m'>Are you sure you want to delete this report?</p>
        <button
          className='general-button danger-button px-4 py-2'
          disabled={isLoading}
          onClick={async () => {
            try {
              setIsLoading(true);
              await deleteReport({ id: report?.id });
            } catch (error) {
              setIsLoading(false);
            }
          }}
        >
          {isLoading && <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />}
          Delete report
        </button>
      </div>
    </ModalComponent>
  );
};

export default ReportsTopNav;
