import React from 'react';
import Popup from '../../../Common/PopUp';
import useEmailComponentFunctions from './useEmailComponentFunctions';
import { Portal } from 'react-portal';

const ContentPopup = ({ closeMicroModal }) => {
  const { isLoading, removeEmail } = useEmailComponentFunctions();

  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
      <div className='text-center'>
        <p>Are you sure you want to delete this draft?</p>

        <div className='my-4'>
          <button
            className='general-button danger-button px-4 py-2'
            onClick={async (e) => {
              let deleteAction = await removeEmail();
              if (deleteAction) {
                closeMicroModal();
              }
            }}
          >
            {isLoading === 'delete' ? (
              <i className='fas fa-spinner fa-spin' style={{ marginRight: '2px' }}></i>
            ) : (
              'Delete draft'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const ConfirmDiscardPopup = (props) => {
  return (
    <Portal>
      <Popup {...props} maxWidth={500}>
        <ContentPopup {...props} />
      </Popup>
    </Portal>
  );
};

export default ConfirmDiscardPopup;
