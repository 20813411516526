import { useParams } from 'react-router-dom';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import useHiddenItemsFunctions from '../Dashboard/hooks/useHiddenItemsFunctions';
import validateFields from '../Login/RegistrationVerification/validateFields';
import createNotification from '../Settings/Utilities/CreateNotification';
import randomPassword from './CreateRandomPasswords';
import { useAdminAgencyUser } from './ui/UserSideNav';

const useCreateANewTeam = (props) => {
  const {
    fields,
    setFields,
    source,
    results,
    setResults,
    setShowSuccessMessage,
    userPage,
    temporaryPassword,
    setTemporaryPassword,
    localKeywordList,
    localClients,
    loadTeamLists,
  } = props;

  const { updateHiddenItemState, updateHiddenClientState } = useHiddenItemsFunctions();
  const { newAgencyPlan } = useAdminAgencyUser();

  const { generalApiCall } = useGeneralApiCall();
  const params = useParams();
  const { id } = params;

  //apiCall

  const createFunction = async (e) => {
    let formFields = fields;
    let setFormFields = setFields;
    let validation = validateFields({ formFields, setFormFields });
    if (validation) {
      let element = e.target;
      element.disabled = true;
      const compiledFields = {};
      fields.forEach((element) => {
        compiledFields[element.name] = element.value;
      });
      const { email, firstName, lastName, userType, organisation, phoneNumber, teamRole } = compiledFields;
      const useOrganisationField = userType !== 'Other' && userType !== 'Parliamentarian' && userType !== 'Campaigner';
      let password = randomPassword.generate(16);
      setTemporaryPassword(password);
      const requestProperties = {
        password,
        email,
        firstName,
        lastName,
        emailVerified: true,
        fullName: `${firstName} ${lastName}`,
        userType: userType,
        phoneNumber,
        organisation: useOrganisationField ? organisation : '',
        jobTitle: userType === 'Other' ? organisation : '',
      };
      const pathname = userPage
        ? `/api/admin/add-user-to-team?organisationId=${id}&teamRole=${teamRole === 'Team member' ? 'Member' : teamRole}`
        : `/api/admin/create-user-and-team`;
      const method = 'post';
      let newUser = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        requestSource: source,
      });
      if (newUser) {
        let items = userPage ? results : results?.results;
        let newResults = [...items];
        let newUserInformation = newUser.user;
        const { email, userID } = newUserInformation;
        let newItem = newUserInformation;
        if (userPage) {
          newItem = {
            ...newItem,
            id: newUser?.createdUserDbId,
            emailAddress: email,
            teamRole: teamRole,
            auth0Id: userID,
          };
          if (newAgencyPlan) {
            if (localClients?.length) {
              await Promise.all(
                localClients
                  .filter((item) => item.myState !== 'Enabled')
                  .map(
                    async (item) =>
                      await updateHiddenClientState({
                        state: item.myState,
                        userId: newUser.createdUserDbId,
                        teamId: item.teamId,
                        showSuccsessMessage: false,
                        adminPage: true,
                      })
                  )
              );
            }
          } else {
            if (localKeywordList.length) {
              await Promise.all(
                localKeywordList
                  .filter((item) => item.myState !== 'Enabled')
                  .map(
                    async (item) =>
                      await updateHiddenItemState({
                        state: item.myState,
                        userId: newUser.createdUserDbId,
                        listId: item.id,
                        showSuccsessMessage: false,
                        adminPage: true,
                      })
                  )
              );
            }
          }
          newResults.unshift(newItem);
          setResults(newResults);
        } else {
          if (loadTeamLists) {
            await loadTeamLists({});
          }
        }
        setShowSuccessMessage(true);
      }
      element.disabled = false;
    }
  };

  const copyCredentials = () => {
    let tempInput = document.createElement('input');
    tempInput.style = 'position: absolute; left: -1000px; top: -1000px;';
    tempInput.value = `${temporaryPassword}`;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    createNotification('success', `Credentials copied`);
  };
  return { createFunction, copyCredentials };
};

export default useCreateANewTeam;
