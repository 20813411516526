import React, { useCallback, useEffect, useRef, useState } from 'react';
import normalize from '../../utilities/normalizeString';
import DayPicker, { DateUtils } from 'react-day-picker';
import { useMainSectionParameter } from '../../helpers/useMainSectionParameter';
import { useCollapse } from 'react-collapsed';
import { useParams } from 'react-router-dom';
import useIsMobile from '../../utilities/useIsMobile';
import MicroModal from 'micromodal';
import { normalizeMonthsNames, normalMonths, shortMonthsLowerCase } from '../../utilities/normalizedDateUK';
import { Portal } from 'react-portal';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
const utc = require('dayjs/plugin/utc');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: shortMonthsLowerCase,
});

const getDateInputValue = (e) => {
  //OP: need to keep this config set up here, because we lost months configuration outside of func
  dayjs.updateLocale('en', {
    monthsShort: shortMonthsLowerCase,
    months: normalMonths.map((month) => month.toLowerCase()),
  });
  return dayjs(
    e.target.value.toLowerCase()?.replaceAll(',', ''),
    ['YYYY MM D', 'D MMMM YYYY', 'D MMM YYYY', 'D MMM YY', 'D MMM', 'D MM YYYY', 'D MM YY', 'D MMMM YY'],
    undefined,
    false
  );
};

const DateOptions = (props) => {
  const { filter, selectOption, notIncludeName, notShowCalendar } = props;
  const { options, optionSelected, name } = filter;

  const params = useParams();
  const { referenceSection, searchResultsSection, inboxSection } = useMainSectionParameter(params);
  const outsideFilters = referenceSection || searchResultsSection;
  const splitItemValue = 3;

  const normalOptions = () => {
    if (outsideFilters) {
      return options.filter((option) => !option.showCalendar).slice(0, splitItemValue);
    } else {
      return options.filter((option) => !option.showCalendar);
    }
  };

  const additionalOptions = () => {
    let optionsInitial = options.filter((option) => !option.showCalendar);
    return optionsInitial.slice(splitItemValue, optionsInitial.length);
  };

  const calendarOptions = options.filter((option) => option.showCalendar);

  const [calendarState, setCalendarState] = useState({
    active: false,
    filterProperty: '',
    selectedDay: new Date(),
    name: '',
    range: { from: '', to: '' },
  });

  const ApplyCalendarFilters = () => {
    let localOptions = [...calendarOptions];
    let optionsToKeep = options.filter((option) => !option.showCalendar);
    let currentOption = localOptions.find((item) => item.filterProperty === calendarState.filterProperty);
    let filterToApply;
    let dates;

    if (currentOption.filterProperty === 'range') {
      filterToApply = [
        {
          field: 'dateTime',
          value: `${dayjs(calendarState.range.from).utc().startOf('day').format().replace('Z', '')}`,
          operator: 'DATE_GTE',
        },
        {
          field: 'dateTime',
          value: `${dayjs(calendarState.range.to).utc().startOf('day').add(1, 'day').format().replace('Z', '')}`,
          operator: 'DATE_LT',
        },
      ];
      dates = `${calendarState.range.from},${calendarState.range.to}`;
    } else {
      filterToApply = [
        {
          field: 'dateTime',
          value: `${dayjs(calendarState.selectedDay).utc().startOf('day').format().replace('Z', '')}`,
          operator: calendarState.filterProperty,
        },
      ];
      dates = `${calendarState.selectedDay}`;
    }

    currentOption.filter = filterToApply;
    currentOption.dates = dates;
    let newValue = {
      optionSelected: currentOption.name,
      options: [...optionsToKeep, ...localOptions],
    };
    selectOption({ filter, newValue });
  };

  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const collapsedProps = () => {
    if (outsideFilters) {
      return { ...getCollapseProps() };
    } else {
      return {};
    }
  };
  const DateItemOption = (props) => {
    const { item } = props;
    const { name } = item;
    return (
      <div className={`radio-button mb-2`}>
        <input
          id={normalize(name)}
          onChange={() => {
            let newValue = { optionSelected: name, options: options };
            selectOption({ filter, newValue });
          }}
          type='radio'
          checked={optionSelected === name}
        />
        <label htmlFor={normalize(name)}>{name}</label>
      </div>
    );
  };

  const getOptionName = ({ item, selected }) => {
    const { name, dates } = item;
    if (selected) {
      if (name === 'Custom') {
        dayjs.updateLocale('en', {
          months: normalMonths,
        });
        const from = dates?.replaceAll('-', ' ').split(',')?.[0];
        const to = dates?.replaceAll('-', ' ').split(',')?.[1];
        let equalYear = dayjs(from).get('year') === dayjs(to).get('year');
        let equalMonth = dayjs(from).get('month') === dayjs(to).get('month');
        let sameYear = dayjs(to).get('year') === dayjs(new Date()).get('year');
        return `${from ? dayjs(from).format(equalYear ? (equalMonth ? 'D' : 'D MMMM') : 'D MMMM YYYY') : '-'} to ${
          to ? dayjs(to).format(equalYear ? `D MMMM${!sameYear ? ' YYYY' : ''}` : 'D MMMM YYYY') : '-'
        }`;
      } else {
        return `${name} ${dayjs(dates).format('D MMMM YY')}`;
      }
    } else {
      return `${name}...`;
    }
  };

  return (
    <>
      {!notIncludeName && <h3>{name}</h3>}
      <div className={`radio-button mb-2 `}>
        <input
          id={'any-time'}
          onChange={() => {
            let newValue = { optionSelected: '', options: options };
            selectOption({ filter, newValue });
          }}
          type='radio'
          checked={optionSelected === ''}
        />
        <label htmlFor={'any-time'}>Any time</label>
      </div>
      {normalOptions().map((item, index) => (
        <DateItemOption item={item} index={index} key={`dateFilter${index}`} />
      ))}
      <div {...collapsedProps()}>
        {outsideFilters && (
          <>
            {additionalOptions().map((item, index) => (
              <DateItemOption item={item} index={index + splitItemValue} key={`dateFilter${index + splitItemValue}`} />
            ))}
          </>
        )}
        <div className={inboxSection ? '' : 'pt-1'}>
          {calendarOptions.map((item, index) => {
            const { name } = item;
            return (
              <div
                className={`radio-button mb-2 `}
                key={`dateCalendar${index}`}
                onClick={() => {
                  if (!notShowCalendar) {
                    setCalendarState({
                      ...calendarState,
                      active: true,
                      filterProperty: item.filterProperty,
                      name: name,
                    });
                  } else {
                    selectOption({ filter, newValue: '' });
                  }
                }}
              >
                <input id={normalize(name)} type='radio' readOnly checked={optionSelected === name} />
                <label htmlFor={normalize(name)}>{getOptionName({ item, selected: optionSelected === name })}</label>
              </div>
            );
          })}
        </div>
      </div>
      {outsideFilters && (
        <button
          className={`mt-2 d-inline-block simple-link-button general-button showitem-button ${
            isExpanded ? 'closeitem-button' : ''
          }`}
          {...getToggleProps({
            onClick: () => setExpanded((prevExpanded) => !prevExpanded),
          })}
        >
          Show {isExpanded ? 'less' : 'all'}
        </button>
      )}
      {calendarState.active && (
        <CalendarPopup
          calendarState={calendarState}
          setCalendarState={setCalendarState}
          ApplyCalendarFilters={ApplyCalendarFilters}
          setShowPopup={(val) => setCalendarState({ ...calendarState, active: val })}
          resetOnApply={() => {
            let newValue = { optionSelected: '', options: options };
            selectOption({ filter, newValue });
          }}
        />
      )}
    </>
  );
};

const DateFilter = (props) => {
  return (
    <>
      <DateOptions {...props} />
    </>
  );
};

const CalendarPopup = (props) => {
  const {
    setShowPopup,
    identifier,
    additionalClass,
    additionalOpenAction,
    additionalCloseAction,
    disableFocus = true,
    awaitOpenAnimation = true,
    resetOnApply,
  } = props;
  const { calendarState, ApplyCalendarFilters, setCalendarState } = props;
  const { from, to } = calendarState.range;
  const isMobile = useIsMobile();
  const datePickerRef = useRef();
  const [currMonth, setCurrMonth] = useState('');
  const micromodalElement = useCallback((node) => {
    if (node !== null) {
      if (additionalOpenAction) {
        additionalOpenAction();
      }
      MicroModal.show(`popup-${identifier}`, {
        awaitOpenAnimation,
        awaitCloseAnimation: true,
        disableFocus,
      });
    }
  }, []);

  useEffect(() => {
    if (datePickerRef?.current) {
      setCurrMonth(datePickerRef?.current?.state?.currentMonth?.nd);
    }
  }, [datePickerRef?.current]);

  const closeMicroModal = () => {
    //IMPORTANT TO CHECK IF WE NEED TO DO SOEMETHING ADDITIONAL ON THIS FUNCTION
    MicroModal.close(`popup-${identifier}`);
    setTimeout(() => {
      setShowPopup(false);
      if (additionalCloseAction) {
        additionalCloseAction();
      }
      setCalendarState({
        ...calendarState,
        active: false,
        range: {
          from: '',
          to: '',
        },
        selectedDay: new Date(),
      });
      setResetedRecently(false);
    }, 300);
  };

  const formatInputVal = (date) => {
    let formattedDateArr = dayjs(date).format('D MMMM, YYYY').split(' ');
    return `${formattedDateArr[0]} ${formattedDateArr[1][0].toUpperCase() + formattedDateArr[1].substring(1)} ${formattedDateArr[2]}`;
  };

  const getInputValue = (type, val) => {
    let state = val ?? calendarState;
    if (state.filterProperty === 'range' && state.range[type] !== '') {
      let date = !isNaN(new Date(state.range[type]).getTime()) ? new Date(state.range[type]) : null;
      if (date) {
        return formatInputVal(date);
      }
    } else if (type === 'selectedDay') {
      let date = !isNaN(new Date(state.selectedDay).getTime()) ? new Date(state.selectedDay) : null;
      if (date) {
        return formatInputVal(date);
      }
    }
    return '';
  };

  const [fromInputVal, setFromInputVal] = useState(getInputValue('from'));
  const [toInputVal, setToInputVal] = useState(getInputValue('to'));
  const [selectedDay, setSelectedDay] = useState(getInputValue('selectedDay'));
  const [resetedRecently, setResetedRecently] = useState(false);

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    if (calendarState.filterProperty !== 'range') {
      setCalendarState({
        ...calendarState,
        selectedDay: day,
      });
      setSelectedDay(
        getInputValue('selectedDay', {
          ...calendarState,
          selectedDay: day,
        })
      );
    } else {
      let rangeToRender = calendarState.range.to !== '' ? { from: '', to: '' } : calendarState.range;
      const range = DateUtils.addDayToRange(day, rangeToRender);
      if (range.to !== null) {
        setCalendarState({
          ...calendarState,
          range: range,
        });
        setFromInputVal(
          getInputValue('from', {
            ...calendarState,
            range: range,
          })
        );
        setToInputVal(
          getInputValue('to', {
            ...calendarState,
            range: range,
          })
        );
      }
    }
    setResetedRecently(false);
  };
  const inputChangeHandler = (e) => {
    const value = getDateInputValue(e);
    const type = e.target.id.split('-date-input')[0];
    const config = {
      data: type === 'selectedDay' ? selectedDay : type === 'from' ? fromInputVal : toInputVal,
      setData: type === 'selectedDay' ? setSelectedDay : type === 'from' ? setFromInputVal : setToInputVal,
    };

    let date = !isNaN(new Date(value).getTime()) ? new Date(value) : null;
    const dateValid = date && date <= dayjs().add('1', 'day').toDate();
    if (e.type === 'change') {
      config.setData(e.target.value);
    } else if (e.type === 'blur' || (e.type === 'keydown' && e.code === 'Enter')) {
      if (dateValid) {
        config.setData(formatInputVal(value));
        if (type === 'to' && new Date(calendarState.range.from).getTime() > new Date(date).getTime()) {
          setTimeout(() => {
            setFromInputVal(formatInputVal(date));
            setToInputVal(formatInputVal(calendarState.range.from));
            setCalendarState({
              ...calendarState,
              range: { ...calendarState.range, from: date, to: calendarState.range.from },
            });
            e.target.blur();
          }, 10);
        }
      } else {
        config.setData('');
        if (e.target.value !== '') {
          datePickerRef?.current?.showMonth(dayjs(new Date()).add(-1, 'month').toDate());
        }
      }
    }
    if (!!date) {
      const validatedDate = dateValid ? date : '';
      if (type === 'selectedDay') {
        setCalendarState({
          ...calendarState,
          selectedDay: validatedDate,
        });
      } else {
        setCalendarState({
          ...calendarState,
          range: { ...calendarState.range, [type]: validatedDate },
        });
      }
      if (dateValid) {
        let value = type === 'selectedDay' || type === 'from' ? 0 : -1;
        datePickerRef?.current?.showMonth(dayjs(date).add(value, 'month').toDate());
      }
    }
    setResetedRecently(false);
    //OP: set normal months names after they been lowercased
    normalizeMonthsNames();
  };

  const twoMonthCondition = !isMobile && calendarState.filterProperty === 'range';

  const switchToNextMonthCondition =
    dayjs(currMonth)
      .add(twoMonthCondition ? 2 : 1, 'month')
      .toDate()
      .getTime() <= new Date().getTime();

  const NextPrevDayArrows = ({ type }) => {
    const changeDay = (val) => {
      const config = {
        data: type === 'selectedDay' ? selectedDay : type === 'from' ? fromInputVal : toInputVal,
        setData: type === 'selectedDay' ? setSelectedDay : type === 'from' ? setFromInputVal : setToInputVal,
      };
      const dateValid = (date) => date && (date <= dayjs().add(val, 'day').toDate() || val === -1);
      const dateToUse = type === 'selectedDay' ? selectedDay : calendarState.range[type];
      const date = dayjs(dateToUse).add(val, 'day').toDate();
      setTimeout(() => {
        if (type === 'selectedDay') {
          if (dateValid(date)) {
            setCalendarState({
              ...calendarState,
              selectedDay: date,
            });
            config.setData(formatInputVal(date));
          }
        } else {
          if (dateValid(date)) {
            setCalendarState({
              ...calendarState,
              range: { ...calendarState.range, [type]: date },
            });
            config.setData(formatInputVal(date));
          }
        }
        setResetedRecently(false);
        if (dateValid(date)) {
          let currMonth = new Date(datePickerRef.current.state.currentMonth).getTime();
          let currDay = new Date(date).getTime();
          if (
            currDay < currMonth ||
            currDay > dayjs(datePickerRef.current.state.currentMonth).add(2, 'month').toDate().getTime()
          ) {
            datePickerRef.current.showMonth(dayjs(date).add(-1, 'month').toDate());
          }
        }
      }, 100);
    };

    const stateVal = type === 'selectedDay' ? calendarState.selectedDay : calendarState.range[type];
    const show = !isNaN(new Date(stateVal).getTime());
    return (
      <>
        {show ? (
          <div className='next-prev-day-arrows-container'>
            <button className='general-button' onClick={() => changeDay(-1)}>
              <span className='icon-arrow-back'></span>
            </button>
            <button className='general-button' onClick={() => changeDay(1)}>
              <span className='icon-arrow-next'></span>
            </button>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const resetFunction = () => {
    setCalendarState({
      ...calendarState,
      range: {
        from: '',
        to: '',
      },
      selectedDay: calendarState.filterProperty === 'range' ? new Date() : '',
    });
    setFromInputVal('');
    setToInputVal('');
    setSelectedDay('');
    datePickerRef.current.showMonth(
      calendarState.filterProperty === 'range' ? dayjs().add(-1, 'month').toDate() : new Date()
    );
    if (calendarState.filterProperty === 'range') {
      setResetedRecently(true);
    }
  };

  const resetCondition =
    calendarState.filterProperty === 'range'
      ? calendarState.range.to !== '' || calendarState.range.from !== ''
      : calendarState.selectedDay !== '';

  const preselectedMonth =
    calendarState.filterProperty === 'range' && calendarState.range.from !== ''
      ? calendarState.range.from
      : twoMonthCondition
        ? dayjs(calendarState.selectedDay).add(-1, 'month').toDate()
        : calendarState.selectedDay;

  return (
    <Portal>
      <div
        className={`modal micromodal-slide website-feeds-modal ${additionalClass ? additionalClass : ''}`}
        id={`popup-${identifier}`}
        aria-hidden='true'
        tabIndex='-1'
        ref={micromodalElement}
      >
        <div className='modal__overlay' tabIndex='-1'>
          <div
            className='transparent-close-modal'
            onClick={() => {
              closeMicroModal();
            }}
          />
          <button
            className='calendar-popup-arrow-back'
            onClick={() => {
              datePickerRef.current.showMonth(
                dayjs(datePickerRef.current.state.currentMonth).add(-1, 'month').toDate()
              );
            }}
          >
            <span className='icon-arrow-back'></span>
          </button>
          <div
            className='modal__container z-max'
            style={{ maxWidth: !isMobile && props.calendarState.filterProperty === 'range' ? 530 : 290 }}
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-alerts-title'
          >
            <button
              className='close-button-filters general-button webfeed-popup-close date-filter-popup-close'
              onClick={(e) => {
                e.stopPropagation();
                closeMicroModal();
              }}
            >
              Close
            </button>
            <div
              className={`calendar-popup-fixed-height calendar-picker calendar-popup px-4 pb-4 pt-3 d-flex flex-column  ${!isMobile && props.calendarState.filterProperty === 'range' ? '' : 'single-item-date-filter-popup'}`}
            >
              <div>
                <div className='calendar-popup-actions pt-2 pb-3'>
                  {!isMobile && (
                    <>
                      {calendarState.filterProperty === 'range' ? (
                        <div className='date-inputs-container'>
                          <label className='date-input-wrapper' htmlFor='from-date-input'>
                            <input
                              type='text'
                              placeholder='From'
                              id='from-date-input'
                              onBlur={inputChangeHandler}
                              onChange={inputChangeHandler}
                              onKeyDown={(e) => {
                                if (e.type === 'keydown' && e.code === 'Enter') {
                                  inputChangeHandler(e);
                                }
                              }}
                              value={fromInputVal}
                              autoComplete='off'
                              spellCheck='false'
                              tabIndex={1}
                            ></input>
                            <NextPrevDayArrows type={'from'} />
                          </label>
                          <div className='date-input-separator' />
                          <label className='date-input-wrapper' htmlFor='to-date-input'>
                            <input
                              type='text'
                              placeholder='To'
                              id={'to-date-input'}
                              onBlur={inputChangeHandler}
                              onChange={inputChangeHandler}
                              onKeyDown={(e) => {
                                if (e.type === 'keydown' && e.code === 'Enter') {
                                  inputChangeHandler(e);
                                }
                              }}
                              value={toInputVal}
                              autoComplete='off'
                              spellCheck='false'
                              tabIndex={2}
                            ></input>
                            <NextPrevDayArrows type={'to'} />
                          </label>
                        </div>
                      ) : (
                        <div className='date-inputs-container'>
                          <label className='date-input-wrapper' htmlFor='selectedDay-date-input'>
                            <input
                              type='text'
                              id={'selectedDay-date-input'}
                              placeholder='Select day'
                              onBlur={inputChangeHandler}
                              onChange={inputChangeHandler}
                              value={selectedDay}
                            ></input>
                            <NextPrevDayArrows type={'selectedDay'} />
                          </label>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <DayPicker
                  ref={datePickerRef}
                  className='Selectable'
                  selectedDays={
                    calendarState.filterProperty === 'range'
                      ? [calendarState.range.from, { from, to }]
                      : calendarState.selectedDay
                  }
                  modifiers={
                    calendarState.filterProperty === 'range'
                      ? {
                          start: calendarState.range.from,
                          end: calendarState.range.to,
                        }
                      : {}
                  }
                  initialMonth={preselectedMonth}
                  onDayClick={handleDayClick}
                  onMonthChange={(e) => setCurrMonth(e)}
                  firstDayOfWeek={1}
                  showOutsideDays
                  disabledDays={(day) => day > dayjs().add('1', 'day').toDate()}
                  weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                  numberOfMonths={twoMonthCondition ? 2 : undefined}
                  pagedNavigation={twoMonthCondition}
                />
              </div>
              <div
                className={`d-flex ${twoMonthCondition ? 'justify-content-end' : 'justify-content-around'} flex-grow-1`}
              >
                {resetCondition && (
                  <button className={`reset-calendar-button px-4 py-1 align-self-end mr-3`} onClick={resetFunction}>
                    Reset
                  </button>
                )}
                <button
                  className={`general-button action-button px-4 py-1 align-self-end`}
                  onClick={async () => {
                    if (resetedRecently && resetOnApply) {
                      resetOnApply();
                    } else {
                      await ApplyCalendarFilters();
                    }
                    setShowPopup(false);
                    setResetedRecently(false);
                  }}
                  disabled={
                    (calendarState.filterProperty === 'range'
                      ? calendarState.range.to === '' || calendarState.range.from === ''
                      : calendarState.selectedDay === '') && !resetedRecently
                  }
                >
                  {calendarState?.isLoading && <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />}{' '}
                  Apply
                </button>
              </div>
            </div>
          </div>
          <button
            className={`calendar-popup-arrow-next`}
            disabled={!switchToNextMonthCondition}
            onClick={() => {
              datePickerRef.current.showMonth(dayjs(datePickerRef.current.state.currentMonth).add(1, 'month').toDate());
            }}
          >
            <span className='icon-arrow-next'></span>
          </button>
        </div>
      </div>
    </Portal>
  );
};

export { DateOptions, DateFilter, CalendarPopup };
