import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { store } from '../../../../Store';
import useIsMobile from '../../../utilities/useIsMobile';
import CrmContactsListBar from '../../CrmContacts/CrmContactsTable/CrmContactsListBar/CrmContactsListBar';
import RemoveGroupsButton from '../../CrmGroups/RemoveGroupsModal';

function CrmContactsSubNav() {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { crmContactsTotalHits } = state;
  const [isLoading, setIsLoading] = useState(false);
  const isSmallMobile = useIsMobile(820);

  const setSelectedCrmContacts = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedCrmContacts',
      value,
    });
  };
  const resetSelectedContacts = () => {
    setSelectedCrmContacts({
      selectedAll: false,
      selectedIds: [],
      selectedAllVisible: [],
    });
  };
  const setShowAddTagsModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddTagsModalCrmContacts',
      value,
    });
  };
  const setShowRemoveContactsModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showRemoveContactsModal',
      value,
    });
  };

  const setShowRemoveContactTagsModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showRemoveContactTagsModal',
      value,
    });
  };

  return (
    <>
      <CrmContactsListBar
        setSelectedCrmContacts={setSelectedCrmContacts}
        totalHits={crmContactsTotalHits}
        setIsLoading={setIsLoading}
        setShowAddTagsModal={setShowAddTagsModal}
        isLoading={isLoading}
        resetSelectedContacts={resetSelectedContacts}
        setShowRemoveContactsModal={setShowRemoveContactsModal}
        setShowRemoveContactTagsModal={setShowRemoveContactTagsModal}
      />
      {!isSmallMobile && (
        <button onClick={resetSelectedContacts} className='stakeholder-subnav-deselect-btn ml-2'>
          Cancel <span className='icon-close paragraph-p5 ml-2' />
        </button>
      )}
    </>
  );
}

const CrmGroupsSelectedSubNav = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  const { contentResults, activeResults, selectedGroups } = state;
  const { Groups } = contentResults[activeResults];
  const { selectedIds, selectedAll } = selectedGroups;
  return (
    <>
      <div className='crm-contacts-list-bar'>
        <div className='contacts-list-bar-helper-text-wrapper nowrap-item'>
          {selectedIds.length > 0 && !selectedAll ? selectedIds?.length : selectedAll ? Groups.length : ''} selected
        </div>
        <div className='mr-2 mr-lg-0'>
          <RemoveGroupsButton />
        </div>
      </div>
      <button
        onClick={() => {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'selectedGroups',
            value: { selectedIds: [], selectedAll: false },
          });
        }}
        className='stakeholder-subnav-deselect-btn'
      >
        Cancel <span className='icon-close paragraph-p5 ml-2' />
      </button>

      {/* <div className='d-flex justify-content-between w-100'>
        <div className='flex-grow-1'>
        </div>
        <div>
        </div>
      </div> */}
    </>
  );
};

export { CrmGroupsSelectedSubNav };
export default CrmContactsSubNav;
