import { useContext, useState } from 'react';
import { store } from '../../Store';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import { useParams } from 'react-router-dom';
import normalize from '../utilities/normalizeString';

const ShowRelevantExtract = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search } = state;
  const { activeSearch } = search;

  const params = useParams();
  const { searchResultsSection } = useMainSectionParameter(params);

  return (
    <div className='border rounded bg-main-white mb-2 outside-filters-spacing-container'>
      <div className='flex-centered mb-3 justify-content-between'>
        <h3 className={`title-h5-bold d-block mb-0`}>Snippet displayed</h3>
        <span
          className='icon-info ml-2 main-subtle-text paragraph-p2'
          data-tooltip-class-name={'header-tooltip tooltip-width'}
          data-tooltip-content={`Switch between seeing ${searchResultsSection && !activeSearch ? 'start of update' : 'a relevant extract'} or a one-line AI-written summary. This text appears under the headline of each key update in the list.`}
          data-tooltip-id={'general-tooltip'}
        />
      </div>
      <RelevantOptions />
    </div>
  );
};

const RelevantOptions = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { search } = state;
  const { activeSearch } = search;

  const { showRelevantExtract } = state;
  const { selectedOptionRelevantExtract } = showRelevantExtract ?? {};

  const { forceStartOfUpdate, tick } = props ?? {};

  const [optionSelected, setOptionSelected] = useState(selectedOptionRelevantExtract);
  const options = ['Most relevant extract', 'One-line AI summary'];

  const params = useParams();
  const { searchResultsSection } = useMainSectionParameter(params);

  return (
    <>
      {options?.map((item, index) => {
        return (
          <div
            key={`item-${index}`}
            className={`${tick ? 'tick-radio-button px-3 py-2' : `mb-${index === options?.length - 1 ? '2' : '3'}`} radio-button `}
          >
            <input
              id={normalize(item)}
              onChange={() => {
                setOptionSelected(item);
                localStorage.setItem('showRelevantExtract', item);
                dispatch({
                  type: 'MODIFY_SECTION',
                  parameter: 'showRelevantExtract',
                  value: {
                    ...showRelevantExtract,
                    selectedOptionRelevantExtract: item,
                  },
                });
              }}
              type={'radio'}
              checked={optionSelected === item}
            />
            <label htmlFor={normalize(item)}>
              {forceStartOfUpdate ? 'Show ' : ''}
              {item === 'Most relevant extract' && ((searchResultsSection && !activeSearch) || forceStartOfUpdate)
                ? forceStartOfUpdate
                  ? 'start of update'
                  : 'Start of update'
                : forceStartOfUpdate
                  ? item[0].toLowerCase() + item.substring(1)
                  : item}
            </label>
            {optionSelected === item && tick && <span className='icon-tick'></span>}
          </div>
        );
      })}
    </>
  );
};

export { RelevantOptions };
export default ShowRelevantExtract;
