import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import addMetaTags from '../Dashboard/utilities/addMetaTags';
import transformToPascalCase from '../Settings/Utilities/TransformToPascalCase';
import { store } from '../Store';
import Create from './ui/Create';

const useSuggestionFunctions = () => {
  const { generalApiCall } = useGeneralApiCall();

  const getSuggestion = async ({ prompt, source }) => {
    try {
      const pathname = `/api/copy-suggestion/chat-completion`;
      const method = 'post';
      const requestProperties = {
        messages: [
          {
            role: 'user',
            content: prompt,
          },
        ],
      };
      return await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
    } catch (error) {}
  };

  const createSummary = async ({ content }) => {
    const prompt = `Summarise: "${content}" into a bullet-point list. Format it as a HTML no ordered list.`;
    return getSuggestion({ prompt });
  };

  return { getSuggestion, createSummary };
};

const WritingCreate = (props) => {
  const { individualPage } = props;
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const { generalApiCall } = useGeneralApiCall();
  const { getSuggestion } = useSuggestionFunctions();

  const params = useParams();
  const { title: contentType } = params;

  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [phrases, setPhrases] = useState(['', '', '']);

  const changeContentType = () => {
    switch (contentType) {
      case 'debate-contribution':
        return 'ParliamentarianDebateContribution';
      case 'question':
        return 'ParliamentarianQuestion';
      case 'edm':
        return 'ParliamentarianEDM';
      case 'response-to-a-constituent':
        return 'ParliamentarianConsituentResponse';
      default:
        return transformToPascalCase(contentType.replace('-', ' '));
    }
  };

  const createFunction = async ({ prompt, body }) => {
    try {
      const pathname = `/api/writing-assistant-generated-content/create`;
      const method = 'post';
      const requestProperties = {
        title,
        contentType: changeContentType(),
        body,
        prompt,
        promptData: {
          title,
          phrases: JSON.stringify(phrases.filter((item) => item !== '')),
        },
      };
      const results = await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
      if (results) {
        return navigate({
          pathname: `/ai-assistant/content/${results?.id}`,
          state: { influencePage: location?.state?.influencePage },
        });
      }
    } catch (error) {}
  };

  const sentPromptFromWritingAssistant = ({ prompt }) => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'promptFromWritingAssistant', value: prompt });
    navigate(
      {
        pathname: `/influence/${contentType === 'policy-ask' ? 'policy-asks' : contentType}/create/1`,
        search: `?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`,
      },
      { state: { headline: title, client: location?.state?.client, websiteLink: location?.state?.websiteLink } }
    );
  };

  useEffect(() => {
    let title = `AI assistant - PolicyMogul`;
    addMetaTags({ title, hash: location.hash, location, dispatch });
  }, []);

  return (
    <>
      <Create
        titleValues={{ title, setTitle }}
        phrasesValues={{ phrases, setPhrases }}
        getSuggestion={getSuggestion}
        createFunction={createFunction}
        individualPage={individualPage}
        sentPromptFromWritingAssistant={sentPromptFromWritingAssistant}
      />
    </>
  );
};

export { useSuggestionFunctions };
export default WritingCreate;
