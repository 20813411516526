import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { CodeElement } from '../Settings/WebsiteFeeds/WebsiteFeedSettings';
import DropdownComponent from '../Settings/WebsiteFeeds/DropdownComponent';
import { useCreateAnalyticsSections } from '../Dashboard/Analytics/ui/AnalyticsSidebar';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
import createNotification from '../Settings/Utilities/CreateNotification';
import { store } from '../Store';
import normalize from '../Dashboard/utilities/normalizeString';
import CustomScrollbar from '../Common/CustomScrollbar';
import { ReportDesignPopup } from '../Reports/ui/ReportDesignButton';
import TopicPicker from '../Common/TopicPicker';
import { useLocation } from 'react-router-dom';
import WebsiteSettingsTopicsLocal from '../Settings/WebsiteFeeds/WebsiteSettingsTopicsLocal';
import { ComponentClientSelector } from '../Dashboard/Navigation/ClientsPicker';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const EmbedAnalyticsSettings = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, clients } = state;

  const { analyticsSections } = useCreateAnalyticsSections();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const location = useLocation();

  const {
    closeMicroModal,
    selectedTopics,
    setSelectedTopics,
    chartTypes,
    setChartTypes,
    selectedCountries,
    designProps,
    searchValue,
    setSearchValue,
    headerProps,
    subheaderProps,
    titleElement,
    clientState,
    topicsByClient,
  } = props;

  const [codeToCopy, setCodeToCopy] = useState('');
  const { designState, setDesignState } = designProps ?? {};
  const { header, setHeader } = headerProps ?? {};
  const { subheader, setSubheader } = subheaderProps ?? {};
  const { client, setClient } = clientState ?? {};

  const generateCodeFunction = () => {
    let iframeUrl = `${window.location.origin}/embed-analytics/?${team?.teamId || newAgencyPlan ? `teamId=${newAgencyPlan ? client?.teamId : team?.teamId}` : ''}${
      !!searchValue
        ? `&search=${encodeURIComponent(searchValue)}`
        : `&topicsIds=${selectedTopics
            .filter((topic) => topic.id)
            .map((item) => item.id)
            ?.join(',')}`
    }&chartTypes=${encodeURIComponent(chartTypes?.join(','))}&backgroundColour=${encodeURIComponent(
      designState?.backgroundColour
    )}&headerColour=${encodeURIComponent(designState?.headerColour)}&includeBranding=${encodeURIComponent(
      designState?.includeBranding
    )}&nations=${normalize(selectedCountries.map((item) => item.value).join('-'))}&header=${encodeURIComponent(header)}&subheader=${encodeURIComponent(subheader)}${agencyUser ? `&agency=${encodeURIComponent(true)}` : ''}${location.hash}`;
    return `<div style="max-width: 1100px; height: 750px;"><iframe width="100%" style="border-radius: 8px;" height="100%" src="${iframeUrl}" title="PolicyMogul analytics embed content" frameborder="0"> </iframe></div>`;
  };

  const changeState = ({ property, newValue }) => {
    let newState = { ...designState };
    newState[property] = newValue;
    setDesignState(newState);
  };

  let contentItems = [
    {
      component: (
        <>
          {newAgencyPlan && (
            <div className='mb-5'>
              <label className='mb-2'>Client</label>
              <ComponentClientSelector
                clientsLists={clients}
                activeClientState={{ activeClient: client }}
                selectClientFunction={({ client }) => {
                  setClient(client);
                }}
                notShowActions={true}
                maximumZIndex={true}
              />
            </div>
          )}
        </>
      ),
      order: 0,
    },
    {
      component: (
        <>
          {!!searchValue ? (
            <div className='mb-5 analytics-search-team-embed agency-dropdown'>
              <label className='mb-2'>Search term</label>
              <TopicPicker
                keywordsLists={[]}
                showTopicCases={false}
                onlySearchbar={true}
                searchFunction={({ freeSearch }) => {
                  if (!!freeSearch) {
                    setSearchValue(freeSearch);
                  }
                }}
                fixedPosition
                mainSearchValue={searchValue}
                allowFreeSearch={!!searchValue}
                customClassOpen={'open-dropdown-analytics-embed'}
              />
            </div>
          ) : (
            <ClientOrTopicsToDisplay
              setSelectedTopics={setSelectedTopics}
              selectedTopics={selectedTopics}
              key={'ClientOrTopicsToDisplay'}
              keywordsLists={newAgencyPlan ? topicsByClient : null}
              client={client}
            />
          )}
        </>
      ),
      order: 1,
    },
    {
      component: (
        <ChartsToInclude
          analyticsSections={analyticsSections}
          chartTypes={chartTypes}
          setChartTypes={setChartTypes}
          key={'ChartsToInclude'}
        />
      ),
      order: 2,
    },

    {
      component: <HeaderComponent header={header} setHeader={setHeader} selectedTopics={selectedTopics} />,
      order: 3,
    },

    {
      component: (
        <SubheaderComponent subheader={subheader} setSubheader={setSubheader} selectedTopics={selectedTopics} />
      ),
      order: 4,
    },

    {
      component: (
        <>
          <div className='mt-5'>
            <ReportDesignPopup
              property={'enableAnalyticsRemoveBranding'}
              designProps={designProps}
              componentText={'dashboard'}
              designState={designState}
              changeState={changeState}
            />
          </div>
        </>
      ),
      order: 5,
    },
  ];

  return (
    <>
      <div
        className='section-title mb-4 px-4 pb-3 flex-centered justify-content-between bg-white website-popup-title main-sticky'
        style={{ minHeight: '72px', top: `${titleElement?.current?.getBoundingClientRect()?.height}px` }}
      >
        <h3 className='topic-title  mb-0'>
          <span className='d-none d-lg-inline-block'>Embed analytics</span>{' '}
          <span className='website-popup-settings-title'>settings</span>
        </h3>

        <div className='d-flex justify-content-end'>
          <button
            className={`${codeToCopy === '' ? 'd-inline-block' : 'd-none'} generate-code-button general-button action-button no-select-button px-4 nowrap-item`}
            onClick={() => {
              if (chartTypes.length === 0) {
                createNotification('danger', 'Please select at least one chart type');
              } else {
                let code = generateCodeFunction();
                setCodeToCopy(code);
                ReactGA.event({
                  category: 'Widget',
                  action: 'Code generated',
                  label: 'Analytics code',
                });
              }
            }}
          >
            Generate code {agencyUser ? 'for client' : ''}
          </button>
          <button
            className={`${codeToCopy === '' ? 'd-none' : 'd-inline-block'}  general-button mr-4 simple-link-button no-select-button`}
            onClick={() => {
              setCodeToCopy('');
            }}
          >
            {'Back'}
          </button>
          <button
            className={`${codeToCopy === '' ? 'd-none' : 'd-inline-block'}  generate-code-button general-button action-button no-select-button px-3 copy-action-button`}
            onClick={() => {
              let tempInput = document.createElement('input');
              tempInput.style = 'position: absolute; left: -1000px; top: -1000px;';
              tempInput.value = codeToCopy;
              document.body.appendChild(tempInput);
              tempInput.select();
              document.execCommand('copy');
              document.body.removeChild(tempInput);
              createNotification('success', `Code copied`);
              closeMicroModal();
            }}
          >
            {'Copy code'}
          </button>
        </div>
      </div>
      <div className='px-4 dashboard-root-container pb-5'>
        <div className='topic-email-alerts-content pt-2'>
          {codeToCopy === '' && (
            <>
              {contentItems
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <React.Fragment key={`component-${index}`}>{item.component}</React.Fragment>
                ))}
            </>
          )}
          {codeToCopy !== '' && (
            <>
              <p>
                Share this code with{' '}
                {newAgencyPlan
                  ? `${client?.teamName}`
                  : `your
                ${agencyUser ? 'client' : 'developers'}`}
              </p>
              <CodeElement codeToCopy={codeToCopy} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const ClientOrTopicsToDisplay = (props) => {
  const { setSelectedTopics, selectedTopics, notAddMargin, feed, client, scrollBarPosition } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const keywordsLists = props?.keywordsLists ?? state?.keywordsLists;
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const { agencyUser, transformTopicOrClientWord } = useTopicOrClientWord();

  const createSelectedTopics = () => {
    const topicLengths = selectedTopics?.length;
    if (topicLengths === 0) {
      return 'Please select a topic';
    } else if (topicLengths === 1) {
      return `${selectedTopics[0]?.name}`;
    } else {
      return `${topicLengths === keywordsListsToUse.length ? 'All' : topicLengths} ${transformTopicOrClientWord({
        plural: true,
      })} selected`;
    }
  };

  return (
    <>
      <div className={`${notAddMargin ? '' : agencyUser ? 'mb-4' : 'mb-5'}`}>
        <label>
          {agencyUser ? 'Client' : 'Topics to include'}{' '}
          <span
            className='d-none d-lg-inline-block icon-info light-50-text d-inline-block pointer paragraph-p3 ml-2'
            data-tooltip-id={'embed-settings-tooltip'}
            data-tooltip-class-name='pricing-feature-tooltip topic-category-tooltip'
            data-tooltip-content={
              feed
                ? 'Choose one or multiple topics to determine which updates should appear in this feed'
                : 'Select one or multiple topics for which data should appear'
            }
            style={{ marginTop: '-4px', verticalAlign: 'middle' }}
          />
        </label>
        <div className='mt-2 keywords-website-settings'>
          <DropdownComponent
            mainItem={() => <span className='hidden-lines hidden-one-line w-100'>{createSelectedTopics()}</span>}
            notCloseOnSelect
            scrollBarPosition={scrollBarPosition}
          >
            <WebsiteSettingsTopicsLocal
              selectedTopics={selectedTopics}
              setSelectedTopics={setSelectedTopics}
              multipleTopics={true}
              changeTopicsWord={true}
              keywordsLists={keywordsLists}
              client={client}
            />
          </DropdownComponent>
        </div>
      </div>
      <ReactTooltip
        effect={'solid'}
        id={'embed-settings-tooltip'}
        className={'header-tooltip'}
        arrowColor={'transparent'}
        place={'top'}
      />
    </>
  );
};

const ChartsToInclude = ({ analyticsSections, chartTypes, setChartTypes }) => {
  const { agencyUser } = useTopicOrClientWord();

  const createChartTypesName = () => {
    if (chartTypes.length === 0) {
      return 'Select a chart type';
    } else if (chartTypes.length === analyticsSections.length) {
      return 'All chart types';
    } else {
      return chartTypes?.join(', ');
    }
  };
  return (
    <div className={`${!agencyUser ? 'mb-4' : 'my-5'}`}>
      <label>Charts to include</label>
      <div className='mt-2'>
        <DropdownComponent
          mainItem={() => <span className='hidden-lines hidden-one-line w-100'>{createChartTypesName()}</span>}
          notCloseOnSelect
        >
          <ChartTypesSettings chartTypes={chartTypes} setChartTypes={setChartTypes} />
        </DropdownComponent>
      </div>
    </div>
  );
};

const ChartTypesSettings = (props) => {
  const { analyticsSections } = useCreateAnalyticsSections();
  const { chartTypes, setChartTypes } = props;
  const referenceHeight = 230;
  const elements = useRef([]);
  const [height, setHeight] = useState(referenceHeight);

  const calculateMaxHeightContainer = () => {
    if (elements.current?.length > 0) {
      let height = 8; //padding-top of box
      for (let i = 0; i < elements.current.length; i++) {
        let el = elements.current[i];
        let currentHeight = height + el.getBoundingClientRect().height + 12; //margin value
        height = currentHeight;
      }
      if (height < referenceHeight) {
        setHeight(height);
      }
    }
  };

  useEffect(() => {
    calculateMaxHeightContainer();
  }, []);

  const chartSections = [...analyticsSections];

  return (
    <div className='keyword-lists-website-feeds'>
      <CustomScrollbar
        className={'main-content-scrollbar main-content-section website-list-scroll-component'}
        style={{ height: `${height}px` }}
        maximalThumbYSize={100}
      >
        <div className='website-popup-container-lists large-container' style={{ paddingBottom: '1px' }}>
          {chartSections?.length > 0 && (
            <>
              {chartSections.map((item, index) => {
                const { name } = item;
                const disabled = name !== 'Dashboard' && chartTypes.includes('Dashboard');
                return (
                  <div
                    ref={(div) => (elements.current[index] = div)}
                    className={`checkbox-item ${disabled ? 'checkbox-item-disabled' : ''}`}
                    key={`chart-type-${name}`}
                  >
                    <input
                      id={`${normalize(name)}`}
                      onChange={() => {
                        if (chartTypes.includes(name)) {
                          let position = chartTypes.indexOf(name);
                          let newTypes = [...chartTypes];
                          newTypes.splice(position, 1);
                          setChartTypes(newTypes);
                        } else {
                          if (name === 'Dashboard') {
                            setChartTypes(['Dashboard']);
                          } else {
                            setChartTypes([...chartTypes, name]);
                          }
                        }
                      }}
                      type='checkbox'
                      disabled={disabled}
                      checked={chartTypes.includes(name) || chartTypes.includes('Dashboard')}
                    />
                    <label
                      style={{ verticalAlign: 'text-top' }}
                      htmlFor={`${normalize(name)}`}
                      className='font-weight-normal'
                    >
                      <p className='mb-0'>{name}</p>
                    </label>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </CustomScrollbar>
    </div>
  );
};

const HeaderComponent = (props) => {
  const { header, setHeader } = props;

  return (
    <div className='my-5'>
      <label className='mb-2'>Header text</label>
      <div className={`border rounded flex-centered px-3 py-2 bg-white`}>
        <input
          className={`input-empty w-100`}
          value={header}
          onChange={(e) => {
            let value = e?.target?.value;
            setHeader(value);
          }}
        />
      </div>
    </div>
  );
};

const SubheaderComponent = (props) => {
  const { subheader, setSubheader, selectedTopics, masterTopic } = props;
  const subheaderText = useRef();

  const getWidthOfInput = (value) => {
    if (value !== null || value !== undefined) {
      let tmp = document.createElement('span');
      tmp.className = 'input-element tmp-element';
      tmp.innerHTML = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      document.body.appendChild(tmp);
      let theWidth = tmp.getBoundingClientRect().width;
      document.body.removeChild(tmp);
      return theWidth + 20;
    }
  };

  const [inputWidth, setInputWidth] = useState(getWidthOfInput(subheader));
  const shortInput = inputWidth <= subheaderText?.current?.getBoundingClientRect()?.width - 125;

  useEffect(() => {
    setInputWidth(getWidthOfInput(subheader));
  }, [subheader]);

  return (
    <div className='my-5'>
      <label className='mb-2'>Subheader text</label>
      <div className={`border rounded flex-centered px-3 py-2 bg-white overflow-auto`} ref={subheaderText}>
        <div
          className={`${selectedTopics?.length === 1 ? 'flex-grow-1' : ''}`}
          style={selectedTopics?.length > 1 ? { width: `${shortInput ? 'calc(100% - 125px)' : 'auto'}` } : null}
        >
          <input
            className={`input-empty`}
            value={subheader}
            style={{ width: shortInput || selectedTopics?.length === 1 ? '100%' : `${inputWidth}px` }}
            onChange={(e) => {
              let value = e?.target?.value;
              setSubheader(value);
            }}
          />
        </div>
        {selectedTopics?.length > 1 && !masterTopic && (
          <span
            className='border bg-grey-50 rounded-sm pr-3 nowrap-item ml-1 paragraph-p3'
            style={{ paddingTop: '2px', paddingBottom: '2px' }}
          >
            <span className='icon-salutation' style={{ margin: '0px 10px', fontSize: '10px' }} />
            topic name
          </span>
        )}
      </div>
    </div>
  );
};

export { ClientOrTopicsToDisplay, SubheaderComponent };
export default EmbedAnalyticsSettings;
