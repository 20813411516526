import { useEffect, useMemo, useState } from 'react';

const useOnScreen = (ref, trigger, rootMargin = '0px 0px 0px 0px') => {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), { rootMargin: rootMargin }),
    [ref, trigger]
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
};

export default useOnScreen;
