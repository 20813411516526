import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useRef, useContext, useEffect } from 'react';
import { store } from '../../Store';
import getUrlParam, { getUrlParamRaw } from '../utilities/getUrlParam';
import useWindowHeight from '../utilities/useWindowHeight';
import MicroModal from 'micromodal';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import { PortalWithState } from 'react-portal';
import ResearchPopUp from '../SubNav/ui/ResearchPopUp';
import useResetReference from '../SubNav/useResetReference';

const InputComponent = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { search, clearValueSimpleSearchBar, searchResultsReturnUrl } = state;
  const {
    homePage,
    setOpenSearchBar,
    openSearchBar,
    closeFunction,
    enforceShortPlaceholder,
    openInNewTab,
    placeholder,
  } = props;

  const { isAuthenticated } = useAuth0();
  const { gimmicks } = search;
  const searchValueFromUrl = getUrlParamRaw('search');
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { windowWidth } = useWindowHeight();
  const [valueSearch, setValueSearch] = useState(
    !!searchValueFromUrl && location.pathname.includes('/search-results') ? decodeURIComponent(searchValueFromUrl) : ''
  );
  const { inboxSection } = useMainSectionParameter(params);
  const inputSearch = useRef(null);
  const { resetReference } = useResetReference();

  const searchResults = (value) => {
    if (openInNewTab) {
      window.open(`/search-results?search=${encodeURIComponent(value)}`, '_blank');
    } else {
      navigate(`/search-results?search=${encodeURIComponent(value)}`);
    }
    dispatch({ type: 'MODIFY_SECTION', parameter: 'contentFilters', value: {} });
    dispatch({ type: 'MODIFY_SECTION', parameter: 'totalNumbers', value: {} });
    localStorage.removeItem('contentFilters');
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'searchResultsReturnUrl',
      value: [...searchResultsReturnUrl, encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)],
    });
    setOpenSearchBar(false);
    if (closeFunction) {
      closeFunction();
    }
  };

  const changeValue = (value) => {
    setValueSearch(value);
  };

  const showGimmick = (word) => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'gimmickWord', value: word });
    MicroModal.show('popup-gimmick', {
      awaitCloseAnimation: true,
      disableFocus: true,
    });
  };

  const focusInput = useRef();

  focusInput.current = () => {
    if (homePage || openSearchBar) {
      setTimeout(() => {
        if (inputSearch.current) {
          inputSearch.current.focus();
        }
      }, 300);
    }
  };

  const clearSearchSimpleBar = () => {
    //AE: In a future implementation could be worth review if just updating the url we can update the state of the search
    clearValue();
    if (!inboxSection && !location.pathname.includes('/stakeholder-mapping')) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'totalNumbers', value: {} });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'search',
        value: {
          ...search,
          activeSearch: false,
          query: '',
          list: null,
        },
      });
      resetReference();
      if (location.pathname.includes('stakeholders') || location.pathname.includes('upcoming-events')) {
        navigate({ pathname: `/search-results/key-updates` });
      } else {
        navigate({ pathname: location.pathname, search: '' });
      }
    }
  };

  const clearValue = () => {
    setValueSearch('');
    inputSearch.current.focus();
  };

  useEffect(() => {
    focusInput.current();
  }, [openSearchBar]);

  useEffect(() => {
    //AE: It was not possible to compare the reference state with a local reference to hide the searchbar
    if (clearValueSimpleSearchBar) {
      setOpenSearchBar(false);
      setValueSearch('');
      if (inputSearch.current) {
        inputSearch.current.focus();
      }
      dispatch({ type: 'MODIFY_SECTION', parameter: 'clearValueSimpleSearchBar', value: false });
    }
  }, [clearValueSimpleSearchBar]);

  const updateSimpleValue = useRef(null);
  updateSimpleValue.current = () => {
    if (searchValueFromUrl && decodeURIComponent(searchValueFromUrl) !== valueSearch) {
      setValueSearch(decodeURIComponent(searchValueFromUrl));
    }
  };

  useEffect(() => {
    updateSimpleValue.current();
  }, [location.search]);

  return (
    <>
      <div className='flex-grow-1'>
        <input
          className={`search-main-input w-100`}
          data-hj-allow
          type='text'
          value={valueSearch}
          onChange={(e) => {
            let value = e.target.value;
            changeValue(value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              let value = e.target.value;
              inputSearch.current.blur();
              if (gimmicks.includes(value.toLowerCase().trim()) && !isAuthenticated) {
                showGimmick(value);
              } else {
                searchResults(value);
              }
            } else if (e.keyCode === 9) {
              e.preventDefault();
            }
          }}
          ref={inputSearch}
          placeholder={
            placeholder
              ? placeholder
              : enforceShortPlaceholder
                ? 'Search PolicyMogul'
                : windowWidth > 991 && windowWidth < 1130
                  ? 'Search'
                  : 'Search the political landscape'
          }
        />
      </div>
      {/* CLOSE BUTTON */}
      <div
        className='close-icon-container'
        style={{
          visibility: valueSearch !== '' && (openSearchBar || homePage) ? 'visible' : 'hidden',
        }}
      >
        <button
          className='general-button close-icon'
          data-tooltip-content='Clear search'
          data-tooltip-id='general-tooltip'
          data-tooltip-place='right'
          onClick={(e) => {
            e.stopPropagation();
            if (getUrlParam('search')) {
              clearSearchSimpleBar();
            } else {
              clearValue();
            }
          }}
        />
      </div>
    </>
  );
};

const SimpleSearchBar = (props) => {
  const { homePage } = props;
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const params = useParams();
  const { searchResultsSection } = useMainSectionParameter(params);
  const location = useLocation();
  const homeLocation = location.pathname === '/';

  return (
    <>
      <div className='position-relative'>
        <div
          className={`simple-searchbar mr-lg-3 flex-centered ${homePage ? 'simple-searchbar-open' : ''} ${searchResultsSection && !homePage ? 'simple-searchbar-active' : ''} ${homeLocation ? 'simple-searchbar-home' : ''}`}
          data-tooltip-content={homePage ? '' : 'Search'}
          data-tooltip-id={'general-tooltip'}
          onClick={() => {
            if (!homePage) {
              setOpenSearchBar(!openSearchBar);
            }
          }}
        >
          <span className={`main-search-icon`} />
          <InputComponent {...props} setOpenSearchBar={setOpenSearchBar} />
        </div>
        {openSearchBar && (
          <>
            <div
              className='close-tips'
              onClick={() => {
                setOpenSearchBar(!openSearchBar);
              }}
            />
            <div className={`box filters-container search-filter-box top-nav-search-box`}>
              <p className='title'>Search</p>
              <div className='flex-centered box-rounded mb-2 top-search-input-container'>
                <InputComponent {...props} setOpenSearchBar={setOpenSearchBar} openSearchBar={openSearchBar} />
              </div>
              <AdvancedSearch setOpenSearchBar={setOpenSearchBar} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const AdvancedSearch = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { referenceState, activePlan } = state;
  const { enableAdvancedSearch } = activePlan;
  const { setOpenSearchBar } = props;
  const { isAuthenticated } = useAuth0();
  return (
    <PortalWithState>
      {({ openPortal, closePortal, portal }) => (
        <>
          <button
            className='general-button main-disabled-text text-underline paragraph-p2 mb-3'
            onClick={() => {
              if (enableAdvancedSearch) {
                openPortal();
              }
            }}
            data-tooltip-content={
              !enableAdvancedSearch || !isAuthenticated
                ? 'Advanced search is available on any paid plan, and allows you to hone in on relevant content and avoid information overload'
                : ''
            }
            data-tooltip-id={'general-tooltip'}
            data-tooltip-class-name={'header-tooltip pricing-tooltip-description'}
          >
            Advanced search{' '}
            {!enableAdvancedSearch && (
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/padlock.svg`}
                className='padlock-title-item'
                alt='padlock feature blocked'
              />
            )}
          </button>
          {portal(
            <ResearchPopUp
              identifier={'research-popup'}
              setShowPopup={closePortal}
              referenceState={referenceState}
              setOpenSearchBar={setOpenSearchBar}
              keywordsSection={'keywords'}
            />
          )}
        </>
      )}
    </PortalWithState>
  );
};

export default SimpleSearchBar;
