import React, { useContext, useRef } from 'react';
import Share from '../components/Share';
import { store } from '../../Store.js';
import useTitleScrolledStyles from '../hooks/useTitleScrolledStyles';
import ContentSummary from '../components/ContentSummary';
import FeedbackOnItem from '../FeedbackItem/FeedbackOnItem';
import ThumbsDown from '../../Common/ThumbsDown';
import TextSelector from '../../Common/TextSelector.js';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});
const ShareButtonRender = (props) => {
  const { shareButtonWidget, currentNew, embed } = props;
  const { id, billTitle, title, canonicalUrl, objectId, objectType } = currentNew;
  let item = {
    id,
    title: billTitle ? billTitle : title,
    objectID: objectId,
    type: objectType,
  };
  const ShareOptions = {
    item,
    id: item.id,
    title: item.title,
    shareButtonWidget,
    itemShare: embed,
  };
  if (canonicalUrl) {
    ShareOptions.link = `${process.env.REACT_APP_APPURL}${canonicalUrl}`;
  }
  return <Share {...ShareOptions} />;
};

const LegislationNewContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, contentResults, activeResults } = state;
  const { Legislation } = contentResults[activeResults];

  const { activeSearch } = search;
  const {
    currentNew,
    shareButtonWidget,
    contentContainer,
    hideSummary,
    reportWidget,
    embed,
    scrollbarRef,
    scrolling,
    isFromPopup,
    notIncludeBranding,
  } = props;
  const createMarkup = (body) => {
    return { __html: body };
  };

  const titleToRender = () => {
    let title;
    if (activeSearch || shareButtonWidget) {
      title = currentNew.billTitle
        ? currentNew.billTitleHighlighted ?? currentNew.billTitle
        : currentNew.titleHighlighted ?? currentNew.title;
    } else {
      title = currentNew.billTitle ?? currentNew.title;
    }
    return title;
  };

  const titleSection = useRef(null);
  let { showFixedTitle, titleStyles } = useTitleScrolledStyles(contentContainer, titleSection);

  const alreadyMarkedFeedback = !!currentNew?.feedback;
  const index = Legislation ? Legislation?.hits?.findIndex((item) => item.id === currentNew.id) : null;
  const ThumbsDownProperties = {
    item: { objectID: currentNew?.objectId },
    nameSection: 'Legislation',
    show: !shareButtonWidget && !!currentNew?.keywordsFromLists,
    embedFeed: embed,
    contentPage: true,
    alreadyMarked: alreadyMarkedFeedback,
    keywordsFromLists: currentNew?.keywordsFromLists,
    thick: true,
  };

  return (
    <>
      <div
        className={`${reportWidget ? 'content-title-in-widget' : 'content-title-with-summary'} section-title pb-2 legislation-content-title content-border-title`}
        ref={titleSection}
      >
        <h3 className='main-title news-title pr-lg-4' dangerouslySetInnerHTML={createMarkup(titleToRender())} />
        <div className='row mx-0 justify-content-between align-items-center'>
          <a className='legislation-source mb-0' href={currentNew.url} target='_blank' rel='noopener noreferrer'>
            Go to source
          </a>
          <div className='flex-centered'>
            <ThumbsDown {...ThumbsDownProperties} />
            {!notIncludeBranding && (
              <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} embed={embed} />
            )}
          </div>
        </div>
      </div>
      {!shareButtonWidget && <ContentSummary content={currentNew} hide={hideSummary} />}
      <div className='px-3 px-lg-4 pb-5 specific-content-container mt-2'>
        {/* FIXED TITLE */}
        <div
          className={`content-item-scrolled-title main-sticky section-title bg-white ${showFixedTitle && !isFromPopup && !reportWidget ? 'item-scrolled-title-visible' : ''}`}
          style={titleStyles}
        >
          <div className='d-flex justify-content-between scrolled-title-container'>
            <h3 className='main-title news-title pr-lg-4' dangerouslySetInnerHTML={createMarkup(titleToRender())} />
            <ThumbsDown {...ThumbsDownProperties} />
            {!notIncludeBranding && <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} />}
          </div>
        </div>
        {/* END FIXED TITLE */}

        {alreadyMarkedFeedback && (
          <FeedbackOnItem
            contentPage={true}
            item={{ objectID: currentNew?.objectId }}
            feedback={currentNew?.feedback}
            keywordsFromLists={currentNew?.keywordsFromLists}
            index={index}
          />
        )}

        <TextSelector currentNew={currentNew} scrollbarRef={scrollbarRef} scrolling={scrolling}>
          <div className='py-3 pr-3 news-content-in legislation-content-in context update-legislation'>
            {currentNew?.extract ? (
              <>
                <p dangerouslySetInnerHTML={createMarkup(currentNew.extract)}></p>
              </>
            ) : (
              <>
                {currentNew.lastEvents && (
                  <div className='row mx-0'>
                    <div className='pl-0'>
                      {!!currentNew.session && (
                        <>
                          <h3>Session</h3>
                          <p>{currentNew.session}</p>
                        </>
                      )}

                      <h3>Type of Bill</h3>
                      <p>{currentNew.typeOfBill ? currentNew.typeOfBill : 'No content'}</p>

                      <h3>Summary</h3>
                      <p dangerouslySetInnerHTML={createMarkup(currentNew.summary)}></p>
                      {/*{currentNew.summary?currentNew.summary:'No content'}*/}

                      <h3>Sponsor</h3>
                      <p>{currentNew.sponsor ? currentNew.sponsor : 'No content'}</p>
                      {currentNew.nextEventName && (
                        <>
                          <h3>Current stage</h3>
                          <p>{currentNew.nextEventName ? currentNew.nextEventName : 'No content'}</p>
                        </>
                      )}
                      <h3>Previous events</h3>
                      <ul className='policy-list px-0 primary-legislation-list mb-3'>
                        {currentNew.lastEvents
                          .sort((a, b) => a.order - b.order)
                          .map((item, index) => (
                            <li className='py-2' key={`item${index}`}>
                              {item.name}
                            </li>
                          ))}
                      </ul>

                      <a
                        className='legislation-source'
                        href={currentNew.allBillDocumentsUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        View all documents, including amendments, tracked changes to the Bill, briefing papers, etc.
                      </a>
                    </div>
                  </div>
                )}
                {currentNew.explanatoryNote && (
                  <>
                    <h3>Explanatory note</h3>
                    <div
                      className='explanatory-container'
                      dangerouslySetInnerHTML={createMarkup(currentNew.explanatoryNote)}
                    ></div>
                  </>
                )}
              </>
            )}
          </div>
        </TextSelector>
      </div>
    </>
  );
};

export default LegislationNewContent;
