import React, { useEffect, useRef, useState } from 'react';
import generalApiCall from '../apiCalls/generalApiCall';
import axios from 'axios';
import useIsMobile from '../utilities/useIsMobile';
import idsWithoutImages from '../key_updates/idsWithoutImages';
import locationSearchDescriptor from '../helpers/locationSearchDescriptor';
import { createSectionName } from '../helpers/controlActiveFilters';
let mouseLeft = false;
const StakeholderHoverLink = (props) => {
  const { name, contactId, navigate, contentContainer } = props;
  const [timesOverItem, setTimesOverItem] = useState(0);
  const [isOverItem, setIsOverItem] = useState(false);
  const [isLoadedContent, setIsLoadedContent] = useState(false);
  const [coordinates, setCoordinates] = useState({});
  const [content, setContent] = useState({});
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const isMobile = useIsMobile();

  const sectionName = createSectionName();

  useEffect(() => {
    return () => {
      source.cancel('Stakeholder over item canceled by the user');
    };
  }, []);

  const hideBox = (e) => {
    if (!isMobile && !!hoverStakeHolderBox.current && !hoverStakeHolderBox.current.contains(e.relatedTarget)) {
      setIsOverItem(false);
    }
  };

  const loadContent = async (e) => {
    let pathname = `/api/search/query`;
    let method = 'post';
    let requestProperties = {
      type: 'stakeholder',
      pageSize: 1,
      filters: [{ field: 'id', value: `${contactId}`, operator: 'str_eq' }],
      pageNumber: 1,
      query: '',
    };
    let requestHeaders = {
      cancelToken: source.token,
    };
    let results = await generalApiCall({ method, pathname, requestProperties, requestHeaders });
    if (!!results && results.hits && results.hits[0]) {
      setContent(results.hits[0]);
      setIsLoadedContent(true);
      setTimeout(() => {
        calculcatePosition();
        if (!mouseLeft) {
          setIsOverItem(true);
        }
      }, 10);
    }
  };

  const calculcatePosition = () => {
    if (!!contentContainer && !!hoverStakeHolderBox.current) {
      let boxHeight = hoverStakeHolderBox.current.getBoundingClientRect().height,
        boxWidth = hoverStakeHolderBox.current.getBoundingClientRect().width,
        leftAdjustment = 20,
        containerWidth = contentContainer.current.getBoundingClientRect().width,
        containerTop = contentContainer.current.getBoundingClientRect().top,
        containerLeft = contentContainer.current.getBoundingClientRect().left;
      const range = document.createRange();
      range.selectNode(stakeholderItem.current);
      let rectList = range.getClientRects(),
        link = rectList[0],
        linkWidth = link.width,
        linkTop = link.top - containerTop,
        linkLeft = link.left,
        linkHeight = link.height,
        topPosition = linkTop - (boxHeight - containerTop),
        leftPosition = linkLeft - leftAdjustment;
      //Calculate top
      if (topPosition < containerTop) {
        topPosition = linkTop + containerTop + linkHeight;
      }

      //Calculate left
      if (leftPosition - containerLeft + boxWidth > containerWidth) {
        leftPosition = linkLeft + linkWidth + leftAdjustment - boxWidth;
      }

      let coordinates = {
        top: topPosition,
        left: leftPosition,
      };
      setCoordinates(coordinates);
    }
  };

  const hoverStakeHolderBox = useRef(null);
  const stakeholderItem = useRef(null);

  return (
    <>
      <a
        href={content.contentUrl}
        className='simple-link-button hover-stakeholder-link'
        ref={stakeholderItem}
        onClick={(e) => {
          e.preventDefault();
          if (!isOverItem && content) {
            navigate(`${content.contentUrl}${window.location.search}`);
          }
        }}
        onMouseOver={() => {
          if (!isMobile) {
            let newTimes = timesOverItem + 1;
            mouseLeft = false;
            setTimesOverItem(newTimes);
            if (newTimes === 1) {
              loadContent();
            } else {
              calculcatePosition();
              setTimeout(() => {
                if (!mouseLeft) {
                  setIsOverItem(true);
                }
              }, 300);
            }
          }
        }}
        onMouseLeave={(e) => {
          mouseLeft = true;
          hideBox(e);
        }}
      >
        {name}
      </a>
      {isLoadedContent && (
        <div
          className='position-fixed bg-white box-rounded hover-stakeholder-message'
          ref={hoverStakeHolderBox}
          style={{
            top: coordinates.top,
            left: coordinates.left,
            visibility: isOverItem ? 'visible' : 'hidden',
            transform: isOverItem ? 'translateY(0)' : 'translateY(-10px)',
            opacity: isOverItem ? 1 : 0,
          }}
          onMouseOver={() => {
            setIsOverItem(true);
          }}
          onMouseLeave={() => {
            setIsOverItem(false);
          }}
        >
          <div className='d-flex'>
            <div className='col-11 p-4 adjust-line-height'>
              <p className='font-weight-bold mb-0 paragraph-p1'>{content.title}</p>
              <p className='mb-4 font-weight-normal paragraph-p1 main-subtle-text'>{content.summary}</p>
              <button
                className='general-button action-button px-4 paragraph-p1'
                onClick={() => {
                  navigate(
                    `${content.contentUrl}${locationSearchDescriptor({ topicId: sectionName === 'KeyUpdates' ? 'all' : null })}`
                  );
                }}
              >
                View profile and activity
              </button>
            </div>
            <div className='col-5 px-0'>
              <div
                className='h-100 w-100 hover-stakeholder-image'
                style={{
                  backgroundImage:
                    content.imageId !== null && content.imageId !== 0 && !idsWithoutImages.includes(content.imageId)
                      ? `url('https://www.parliamenttoday.com/image.php?imageid=${content.imageId}&ml=1')`
                      : `url('${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png')`,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StakeholderHoverLink;
