import React from 'react';
import useIsMobile from '../../../utilities/useIsMobile';
import useValidateContactsOversubscribed from '../../../utilities/useValidateContactsOversubscribed';
import { Link } from 'react-router-dom';

function CreateEmailLink({ showToolTip, text, redirectToEmails, noIcon, additionalText }) {
  const isSmallMobile = useIsMobile(520);
  const { setShowContactsOversubscribedModal, contactsOversubscribed } = useValidateContactsOversubscribed();
  return (
    <Link
      className={
        isSmallMobile && !noIcon
          ? 'add-to-contact-mobile-button'
          : `general-button sort-button d-lg-block top-nav-create-email-button ${
              redirectToEmails ? '' : 'ml-3'
            } ${showToolTip ? 'top-nav-create-email-button-disabled' : ''}`
      }
      data-tooltip-content={showToolTip && !isSmallMobile ? 'Please configure an email domain' : undefined}
      data-tooltip-id={showToolTip && !isSmallMobile ? 'general-tooltip' : undefined}
      data-tooltip-class-name={showToolTip && !isSmallMobile ? 'header-tooltip pricing-tooltip-description' : undefined}
      to={
        (showToolTip && !isSmallMobile) || contactsOversubscribed < 0
          ? '#'
          : redirectToEmails
            ? `/influence/emails`
            : `/influence/emails/create`
      }
      onClick={() => (contactsOversubscribed < 0 ? setShowContactsOversubscribedModal(true) : undefined)}
    >
      {isSmallMobile && !noIcon ? '' : text ? text : 'Create email'}
      {additionalText && <span className='d-none d-lg-inline-block'>{additionalText}</span>}
    </Link>
  );
}

export default CreateEmailLink;
