import { Link, NavLink, useLocation } from 'react-router-dom';
import returnCorrectWPUrl from '../utilities/returnCorrectWPUrl';
import { useRef, useState } from 'react';
import { ProductsInformation } from '../../home/HomeComponents';
import normalize from '../utilities/normalizeString';
import { Portal } from 'react-portal';
import useIsMobile from '../utilities/useIsMobile';
import ModalComponent from '../../Common/Modal';

const NavPagesLoggedOut = (props) => {
  const { closeMenu } = props;
  const location = useLocation();
  return (
    <>
      <ProductButton />
      <li>
        <NavLink
          to='/pricing'
          className={({ isActive }) => `${isActive ? 'active-page' : ''} static-page-link`}
          onClick={() => {
            closeMenu();
          }}
        >
          Pricing
        </NavLink>
      </li>
      <li>
        <a href={`${returnCorrectWPUrl()}/customer-stories`} className='static-page-link'>
          Customers
        </a>
      </li>
      <li>
        <a
          href={`${returnCorrectWPUrl()}/demo`}
          className='static-page-link'
          onClick={() => {
            closeMenu();
          }}
        >
          Demo
        </a>
      </li>
      <li>
        <NavLink
          to='/data-hub'
          className={({ isActive }) =>
            `static-page-link ${isActive || location.pathname.includes('/data-hub') ? 'active-page' : ''}`
          }
          onClick={() => {
            closeMenu();
          }}
        >
          Data hub
        </NavLink>
      </li>
      <li>
        <a href={`${returnCorrectWPUrl()}/for-parliamentarians`} className='static-page-link'>
          For policymakers
        </a>
      </li>
    </>
  );
};

const LoggedOutTopHeader = () => {
  const location = useLocation();
  const loginUrl = `/login?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`;
  return (
    <>
      <div className='d-none d-lg-block bg-main-white z-extra-max position-relative white-top-header-bar'>
        <div className='main-content-wrapper mx-auto px-3 px-lg-5 flex-centered justify-content-between'>
          <div>
            <Link className='main-dark-text reset-link link-hover mr-lg-5 mr-3' to='/'>
              PolicyMogul
            </Link>
            <a
              className='main-dark-text reset-link link-hover'
              href={`${process.env.REACT_APP_POLICYINFLUENCE_APP_URL}`}
            >
              PolicyInfluence
            </a>
          </div>
          <div>
            <a className='main-dark-text reset-link link-hover' href={`${returnCorrectWPUrl()}/were-hiring/`}>
              We're hiring
            </a>
            <button className='general-button main-dark-text reset-link link-hover ml-3 ml-lg-5 contact-button-launch-messenger'>
              Contact us
            </button>
            <Link
              to={loginUrl}
              className='reset-link  main-dark-text border-general border-main-blue-60 rounded-pill px-4 specific-top-padding-3 specific-bottom-padding-5 ml-3 ml-lg-5 btn-login-home'
              onClick={() => {
                window.location.assign(loginUrl);
              }}
            >
              Log in
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const ProductButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);

  const productRef = useRef();
  const { top, left, height } = productRef?.current?.getBoundingClientRect() ?? {};
  const isMobile = useIsMobile();
  const popupRef = useRef(null);
  const hoverTimeoutRef = useRef(null);

  const handleMouseLeave = (e) => {
    if (popupRef?.current && !isMobile) {
      const relatedTarget = e.relatedTarget;
      if (!relatedTarget || !(relatedTarget instanceof Node) || !popupRef.current.contains(relatedTarget)) {
        setIsOpen(false);
      }
    }
  };

  const handleMouseOver = () => {
    if (!isMobile) {
      hoverTimeoutRef.current = setTimeout(() => {
        setIsOpen(true);
      }, 300);
    }
  };

  const handleMouseOut = () => {
    clearTimeout(hoverTimeoutRef.current);
  };

  return (
    <>
      <li
        className='position-relative navpage-product'
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          if (isMobile) {
            setIsOpenMobile(true);
          }
        }}
      >
        <button
          ref={productRef}
          className={`general-button static-page-link dropdown-item-element dropdown-item-element-10 ${isOpen || isOpenMobile ? 'open' : ''} `}
        >
          Product
        </button>
        {isOpen && (
          <Portal>
            <div
              ref={popupRef}
              className='pt-3 position-fixed z-extra-max'
              style={{ top: `${top + height}px`, left: `${left}px`, width: '650px' }}
            >
              <LoggedOutProductContent />
            </div>
          </Portal>
        )}
      </li>
      <ModalComponent isOpen={isOpenMobile} setIsOpen={setIsOpenMobile} maxWidth={650}>
        <LoggedOutProductContent />
      </ModalComponent>
    </>
  );
};

const LoggedOutProductContent = () => {
  return (
    <div className='bg-main-white rounded d-flex flex-column flex-lg-row  box-shadow w-100 '>
      <div className='p-4 px-lg-5 pb-lg-5 flex-grow-1 order-1 order-lg-0'>
        <h3 className='title-h4-bold mb-3'>Explore features</h3>
        <div className='product-information-list'>
          {ProductsInformation.map((item) => {
            const { name, icon } = item;
            const itemUrl = `${returnCorrectWPUrl()}/product/${normalize(name)}`;
            return (
              <a
                key={itemUrl}
                href={itemUrl}
                className='d-block submenu-item list-action-button flex-centered reset-link mb-2 rounded'
              >
                <div className='d-flex'>
                  <div className={`icon-container-${icon}`}>
                    <span className={`icon-${icon}`} />
                  </div>
                  <span>{name}</span>
                </div>
              </a>
            );
          })}
        </div>
      </div>
      <div className='bg-salmon rounded-right p-4 px-lg-5 pb-lg-5 order-0 order-lg-1'>
        <h3 className='title-h4-bold mb-4'>See it in action</h3>
        <div className='bg-white rounded-bottom'>
          <img
            alt='see in action'
            className='w-100 rounded-top d-none d-lg-block'
            src={`${process.env.REACT_APP_CDNURL}/images/20240923-action-image.png`}
          />
          <div className={'p-4 text-center'}>
            <p className='mx-auto' style={{ maxWidth: '160px' }}>
              Book a live demo with a PolicyMogul expert
            </p>
            <a
              href={`${returnCorrectWPUrl()}/demo`}
              target='_blank'
              rel='noopener noreferrer'
              className='rounded-pill general-button text-white bg-main-blue px-4 py-2 home-action-button d-lg-block reset-link'
            >
              Book a demo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoggedOutTopHeader };
export default NavPagesLoggedOut;
