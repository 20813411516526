import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Preloader from '../Analytics/ui/AnalyticsDashboard/Preloader';
import { ChartViewOptions, DateFilterButton, TypeOfChartOptions } from '../Analytics/ui/MentionsByTopic/FiltersBar';
import SentimentOverTimeChart from '../Analytics/ui/SentimentOverTime/SentimentOverTimeChart';
import getUrlParam, { getUrlParamRaw } from '../utilities/getUrlParam';
import SentimentSharePopup from './SentimentSharePopup';

const SentimentButtons = ({ slackIntegrationPage }) => {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const url = `${window.location.origin}${location.pathname}${!slackIntegrationPage ? '?utm_source=sentiment-share-button' : ''}`;
  const resultsPage = !!getUrlParam('search');
  return (
    <div className='flex-centered'>
      {!slackIntegrationPage && (
        <a
          href={`http://support.policymogul.com/en/articles/6429988-sentiment-analysis-faqs`}
          target='_blank'
          rel='noopener noreferrer'
          className='action-state-hover general-button rounded-button bg-white mx-2 mx-lg-4 sentiment-page-main-button sentiment-button-faqs'
        >
          FAQs
        </a>
      )}
      <button
        className={`general-button rounded-button bg-${resultsPage ? 'white' : 'yellow'} sentiment-page-main-button sentiment-button-share ${resultsPage ? 'results-page' : ''}`}
        onClick={() => {
          setShowPopup(true);
        }}
      >
        Share{' '}
      </button>
      {resultsPage && (
        <Link
          to={'/register?utm_source=sentiment-page-try-policymogul-button'}
          className='action-button rounded-button px-4 ml-2 ml-lg-4 sentiment-button-try'
        >
          Try PolicyMogul
        </Link>
      )}
      {showPopup && (
        <SentimentSharePopup
          identifier={'sentiment-share-popup'}
          setShowPopup={setShowPopup}
          url={url}
          title={`Find out what MPs think about any issue with PolicyMogul's political sentiment analysis tool:`}
          additionalClass={'delete-keywordcategory-modal'}
        />
      )}
    </div>
  );
};

const SentimentGraphPage = (props) => {
  const { isLoading, data, sentimentState, modifyFiltersBar, showPopup } = props;
  const chartRef = useRef();
  const location = useLocation();

  return (
    <div className='text-white pt-5 position-relative h-100 sentiment-page-graph-container'>
      <div className='px-3 px-lg-5 container-lg h-100 d-flex flex-column px-0'>
        <div className='justify-content-between d-md-flex mb-4'>
          <p className='mb-3 mb-md-0 title-h3'>
            Political sentiment for '{decodeURIComponent(getUrlParamRaw('search'))}'
          </p>
          <div>
            <Link
              to={`${location.pathname}`}
              className='action-state-hover general-button rounded-button bg-white sentiment-page-main-button sentiment-button-new-search'
            >
              New search
            </Link>
          </div>
        </div>
        <div
          className='box-top-rounded px-3 py-5 px-lg-5 bg-salmon flex-grow-1'
          onMouseOut={() => {
            let tooltip = document.getElementById('external-mentions-tooltip');
            if (tooltip) {
              tooltip.style.opacity = 0;
            }
          }}
        >
          <div className='d-flex justify-content-lg-between mb-5 sentiment-page-filters overflow-auto'>
            <TypeOfChartOptions
              analyticsState={sentimentState}
              modifyFiltersBar={modifyFiltersBar}
              sentimentChanges={true}
            />
            <div className='flex-centered'>
              <DateFilterButton analyticsState={sentimentState} modifyFiltersBar={showPopup} />
              <ChartViewOptions analyticsState={sentimentState} modifyFiltersBar={showPopup} />
            </div>
          </div>
          {isLoading && <Preloader />}
          {!isLoading && (
            <div className='box-rounded px-3 py-5'>
              <SentimentOverTimeChart ref={chartRef} data={data} analyticsState={sentimentState} external />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { SentimentButtons };
export default SentimentGraphPage;
