import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import Loading from '../Login/Loading';
import MainTeamList from './MainAdminTeamList';
import TeamUserPage from './TeamUserPage';
import AdminHeader from './ui/AdminHeader';
import SettingsTooltips from '../Settings/settings-tooltips';
import { Route, Routes } from 'react-router-dom';
import { AdminStateProvider } from './AdminState';
import useGetAccessToken from '../Dashboard/apiCalls/useGetAccessToken';

const Admin = () => {
  const { logout } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    initialValidations();
  }, []);

  const initialValidations = async () => {
    let token = await getAccessToken();
    let decodedToken = jwtDecode(token);
    let permissions = decodedToken.permissions;
    if (permissions.length === 0 && !permissions.includes('admin:user')) {
      return window.location.replace('/monitor-inbox/key-updates');
    } else {
      setIsLoading(false);
    }
  };

  const headerProperites = () => {
    const logoutWithRedirect = () => logout({ logoutParams: { returnTo: window.location.origin } });
    const urlLink = () => {
      return '/admin';
    };
    return { logoutWithRedirect, urlLink };
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <AdminStateProvider>
            <AdminHeader headerProperites={headerProperites()} />
            <Routes>
              <Route path={`/`} element={<MainTeamList />} />
              <Route path={`/organisation/:id/*`} element={<TeamUserPage />} />
            </Routes>
            <SettingsTooltips />
          </AdminStateProvider>
        </>
      )}
    </>
  );
};

export default withAuthenticationRequired(Admin, {
  onRedirecting: () => <Loading />,
});
