import React, { useState, useCallback } from 'react';
import MicroModal from 'micromodal';

const DeleteInfluenceItemPopUp = (props) => {
  const { deleteFunction, type, setShowDeletePopUp } = props;
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const micromodalElement = useCallback((node) => {
    if (node !== null) {
      MicroModal.show('deleteinfluence-pop-up', {
        awaitCloseAnimation: true,
      });
    }
  }, []);

  const closeMicroModal = () => {
    MicroModal.close('deleteinfluence-pop-up');
    setTimeout(() => {
      setShowDeletePopUp(false);
    }, 300);
  };

  return (
    <div
      className='modal micromodal-slide delete-keywordcategory-modal'
      id={`deleteinfluence-pop-up`}
      aria-hidden='true'
      tabIndex='-1'
      ref={micromodalElement}
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div className='modal__container py-4' role='dialog' aria-modal='true' aria-labelledby='modal-alerts-title'>
          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>

          <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
            <div className='text-center'>
              <p>
                Are you sure you want to delete this {type === 'policy-asks' ? 'policy ask' : type.replace('-', ' ')}?
              </p>

              <div className='my-4'>
                <button
                  className='general-button delete-button px-4 mr-3 py-1'
                  onClick={(e) => {
                    e.stopPropagation();
                    closeMicroModal();
                  }}
                >
                  Cancel
                </button>
                <button
                  className='general-button danger-button px-4 py-2'
                  disabled={isLoadingButton}
                  onClick={async () => {
                    setIsLoadingButton(true);
                    deleteFunction();
                  }}
                >
                  {`Delete ${type === 'policy-asks' ? 'policy ask' : type.replace('-', ' ')}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteInfluenceItemPopUp;
