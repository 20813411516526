import getUrlParam from '../utilities/getUrlParam';
import { FilterPartyNames } from './ui/MentionsByTopic/PartyMetadata';
import { TweetsFilters, graphDataSections } from './useCallMentionsByTopic';
import { useContext } from 'react';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import { store } from '../../Store';
import { chartDataOptions } from './createAnalyticsState';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import useSelectedCountriesFilters from './useSelectedCountriesFilters';
import { useChartDataOptionSelected } from './ui/MentionsByTopic/ChartDataTable';
import ChangeSearchQuery from '../utilities/changeSearchQuery';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const useRightPanelIndividualCall = (props) => {
  const { analyticsState, contentState } = props;
  const { dataKey, chartDataKeys, date, region, totalRow } = contentState;

  const { currentView, allowFrontbenchers } = analyticsState;
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;
  const list = keywordsLists.find((item) => item.id === parseInt(getUrlParam('topic-id')));

  const location = useLocation();
  const InterestByRegionSection = location.pathname.includes('/analytics/interest-by-region');
  const { chartOptionName: chartDataOptionNameState } = useChartDataOptionSelected();
  const chartOptionName = InterestByRegionSection ? 'Region' : chartDataOptionNameState;

  const totalCell = dataKey === 'totalCell';
  const contributionGraph = chartOptionName === 'Contribution type' || chartOptionName === 'Overall';
  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);
  const parliamentaryPositionsFilters = chartDataOptions.find(
    (item) => item.name === 'Parliamentary position'
  )?.filters;

  const { generalApiCall } = useGeneralApiCall();
  const { getCountrySpecificFilters, UKSelected, WalesSelected } = useSelectedCountriesFilters();

  const individualApiCall = async ({ type, sentiment, pageNumber, snapshot, source, freeSearch }) => {
    const pathname = `/api/search/${freeSearch ? 'query' : 'query-by-keyword-list'}`;
    const method = 'post';
    const typeToCall = type?.toLowerCase()?.includes('tweet') ? 'tweet' : 'ParliamentaryRecord';

    if ((type === 'Edm' && !UKSelected) || (type === 'SeneddCommitteeTranscriptContribution' && !WalesSelected)) {
      return {};
    }

    let requestProperties = {
      pageSize,
      pageNumber: pageNumber ?? 1,
      type: typeToCall,
      includeHighlights: true,
      sort: { field: 'dateTime', order: 'DESC' },
    };

    if (freeSearch) {
      requestProperties = {
        ...requestProperties,
        query: ChangeSearchQuery(decodeURIComponent(freeSearch)),
      };
    } else {
      requestProperties = {
        ...requestProperties,
        keywordListId: list?.id,
      };
    }

    let chartDataOption = chartDataOptions.find((option) => option.name === chartOptionName);
    let filters = [];

    if (type === 'Committee contributions') {
      filters = [
        ...filters,
        {
          field: 'contentType',
          value: 'SeneddCommitteeTranscriptContribution',
          operator: 'str_eq',
        },
      ];
    }

    if (sentiment) {
      filters.push({
        field: 'sentiment',
        value: sentiment,
        operator: 'str_eq',
      });
    }

    let sectionItem = graphDataSections.find((item) => item.name === type);
    if (sectionItem) {
      filters = [...filters, ...getCountrySpecificFilters({ value: sectionItem?.value, field: sectionItem?.field })];
    }

    if (totalCell && !InterestByRegionSection) {
      filters = [...filters, ...parliamentaryPositionsFilters];
      if ((chartOptionName === 'Party' || chartOptionName === 'Region') && !!chartDataKeys) {
        chartDataKeys.forEach((key) => {
          let partyOption = chartOptionName === 'Party';
          filters.push({
            field: partyOption ? 'contactParty' : 'contactRegion',
            value: partyOption && FilterPartyNames[key] ? FilterPartyNames[key] : key,
            operator: 'str_eq',
          });
        });
      }
    }
    if (totalCell && InterestByRegionSection) {
      if (!!chartDataKeys) {
        if (totalRow) {
          const value = region === 'Yorkshire and the Humber' ? 'yorkshire & the humber' : region;
          filters.push({
            field: 'contactRegion',
            value: value,
            operator: 'str_eq',
          });
        } else {
          chartDataKeys.forEach((key) => {
            const region = key === 'Yorkshire and the Humber' ? 'yorkshire & the humber' : key;
            filters.push({
              field: 'contactRegion',
              value: region,
              operator: 'str_eq',
            });
          });
        }
      }
    }

    if (typeToCall.toLowerCase() === 'tweet') {
      filters = [...filters, ...TweetsFilters];
    }

    if (!totalCell && !contributionGraph) {
      const changeDataKey = () => {
        let key = dataKey;
        if (chartOptionName === 'Party' && FilterPartyNames[dataKey]) {
          key = FilterPartyNames[dataKey];
        }
        if (chartOptionName === 'Parliamentary position') {
          if (dataKey === 'Backbenchers') {
            key = 'backbencher';
          } else if (dataKey === 'MSs') {
            key = 'ms';
          } else if (dataKey === 'MSPs') {
            key = 'msp';
          } else {
            key = dataKey.toLowerCase();
          }
        }

        if (chartOptionName === 'Region' && dataKey === 'Yorkshire and the Humber') {
          key = 'yorkshire & the humber';
        }

        if (InterestByRegionSection) {
          key = region === 'Yorkshire and the Humber' ? 'yorkshire & the humber' : region;
        }
        return key;
      };
      filters.push({
        field: `${chartDataOption.bucketField}`,
        value: changeDataKey(),
        operator: 'str_eq',
      });
    }

    if (InterestByRegionSection) {
      if (!allowFrontbenchers) {
        filters = [
          ...filters,
          {
            field: 'contactPositionCategories',
            value: 'Backbencher',
            operator: 'str_eq',
          },
          {
            field: 'contactPositionCategories',
            value: 'MSP',
            operator: 'str_eq',
          },
          {
            field: 'contactPositionCategories',
            value: 'MS',
            operator: 'str_eq',
          },
        ];
      } else {
        filters.push({
          field: 'contactCategories',
          value: 'MP',
          operator: 'str_eq',
        });
      }
    } else {
      filters = [...filters, ...parliamentaryPositionsFilters];
    }

    //DATE FILTER
    if (snapshot) {
      filters = [...filters, ...parliamentaryPositionsFilters];
      filters.push(
        {
          field: 'dateTime',
          value: `${dayjs()
            .subtract(process.env.REACT_APP_SENTIMENT_SNAPSHOT_NUM_DAYS ?? 90, 'day')
            .startOf('day')
            .format('YYYY-MM-DD')}`,
          operator: 'DATE_GTE',
        },
        {
          field: 'dateTime',
          value: `${dayjs().format('YYYY-MM-DD')}`,
          operator: 'DATE_LT',
        }
      );
    } else if (InterestByRegionSection) {
      filters.push(
        {
          field: 'dateTime',
          value: dayjs().subtract(365, 'day').add(-1, 'day').startOf('day').format('YYYY-MM-DD'),
          operator: 'date_gte',
        },
        {
          field: 'dateTime',
          value: dayjs().add('1', 'day').format('YYYY-MM-DD'),
          operator: 'date_lt',
        }
      );
    } else {
      let nextDate = dayjs(date).startOf('day').add('1', currentView.toLowerCase()).format('YYYY-MM-DD');
      filters.push(
        {
          field: 'dateTime',
          value: `${dayjs(date).startOf('day').format('YYYY-MM-DD')}`,
          operator: 'DATE_GTE',
        },
        { field: 'dateTime', value: `${nextDate}`, operator: 'DATE_LT' }
      );
    }
    requestProperties = {
      ...requestProperties,
      filters,
    };

    return await generalApiCall({
      pathname,
      method,
      requestProperties,
      requestSource: source,
      notShowErrorMessage: true,
      bigInt: true,
      needsAuthentication: true,
    });
  };

  return { individualApiCall };
};

export default useRightPanelIndividualCall;
