import { useRef, useState } from 'react';
import useOutsideElement from '../Dashboard/utilities/useOutsideElement';
import { WistiaPlayer } from '@wistia/wistia-player-react';

const videoOptions = [
  {
    name: 'PolicyMogul background music',
    id: '02e6sczx6x',
  },
  {
    name: 'Legis-late night jazz',
    id: 'kaxukvkyvf',
  },
  {
    name: 'Govern-metal',
    id: '39owecohvc',
  },
];

const HomeSearchButton = (props) => {
  const { optionSelected, setOptionSelected } = props;
  const [isOpen, setIsOpen] = useState(false);
  const hideActions = () => {
    setIsOpen(false);
  };

  const actionsElement = useRef();
  useOutsideElement(actionsElement, hideActions);

  const options = [
    { name: 'political updates', icon: 'key-update' },
    { name: 'political data', icon: 'statistics' },
  ];

  return (
    <>
      <div className='position-relative' ref={actionsElement}>
        <button
          className='rounded-pill text-white bg-main-blue px-4 py-2 text-left border-general border-main-blue w-100'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{ minWidth: '238px' }}
        >
          <span className={`w-100 dropdown-item-element dropdown-item-element-10 ${isOpen ? 'open' : ''}`}>
            Search {optionSelected}
          </span>
        </button>
        {isOpen && (
          <div className={`position-absolute box-shadow rounded py-2 z-max w-100`}>
            {options.map((item) => {
              const { name, icon } = item;
              return (
                <button
                  key={`search-${name}`}
                  className={`w-100 flex-centered text-left general-button list-action-button px-3 py-2 main-subtle-text position-relative`}
                  onClick={(e) => {
                    setOptionSelected(name);
                    setIsOpen(false);
                  }}
                >
                  <span className={`icon-${icon} mr-3`} />
                  Search {name} {/* {optionSelected === name && <span className='icon-tick topic-selected-mark' />} */}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

const SpeakerButton = (props) => {
  const { optionSelectedState, videoElementRef } = props;
  const { optionSelected, setOptionSelected } = optionSelectedState ?? {};
  const [isOpen, setIsOpen] = useState(false);
  const hideActions = () => {
    setIsOpen(false);
  };
  const actionsElement = useRef();
  useOutsideElement(actionsElement, hideActions);

  return (
    <>
      <div className='d-flex justify-content-lg-end mb-2 mt-4 mb-lg-3 mb-lg-0' ref={actionsElement}>
        <div className={`position-relative d-inline-block d-flex justify-content-end`} style={{ minWidth: '284px' }}>
          <button
            className='general-button speaker-button flex-centered mb-3'
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <span className='circle-button-medium bg-yellow specific-right-margin-12 text-center flex-centered justify-content-center'>
              <span className='icon-speaker paragraph-p4 text-white' />
            </span>
            <span className={`w-100 dropdown-item-element dropdown-item-element-10 ${isOpen ? 'open' : ''}`}>
              {optionSelected}
            </span>
          </button>
          {isOpen && (
            <div
              className={`position-absolute box-shadow rounded py-2 z-extra-max`}
              style={{ top: `40px`, left: '40px', width: '275px' }}
            >
              {videoOptions.map((item) => {
                const { name } = item;
                return (
                  <button
                    key={`search-${name}`}
                    className={`w-100 flex-centered justify-content-between text-left general-button list-action-button px-3 py-2 main-light-text`}
                    onClick={(e) => {
                      setOptionSelected(name);
                      setIsOpen(false);
                      setTimeout(() => {
                        if (videoElementRef?.current) {
                          videoElementRef.current.play();
                        }
                      }, 300);
                    }}
                  >
                    {name} {optionSelected === name && <span className='icon-tick' />}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const VideoPlayer = (props) => {
  const { optionSelectedState, videoElementRef } = props;
  const { optionSelected } = optionSelectedState ?? {};
  const videoId = videoOptions?.find((item) => item?.name === optionSelected)?.id;

  return (
    <div className='position-relative home-video-player'>
      <div className='position-relative home-video-player-thumbnail'>
        <img
          src={`${process.env.REACT_APP_CDNURL}/images/20241106-video.png`}
          alt='photograph of video thumbnail'
          className='img-fluid'
        />
        <div className='home-video-player-button'>
          <img
            src={`${process.env.REACT_APP_CDNURL}/images/20241106-yellow-play-triangle.svg`}
            alt='photograph of video thumbnail'
          />{' '}
        </div>
      </div>
      <div className='position-absolute w-100 h-100 z-max pointer' style={{ top: '0px', left: '0px', opacity: 0 }}>
        <WistiaPlayer ref={videoElementRef} mediaId={videoId} wistiaPopover={true} popoverContent='link'>
          <span></span>
        </WistiaPlayer>
      </div>
    </div>
  );
};

export { HomeSearchButton, SpeakerButton, VideoPlayer };
