import React, { useEffect, useState, useContext, useRef } from 'react';
import SavedItemsComponent from './ui';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import InitialValidations from '../InitialValidations';
import Loading from '../Login/Loading';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import { store } from '../Store';
import createNotification from '../Settings/Utilities/CreateNotification';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import GeneralTooltips from '../Dashboard/components/GeneralTooltips';
import { useNavigate, useLocation } from 'react-router-dom';
import addMetaTags from '../Dashboard/utilities/addMetaTags';
import SaveItemPopup from './SaveItemPopup';
import axios from 'axios';
import getCurrentTeam from '../Settings/Team/getCurrentTeam';
import { useResetClientNavigation } from '../Dashboard/Navigation/ClientNavigation';
import useGetAccessToken from '../Dashboard/apiCalls/useGetAccessToken';

const SavedItems = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists, search, userSavedItemsFolders, team } = state;

  const [isLoading, setIsLoading] = useState(true);
  const [currentFolder, setCurrentFolder] = useState({});
  const [filtersResources, setFiltersResources] = useState([]);
  const [isLoadingFolder, setIsLoadingFolder] = useState(true);
  const [savedItems, setSavedItems] = useState([]);

  const { generalApiCall } = useGeneralApiCall();
  const { getAccessToken } = useGetAccessToken();

  const location = useLocation();
  const navigate = useNavigate();
  const folderParam = getUrlParam('folder-id');
  const isMounted = useRef(false);
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  const initialActions = useRef();
  const sourceRef = useRef(axios.CancelToken.source());
  const { resetClientState } = useResetClientNavigation();

  initialActions.current = async (source) => {
    try {
      //AE: THIS IS NECCESSARY TO AVOID DUPLICATE CALLS WHEN YOU CLICK ON A SETTINGS PAGE AND GO BACK TO THE MAIN APP
      resetClientState();
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'search',
        value: {
          ...search,
          searchState: false,
        },
      });
      setIsLoading(true);
      setIsLoadingFolder(true);
      await loadFolders(source);
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialActions.current(source);
    return () => {
      source.cancel('Saved items cancelled by the user');
    };
  }, []);

  const updateFolderForParameter = useRef();
  updateFolderForParameter.current = (source) => {
    try {
      let position = userSavedItemsFolders.findIndex((item) => item.id === parseInt(folderParam));
      if (position >= 0) {
        setCurrentFolder(userSavedItemsFolders[position !== undefined ? position : 0]);
        setFiltersResources([]);
        loadFolderItems(parseInt(folderParam), false, source);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    updateFolderForParameter.current(source);
    return () => {
      source.cancel('Saved items canceled by the user.');
    };
  }, [folderParam, userSavedItemsFolders]);

  const loadFolders = async (source) => {
    try {
      let results = await loadFoldersCall(source);
      if (results) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: results });
        let item;
        if (!!getUrlParam('folder-id')) {
          let folder = results.find((item) => item.id === parseInt(getUrlParam('folder-id')));
          item = folder ?? results[0];
        } else {
          item = results[0];
        }
        setCurrentFolder(item);
        navigate(`/saved-items?folder-id=${item.id}`, { replace: true });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const loadFoldersCall = async (source) => {
    let pathname = `/api/saved-items/saved-item-folders${showTweets ? '' : '?excludeTweets=true'}`;
    let method = 'get';
    if (keywordsLists.length === 0) {
      await loadKeywordsLists();
    }
    let currentTeam = Object.keys(team)?.length === 0 ? await getCurrentTeam(getAccessToken, source) : team;
    if (!!currentTeam) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
    }
    let results = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      notShowErrorMessage: true,
      requestSource: source,
    });
    return results;
  };

  const updateFolders = async () => {
    let folders = await loadFoldersCall();
    if (folders) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: folders });
    }
  };

  const loadKeywordsLists = async () => {
    let pathname = '/api/keyword-list/get-team-keyword-lists';
    let method = 'get';
    let results = await generalApiCall({ pathname, method, needsAuthentication: true, notShowErrorMessage: true });
    if (results) {
      let { myKeywordLists, teamMemberKeywordLists } = results;
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'keywordsLists',
        value: [...myKeywordLists, ...teamMemberKeywordLists],
      });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'readyKeywordLists', value: true });
    }
  };

  //CREATE A FOLDER
  const createEditAFolder = async (props) => {
    const { e, name, id } = props;
    let el = e.target;
    el.disabled = true;
    let pathname = `/api/saved-items/${id ? 'update' : 'create'}-saved-item-folder`;
    let method = 'post';
    let requestProperties = {
      name,
    };
    if (id) {
      requestProperties = { ...requestProperties, id };
    }
    let results = await generalApiCall({ pathname, method, needsAuthentication: true, requestProperties });
    if (results) {
      let newFolders = [...userSavedItemsFolders];
      if (id) {
        let position = newFolders.findIndex((item) => item.id === id);
        newFolders[position].name = name;
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: newFolders });
      } else {
        newFolders = [...newFolders, results];
        setCurrentFolder(results);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: newFolders });
        await loadFolderItems(results.id);
      }
      createNotification('success', `Folder ${id ? 'edited' : 'created'} successfully`);
      return true;
    }
    el.disabled = false;
    return false;
  };

  let savedItemsSearchDescriptor = (id) => {
    let url = new URL(window.location.href);
    url.searchParams.set('folder-id', id);
    return `?${url.searchParams.toString()}`;
  };

  const loadFolderItems = async (id, resources, source) => {
    setIsLoadingFolder(true);
    let pathname = `/api/saved-items/saved-items?savedItemFolderId=${id}${resources?.length > 0 ? `&contentTypes=${resources.join(',')}` : ''}${showTweets ? '' : '&excludeTweets=true'}`;
    let method = 'get';
    let results = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      notShowErrorMessage: true,
      bigInt: true,
      requestSource: source,
    });
    if (results) {
      let finalResults = results;
      if (process.env.REACT_APP_ENABLE_TWITTER === 'false') {
        finalResults = finalResults.filter((item) => item?.contentType !== 'Tweet');
      }
      setSavedItems(finalResults);
      navigate(`/saved-items${savedItemsSearchDescriptor(id)}`, { replace: true });
      let title = `Saved items - PolicyMogul`;
      addMetaTags({
        title,
        location: {
          ...location,
          search: savedItemsSearchDescriptor(id),
        },
        dispatch,
      });
      setIsLoadingFolder(false);
      if (!isMounted.current) {
        isMounted.current = true;
      }
    }
  };

  const changeFolder = (folder) => {
    sourceRef.current.cancel('Mark item canceled by the user');
    navigate(`${location.pathname}${savedItemsSearchDescriptor(folder.id)}`);
  };

  const removeFromFolder = async (e, id) => {
    let el = e.target;
    el.disabled = true;
    let pathname = `/api/saved-items/saved-item/${id}`;
    let method = 'delete';
    let results = await generalApiCall({ pathname, method, needsAuthentication: true, returnCompleteRequest: true });
    if (results.status === 200) {
      let newItems = savedItems.slice();
      let position = newItems.findIndex((item) => item.id === id);
      newItems.splice(position, 1);
      setSavedItems(newItems);
      //update count number
      let newFolders = [...userSavedItemsFolders];
      let folderPosition = newFolders.findIndex((item) => item.id === currentFolder.id);
      newFolders[folderPosition].itemCount -= 1;
      dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: newFolders });
      createNotification('success', `Removed from ${currentFolder.name}`);
    } else {
      el.disabled = false;
    }
  };

  const deleteAFolder = async (e, id) => {
    if (userSavedItemsFolders.length > 1) {
      let el = e.target;
      el.disabled = true;
      let pathname = `/api/saved-items/saved-item-folder/${id}`;
      let method = 'delete';
      let results = await generalApiCall({ pathname, method, needsAuthentication: true, returnCompleteRequest: true });
      if (results.status === 200) {
        let newFolders = [...userSavedItemsFolders];
        let position = newFolders.findIndex((item) => item.id === id);
        newFolders.splice(position, 1);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: newFolders });
        let newCurrentFolder = newFolders[newFolders.length - 1];
        setCurrentFolder(newCurrentFolder);
        loadFolderItems(newCurrentFolder.id);
        createNotification('success', 'Folder deleted successfully');
        return true;
      } else {
        el.disabled = false;
      }
    } else {
      createNotification('danger', 'You must have at least 1 folder');
    }
  };

  const finalHits = () => {
    return savedItems.map((item) => {
      let itemContent = { ...item.content };
      if (item.contentType === 'UserContent') {
        itemContent.link = `${itemContent.contentUrl}`;
        itemContent.organisation = itemContent[`creatorOrganisation`];
        itemContent.organisationWebsite = itemContent[`creatorOrganisationWebsite`];
        itemContent.imageUrl = itemContent[`creatorOrganisationLogo`];
      }
      return {
        ...itemContent,
        savedItemid: item.id,
      };
    });
  };

  return (
    <>
      <InitialValidations />
      <SavedItemsComponent
        isLoading={isLoading}
        folders={userSavedItemsFolders}
        createEditAFolder={createEditAFolder}
        currentFolder={currentFolder}
        isLoadingFolder={isLoadingFolder}
        savedItems={savedItems}
        changeFolder={changeFolder}
        removeFromFolder={removeFromFolder}
        deleteAFolder={deleteAFolder}
        loadFolderItems={loadFolderItems}
        filtersResources={filtersResources}
        setFiltersResources={setFiltersResources}
        finalHits={finalHits}
      />
      <GeneralTooltips />
      <SaveItemPopup
        saveItemsPageFunctions={{
          currentFolder,
          loadFolderItems,
          updateFolders,
        }}
      />
    </>
  );
};

export default withAuthenticationRequired(SavedItems, {
  onRedirecting: () => <Loading />,
});
