import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomTooltip from '../../../../Common/CustomToolTip';
import useEmailComponentFunctions from '../../CrmEmails/useEmailComponentFunctions';
import useValidateContactsOversubscribed from '../../../utilities/useValidateContactsOversubscribed';

function SendTooltipOnTrial() {
  const navigate = useNavigate();

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  const { isLoading, saveEmail } = useEmailComponentFunctions();

  return (
    <CustomTooltip
      content={
        <div className='send-email-trial-tooltip'>
          <p className='line-height-1-5 mb-0 p-3'>Sign up to a Mogul or Enterprise subscription to send emails</p>
          <div className='trial-tooltip-separator' />
          <div className='d-block text-center py-3'>
            <span
              className='px-3 py-2 action-button general-button position-relative d-inline-block'
              onClick={async () => {
                const res = await validateContactsOversubscribed(
                  async () =>
                    await saveEmail({
                      isForSending: false,
                      returnResult: true,
                      notRedirect: true,
                    }),
                  'save_email'
                );
                if (!isLoading && res) {
                  navigate(`/pricing?returnUrl=${encodeURIComponent(`influence/emails/${res?.crmEmailId}`)}`, {
                    replace: true,
                  });
                }
              }}
            >
              Compare plans
            </span>
          </div>
        </div>
      }
      showDelay={200}
      hideDelay={500}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
      />
    </CustomTooltip>
  );
}

export default SendTooltipOnTrial;
